import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { TextField, Typography} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useEffect } from 'react';
import {API_URL} from '../constant';
// ----------------------------------------------------------------------
const phoneRegExp = /^([0-9]){10}?$/
const validationSchema = yup.object({
  name: yup.string().required('field cannot be empty'),
  address: yup.string().required('field cannot be empty'),
  company_type: yup.string().required('field cannot be empty'),
  city: yup.string().required('field cannot be empty'),
  website: yup.string(),
  cin: yup.string(),
  primary_email: yup.string().required('Email is required'),
  secondary_email: yup.string(),
  primary_mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('field cannot be empty'),
  secondary_mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid')

});

export default function ViewCompany() {
    const { id } = useParams()
    const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      company_type:'',
      address:'',
      primary_email:'',
      secondary_email:'',
      primary_mobile:'',
      secondary_mobile:'',
      city:'',
      website:'',
      cin:'',
    },
    validationSchema,
    onSubmit: async (value) => {
      const response = await axios.post(`${API_URL}/company/update/${id}`, value);
      if(response.data.status === 'success'){
        navigate('/dashboard/companylist', { replace: true });
      }
    },
  });
  const getData = async () => {
    const data = await axios.get(`${API_URL}/company/${id}`)
    if(data?.data?.status === 'success'){
        const datas = data.data.data;
        formik.setFieldValue('name', datas.name)
        formik.setFieldValue('company_type', datas.company_type)
        formik.setFieldValue('address', datas.address)
        formik.setFieldValue('primary_email', datas.primary_email)
        formik.setFieldValue('secondary_email', datas.secondary_email)
        formik.setFieldValue('primary_mobile', datas.primary_mobile)
        formik.setFieldValue('secondary_mobile', datas.secondary_mobile)
        formik.setFieldValue('city', datas.city)
        formik.setFieldValue('website', datas.website)
        formik.setFieldValue('cin', datas.cin)
    }
  
}
  useEffect(()=>{
  getData()
  },[])
    return (
    <div>
      <Typography variant="h4" gutterBottom>
              View Company Detail
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={11} sm={6}>
          <TextField
            autoComplete='off'
            fullWidth
            name="name"
            type={'text'}
            label="Company Name"
            disabled={true}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          </Grid>
          <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="company_type"
              type={'text'}
              label="Company Type"
              disabled={true}
              value={formik.values.company_type}
              onChange={formik.handleChange}
              error={formik.touched.company_type && Boolean(formik.errors.company_type)}
              helperText={formik.touched.company_type && formik.errors.company_type}
            />
          </Grid>
          <Grid item xs={11} sm={6}>
          <TextField
            autoComplete='off'
            fullWidth
            name="address"
            type={'text'}
            label="Address"
            disabled={true}
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
          </Grid>
          <Grid item xs={11} sm={6}>
          <TextField
              fullWidth
              autoComplete='off'
              name="primary_email"
              type={'email'}
              label="Primary Email address"
              disabled={true}
              value={formik.values.primary_email}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.primary_email && formik.errors.primary_email)}
              helperText={formik.touched.primary_email && formik.errors.primary_email}
            /> 
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
                fullWidth
                autoComplete='off'
                name="secondary_email"
                type={'email'}
                label="Secondary Email address"
                disabled={true}
                value={formik.values.secondary_email}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.secondary_email && formik.errors.secondary_email)}
                helperText={formik.touched.secondary_email && formik.errors.secondary_email}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="primary_mobile"
              type={'number'}
              label="Primary Mobile"
              disabled={true}
              value={formik.values.primary_mobile}
              onChange={formik.handleChange}
              error={formik.touched.primary_mobile && Boolean(formik.errors.primary_mobile)}
              helperText={formik.touched.primary_mobile && formik.errors.primary_mobile}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="secondary_mobile"
              type={'number'}
              label="Secondary Mobile"
              disabled={true}
              value={formik.values.secondary_mobile}
              onChange={formik.handleChange}
              error={formik.touched.secondary_mobile && Boolean(formik.errors.secondary_mobile)}
              helperText={formik.touched.secondary_mobile && formik.errors.secondary_mobile}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="city"
              type={'text'}
              label="City"
              disabled={true}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="website"
              type={'text'}
              label="Website"
              disabled={true}
              value={formik.values.website}
              onChange={formik.handleChange}
              error={formik.touched.website && Boolean(formik.errors.website)}
              helperText={formik.touched.website && formik.errors.website}
            />
            </Grid> 
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="cin"
              type={'text'}
              label="Cin No"
              disabled={true}
              value={formik.values.cin}
              onChange={formik.handleChange}
              error={formik.touched.cin && Boolean(formik.errors.cin)}
              helperText={formik.touched.cin && formik.errors.cin}
            />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}