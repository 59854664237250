import {filter} from 'lodash';
import {useEffect,useState} from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Link,
  Tooltip
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead,UserListToolbar,UserMoreMenu} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL,TYPE,STATE} from '../constant';
import Styles from '../css/filter.module.css'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import moment from 'moment'

// ----------------------------------------------------------------------

let TABLE_HEAD = [
  {id: 'type',label: 'Type',alignRight: false},
  {id: 'created_at',label: 'Date',alignRight: false},
  {id: 'customer_name',label: 'Name',alignRight: false},
  {id: 'invoice_number',label: 'Invoice Number',alignRight: false},
  {id: 'totel_items',label: 'Total Items',alignRight: false},
  {id: 'amount_without_gst',label: 'Amount Without Gst',alignRight: false},
  {id: 'discount_amount',label: 'Discount Amount',alignRight: false},
  {id: 'total_amount',label: 'Total Amount',alignRight: false},
  {id: 'state',label: 'State',alignRight: false},
];

// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
  if(b[orderBy] < a[orderBy]) {
    return -1;
  }
  if(b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order,orderBy) {
  return order === 'desc'
    ? (a,b) => descendingComparator(a,b,orderBy)
    : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
  const stabilizedThis = array.map((el,index) => [el,index]);
  stabilizedThis.sort((a,b) => {
    const order = comparator(a[0],b[0]);
    if(order !== 0) return order;
    return a[1] - b[1];
  });
  if(query) {
    return filter(array,(_user) => _user?.customer_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
let customerName = ''
let invoiceNumber=''
let type=''
export default function SalesList() {
  const [page,setPage] = useState(0);
  const [value,setValue] = useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [order,setOrder] = useState('asc');
  const [selected,setSelected] = useState([]);
  const [orderBy,setOrderBy] = useState('name');
  const [rowsPerPage,setRowsPerPage] = useState(10);
  const navigate=useNavigate()

  const handleRequestSort = (event,property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    setSelected([]);
  };

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/sales`);
      setValue(res.data.data);
      setFilteredValue(res.data.data)
    }
    if(isMobile){
      TABLE_HEAD.length = 3;
    }else{
      TABLE_HEAD.length = 9;
    }
    
    get()
  },[]);

  const handleChangePage = (event,newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value,10));
    setPage(0);
  };

  const handleFilter = (e) => {
    if(e.target.name === 'customer_name'){
      customerName = e.target.value
    }
    else if(e.target.name === 'invoice_number'){
      invoiceNumber=e.target.value
    }
    else if(e.target.name === 'type'){
      type=e.target.value
    }
    filteredValue = value.filter(v => ((v?.customer_name || '').toLowerCase()).includes((customerName).toLowerCase()) && ((v?.invoice_number || '').toLowerCase()).includes((invoiceNumber).toLowerCase())&& ((v?.type || '').toLowerCase()).includes((type).toLowerCase()))
    setFilteredValue([...filteredValue])
  };

  const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(filteredValue,getComparator(order,orderBy));
  const isUserNotFound = value.length === 0;

  const download=(type,id)=>{
    switch (type) {
      case "quotation":navigate(`/dashboard/download_quotation/${id}`)
        break;
        case "invoice":navigate(`/dashboard/salesInvoice/${id}`)
        break;
      default:
        break;
    }
  }

 

  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4"  gutterBottom>
            Sales List
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/add_sales_quotation" underline="none" style={{paddingRight:'10px'}}>
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-cart"></i> Add Quotation</LoadingButton>
          </Link>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/addsales" underline="none">
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-cart-plus"></i> Add Invoice</LoadingButton>
          </Link>
          </Stack>
          </Stack>
        
        <Card >
          <Scrollbar>
            <TableContainer sx={{minWidth:100}}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  <TableRow>
                  <TableCell align="left"><TextField className={Styles.filter} name = 'type' onChange={handleFilter} autoComplete='off' /></TableCell>
                  <TableCell></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name = 'customer_name' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <Tooltip title={'Search Invoice or Quotation Number'} placement="right-start" arrow><TableCell align="left"><TextField className={Styles.filter} name='invoice_number' onChange={handleFilter} autoComplete='off' /></TableCell></Tooltip>
                  </TableRow>
                  {filteredUsers.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage).map((v,index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                      ><TableCell style={{width:'90px'}}>
                          {v.type==='return' ? <Button color="error" variant="contained" size="small" style={{marginLeft: '4px',color: 'white',width: '95px'}} > {v?.type?.toUpperCase()} </Button>: v.type === 'quotation' ?<Button variant="contained" size="small" style={{marginLeft: '4px',color: 'white',backgroundColor: '#00B4D8',width: '95px'}} onClick={()=>download("quotation",v?.sales_id)}>  {v?.type?.toUpperCase()} </Button> : <Button color="success" variant="contained" size="small" style={{marginLeft: '4px',color: 'white',width: '95px'}} onClick={()=>download("invoice",v?.sales_id)}> {v?.type?.toUpperCase()} </Button> }
                        </TableCell>
                        <TableCell align="left" style={{width:'120px'}}>{moment(v?.created_at).format('DD-MM-YYYY')}</TableCell>
                        <TableCell align="left" style={{width:'210px'}}>{v?.customer_name}</TableCell>
                        <TableCell align="left">{v?.invoice_number}</TableCell>
                        {!isMobile && <>
                        <TableCell align="left">{v?.totel_items}</TableCell>
                        <TableCell align="left">{v?.amount_without_gst}</TableCell>
                        <TableCell align="left" style={{width:'90px'}}>{v?.discount_amount}</TableCell>
                        <TableCell align="left">{v?.total_amount}</TableCell>
                        <TableCell align="left" style={{width:'90px'}}>{v?.state}</TableCell>
                        </>}
                        <TableCell align="right">
                          {v?.state === STATE.DRAFT && v?.type === TYPE.QUOTATION && <UserMoreMenu linkTo={`/dashboard/quotation/${v?.sales_id}/edit`}
                            view={`/dashboard/sales_detail/${v.sales_id}`}
                            invoice={`/dashboard/download_quotation/${v.sales_id}`} />}
                          {v?.state === STATE.DRAFT && v?.type !== TYPE.QUOTATION && <UserMoreMenu linkTo={`/dashboard/sales/${v?.sales_id}/edit`}
                            view={`/dashboard/sales_detail/${v?.sales_id}`} />}
                          {v?.state === STATE.SUBMIT && v?.type === TYPE.QUOTATION && <UserMoreMenu invoice={`/dashboard/download_quotation/${v?.sales_id}`}
                            convertInvoice={`/dashboard/sales/${v?.sales_id}/edit`} />}
                          {v?.state===STATE.SUBMIT && v?.type === TYPE.INVOICE && <UserMoreMenu linkTo={`/dashboard/sales/${v?.sales_id}/edit`}
                            invoice={`/dashboard/salesInvoice/${v?.sales_id}`}
                            list={`/dashboard/sales_detail/${v?.sales_id}`} 
                            insurance={`/dashboard/sales_insurance_detail/${v?.sales_id}`}
                            transaction={`/dashboard/transactions/${v?.sales_id}/Sales`}
                            cancel={`/dashboard/sales_detail/${v?.sales_id}`}
                            />}
                            {v?.state===STATE.SUBMIT && v?.type === TYPE.RETURN && <UserMoreMenu
                            list={`/dashboard/sales_detail/${v?.sales_id}`}
                            transaction={`/dashboard/transactions/${v?.sales_id}/Sales`}
                            
                            />}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5,10,25]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
