import PropTypes from 'prop-types';
// material
import { Card, Typography, CardHeader, CardContent, Button } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot
} from '@mui/lab';
// utils
import { fDateTime } from '../../../utils/formatTime';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, STATE, TYPE } from '../../../constant';
import {Link, useNavigate} from 'react-router-dom';

// ----------------------------------------------------------------------

let TIMELINES = [];

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

function OrderItem({ item, isLast }) {
  const { type, title, time } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor:
              (type === 'order1' && 'primary.main') ||
              (type === 'order2' && 'success.main') ||
              (type === 'order3' && 'info.main') ||
              (type === 'order4' && 'warning.main') ||
              'error.main'
          }}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fDateTime(time)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default function AppOrderTimeline(props) {
  const [salesData,setSalesData]=useState([])
  const [purchaseData,setPurchaseData]=useState([])
  const navigate=useNavigate()

  const timelines = async() => {
    
    if(props.title === 'Purchase Timeline'){
      const res1= await axios.get(`${API_URL}/purchase`);
      let data1 = res1?.data?.data
      data1 = data1.filter(d=>d.type === TYPE.BILL && d.state === STATE.SUBMIT)
      data1.sort(function(a, b) { 
        return a.purchase_id - b.purchase_id;
      });
      data1.reverse()
      if(data1.length > 4){
        data1.length=5
      }
      const purchaseArray = data1.map((d,i)=> {
        return {
          title: `Purchase of amount ₹ ${Math.round(d?.total_amount)} from ${d?.vendor_name}`,
          time: d?.created_at,
          type: `order${i+1}`,
        }
      })
     setPurchaseData([...purchaseArray]);
    }
    else if(props.title === 'Sales Timeline'){
      const res= await axios.get(`${API_URL}/sales`);
      let data = res?.data?.data
      data = data.filter(d=>d.type === TYPE.INVOICE && d.state === STATE.SUBMIT)
      data.sort(function(a, b) { 
        return a.sales_id - b.sales_id;
      });
      data.reverse()
      if(data.length > 4){
        data.length=5
      }
      const salesArray = data.map((d,i)=> {
        return {
          title: `Sale of  amount ₹ ${Math.round(d?.total_amount)} to ${d?.customer_name}`,
          time: d?.created_at,
          type: `order${i+1}`,
        }
      })
     setSalesData([...salesArray]);
    }
  }

  useEffect(() =>{
    timelines()
  },[])

  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        }
      }}
    >
      <CardHeader title={props.title}/>
      <CardContent>
        <Timeline>
          {Boolean(salesData.length) && props.title === 'Sales Timeline' &&
          salesData?.map((item, index) => (
            <OrderItem key={item.sales_id} item={item} isLast={index === TIMELINES.length - 1} />
          ))
          }
          {!Boolean(salesData.length) && props.title === 'Sales Timeline' &&
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px'}}>
            <div style={{textAlign: 'center'}}>
           <Typography style={{textAlign:'center',fontWeight:'bold'}}> No Sales Done Yet</Typography>
           <Link to={'/dashboard/addsales'} style={{textDecoration: 'none'}}>
            Add Sales <i className="bi bi-plus-circle-fill"></i>
           </Link>
            </div>
          </div>
          }
          { Boolean(purchaseData.length) && props.title === 'Purchase Timeline' &&
          purchaseData?.map((item, index) => (
            <OrderItem key={item.purchase_id} item={item} isLast={index === TIMELINES.length - 1} />
          ))
          }
          {!Boolean(purchaseData.length) && props.title === 'Purchase Timeline' &&
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px'}}>
           <div style={{textAlign: 'center'}}>
          <Typography style={{textAlign:'center',fontWeight:'bold'}}> No Purchase Done Yet</Typography>
          <Link variant="subtitle2" to='/dashboard/purchase_bill' style={{textDecoration: 'none'}}>
           Add Purchase <i className="bi bi-plus-circle-fill"></i>
          </Link>
           </div>
         </div>
          }
        </Timeline>
      </CardContent>
    </Card>
  );
}
