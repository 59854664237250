import * as yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {Button, Input, MenuItem,Stack,styled,TextField,Tooltip,Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, NumberToWordConverter} from '../constant';
import {useContext,useEffect,useState} from 'react';
import {LoginContext} from '../context/logincontext'
import ConfirmDialog from '../components/confirmDialog';
import * as XLSX from 'xlsx';
import Iconify from '../components/Iconify';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

const validationSchema = yup.object({
  part_name: yup.string().required('field cannot be empty'),
  brand: yup.string().required('field cannot be empty'),
  price: yup.string().required('field cannot be empty'),
  min_qty: yup.string().required('field cannot be empty'),
  units: yup.string().required('field cannot be empty'),
  qty: yup.string().required('field cannot be empty'),
});

export default function AddParts(props) {
  const context = useContext(LoginContext)
  const { enqueueSnackbar } = useSnackbar();
  const [vendorId,setVendorId] = useState([])
  const navigate = useNavigate();
  const [vendor,setValue] = useState([]);
  const [userData,setUserData] = useState(context.user)
  const [isProductIdExist,setIsProductIdExist] = useState(false)
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const [csvData, setCsvData] = useState([]);
  const [file,setFile] = useState()
  
  const formik = useFormik({
    initialValues: {
      product_id: '',
      vendor_name: '',
      part_name: '',
      gst_percentage: '',
      hsn: '',
      units: 'units',
      min_qty: '',
      brand: '',
      price: '',
      cost_price: '',
      description: '',
      qty: 0,
      user_id: context?.user?.user_id,
      category:''
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },

  });
  async function submitForm(value) {
    //value.vendor_id=vendorId
    value.user_id = userData.user_id
    const response = await axios.post(`${API_URL}/parts`,value);
    if(response.data.status === 'success') {
      enqueueSnackbar(`Model data is Saved.`, { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
      if(props?.open){
        props.close()
      }else{
        navigate('/dashboard/products',{replace: true});
      }
    }
    else if(response.data.status === 'fail') {
      // alert("Model Id Already exists")
      setIsProductIdExist(true)
      setTimeout(()=>{
      setIsProductIdExist(false)
      },2000)
    }
  }
  // const handleVendorChange=(e)=>{
  //   formik.setFieldValue('vendor_name',e.target.value)
  //   const vendorData = vendor.filter(v=>v.vendor_name==e.target.value)
  //   setVendorId(vendorData[0]['vendor_id'])
  // }

  const getFileDownLoad = (type) => {
    return `${API_URL}/csv_template/${type}/download`
  }
///////////////////////////////Submit Csv Bulk Data Convert Data Process////////////////////
  const uploadCsvFile = async () => {
    let partData = []
    for(let part of csvData){
      partData.push({...part,user_id:userData.user_id})
    }
    const response = await axios.post(`${API_URL}/parts_bulk_data`,partData);
    if(response.data.status === 'success'){
      enqueueSnackbar(`Bulk product data file Uploaded `, { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
      if(props?.open){
        props.close()
      }else{
        navigate('/dashboard/products',{replace: true});
      }
    }
  }

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/vendor`);
      setValue(res.data.data);

    }
    get()
  },[]);
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  ///////////////////////////////Upload Csv Bulk Data Convert Data Process////////////////////
  var i=2
  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
 
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
 
        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
 
    // prepare columns list from headers
    // const columns = headers.map(c => ({
    //   name: c,
    //   selector: c,
    // }));
    let productData = []
        for (let product of list){
            if(product.part_name && product.product_id){
                productData.push(product)
                i = i+1
            }else{
                enqueueSnackbar(`In Uploaded file row number : ${i} have some column is empty check ! `, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'}});
                i = i+1
            }
        }
 
    setCsvData(productData);
  }
 
  // handle file upload
  const handleFileUpload = e => {
    setFile(Object.values(e.target.files))
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  }
  const Input = styled('input')({
    display: 'none',
  });

  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography style={{paddingLeft:'20px'}} variant="h4" gutterBottom>
        Add Model
      </Typography>
      
      <div style={{display: 'block',justifyContent: 'center'}}>        
        <Typography style={{textAlign: 'center',fontSize:'15px',paddingRight: '50px'}}><Iconify id={'Download Template'} icon="entypo:download" aria-label="download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',marginLeft: '30px',verticalAlign: 'middle'}} style={{marginRight:'10px'}}  onClick={() => window.open(getFileDownLoad('productCsvTemp'),'_blank')} /> Download Template</Typography> 
               <label htmlFor="import_product" style={{marginLeft:'20px'}}>
              <Input
              id="import_product"
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={handleFileUpload}
            />
              <Typography style={{textAlign: 'center',fontSize:'15px',paddingRight: '50px',paddingLeft:'10px'}}> <Iconify icon={"entypo:upload"} sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle'}} style={{marginRight:'10px'}} />   Import Model</Typography>
                </label>
        {file?.map(doc => <span style={{fontSize: '10px',marginLeft:'80px',marginRight:'30px'}}>{doc.name}, </span>)}
        {!isEmpty(file) && <LoadingButton
                style={{width:'100px',marginLeft:'10px'}}
                size="small"
                type="button"
                variant="contained"
                onClick={()=>uploadCsvFile()}
              >
                Upload Data
              </LoadingButton>}
              
        </div>
      
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='center'>
           
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="product_id"
                label="Model ID"
                value={formik.values.product_id}
                onChange={formik.handleChange}
                error={isProductIdExist && 'this product id already exist'}
                helperText={isProductIdExist && 'this product id already exist'}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="part_name"
                label="Model Name *"
                value={formik.values.part_name}
                onChange={formik.handleChange}
                error={formik.touched.part_name && Boolean(formik.errors.part_name)}
                helperText={formik.touched.part_name && formik.errors.part_name}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="units"
                label="Units/Kg/Litre/ *"
                value={formik.values.units}
                onChange={formik.handleChange}
                error={formik.touched.units && Boolean(formik.errors.units)}
                helperText={formik.touched.units && formik.errors.units}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                id="category"
                name="category"
                label="Category"
                value={formik.values.category}
                onChange={formik.handleChange}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helpertext={formik.touched.category && formik.errors.category}
              >
                <MenuItem value={'L3'}>L3</MenuItem>
                <MenuItem value={'L5'}>L5</MenuItem>
                <MenuItem value={'Spare Part'}>Spare Part</MenuItem>
                <MenuItem value={'Service'}>Service</MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="qty"
                label="Quantity *"
                value={formik.values.qty}
                onChange={formik.handleChange}
                error={formik.touched.qty && Boolean(formik.errors.qty)}
                helperText={formik.touched.qty && formik.errors.qty}
              />
            </Grid> */}
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="min_qty"
                label="Min Qty *"
                value={formik.values.min_qty}
                onChange={formik.handleChange}
                error={formik.touched.min_qty && Boolean(formik.errors.min_qty)}
                helperText={formik.touched.min_qty && formik.errors.min_qty}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="brand"
                label="Brand *"
                value={formik.values.brand}
                onChange={formik.handleChange}
                error={formik.touched.brand && Boolean(formik.errors.brand)}
                helperText={formik.touched.brand && formik.errors.brand}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="price"
                label="Selling Price *"
                value={formik.values.price}
                onChange={formik.handleChange}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={Boolean(formik.values.price) && NumberToWordConverter.convert(formik.values.price)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="gst_percentage"
                label="GST %"
                value={formik.values.gst_percentage}
                onChange={formik.handleChange}
                error={formik.touched.gst_percentage && Boolean(formik.errors.gst_percentage)}
                helperText={formik.touched.gst_percentage && formik.errors.gst_percentage}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="hsn"
                label="HSN/SAC"
                value={formik.values.hsn}
                onChange={formik.handleChange}
                error={formik.touched.hsn && Boolean(formik.errors.hsn)}
                helperText={formik.touched.hsn && formik.errors.hsn}
              />
            </Grid>
           
            
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="cost_price"
                label="Cost Price"
                value={formik.values.cost_price}
                onChange={formik.handleChange}
                error={formik.touched.cost_price && Boolean(formik.errors.cost_price)}
                helperText={Boolean(formik.values.cost_price) && NumberToWordConverter.convert(formik.values.cost_price)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}

              />
            </Grid>
          </Grid>
        </Box>
        {/* <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot gst_percent?
          </Link> */}
        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
