// material
import {alpha,styled} from '@mui/material/styles';
import {Card,Link,Typography} from '@mui/material';
// utils
import {fShortenNumber} from '../../../utils/formatNumber';
//
import Iconify from '../../../components/Iconify';
import {useEffect,useState} from 'react';
import axios from 'axios';
import {API_URL} from '../../../constant';
import {LoadingButton} from '@mui/lab';
import {Link as RouterLink} from 'react-router-dom';
import {get} from 'lodash';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5,0),
  color: '#3F0071',
  backgroundColor: '#EDD2F3'
}));

const IconWrapperStyle = styled('div')(({theme}) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: '#3F0071',
  backgroundImage: `linear-gradient(135deg, ${alpha('#8E05C2',0)} 0%, ${alpha(
    '#8E05C2',
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------


export default function NetBalance() {
  const [total,setTotal] = useState(0)
  const [value,setValue] = useState([])
  useEffect(async() => {
      const finance = await axios.get(`${API_URL}/finance_ledger`);
      // ["dealerSecurityPaymentData","dealerTALedgerData","rcPaymentData","allLedgerBalance"]
      if(finance.data.status === 'success') {
        const financeLedger = get(finance, 'data.data.dealerTALedgerData', []);
        setValue([...financeLedger]);
      }
  },[]);
  const adjustAmount = (amount) => {
    if(amount) {
      let x = amount;
      x = x?.toString();
      let lastThree = x?.substring(x.length - 3);
      let otherNumbers = x?.substring(0,x.length - 3);
      if(otherNumbers !== '')
        lastThree = ',' + lastThree;
      let res = otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g,",") + lastThree;
      return res
    }
  }
  return (
    <RootStyle>
      <IconWrapperStyle>
        <i className="bi bi-wallet2" style={{fontSize: '30px'}}></i>
      </IconWrapperStyle>
      <div style={{display: 'inline-flex'}}>
        <Typography variant="h3">{adjustAmount((Math.abs(value[value?.length-1]?.balance))) || 0}</Typography><Typography variant="subtitle1" sx={{opacity: 0.72}} style={{paddingTop: '15px',paddingLeft: '10px'}}>
          {adjustAmount(value[value?.length-1]?.balance) ? value[value?.length-1]?.balance < 0 ? ' DR' : ' CR' : 'Balance'}
        </Typography>
      </div>
      <Link style={{display: 'block'}} variant="subtitle1" component={RouterLink} to='/dashboard/loan_ledger' underline="none">
        <LoadingButton style={{color: '#3F0071',backgroundColor: 'inherit',border: 'none',boxShadow: '0 0 10px #3F0071'}}>Net Balance</LoadingButton>
      </Link>
    </RootStyle>
  );
}
