import * as yup from 'yup';
import {useEffect, useState} from 'react';
import {useFormik,Form,FormikProvider} from 'formik';
import {useNavigate} from 'react-router-dom';
// material
import {Stack,TextField,IconButton,InputAdornment} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import {API_URL,MOBILE_REGEX,NAME_REGEX} from '../../../constant';
import axios from 'axios';
import { isEmpty } from 'lodash';
import CustomizedSnackbars from '../../../pages/Alert/CustomizedSnackbars';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword,setShowPassword] = useState(false);
  const [otpSent,setOtpSent] = useState(false)
  const [otpSubmitted,setOtpSubmitted] = useState(false)
  const [confirmPassword,setConfirmPassword] = useState(false)
  const [message,setMeassage] = useState('')
  const [severity,setSeverity] = useState('')
  const [isOpen,setIsOpen] = useState(false)
  const RegisterSchema = yup.object().shape({
    user_name: yup.string().matches(NAME_REGEX,'Enter the valid number').required('This field is required'),
    mobile: yup.string().matches(MOBILE_REGEX,'Phone number is not valid').required('field cannot be empty'),
    otp: yup.number().min(6,'otp should be atleast 6 digits'),
    password: yup.string().min(8,'password should not be less than 8')
  });

  const formik = useFormik({
    initialValues: {
      user_name: '',
      mobile: '',
      otp: '',
      password: '',
      confirm_password: '',
      updated_by_name:'',
      personal_mobile:''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (value) => {
      value={...value,updated_by_name:'evcred',personal_mobile:value.mobile}
      if(otpSubmitted){
        if(value.password === value.confirm_password){
          const response = await axios.post(`${API_URL}/register`,{data: JSON.stringify(value)});
          if(response.data.status === 'success') {
            navigate('/login',{replace: true}); 
          }
        }else{
          setConfirmPassword(true) 
        }
      }else if(otpSent){
        const response = await axios.post(`${API_URL}/register`,{data: JSON.stringify(value)});
        if(response.data.status === 'success'){
          setOtpSubmitted(true)
          setOtpSent(false)
        }else{
          setMeassage(response.data.status)
          setSeverity('info')
          setIsOpen(true);
        }
      }else{
        const response = await axios.post(`${API_URL}/register`,{data: JSON.stringify(value)});
        if(response.data.status !== 'success'){
          setMeassage(response.data.status)
          setSeverity('info')
          setIsOpen(true);
        }
        else{
          setMeassage('otp sent your mobile number is successfully')
          setSeverity('success')
          setIsOpen(true);
          setOtpSent(true)
        }
      }
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const closeAlert = () => {
    setIsOpen(false);
    setMeassage('')
    setSeverity('')
  };


  const {errors,touched,handleSubmit,isSubmitting,getFieldProps} = formik;

  return (
    <>
    {isOpen && <CustomizedSnackbars open={isOpen} onClose={closeAlert} message={message} severity={severity} close={closeAlert}/>}
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Full Name"
            disabled={otpSent || otpSubmitted}
            name='user_name'
            value={formik.values.user_name}
            onChange={formik.handleChange}
            error={Boolean(touched.user_name && errors.user_name)}
            helperText={touched.user_name && errors.user_name}
          />
          <TextField
            autoComplete='off'
            fullWidth
            disabled={otpSent || otpSubmitted}
            name="mobile"
            inputProps={{maxLength: 10}}
            label="Mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
          />
          {otpSent &&
            <TextField
              autoComplete='off'
              fullWidth
              name="otp"
              type={'number'}
              inputProps={{maxLength: 6}}
              label="OTP"
              value={formik.values.otp}
              onChange={formik.handleChange}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
            />
          }
          {otpSubmitted &&
            <>
              <TextField
                fullWidth
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                name="password"
                label="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={String(formik.values.password).length < 8}
                helperText={String(formik.values.password).length < 8 && 'Password cannot be less than 8 characters'}
              />
              <TextField
                fullWidth
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                name="confirm_password"
                label="Confirm Password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={!isEmpty(formik.values.confirm_password) && !(formik.values.password ===formik.values.confirm_password)}
                helperText={!isEmpty(formik.values.confirm_password) && !(formik.values.password ===formik.values.confirm_password) && 'password does not match'}
              />
            </>
          }
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
    </>
  );
}
