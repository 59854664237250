import Footer from './Footer';
import { Paper } from '@mui/material';
// ----------------------------------------------------------------------

export default function Terms() {

  return (<>
  <div style={{boxSizing:'border-box'}}>
    <Paper style={{position:'relative',textAlign:'center',color:'black',paddingTop:'100px'}}>
      <h3 style={{fontSize:'28px'}}>Terms & Conditions</h3>
      <div style={{marginTop:'20px',textAlign:'left',maxWidth:'1000px',width:'100%',paddingLeft:'15px',paddingRight:'15px',marginLeft:'auto',marginRight:'auto',display:'block'}}>
      <h3 style={{fontSize:'28px'}}>Website Terms of Use</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
            PLEASE READ THESE WEBSITE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE (HEREINAFTER 'WEBSITE'). THESE WEBSITE TERMS OF USE (HEREINAFTER 'TERMS OF USE') GOVERN YOUR ACCESS TO AND USE OF THE WEBSITE. THE WEBSITE IS AVAILABLE FOR YOUR USE ONLY ON THE CONDITION THAT YOU AGREE TO THE TERMS OF USE SET FORTH BELOW. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF USE, DO NOT ACCESS OR USE THE WEBSITE. BY ACCESSING OR USING THE WEBSITE, YOU AND THE ENTITY YOU ARE AUTHORISED TO REPRESENT (HEREINAFTER 'YOU' OR 'YOUR') SIGNIFY YOUR AGREEMENT TO BE BOUND BY THE TERMS OF USE.
        </p>

        <h3 style={{fontSize:'28px'}}>User Eligibility</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
            The Web Site is provided by EVCred and available only to entities and persons who have reached the age of legal majority and are competent to enter into a legally binding agreement(s) under the applicable law. If You do not qualify, You are not permitted to use the Web Site.
        </p>

        <h3 style={{fontSize:'28px'}}>Scope Of Terms Of Use</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        These Terms of Use govern Your use of the Website and all applications, software and services (collectively known as "Services") available via the Web Site, except to the extent that such Services are the subject of a separate agreement. Specific terms or agreements may apply to the use of certain Services and other items provided to You via the Web Site ("Service Agreement(s)"). Any such Service Agreements will accompany the applicable Services or are listed in association therewith or via a hyperlink associated therewith.
        </p>

        <h3 style={{fontSize:'28px'}}>Modifications</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        EVCred may revise and update these Terms of Use at any time. Your continued usage of the Web Site after any changes to these Terms of Use will be deemed as acceptance of such changes. Any aspect of the Web Site may be changed, supplemented, deleted or updated without notice, at the sole discretion of EVCred. EVCred may also change or impose fees for products and services provided through the Web Site at any time, at its sole discretion. EVCred may establish or change, at any time, general practices and restrictions concerning other EVCred products and services at its sole discretion.
        </p>

        <h3 style={{fontSize:'28px'}}>EVCred Privacy Notice</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        With respect to any individual whose personal information is provided by You to EVCred, You represent to EVCred that You have obtained all necessary consents for the processing of such personal information contemplated by the Services.
        </p>

        <h3 style={{fontSize:'28px'}}>Licence and Ownership</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        Any and all intellectual property rights ("Intellectual Property") associated with the Web Site and its contents (the "Content") are the sole property of EVCred, its affiliates or third parties. The Content is protected by Intellectual Property and other laws both in India and other countries. Elements of the Web Site are also protected by trade name, trade secret, unfair competition, and other laws and may not be copied or imitated in whole or in part. All customised graphics, icons, and other items that appear on the Web Site are trademarks, service marks or trade name ("Marks") of EVCred, its affiliates or other entities that have granted EVCred the right and licence to use such Marks and may not be used or interfered with in any manner without the express written consent of EVCred. Except as otherwise expressly authorised by these Terms of Use, You may not copy, reproduce, modify, amend, lease, loan, sell and/or create derivative works from, upload, transmit, and/or distribute the Intellectual Property of the Web Site in any way without EVCred's prior written permission or that of an appropriate third party. Except as expressly provided herein, EVCred does not grant to You any express or implied rights to the Intellectual Property of EVCred or that of any third party.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        EVCred hereby grants You a limited, personal, non-transferable, non-sublicensable, revocable licence to (a) access and use only the Web Site, Content and Services only in the manner presented by EVCred, and (b) access and use the EVCred computer and network services offered within the Web Site (the "EVCred Systems") only in the manner expressly permitted by EVCred. Except for this limited license, EVCred does not convey any interest in or to the EVCred Systems, information or data available via the EVCred Systems (the "Information"), Content, Services, Web Site or any other EVCred property by permitting You to access the Web Site. Except to the extent required by law or as expressly provided herein, none of the Content and/or Information may be reverse-engineered, modified, amended, reproduced, republished, translated into any language or computer language, re-transmitted in any form or by any means, resold or redistributed without the prior written consent of EVCred. You may not make, sell, offer for sale, modify, amend, reproduce, display, publicly perform, import, distribute, retransmit or otherwise use the Content in any way unless expressly permitted to do so by EVCred.
        </p>

        <h3 style={{fontSize:'28px'}}>Restrictions on Use Of The Website</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        In addition to other restrictions set forth in these Terms of Use, You agree that:
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You shall not disguise the origin of information transmitted through the Web Site.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You will not place false or misleading information on the Web Site.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You will not use or access any service, information, application or software available via the Web Site in a manner not expressly permitted by EVCred.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You will not input or upload to the Web Site any information that may contain viruses, Trojan horses, worms, time bombs or other computer programming routines that are intended to damage, interfere with, intercept or expropriate any system, the Web Site or Information or that infringes the Intellectual Property rights of another.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        Certain areas of the Web Site are restricted to customers of EVCred.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You may not use or access the Web Site or the EVCred Systems or Services in any way that, in EVCred's judgment, adversely affects the performance or function of the EVCred Systems, Services or the Web Site or interferes with the ability of authorised parties to access the EVCred Systems, Services or the Web Site.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You may not frame or utilize framing techniques to enclose any portion or aspect of the Content or the Information, without the express written consent of EVCred.
        </p>

        <h3 style={{fontSize:'28px'}}>Links</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        Outbound Links. The Web Site may contain links to third-party Web Sites and resources (referred to collectively hereinafter as "Linked Sites"). These Linked Sites are provided solely as a convenience to You and not as an endorsement by EVCred of the content of such Linked Sites. EVCred makes no representations or warranties regarding the correctness, accuracy, performance or quality of any content, software, service or application found at any Linked Site. EVCred shall not be responsible for the availability of the Linked Sites or the content or activities of such sites. If You decide to access Linked Sites, You do so at Your own risk. In addition, Your use of Linked Sites is subject to any applicable policies and terms and conditions of use, including but not limited to, the Linked Site's privacy policy.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        Inbound Links. Linking to any page of the Web Site other than to Evcred - Leading Automobile Dealer Management Software through a plain text link is strictly prohibited in the absence of a separate linkage agreement with EVCred. Any website or other devices that link to Evcred - Leading Automobile Dealer Management Software or any page available therein is prohibited from replicating Content, using a browser or border environment around the Content, implying in any fashion that EVCred or any of its affiliates endorse it or its products, misrepresenting any state of facts, including its relationship with EVCred or any of the EVCred affiliates,
        presenting false information about EVCred products or services, and
        using any logo or mark of EVCred or any of its affiliates, without express written permission from EVCred.
        </p>

        <h3 style={{fontSize:'28px'}}>Termination</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You agree that EVCred, at its sole discretion, may terminate or suspend Your use of the Web Site, the EVCred Systems, Information, Services and Content at any time and for any or no reason at its sole discretion, even if access and use continue to be allowed to others. Upon such suspension or termination, You must immediately discontinue Your use of the Web Site, and destroy any copies You may have made of any portion of the Content. Accessing the Web Site, the EVCred Systems, Information or Services after such termination, suspension or discontinuation shall constitute an act of trespass. Furthermore, You agree that EVCred shall not be liable to You or to any third party for any termination or suspension of Your access to the Web Site, the EVCred Systems, Information and/or the Services.
        </p>

        <h3 style={{fontSize:'28px'}}>Disclaimer of Warranties</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        EVCred MAKES NO REPRESENTATIONS ABOUT THE RESULTS TO BE OBTAINED FROM USING THE WEBSITE, THE CRISIL SYSTEMS, THE SERVICES, THE INFORMATION OR THE CONTENT. THE USE OF SAME IS AT YOUR OWN RISK. THE WEBSITE, THE EVCred SYSTEMS, THE INFORMATION, THE SERVICES AND THE CONTENT ARE PROVIDED ON AN "AS IS" BASIS. EVCred, ITS LICENSORS, AND ITS SUPPLIERS, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE. EVCred AND ITS AFFILIATES, LICENSORS AND SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES CONCERNING THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE CONTENT, INFORMATION OR SERVICES PROVIDED ON OR THROUGH THE USE OF THE WEB SITE OR THE EVCred SYSTEMS. NO INFORMATION OBTAINED BY YOU FROM THE WEBSITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED BY EVCred IN THESE TERMS OF USE.
        </p>

        <h3 style={{fontSize:'28px'}}>Limitation of Liability</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        TO THE EXTENT PERMITTED BY APPLICABLE LAW AND TO THE EXTENT THAT EVCred IS OTHERWISE FOUND TO BE RESPONSIBLE FOR ANY DAMAGES, EVCred SHALL BE RESPONSIBLE FOR ACTUAL DAMAGES ONLY. TO THE EXTENT PERMITTED BY LAW, IN NO EVENT SHALL EVCred, ITS AFFILIATES, LICENSORS, SUPPLIERS OR ANY THIRD PARTIES MENTIONED AT THE WEB SITE BE LIABLE FOR ANY INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE AND/OR CONSEQUENTIAL DAMAGES, LOST PROFITS, AND/OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION RESULTING FROM THE USE OF AND/OR INABILITY TO USE THE WEBSITE, THE EVCred SYSTEMS, INFORMATION, SERVICES OR THE CONTENT WHETHER BASED ON WARRANTY, CONTRACT, TORT, DELICT, OR ANY OTHER LEGAL FOUNDATION, AND WHETHER OR NOT EVCred IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT PERMITTED BY LAW, THE REMEDIES STATED FOR YOU IN THESE TERMS OF USE ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY PROVIDED FOR HEREIN.
        </p>

        <h3 style={{fontSize:'28px'}}>Compliance With Law including Export Control</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You agree to use the Web Site in strict compliance with all applicable laws, rulings, and regulations and in a fashion that does not, in the sole judgment of CRISIL, negatively reflect on the goodwill or reputation of CRISIL and You shall take no action which might cause CRISIL to be in breach of any laws, rulings or regulations applicable to EVCred.
        </p>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        CRISIL and the Web Site are based in India. India, the United States and certain other jurisdictions control the export of products and information. You agree to comply with all such applicable restrictions and not to export or re-export the Content (including any software or the Services) to countries or persons prohibited under India or other applicable export control laws or regulations. If You access and download the Content (including any software or the Services) or Information, You represent that You are not in a country where such export is prohibited or are not a person or entity to which such export is prohibited. You are solely responsible for compliance with the laws of Your local jurisdiction and any other applicable laws regarding the import, export, or re-export of the Content (including any software or the Services).
        </p>

        <h3 style={{fontSize:'28px'}}>Governing Law and Jurisdiction</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        To the fullest extent permitted by law, these Terms of Use are governed by the internal laws of India and courts in Mumbai, India will have jurisdiction.
        </p>

        <h3 style={{fontSize:'28px'}}>General</h3>
        <p style={{display:'block',textAlign:'justify',fontSize:'20px',letterSpacing:'0px',lineHeight:'32px',marginBottom:'1rem'}}>
        You may not assign these Terms of Use or any of Your interests, rights or obligations under these Terms of Use. If any provision of these Terms of Use shall be found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force and effect. No waiver of any of these Terms of Use shall be deemed a further or continuing waiver of such term or condition or any other term or condition. You may preserve these Terms of Use in written form by printing them for Your records, and You waive any other requirement for these Terms of Use to be proved by means of a written document.
        </p>
      </div>
    </Paper>
  </div>
  <Footer />
    </>
  );
}
