import { filter, isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
/// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  MenuItem,
  Dialog,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL, NACH_BANK} from '../constant';
import {LoginContext} from '../context/logincontext';
import Styles from '../css/filter.module.css'
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import ConfirmDialog from '../components/confirmDialog';
import { Box } from '@mui/system';
import { useFormik } from 'formik';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'member', label: 'Member Name', alignRight: false },
  { id: 'nach_type', label: 'Nach Type', alignRight: false },
  { id: 'account_name_in_nach_status', label: 'Account Name In Nach Status', alignRight: false },
  { id: 'lotus_api_url', label: 'Link', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'account_no', label: 'Account No.', alignRight: false },
  { id: 'umrn', label: 'UMRN', alignRight: false },
  {id: 'activate', label: 'Activate', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

let member=''
let nachType=''

export default function NachStatusList(props) {
  const loanId = props.loanId
  const data = props.data
  const context = useContext(LoginContext)
  const [page, setPage] = useState(0);
  const[value,setValue]=useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userData,setUserData]=useState(context.user);
  const [open, setOpen] = useState(false)
  const [formvalue, setFormValue] = useState({})
  const [SelectedValue, setSelectedValue] = useState({});
  const [bank,setBank] = useState([])
  const [openConfirmModel,setOpenConfirmModel] = useState(false)
  const formik = useFormik({
    initialValues: {
      customer_name:'',
      bank_code: '',
      bank_account_number: '',
      bank_account_type: '',
      maximum_amount:'',
      loan_id:'',
      dealer_mobile:userData?.mobile||null,
      loan_type:'',
      customer_nach_status_id:null
    },
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
      setOpenConfirmModel(true)
    },
  });

  async function submitForm(value){
    formik.values.customer_name=SelectedValue[0].name;
    formik.values.loan_id=SelectedValue[0].loan_id;
    formik.values.loan_type=SelectedValue[0].loan_type;
    formik.values.relation_id=SelectedValue[0].relation_id;
    formik.values.nach_type=NACH_BANK.IDFC;
    formik.values.created_by= userData.user_id
    formik.values.created_by_name= userData.user_name
    formik.values.updated_by= userData.user_id
    formik.values.updated_by_name=userData.user_name
    try{
      const response = await axios.post(`${API_URL}/loan/e_nach_create_new_source`, formik.values);
      if(response?.data?.status === 'success'){
        alert('Added Successfully')
        setOpen(false)
        setOpenConfirmModel(false)
      }
    }
    catch(err){
      return err
    } 
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    async function get() {
      if(loanId){
        const res= await axios.get(`${API_URL}/loan/nach_status/${loanId}`);
        setValue(res?.data?.data);
        setFilteredValue(res?.data?.data)
      }
    }
    if(isMobile){
      TABLE_HEAD.length = 4
    }else{
      TABLE_HEAD.length = 8
    }
    get()
  },[loanId]);

  useEffect(() => {
    function get() {
        setValue(data);
        setFilteredValue(data)
    }
    get()
  },[data]);


  useEffect(() => {
    async function getBank(){
      const res= await axios.get(`${API_URL}/banks/activeENach`)
      if(res?.data?.status === 'success'){
        setBank(res?.data?.data);
      }
    }
    getBank()
  }, [])
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (e) => {
    if(e.target.name === 'name'){
      member = e.target.value
    }
    else if(e.target.name === 'nach_type'){
      nachType =e.target.value
    }  
    filteredValue = value.filter(v => ((v?.name || '').toLowerCase()).includes((member).toLowerCase()) && ((v?.nach_type || '').toLowerCase()).includes((nachType).toLowerCase()))
    setFilteredValue([...filteredValue])
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - value?.length) : 0;

  const filteredUsers = applySortFilter(filteredValue, getComparator(order, orderBy));
  const isUserNotFound = value?.length === 0;
  
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
    setOpenConfirmModel(false)
  };

  const closeDialog = () => {
    setOpen(false);
    setOpenConfirmModel(false)
  };
  
  const setModel=(id)=>{
    const selectedData = value?.filter(data=>data.customer_nach_status_id===id)
    if(!isEmpty(selectedData)){
      setSelectedValue(selectedData)
      formik.values.name=selectedData[0]?.name
      formik.values.bank_account_number=selectedData[0]?.account_no
      formik.values.maximum_amount=selectedData[0]?.maximum_amount
      formik.values.customer_nach_status_id=id
      const bankDetails = bank.filter(data=>data.name===selectedData[0]?.bank_name)
      if(!isEmpty(bankDetails)){
        formik.values.bank_code=bankDetails[0]?.bank_code
      }
    }
    setOpen(true)
  }

  return (
    <Page>
      <Container>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value?.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                <TableRow>
                    <TableCell align="left"><TextField className={Styles.filter} name='name' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='nach_type' onChange={handleFilter} autoComplete='off' /></TableCell>
                  </TableRow>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v,index)=>{
                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                          role="checkbox"
                        >                          
                          <TableCell align="left">{v?.name}</TableCell>
                          <TableCell align="left">{v?.nach_type}</TableCell>
                          <TableCell align="left">{v?.account_name_in_nach_status}</TableCell>
                          <TableCell align="left" style={{maxWidth:'130px',textOverflow:'clip',overflow:'clip'}}>{ v?.lotus_api_url ? <div style={{cursor:'pointer',color:'blue',textOverflow:'clip'}} onClick={()=>window.open(v?.lotus_api_url)}>{v?.lotus_api_url}</div>:''}  </TableCell>
                          {!isMobile && <>
                            <TableCell align="left">{v?.status}</TableCell>
                          <TableCell align="left">{v?.account_no}</TableCell>
                          <TableCell align="left">{v?.umrn}</TableCell>
                          <TableCell align="left"><Button variant="contained" size="small" style={{marginLeft: '4px',color: 'white',width: '95px',backgroundColor: v?.status==='active' ? 'green':'blue'}} disabled={v?.status==='active'} onClick={()=>setModel(v.customer_nach_status_id)}>{v?.status==='active' ? `Activated` :'Activate'}</Button></TableCell>
                          </>}
                          <TableCell align="right">
                        </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={value?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {open && <div>
        <Dialog
          id={`nach_form`}
          open={open}
          anchorEl={open}
          placement="bottom-start"
          maxWidth={'lg'}
          onClose={()=> setOpen(false)}
        >
        <ConfirmDialog open={openConfirmModel} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
        <Typography style={{paddingLeft:'20px',marginTop:5}} variant="h4" gutterBottom>
          Activate Nach Status
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='center'> 
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="bank_code"
                id="bank_code"
                name="bank_code"
                label="Bank Name *"
                value={formik.values.bank_code}
                onChange={formik.handleChange}
                error={formik.touched.bank_code && Boolean(formik.errors.bank_code)}
                helpertext={formik.touched.bank_code && formik.errors.bank_code}
              >{  
                bank?.map((v)=>{
                  return <MenuItem value={v.bank_code}>{v.name}</MenuItem>
                })
              }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              type={'text'}
              inputProps={{ maxLength: 18}}
              name="name"
              label="Customer Account Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              type={'text'}
              inputProps={{ maxLength: 18}}
              name="bank_account_number"
              label="Bank Account Number"
              value={formik.values.bank_account_number}
              onChange={formik.handleChange}
              error={formik.touched.bank_account_number && Boolean(formik.errors.bank_account_number)}
              helperText={formik.touched.bank_account_number && formik.errors.bank_account_number}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="bank_account_type"
                id="bank_account_type"
                name="bank_account_type"
                label="Account Type *"
                value={formik.values.bank_account_type}
                onChange={formik.handleChange}
                error={formik.touched.bank_account_type && Boolean(formik.errors.bank_account_type)}
                helpertext={formik.touched.bank_account_type && formik.errors.bank_account_type}
              >
                <MenuItem value={'savings'}>Savings</MenuItem>
                <MenuItem value={'current'}>Current</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              name="maximum_amount"
              label="Maximum Amount"
              value={formik.values.maximum_amount}
              onChange={formik.handleChange}
              error={formik.touched.maximum_amount && Boolean(formik.errors.maximum_amount)}
              helperText={formik.touched.maximum_amount && formik.errors.maximum_amount}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="first_collection_date"
                type={'Date'}
                className={formik.values.first_collection_date === null && Styles.dates}
                label="First Collection Date"
                value={formik.values.first_collection_date}
                onChange={formik.handleChange}
                error={formik.touched.first_collection_date && Boolean(formik.errors.first_collection_date)}
                helperText={formik.touched.first_collection_date && formik.errors.first_collection_date}
              />
            </Grid>
          </Grid>  
          </Box>
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={8} sm={6}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
              >
              Submit
              </LoadingButton>
              </Grid>
            </Grid>
          </Box>
          </form>
          </Dialog>
          </div>
          }
        </Container>
    </Page>
  );
}
