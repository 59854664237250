import * as yup from 'yup';
import {useNavigate,useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {FormControl,InputLabel,MenuItem,Select,TextField,Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL} from '../constant';
import {useContext,useEffect,useState} from 'react';
import {LoginContext} from '../context/logincontext';
import ConfirmDialog from '../components/confirmDialog';
// ----------------------------------------------------------------------
const phoneRegExp = /^([0-9]){10}?$/
const validationSchema = yup.object({
  vendor_name: yup.string().required('field cannot be empty'),
  address: yup.string().required('field cannot be empty'),
  company_type: yup.string().required('field cannot be empty'),
  owner_name: yup.string().required('field cannot be empty'),
  primary_mobile: yup.string().matches(phoneRegExp,'Phone number is not valid').required('field cannot be empty'),

});

export default function EditVendor() {
  const {id} = useParams()
  const context = useContext(LoginContext)
  const [userData,setUserData] = useState(context.user);
  const navigate = useNavigate();
  const [pin,setPin] = useState('')
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const formik = useFormik({
    initialValues: {
      vendor_name: '',
      owner_name: '',
      gstin: '',
      category: '',
      pan: '',
      account_no: '',
      ifsc_code: '',
      bank_name: '',
      company_type: '',
      address: '',
      primary_email: '',
      secondary_email: '',
      primary_mobile: '',
      secondary_mobile: '',
      city: '',
      website: '',
      cin: '',
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  async function submitForm(value) {
    value.user_id = userData.user_id
    if(pin?.length > 5) {
      value.pin_code = pin
    }
    const response = await axios.post(`${API_URL}/vendor/update/${id}`,value);
    if(response.data.status === 'success') {
      navigate('/dashboard/suppliers',{replace: true});
    }
  }
  const getData = async () => {
    const data = await axios.get(`${API_URL}/vendor/${id}`)
    if(data?.data?.status === 'success') {
      const datas = data.data.data;
      formik.setFieldValue('vendor_name',datas.vendor_name)
      formik.setFieldValue('company_type',datas.company_type)
      formik.setFieldValue('address',datas.address)
      formik.setFieldValue('primary_email',datas.primary_email)
      formik.setFieldValue('secondary_email',datas.secondary_email)
      formik.setFieldValue('primary_mobile',datas.primary_mobile)
      formik.setFieldValue('secondary_mobile',datas.secondary_mobile)
      formik.setFieldValue('city',datas.city)
      formik.setFieldValue('website',datas.website)
      formik.setFieldValue('cin',datas.cin)
      formik.setFieldValue('owner_name',datas.owner_name)
      formik.setFieldValue('gstin',datas.gstin)
      formik.setFieldValue('category',datas.category)
      formik.setFieldValue('pan',datas.pan)
      formik.setFieldValue('account_no',datas.account_no)
      formik.setFieldValue('ifsc_code',datas.ifsc_code)
      formik.setFieldValue('bank_name',datas.bank_name)
      setPin(datas?.pin_code)
    }

  }
  useEffect(() => {
    getData()
  },[])
  const handlePinChange = async (e) => {
    setPin(e.target.value)
    if((e.target.value).length > 5) {
      const response = await axios.get(`${API_URL}/get_city_by_pin/${e.target.value}`);
      if(response.data.status === 'success') {
        formik.setFieldValue('city',response?.data?.data?.district_name)
      }
    } else {
      formik.setFieldValue('city','')
    }
  }
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography variant="h4" gutterBottom>
        Edit Supplier
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="vendor_name"
                type={'text'}
                label="Supplier Name"
                value={formik.values.vendor_name}
                onChange={formik.handleChange}
                error={formik.touched.vendor_name && Boolean(formik.errors.vendor_name)}
                helperText={formik.touched.vendor_name && formik.errors.vendor_name}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="owner_name"
                type={'text'}
                label="Owner Name"
                value={formik.values.owner_name}
                onChange={formik.handleChange}
                error={formik.touched.owner_name && Boolean(formik.errors.owner_name)}
                helperText={formik.touched.owner_name && formik.errors.owner_name}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="company_type">Company Type</InputLabel>
                <Select
                  fullWidth
                  labelId='company_type'
                  id="company_type"
                  name="company_type"
                  label="Company Type"
                  value={formik.values.company_type}
                  onChange={formik.handleChange}
                  error={formik.touched.company_type && Boolean(formik.errors.company_type)}
                  helpertext={formik.touched.company_type && formik.errors.company_type}
                >
                  <MenuItem value={'Proprietorship'}>Proprietorship</MenuItem>
                  <MenuItem value={'Limited Liability Partnership'}>Limited Liability Partnership</MenuItem>
                  <MenuItem value={'Private Limited'}>Private Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="address"
                type={'text'}
                label="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="primary_mobile"
                type={'number'}
                label="Primary Mobile"
                value={formik.values.primary_mobile}
                onChange={formik.handleChange}
                error={formik.touched.primary_mobile && Boolean(formik.errors.primary_mobile)}
                helperText={formik.touched.primary_mobile && formik.errors.primary_mobile}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="gstin"
                type={'text'}
                label="GSTIN"
                value={formik.values.gstin}
                onChange={formik.handleChange}
                error={formik.touched.gstin && Boolean(formik.errors.gstin)}
                helperText={formik.touched.gstin && formik.errors.gstin}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="category"
                type={'text'}
                label="Category"
                value={formik.values.category}
                onChange={formik.handleChange}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helperText={formik.touched.category && formik.errors.category}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="pan"
                type={'text'}
                label="Pan Number"
                value={formik.values.pan}
                onChange={formik.handleChange}
                error={formik.touched.pan && Boolean(formik.errors.pan)}
                helperText={formik.touched.pan && formik.errors.pan}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="account_no"
                type={'text'}
                label="Account Number"
                value={formik.values.account_no}
                onChange={formik.handleChange}
                error={formik.touched.account_no && Boolean(formik.errors.account_no)}
                helperText={formik.touched.account_no && formik.errors.account_no}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="ifsc_code"
                type={'text'}
                label="Ifsc Code"
                value={formik.values.ifsc_code}
                onChange={formik.handleChange}
                error={formik.touched.ifsc_code && Boolean(formik.errors.ifsc_code)}
                helperText={formik.touched.ifsc_code && formik.errors.ifsc_code}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="bank_name"
                type={'text'}
                label="Bank Name"
                value={formik.values.bank_name}
                onChange={formik.handleChange}
                error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
                helperText={formik.touched.bank_name && formik.errors.bank_name}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                autoComplete='off'
                name="primary_email"
                type={'email'}
                label="Primary Email address"
                value={formik.values.primary_email}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.primary_email && formik.errors.primary_email)}
                helperText={formik.touched.primary_email && formik.errors.primary_email}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                autoComplete='off'
                name="secondary_email"
                type={'email'}
                label="Secondary Email address"
                value={formik.values.secondary_email}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.secondary_email && formik.errors.secondary_email)}
                helperText={formik.touched.secondary_email && formik.errors.secondary_email}
              />
            </Grid>

            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="secondary_mobile"
                type={'number'}
                label="Secondary Mobile"
                value={formik.values.secondary_mobile}
                onChange={formik.handleChange}
                error={formik.touched.secondary_mobile && Boolean(formik.errors.secondary_mobile)}
                helperText={formik.touched.secondary_mobile && formik.errors.secondary_mobile}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'number'}
                label="Pin Code"
                inputProps={{maxLength: 6}}
                value={pin}
                onChange={handlePinChange}
                error={formik.touched.pin_code && Boolean(formik.errors.pin_code)}
                helperText={formik.touched.pin_code && formik.errors.pin_code}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="city"
                type={'text'}
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="website"
                type={'text'}
                label="Website"
                value={formik.values.website}
                onChange={formik.handleChange}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="cin"
                type={'text'}
                label="Cin No"
                value={formik.values.cin}
                onChange={formik.handleChange}
                error={formik.touched.cin && Boolean(formik.errors.cin)}
                helperText={formik.touched.cin && formik.errors.cin}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}