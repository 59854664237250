import React, {useEffect, useState} from "react";
import { border, styled } from '@mui/system';
import Pdf from "react-to-pdf";
import moment from "moment";
import { NumberToWordConverter } from "../constant";
import { Button } from '@mui/material';
import { useParams } from "react-router-dom";
import { API_URL } from "../constant";
import axios from "axios";
import { PDFExport } from "@progress/kendo-react-pdf";
const ref = React.createRef();
const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #000;
  }
  td,
  th {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    text-align: left;
  }
  th {
    height: 50px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    padding: 0 2px
  }
  td {
    padding-left: 5px;
    font-size: 16px
  }
`;

const tableHead = [<th style={{width: '5%'}}>S.No</th>, <th style={{width: '30%'}}>Model Name</th>, <th style={{width: '15%'}}>HSN</th>,<th style={{width: '7%'}}>Quantity</th>,<th style={{width: '13%'}}>Rate</th>,<th style={{width: '20%'}}>Amount</th>]

export default function CreditNote(props) {
  const { id } = useParams()
  const [supplier, setSupplier] = useState({})
  const [sales, setSales] = useState({})
  const [company, setCompany] = useState({})
  const [transaction, setTransaction] = useState({})
  const [customer, setCustomer] = useState()
  const [noteNumber, setNoteNumber] = useState()
  
  
  useEffect(()=>{
    const get = async () => {
      const transactionData = await axios.get(`${API_URL}/transaction/${id}`)
      const res= transactionData?.data?.data
      setTransaction(res)
      const salesData=await axios.get(`${API_URL}/sales_insurances/${res.ref_id}`)
      const result= salesData?.data?.data
      setCustomer(result)
      setNoteNumber(result.invoice_number)
      const company=await axios.get(`${API_URL}/user/company/${result.user_id}`)
      const userCompany=company?.data?.data
      console.log(company);
       setCompany(userCompany);
    
    }
    get()
  },[])
  

 
  const todayDate = moment().format('D-MMMM-YYYY');
  const pdfExportComponent = React.useRef(null);
  return (
    <>
    <Root ref={ref} sx={{ maxWidth: '100%', width: 800, margin: 'auto' }}>
      <div style={{ paddingLeft: '50px', paddingRight: '50px'}} id='pdf_generate'>
      <PDFExport paperSize="a4"  scale={0.7} margin="1cm" ref={pdfExportComponent} fileName={`${noteNumber}.pdf`}>
      <div style={{textAlign: 'center', fontWeight: 'bold', color: '#000', paddingTop: '30px', paddingBottom: '10px',fontSize: '20px'}}>Credit Note</div>
        <table style={{borderBottom:'none'}}>
        <thead>
          <tr>
            <th style={{width: '60%',borderBottom:'none'}}>
              <div style={{display: 'flex', minHeight: '200px', paddingTop: '5px', paddingLeft: '10px'}}>
              {/* <div><img src='https://ascendcap.in/images/ascend_logo.png'  alt="logo" height='auto' width='150px'  />{} </div> */}
              <div style={{fontSize:'15px'}}>
                <div style={{textAlign: 'left',paddingTop: '0px',fontWeight: 'bold',fontSize:'18px'}}>{company?.name}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Mobile No. : {company?.primary_mobile}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Email Id : {company?.primary_email}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Address : {company?.address}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Website : {company?.website}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>GSTIN: {company?.gstin}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>PAN: {company?.pan}</div>
              </div>
              </div>
            </th>
            <table style={{border:'none'}}>
            <tr style={{border:'none'}}>
              <td style={{border:'1px solid #000' ,textAlign:'center', borderCollapse: 'collapse',borderLeft:'none',borderBottom:'none',borderTop:'none'}}><div>Credit Note No.</div>
                    <div style={{fontWeight: 'bold'}}>{noteNumber}</div></td>
              <td style={{border: 'none',textAlign:'center', borderCollapse: 'collapse',borderTop:'none'}}><div>Dated</div>
                    <div style={{fontWeight: 'bold'}}>{todayDate}</div></td>
            </tr>
            </table>
            <table style={{border:'none',borderTop:'1px solid',borderBottom:'1px solid'}}>
            <tr>
            <div style={{paddingLeft: '10px',fontSize:'15px'}}>
                <div style={{paddingTop: '0px',textAlign:'left'}}> Customer Name: {customer?.lead?.name}</div>
                <div style={{paddingTop: '5px',textAlign:'left'}}> Mobile: {customer?.lead?.mobile}</div>
                <div style={{paddingTop: '5px',textAlign:'left'}}>Address: {customer?.lead?.address}</div>
              </div>
              </tr>
              </table>
              {/* <tr>
              <div style={{paddingLeft: '10px',textAlign: 'left',paddingTop: '5px',fontSize:'15px'}}>Financer Name:</div>
              </tr> */}
              
          </tr>
        </thead>    
        </table>
        
      <table style={{tableLayout: 'fixed',borderTop:'none',borderBottom:'none'}}> 
        <thead>
          <tr>
          <th style={{borderRight:'none'}}>  This Amount is against Invoice No. -  {noteNumber}</th>
            <th style={{textAlign:'right',borderLeft:'none'}}> Rs {transaction.amount}</th>
          
          </tr>
        </thead>
        <tbody>
       
        </tbody>
      </table>
       
      <table style={{borderTop:'none',borderBottom:'none'}}> 
        <tbody align="left" style={{textAlign:'right',fontWeight:'bold',verticalAlign:'text-top'}}>
        <tr>
          <th style={{textAlign:'left',width: '35%',borderRight:'none', borderCollapse: 'collapse',borderBottom:'none',borderTop:'none'}}><div style={{paddingLeft:'5px'}}>Total Amount</div><div style={{fontSize: '18px', fontWeight: 'bold',paddingLeft:'5px'}}>Final Amount</div></th>  
          <th style={{textAlign:'right',borderLeft:'none', borderCollapse: 'collapse',borderBottom:'none',borderTop:'none'}} ><div style={{paddingRight:'5px'}}>Rs  {transaction.amount}</div><div style={{fontSize: '18px', fontWeight: 'bold',paddingRight:'5px'}}>Rs  {Math.round(transaction?.amount).toFixed(2)}</div></th> 
        </tr>
        </tbody>
      </table>
      <div>
        <table style={{textAlign:'left'}}>
        <tr style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
        <div style={{paddingTop: '5px',paddingLeft: '5px'}}>Chargable Amount(in words)</div>
        <div style={{ fontWeight: 'bold',paddingTop: '5px',textTransform:'uppercase',paddingLeft:'5px'}}>INR {NumberToWordConverter.convert(isNaN(transaction.amount)?0:Number(Math.round(transaction.amount)).toFixed(2))}</div>
        </tr>
        
        <th style={{width: '40%',border:'none',textAlign:'left',verticalAlign:'text-bottom', borderCollapse: 'collapse'}}>
        
          <div style={{position:'relative',display:'inline-flex',left:'540px',top:'70px'}}>Authorised Signatory</div>
          
            <div style={{display:'inherit',paddingLeft: '5px'}}>Company's Bank Details</div>
            <tr>
              <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                <tr >
                  <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                    <div>Bank Name</div>
                    <div>A/c No. </div>
                    <div>IFS Code </div>
                  </th>
                  <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                    <div style={{ fontWeight: 'bold'}}>: {company?.bank_name}</div>
                    <div style={{ fontWeight: 'bold'}}>: {company?.account_no}</div>
                    <div style={{ fontWeight: 'bold'}}>: {company?.ifsc_code}</div>
                  </th>
                </tr>
              </th>
            </tr>
          </th>
          </table>
        <div style={{paddingTop: '5px'}}>
        <table style={{border:'none',textAlign:'left',paddingTop: '10px', borderCollapse: 'collapse',borderTop:'none'}}>
        <tr style={{width: '100%',borderLeft:'none', borderCollapse: 'collapse'}}>
          <th style={{width: '60%',border:'none',textAlign:'left', borderCollapse: 'collapse'}}>            
            <div>Declaration</div>
            We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.
          </th>
          
        </tr>
      </table>
      </div>
      </div>
      <div style={{marginTop: '30px', textAlign: 'center', width: '100%'}}>This is system generated.</div>
      </PDFExport>
      </div>
    </Root>
    <div style={{textAlign: 'center', marginTop: '10px'}}>
      <Pdf targetRef={ref}>
        {({ toPdf }) => <Button color="success" variant="contained" onClick={() => {
            if (pdfExportComponent.current) {
              pdfExportComponent.current.save();
            }
          }}>Download</Button>}
      </Pdf>
    </div>
   
    </>
  );
}
