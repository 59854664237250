import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
// material
import { Button, TableCell, TextField, Typography} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MenuItem from '@mui/material/MenuItem';

// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, FILE_UPLOAD_TYPE} from '../constant';
import ConfirmDialog from '../components/confirmDialog';
import { LoginContext } from '../context/logincontext'
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

export default function PdcChequeDetails(props) {
  const context = useContext(LoginContext)
  const { enqueueSnackbar } = useSnackbar();
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const [userData,setUserData]=useState(context.user);
  const [files,setFiles] = useState([])
  const [PdcChequeData,setPdcChequeData] = useState({})
  const [members,setMembers] = useState([])
  const [bank,SetBank]=useState([])
  const [disable,setDisable]=useState(false)
  const formik = useFormik({
    initialValues: {
        remarks: '',
        staff_id: userData?.los_staff_id,
        relation_id:null,
        loan_id: props?.data?.loan_id,
        bank_id: null,
        name_in_bank: '',
        bank_account_no: '',
        count : '',
        ifsc_code:'',
        cheque_number:'',
        updated_by:userData?.user_name,
        cheque_id:null
    },

    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });

  useEffect(() => {
    async function getBank(){
        const res= await axios.get(`${API_URL}/banks/activeENach`)
        if(res?.data?.status === 'success'){
          SetBank(res?.data?.data);
        }
    }
    async function getLoanMembers(){ 
        if(props?.data?.loan_id){
          const res= await axios.get(`${API_URL}/customer/loan/${props?.data?.loan_id}/loan_members`)
          if(res?.data?.status === 'success'){
            setMembers(res?.data?.data);
          }
        } 
    }
    getBank()
    getLoanMembers()
  },[props?.data?.loan_id]);

  useEffect(() => {
    async function getPdcChequeDetails(){
        if(props?.data?.customer_id && props?.data?.loan_id){
            const res= await axios.get(`${API_URL}/customer/${props?.data?.customer_id}/loan/${props?.data?.loan_id}/pdc_cheque_details`)
            if(res?.data?.status === 'success'){
            const pdcChequeData = res?.data?.data[0]
            setPdcChequeData(pdcChequeData);
            formik.setFieldValue('relation_id',pdcChequeData?.relation_id)
            formik.setFieldValue('remarks',pdcChequeData?.remarks)
            formik.setFieldValue('bank_id',pdcChequeData?.bank_id)
            formik.setFieldValue('name_in_bank',pdcChequeData?.name_in_bank)
            formik.setFieldValue('cheque_number',pdcChequeData?.cheque_number)
            formik.setFieldValue('ifsc_code',pdcChequeData?.ifsc_code)
            formik.setFieldValue('count',pdcChequeData?.count)
            formik.setFieldValue('updated_by',pdcChequeData?.updated_by)
            formik.setFieldValue('bank_account_no',pdcChequeData?.account_no)
            formik.setFieldValue('cheque_id',pdcChequeData?.cheque_id)
            }
        }
    }
    getPdcChequeDetails()
  },[props?.data?.loan_id])
  
  async function submitForm(value){
    const data = new FormData();
    if(!isEmpty(files)){
      data.append(FILE_UPLOAD_TYPE.PDC_CHEQUE,...files);
    }
    let formData = {...value,doc_type:FILE_UPLOAD_TYPE.PDC_CHEQUE}
    if(PdcChequeData?.cheque_id){
      formData = {...formData,cheque_id : PdcChequeData?.cheque_id,is_edit:true}
    }
    data.append('data',JSON.stringify({...formData}))
    try{
      const response = await axios.post(`${API_URL}/lead/update/${props.data?.lead_id}`,data);
      if(response?.data?.status === 'success'){
        setDisable(false)
        enqueueSnackbar('PDC Cheque successfuly save', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
        props.onClose()
      }else{
        enqueueSnackbar(response?.data?.data?.message, { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
        setDisable(false)
      }
    }
    catch(err){
      enqueueSnackbar(err?.message, { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
      props.onClose()
      setDisable(false)
    }
  }

  const submitFormValue = () => {
    setDisable(true)
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const uploadFile = (e) =>{
    setFiles(Object.values(e.target.files))
  }

  return (
    <div style={{margin:50}}>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{textAlign:'center'}} variant="h4" gutterBottom>
            Pdc Cheque Details Form
      </Typography>
      <Grid container  style={{paddingLeft:50,paddingTop:30}} boxShadow={false} spacing={2} justifyContent='center' columns={{ xs: 3, sm: 54 }}>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                Name : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.name}</h4>
            </Typography></TableCell>
        </Grid>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                Lead Type : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.lead_type}</h4>
            </Typography></TableCell>
        </Grid>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                Loan Id : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.loan_id}</h4>
            </Typography></TableCell>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                type={'number'}
                labelId="relation_id"
                id="relation_id"
                name="relation_id"
                label="Member *"
                value={formik.values.relation_id}
                onChange={formik.handleChange}
                error={formik.touched.relation_id && Boolean(formik.errors.relation_id)}
                helpertext={formik.touched.relation_id && formik.errors.relation_id}
              >{  
                members?.map((member,i)=>{
                  return <MenuItem key={i} value={member.relation_id}>{`${member.name}-${member.relation_type}-${member.relation}`}</MenuItem>
                })
              }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="bank_id"
                id="bank_id"
                name="bank_id"
                label="Bank Name *"
                value={formik.values.bank_id}
                onChange={formik.handleChange}
                error={formik.touched.bank_id && Boolean(formik.errors.bank_id)}
              >{  
                bank?.map((v)=>{
                  return <MenuItem value={v.bank_id}>{v.name}</MenuItem>
                })
              }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                required
                type={'text'}
                labelId="name_in_bank"
                id="name_in_bank"
                name="name_in_bank"
                label="Name In Bank"
                value={formik.values.name_in_bank}
                onChange={formik.handleChange}
                error={formik.touched.name_in_bank && Boolean(formik.errors.name_in_bank)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                required
                type={'number'}
                labelId="bank_account_no"
                id="bank_account_no"
                name="bank_account_no"
                label="Bank Account No"
                value={formik.values.bank_account_no}
                onChange={formik.handleChange}
                error={formik.touched.bank_account_no && Boolean(formik.errors.bank_account_no)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                required
                type={'number'}
                labelId="count"
                id="count"
                name="count"
                label="Check Count"
                value={formik.values.count}
                onChange={formik.handleChange}
                error={formik.touched.count && Boolean(formik.errors.count)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                required
                type={'number'}
                labelId="cheque_number"
                id="cheque_number"
                name="cheque_number"
                label="Check Number"
                value={formik.values.cheque_number}
                onChange={formik.handleChange}
                error={formik.touched.cheque_number && Boolean(formik.errors.cheque_number)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                required
                type={'text'}
                labelId="ifsc_code"
                id="ifsc_code"
                name="ifsc_code"
                label="IFSC Code"
                value={formik.values.ifsc_code}
                onChange={formik.handleChange}
                error={formik.touched.ifsc_code && Boolean(formik.errors.ifsc_code)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                required
                type={'text'}
                labelId="remarks"
                id="remarks"
                name="remarks"
                label="Remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
              />
            </Grid>
            <Grid item xs={11} sm={6} style={{position: 'relative'}}>
              <TextField
                fullWidth
                required={PdcChequeData?.file_url ? false : true}
                InputLabelProps={{ shrink: true}}
                placeholder='PDC Cheque'
                type={'file'}
                name="pdc_cheque"
                label="PDC Cheque"
                onChange={(e)=>uploadFile(e)}
              />
              {PdcChequeData?.file_url && <Button onClick={() => window.open(PdcChequeData?.file_url ,'_blank')} style={{position: 'absolute',right:'1%',zIndex: 9,bottom:'-20%',transform: 'translate(-10%, -30%)'}}><Iconify id={'Download Template'} icon="entypo:download" aria-label="download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer'}}/></Button>}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={disable}
              loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
