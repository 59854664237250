import React from 'react'
import LeadContextProvider from '../../context/leadContext'
import AddLead from './AddLead'

function Index(props) {
    return (
        <LeadContextProvider>
            <AddLead {...props} />
        </LeadContextProvider>
    )
}

export default Index