import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
/// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL} from '../constant';
import {LoginContext} from '../context/logincontext';
import { isMobile } from 'react-device-detect';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'user_name', label: 'Dealer Name', alignRight: false },
    { id: 'mobile', label: 'Mobile', alignRight: false },
    { id: 'total_purchase', label: 'Total Purchase', alignRight: false },
    { id: 'total_sales', label: 'Total Sales', alignRight: false },
    { id: 'sales', label: 'Sales Total Amount', alignRight: false },
    { id: 'purchase', label: 'Purchase Total Amount', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user?.user_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ReportList() {
  const {company_id} = useParams()
  const context = useContext(LoginContext)
  const [page, setPage] = useState(0);
  const[value,setValue]=useState([]);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('user_name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };

  useEffect(() => {
    async function get() {
      const res= await axios.get(`${API_URL}/dealer`);
      if(res.data.status === 'success'){
        const dealerData = res?.data?.data
        const dealer = dealerData.filter(d=>d.company_id===Number(company_id))
        const dealerUserData = dealer[0]
        setValue(dealerUserData.system_users);
      }
    }
    if(isMobile){
      TABLE_HEAD.length = 4
    }else{
      TABLE_HEAD.length = 6
    }
    get()
  },[company_id]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - value.length) : 0;

   const filteredUsers = applySortFilter(value, getComparator(order, orderBy), filterName);
  const isUserNotFound = value.length === 0;

  function total(arr) {
    if(!Array.isArray(arr)) return 0;
    let sum=0;
    arr.forEach(each => {
      sum+=Number(each.total_amount);
    });
    return sum.toFixed(2);
  };
  

  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Dealer List
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            name={'Search Dealer Name......'}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v,index)=>{
                      return (
                          <>
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                      //   selected={isItemSelected}
                      //   aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                          //   checked={isItemSelected}
                            onChange={(event) => handleClick(event, v.po_number)}
                          />
                          </TableCell> */}
                          <TableCell align="left">{v?.user_name}</TableCell>
                          <TableCell align="left">{v?.mobile}</TableCell>
                          <TableCell align="left">{v?.purchases?.length}</TableCell>
                          <TableCell align="left">{v?.sales?.length}</TableCell>
                          {!isMobile && <>
                          <TableCell align="left">{total(v?.sales)}</TableCell>
                          <TableCell align="left">{total(v?.purchases)}</TableCell>
                          </>}
                          {/* <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(status === 'banned' && 'error') || 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell> */}
                          <TableCell align="right">
                            <UserMoreMenu purchase={`/dashboard/dealer_inventory/${company_id}/purchase/${v.user_id}`}
                             sales={`/dashboard/dealer_inventory/${company_id}/sales/${v.user_id}`}
                              />
                          </TableCell>
                        </TableRow>
                    </>
                    );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
