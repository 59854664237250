import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// material
import { TextField,  IconButton, InputAdornment, Typography, FormControl, InputLabel, Stack, FormControlLabel, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Iconify from '../components/Iconify';
import {API_URL} from '../constant';
import Styles from '../css/dates.module.css'
import ConfirmDialog from '../components/confirmDialog';
import { omit } from 'lodash';
// ----------------------------------------------------------------------
const phoneRegExp = /^([0-9]){10}?$/
const aadhaarRegex = /^([0-9]){4}([0-9]){4}([0-9]){4}?$/
const panRegex  = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
const validationSchema = yup.object({
  user_name: yup.string().required('please select gender'),
  mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('field cannot be empty'),
  aadhaar_no: yup.string().matches(aadhaarRegex,'Aadhaar number is not valid').nullable(true),
  pan: yup.string().matches(panRegex, 'Pan number is not valid').nullable(true),
  dob: yup.date().required('field cannot be empty').nullable(true),
  gender: yup.number().required('please select gender'),
  password: yup.string().min(8 ,'password should not be less than 8')
});

export default function LoginForm() {
  const { id } = useParams()
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const[company,SetCompany]=useState([])
  const[data,SetData]=useState([])
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const formik = useFormik({
    initialValues: {
      user_name: '',
      mobile: '',
      password: '',
      gender: '',
      dob: '',
      aadhaar_no:'',
      pan: '',
      company_id:'',
      is_active:false,
      is_show_ledger:false
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  async function submitForm(value){
    value.company_name = (company?.find(comp=> comp?.company_id === value?.company_id))?.name
    if(!value.password){
      value = omit(value,'password')
    }
    const response = await axios.post(`${API_URL}/system_user/update/${id}`, value);
    if(response.data.status === 'success'){
      navigate('/dashboard/users', { replace: true });
    }
  }
  const getData = async () => {
    const data = await axios.get(`${API_URL}/system_user/${id}`)
    if(data?.data?.status === 'success'){
        const datas = data?.data?.data;
        formik.setFieldValue('user_name', datas?.user_name)
        formik.setFieldValue('mobile', datas?.mobile)
        formik.setFieldValue('gender', datas?.gender)
        formik.setFieldValue('dob', datas?.dob)
        formik.setFieldValue('aadhaar_no', datas?.aadhaar_no)
        formik.setFieldValue('pan', datas?.pan)
        formik.setFieldValue('company_id', datas?.company_id)
        formik.setFieldValue('is_active', datas?.is_active)
        formik.setFieldValue('is_show_ledger', datas?.is_show_ledger)
    }
}
  useEffect(()=>{
    async function get(){
      const result= await axios.get(`${API_URL}/company`)
      if(result.data.status === 'success'){
        SetCompany(result.data.data)  
     }
      const res = await axios.get(`${API_URL}/company/${id}`)
      if(res.data.status === 'success'){
        SetData(res.data.data)
           }
      }
      get()
  getData()
  },[])
  // useEffect(()=>{
    
  //   formik.setFieldValue('company_name', companydata.company_name)
  //   console.log(formik.values);       
  // },[formik.values.company_id])
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography style={{paddingLeft:'20px'}} variant="h4" gutterBottom>
                Edit System User
        </Typography>
        <FormControlLabel
          sx={{
            display: 'block',
          }}
          control={
            <div>
              <label>{formik.values.is_active ? 'User Active' : 'User InActive'}</label>
              <Switch
              checked={formik.values.is_active}
              onChange={() => formik.setFieldValue('is_active',formik.values.is_active ? false : true)}
              name={formik.values.is_active ? 'User Active' : 'User InActive'}
              color="primary"
            />
            </div>
          }
          label={''}
        />
        <FormControlLabel
            sx={{
              display: 'block',
            }}
            control={
              <div>
                <label>Show Ledger</label>
                <Switch
                  checked={formik.values.is_show_ledger}
                  onChange={() => formik.setFieldValue('is_show_ledger',formik.values.is_show_ledger ? false : true)}
                  name={'is_show_ledger'}
                  color="primary"
                />
              </div>
             
            }
            label={''}
          />
      </Stack>
      <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="user_name"
              label="Name *"
              value={formik.values.user_name}
              onChange={formik.handleChange}
              error={formik.touched.user_name && Boolean(formik.errors.user_name)}
              helperText={formik.touched.user_name && formik.errors.user_name}
            />
            </Grid>
            <Grid item xs={11} md={6}>
          <TextField
          select
          fullWidth
          id='company_id'
          name="company_id"
          label="Company Name"
          value={formik.values.company_id}
          onChange={formik.handleChange}
          error={formik.touched.company_id && Boolean(formik.errors.company_id)}
          helpertext={formik.touched.company_id && formik.errors.company_id}
          >
            {company.map((v,i) => {
              return (
                <MenuItem  value={v.company_id}>{v.name}</MenuItem>
              );
            })}
          </TextField>

              </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="mobile"
              label="Mobile *"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
              select
              fullWidth
              labelId="Gender"
              id="gender"
              name="gender"
              label="Gender *"
              value={formik.values.gender}
              onChange={formik.handleChange}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              helpertext={formik.touched.gender && formik.errors.gender}
              >
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
            id="dob"
            type="date"
            autoComplete='off'
            fullWidth
            name="dob"
            label="Date Of Birth *"
            className={formik.values.dob === null && Styles.dates}
            value={formik.values.dob}
            onChange={formik.handleChange}
            error={formik.touched.dob && Boolean(formik.errors.dob)}
            helperText={formik.touched.dob && formik.errors.dob}
          />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              inputProps={{ maxLength: 12 }}
              name="aadhaar_no"
              label="Aadhaar Number "
              value={formik.values.aadhaar_no}
              onChange={formik.handleChange}
              error={formik.touched.aadhaar_no && Boolean(formik.errors.aadhaar_no)}
              helperText={formik.touched.aadhaar_no && formik.errors.aadhaar_no}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              name="pan"
              label="Pan Number "
              inputProps={{ maxLength: 10 }}
              value={formik.values.pan}
              onChange={formik.handleChange}
              error={formik.touched.pan && Boolean(formik.errors.pan)}
              helperText={formik.touched.pan && formik.errors.pan}
            />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
