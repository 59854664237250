import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { MenuItem, Select, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import {API_URL, NumberToWordConverter} from '../constant';
import { LoginContext } from '../context/logincontext';
import ConfirmDialog from '../components/confirmDialog';
// ----------------------------------------------------------------------
 
const validationSchema = yup.object({
  part_name: yup.string().required('field cannot be empty'),
  brand: yup.string().required('field cannot be empty'),
  price: yup.string().required('field cannot be empty'),
  min_qty: yup.string().required('field cannot be empty'),
  units: yup.string().required('field cannot be empty'),
  gst_percentage: yup.string().required('field cannot be empty')
});

export default function EditParts() {
  const { id } = useParams()
  const context = useContext(LoginContext)
  const[userData,setUserData]=useState(context.user);
  const navigate = useNavigate();
  const[vendor,setValue]=useState([]);
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  
  const formik = useFormik({
    initialValues: {
      product_id:'',
      vendor_name:'',
      part_name: '',
      gst_percentage: '',
      hsn:'',
      units: '',
      min_qty: '',
      qty:0,
      brand: '',
      price: '',
      cost_price:'',
      description:'',
      category:''
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  
  async function submitForm(value) {
    value.user_id=userData.user_id
    const response = await axios.post(`${API_URL}/parts/update/${id}`, value);
    if(response.data.status === 'success'){
      navigate('/dashboard/products', { replace: true });
    }
  }
const getData = async () => {
    const data = await axios.get(`${API_URL}/part/${id}`)
    if(data?.data?.status === 'success'){
        const datas = data.data.data;
        formik.setFieldValue('product_id', datas.product_id)
        formik.setFieldValue('part_name', datas.part_name)
        formik.setFieldValue('gst_percentage', datas.gst_percentage)
        formik.setFieldValue('hsn', datas.hsn)
        formik.setFieldValue('units', datas.units)
        formik.setFieldValue('min_qty', datas.min_qty)
        formik.setFieldValue('brand', datas.brand)
        formik.setFieldValue('price', datas.price)
        formik.setFieldValue('cost_price', datas.cost_price)
        formik.setFieldValue('description', datas.description)
        formik.setFieldValue('qty', datas.qty)
        formik.setFieldValue('category', datas.category)
    }
  
}

useEffect(()=>{
  async function get() {
    const res= await axios.get(`${API_URL}/vendor`);
    setValue(res.data.data);
  }
  get()
  getData()
  },[])
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography variant="h4" gutterBottom>
              Edit Model
      </Typography>
      <form onSubmit={formik.handleSubmit}>
      <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="product_id"
          label="Model ID"
          value={formik.values.product_id}
          onChange={formik.handleChange}
          error={formik.touched.product_id && Boolean(formik.errors.product_id)}
          helperText={formik.touched.product_id && formik.errors.product_id}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="part_name"
          label="Model Name *"
          value={formik.values.part_name}
          onChange={formik.handleChange}
          error={formik.touched.part_name && Boolean(formik.errors.part_name)}
          helperText={formik.touched.part_name && formik.errors.part_name}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="units"
                label="Units/Kg/Litre/ *"
                value={formik.values.units}
                onChange={formik.handleChange}
                error={formik.touched.units && Boolean(formik.errors.units)}
                helperText={formik.touched.units && formik.errors.units}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                id="category"
                name="category"
                label="Category"
                value={formik.values.category}
                onChange={formik.handleChange}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helpertext={formik.touched.category && formik.errors.category}
              >
                <MenuItem value={'Product'}>Model</MenuItem>
                <MenuItem value={'Spare Part'}>Spare Part</MenuItem>
                <MenuItem value={'Service'}>Service</MenuItem>
              </TextField>
            </Grid>
        {/* <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="qty"
          label="Quantity *"
          value={formik.values.qty}
          onChange={formik.handleChange}
          error={formik.touched.qty && Boolean(formik.errors.qty)}
          helperText={formik.touched.qty && formik.errors.qty}
        />
        </Grid> */}
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="min_qty"
          label="Min Oty *"
          value={formik.values.min_qty}
          onChange={formik.handleChange}
          error={formik.touched.min_qty && Boolean(formik.errors.min_qty)}
          helperText={formik.touched.min_qty && formik.errors.min_qty}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="brand"
          label="Brand *"
          value={formik.values.brand}
          onChange={formik.handleChange}
          error={formik.touched.brand && Boolean(formik.errors.brand)}
          helperText={formik.touched.brand && formik.errors.brand}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="price"
          label="Selling Price *"
          value={formik.values.price}
          onChange={formik.handleChange}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={Boolean(formik.values.price) && NumberToWordConverter.convert(formik.values.price)}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="gst_percentage"
          label="GST %"
          value={formik.values.gst_percentage}
          onChange={formik.handleChange}
          error={formik.touched.gst_percentage && Boolean(formik.errors.gst_percentage)}
          helperText={formik.touched.gst_percentage && formik.errors.gst_percentage}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="hsn"
          label="HSN/SAC"
          value={formik.values.hsn}
          onChange={formik.handleChange}
           
        />
        </Grid>
        
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="cost_price"
          label="Cost Price"
          value={formik.values.cost_price}
          onChange={formik.handleChange}
          error={formik.touched.cost_price && Boolean(formik.errors.cost_price)}
          helperText={Boolean(formik.values.cost_price) && NumberToWordConverter.convert(formik.values.cost_price)}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="description"
          label="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
           
        />
        </Grid>
      </Grid>
    </Box>
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={8} sm={6}>
              <LoadingButton
               fullWidth
               size="large"
               type="submit"
               variant="contained"
               loading={formik.isSubmitting}
              >
              Submit
              </LoadingButton>
              </Grid>
            </Grid>
          </Box>
      </form>
    </div>
  );
}
