import {API_URL} from "../constant"

export const GET_PARTS =  `${API_URL}/parts`

export const GET_PART_BY_ID =  `${API_URL}/part/:part_id`

export const ADD_PART =  `${API_URL}/parts`

export const ADD_BULK_PARTS = `${API_URL}/parts/parts_bulk_data`

export const UPDATE_PART = `${API_URL}/parts/update/:part_id`

export const ADD_USER = `${API_URL}/system_user`

export const GET_USERS = `${API_URL}/system_user`

export const GET_USER_BY_ID = `${API_URL}/system_user/:user_id`

export const UPDATE_USER = `${API_URL}/system_user/update/:user_id`

export const GET_USER_BY_MOBILE = `${API_URL}/system_user/mobile/:mobile`

export const ADD_PURCHASE = `${API_URL}/purchase`

export const GET_PURCHASE = `${API_URL}/purchase`

export const GET_PURCHASE_BY_ID = `${API_URL}/purchase`

export const GET_PURCHASE_DATA_BY_ID = `${API_URL}/purchase/data/:purchase_id`

export const GET_PURCHASE_DETAILS_BY_ID = `${API_URL}/purchase/:purchase_id`

export const UPDATE_PURCHASE = `${API_URL}/purchase/update/:purchase_id`

export const GET_DATA_BY_SALES_ID = `${API_URL}/sales/data/:sales_id`

export const ADD_PURCHASE_DATA = `${API_URL}/purchase_detail`

export const GET_PURCHASE_DATA = `${API_URL}/purchase_detail`

export const UPDATE_PURCHASE_DATA = `${API_URL}/purchase_detail/update/:purchase_detail_id`

export const GET_PURCHASE_DETAIL_BY_ID = `${API_URL}/purchase_detail/purchase/:purchase_id`

export const ADD_COMPANY = `${API_URL}/company`

export const REGISTER = `${API_URL}/register`

export const GET_COMPANIES = `${API_URL}/company`

export const GET_DEALER_FOR_PARENT_COMPANY = `${API_URL}/dealer`

export const GET_COMPANY = `${API_URL}/company/:company_id`

export const UPDATE_COMPANY = `${API_URL}/company/update/:company_id`

export const ADD_USER_COMPANY = `${API_URL}/user_company`

export const GET_USER_COMPANIES = `${API_URL}/user_company`

export const GET_USER_COMPANY = `${API_URL}/user/company/:user_id`

export const GET_USER_COMPANY_DATA = `${API_URL}/user_company/:user_company_id`

export const UPDATE_USER_COMPANY = `${API_URL}/user_company/update`

export const ADD_LEAD = `${API_URL}/lead`

export const ADD_BULK_LEAD_DATA = `${API_URL}/lead_bulk_data`

export const GET_LEAD = `${API_URL}/data/leads`

export const GET_LEAD_BY_LEAD_ID = `${API_URL}/leads/:lead_id`

export const UPDATE_LEAD = `${API_URL}/lead/update/:lead_id`

export const ADD_VENDOR = `${API_URL}/vendor`

export const GET_VENDERS = `${API_URL}/vendor`

// export const UPDATE_VENDER = `${API_URL}/vendor/update/:vendor_id`

// `${API_URL}/vendor/:vendor_id`

// `${API_URL}/company_docs`

// `${API_URL}/company_docs`

// `${API_URL}/company_docs/:company_id`

// `${API_URL}/company_docs/:doc_id`

// `${API_URL}/vendor_docs`

// `${API_URL}/vendor_docs`

// `${API_URL}/vendor_docs/:vendor_doc_id`

// `${API_URL}/vendor_docs/:vendor_doc_id`

// `${API_URL}/parts/vendor/:vendor_id`

// `${API_URL}/sales`

// `${API_URL}/sales`

// `${API_URL}/sales/:sales_id`

// `${API_URL}/sales/:sales_id`

// `${API_URL}/sales_detail`

// `${API_URL}/sales_detail`

// `${API_URL}/sales_detail/:sales_detail_id`

// `${API_URL}/sales_detail/:sales_detail_id`

// `${API_URL}/login`

// `${API_URL}/login`

// `${API_URL}/logout`

// `${API_URL}/user`

// `${API_URL}/verify_pan/:lead_id`

// `${API_URL}/verify_aadhaar`

// `${API_URL}/lead/:lead_id/docType/:type/download`

// `${API_URL}/financer`

// `${API_URL}/financer`

// `${API_URL}/financer/update/:financer_id`

// `${API_URL}/financer/:financer_id`

// `${API_URL}/reset_password`

// `${API_URL}/insurer`

// `${API_URL}/insurers`

// `${API_URL}/insurer/:insurer_id`

// `${API_URL}/insurer/:insurer_id`

// `${API_URL}/sales_insurances/:sales_id`

// `${API_URL}/sales_insurance/:sales_id`

// `${API_URL}/transaction`

// `${API_URL}/transactions`

// `${API_URL}/transaction/:transaction_id`

// `${API_URL}/transaction/:transaction_id`

// `${API_URL}/transaction/:transaction_id/docType/:type/download`

// `${API_URL}/companies/:company_id/docType/:type/download`

// `${API_URL}/los_all_dealer`

// `${API_URL}/finance_ledger`

// `${API_URL}/get_city_by_pin/:pin`

// `${API_URL}/purchase_invoice/:type`

// `${API_URL}/sales_invoice/:type`

// `${API_URL}/counter_party`

// `${API_URL}/counter_party`

// `${API_URL}/counter_party/update/:counter_party_id`

// `${API_URL}/counter_party/:counter_party_id`

// `${API_URL}/csv_template/:type/download`

// `${API_URL}/banks/activeENach`

// `${API_URL}/loan/e_nach_create_new_source`

// `${API_URL}/loan/nach_status/:loan_id`

export const GET_REMARKS = `${API_URL}/get_dealer_remark`

export const UPDATE_NOTIFICATION_TIME = `${API_URL}/update_notification_time`

export const READ_REMARKS = `${API_URL}/read_dealer_remark`

export const GET_LEADS_NOTIFICATION = `${API_URL}/lead_tentative_purchase_date_notification`

export const CLEAR_REMARKS = `${API_URL}/clear_dealer_remark`

export const SEEN_LEAD_NOTIFICATION = `${API_URL}/seen_lead_notification`

export const UPDATE_NOTIFICATION = `${API_URL}/notification`

export const GET_NOTIFICATION = `${API_URL}/notification`
