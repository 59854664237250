// component

import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Admin',
    path: '',
    icon: getIcon('bi:person-fill'),
    children: [
      {
        title: 'Add Client',
        path: '/dashboard/company',
        icon: getIcon('eva:shopping-bag-fill')
      },
      {
        title: 'Client List',
        path: '/dashboard/companies',
        icon: getIcon('eva:shopping-bag-fill')
      },{
        title: 'DSA Client List',
        path: '/dashboard/dsa',
        icon: getIcon('eva:shopping-bag-fill')
      },{
        title: 'Add User',
        path: '/dashboard/user',
        icon: getIcon('eva:shopping-bag-fill')
      },
      {
        title: 'All Users',
        path: '/dashboard/users',
        icon: getIcon('eva:shopping-bag-fill')
      }
    ]
  },
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },

  {
    title: 'Customer',
    path: '/dashboard/lead',
    icon: getIcon('eva:people-fill'),
    children: [
    //   {
    //   title: 'Quick Lead',
    //   path: '/dashboard/quicklead',
    //   icon: getIcon('eva:shopping-bag-fill')
    // },
    {
      title: 'Quick Lead',
      path: '/dashboard/lead',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Customer List',
      path: '/dashboard/leads',
      icon: getIcon('eva:shopping-bag-fill')
    }
    ]
  },
  {
    title: 'Loan',
    path: '',
    icon: getIcon('mdi:finance'),
    children: [
      {
        title: 'Disbursed Loans',
        path: '/dashboard/loan_list/disbursed_loans',
        icon: getIcon('eva:shopping-bag-fill')
      },
      {
        title: 'Pending Loans',
        path: '/dashboard/loan_list/in_process_loans',
        icon: getIcon('eva:shopping-bag-fill')
      },
      {
        title: 'Loan Ledger',
        path: '/dashboard/loan_ledger',
        icon: getIcon('eva:shopping-bag-fill')
      },
      {
        title: 'Rc Ledger',
        path: '/dashboard/rc_ledger',
        icon: getIcon('eva:shopping-bag-fill')
      },
      {
        title: 'Security Ledger',
        path: '/dashboard/security_ledger',
        icon: getIcon('eva:shopping-bag-fill')
      },
    ]
  },
  {
    title: 'Sales',
    path: '',
    icon: getIcon('dashicons:admin-tools'),
    children: [{
      title: 'Sales Quotation',
      path: '/dashboard/add_sales_quotation',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Sales Invoices',
      path: '/dashboard/addsales',
      icon: getIcon('eva:shopping-bag-fill')
    },{
      title: 'All Invoices',
      path: '/dashboard/sales_list',
      icon: getIcon('eva:shopping-bag-fill')
    }
    ]
  },
  {
    title: 'Purchase',
    path: '',
    icon: getIcon('eva:shopping-bag-fill'),
    children: [{
      title: 'Purchase Order',
      path: '/dashboard/purchase',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Purchase Bill',
      path: '/dashboard/purchase_bill',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'All Purchases',
      path: '/dashboard/purchase_list',
      icon: getIcon('eva:shopping-bag-fill')
    }
    ]
  },
  {
    title: 'Supplier',
    path: '/dashboard/vendor',
    icon: getIcon('eva:people-fill'),
    children: [{
      title: 'Add Supplier',
      path: '/dashboard/supplier',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Suppliers',
      path: '/dashboard/suppliers',
      icon: getIcon('eva:shopping-bag-fill')
    }
    ]
  },
  
  {
    title: 'Model',
    path: '',
    icon: getIcon('dashicons:admin-tools'),
    children: [{
      title: 'Add Model',
      path: '/dashboard/product',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Model List',
      path: '/dashboard/products',
      icon: getIcon('eva:shopping-bag-fill')
    }
    ]
  },
  {
    title: 'Vehicle Details',
    path: '',
    icon: getIcon('dashicons:admin-tools'),
    children: [
      {
        title: 'Add Vehicle Details',
        path: '/dashboard/add_vehicle_details',
        icon: getIcon('eva:shopping-bag-fill')
      },
      {
      title: 'Vehicle Details List',
      path: '/dashboard/vehicle_details/list',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Sale Vehicle Details',
      path: '/dashboard/vehicle_details/sales',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Vehicle List',
      path: '/dashboard/vehicle_list',
      icon: getIcon('eva:shopping-bag-fill')
    }
    ]
  },
  {
    title: 'Financer',
    path: '',
    icon: getIcon('bxs:bank'),
    children: [{
      title: 'Add Financer',
      path: '/dashboard/financer',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Financers List',
      path: '/dashboard/financers',
      icon: getIcon('eva:shopping-bag-fill')
    }
    ]
  },
  {
    title: 'Insurer',
    path: '',
    icon: getIcon('bxs:check-shield'),
    children: [{
      title: 'Add Insurer',
      path: '/dashboard/insurer',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Insurers List',
      path: '/dashboard/insurers',
      icon: getIcon('eva:shopping-bag-fill')
    }
    ]
  },
 {
    title: 'Transaction',
    path: '',
    icon: getIcon('icon-park-outline:transaction'),
    children: [
    {
      title: 'Transaction List',
      path: `/dashboard/all_transactions`,
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Financer Transaction List',
      path: '/dashboard/financer_transactions',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Supplier Transaction List',
      path: '/dashboard/supplier_transactions',
      icon: getIcon('eva:shopping-bag-fill')
    },
    
    {
      title: 'Add Expense',
      path: 'expense/expense',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Expense List',
      path: '/dashboard/expense_list',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Add Counter Party',
      path: '/dashboard/counter_party',
      icon: getIcon('eva:shopping-bag-fill')
    },
    {
      title: 'Counter Party List',
      path: '/dashboard/counter_parties',
      icon: getIcon('eva:shopping-bag-fill')
    },
    ]
  },
 {
    title: 'Dealer Reports',
    path: '/dashboard/dealer_company_reports',
    icon: getIcon('bi:bar-chart-fill'),
  },
  {
    title: 'System User Reports',
    path: '/dashboard/reports',
    icon: getIcon('eva:pie-chart-2-fill')
  },
];

export default sidebarConfig;
