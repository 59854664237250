import React, {useEffect, useState} from "react";
import { border, styled } from '@mui/system';
import Pdf from "react-to-pdf";
import moment from "moment";

import { Button } from '@mui/material';
import { useParams } from "react-router-dom";
import { API_URL, NumberToWordConverter, TYPE } from "../constant";
import axios from "axios";
import converter from 'number-to-words'
import { PDFExport } from "@progress/kendo-react-pdf";
const ref = React.createRef();
const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #000;
  }
  td,
  th {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    text-align: left;
  }
  th {
    height: 50px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    padding: 0 2px
  }
  td {
    padding-left: 5px;
    font-size: 16px
  }
`;

const tableHead = [<th style={{width: '5%'}}>S.No</th>, <th style={{width: '30%'}}>Model Name</th>, <th style={{width: '15%'}}>HSN</th>,<th style={{width: '7%'}}>Quantity</th>,<th style={{width: '13%'}}>Rate</th>,<th style={{width: '20%'}}>Amount</th>]

export default function DownloadQuotation(props) {
  const { id } = useParams()
  const [invoiceData, setInvoiceData] = useState([])
  const [Lead, setLead] = useState({})
  const [sales, setSales] = useState({})
  const [company, setCompany] = useState({})
  const [financer, setFinancer] = useState([])
  useEffect(()=>{
    const get = async () => {
      const data = await axios.get(`${API_URL}/sales/data/${id}`)
      const res= data?.data?.data
      const UserId = res[0].user_id
      const CompanyData= await axios.get(`${API_URL}/user/company/${UserId}`)
      const UserCompanyData=CompanyData.data.data
      setLead(res[0].lead)
      setCompany(UserCompanyData)
      setSales(res[0])
      setInvoiceData(res[0].sales_details)
      setFinancer(res[0].financer_name)
    }
    get()
  },[])
 
  const todayDate = moment().format('D-MMMM-YYYY');
  const pdfExportComponent = React.useRef(null);
  return (
    <>
    <Root ref={ref} sx={{ maxWidth: '100%', width: 800, margin: 'auto' }}>
      <div style={{ paddingLeft: '50px', paddingRight: '50px'}} id='pdf_generate'>
      <PDFExport paperSize="a4"  scale={0.7} margin="1cm" ref={pdfExportComponent} fileName={`${sales?.invoice_number}.pdf`}>
      <div style={{textAlign: 'center', fontWeight: 'bold', color: '#000', paddingTop: '30px', paddingBottom: '10px',fontSize: '20px'}}>Sales Quotation</div>
        <table style={{borderBottom:'none'}}>
        <thead>
          <tr>
            <th style={{width: '60%', borderBottom:'none'}}>
              <div style={{display: 'flex', minHeight: '200px', paddingTop: '5px', paddingLeft: '10px'}}>
              {/* <div><img src='https://ascendcap.in/images/ascend_logo.png'  alt="logo" height='auto' width='150px'  />{} </div> */}
              <div style={{fontSize:'15px'}}>
                <div style={{textAlign: 'left',paddingTop: '0px',fontWeight: 'bold',fontSize:'18px'}}>{company?.name}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Mobile No. : {company?.primary_mobile}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Email Id : {company?.primary_email}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Address : {company?.address}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Website : {company?.website}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>GSTIN: {company?.gstin}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>PAN: {company?.pan}</div>
              </div>
              </div>
            </th>
            <table style={{border:'none'}}>
            <tr style={{border:'none'}}>
              <td style={{border:'1px solid #000' ,textAlign:'center', borderCollapse: 'collapse',borderLeft:'none',borderBottom:'none',borderTop:'none'}}><div>Sales Quotation No.</div>
                    <div style={{fontWeight: 'bold'}}>{(sales.type ===TYPE.INVOICE || sales.type ===TYPE.RETURN) ? `${sales.invoice_number}`: `${sales.invoice_number}`}</div></td>
              <td style={{border: 'none',textAlign:'center', borderCollapse: 'collapse',borderTop:'none'}}><div>Dated</div>
                    <div style={{fontWeight: 'bold'}}>{todayDate}</div></td>
            </tr>
            </table>
            <table style={{border:'none',borderTop:'1px solid',borderBottom:'none'}}>
            <tr>
            <div style={{paddingLeft: '10px',fontSize:'15px'}}>
                <div style={{paddingTop: '0px',textAlign:'left'}}> Customer Name: {Lead.name}</div>
                <div style={{paddingTop: '5px',textAlign:'left'}}> Mobile: {Lead.mobile}</div>
                <div style={{paddingTop: '5px',textAlign:'left'}}>Address: {Lead.address}</div>
              </div>
              </tr>
              </table>
          </tr>
        </thead>    
        </table>
        
      <table style={{tableLayout: 'fixed'}}> 
        <thead>
          <tr>
            {tableHead.map(head=>head)}
          </tr>
        </thead>
        <tbody>
        {invoiceData.map((d,i) => (
            <tr key={d.id}>
              <td align="right" style={{width: '5%',verticalAlign:'text-top'}}>
                {i + 1}
              </td>
              <td align="right" style={{width: '30%',verticalAlign:'text-top',fontWeight:'bold', wordBreak: 'break-word' }}>
              {d?.item_name}
               <div style={{textAlign:'left',fontWeight:'normal',fontSize: '14px',marginTop:'0px'}}>
                {d?.details[0]['motor_no']&& <div>Motor No. {d?.details[0]['motor_no']}</div>}
                {d?.details[0]['chassis_no']&& <div>Chassis No. {d?.details[0]['chassis_no']}</div>} 
                {d?.details[0]['battery_no']&& <div>Battery No. {(d?.details[0]['battery_no'].split('\n')).map(d => <><span>{d}</span><br /></>)}</div>} 
                {d?.details[0]['color']&& <div>Color. {d?.details[0]['color']}</div>}  
                {d?.details[0]['controller_no']&& <div>Controller No. {d?.details[0]['controller_no']}</div>}   
                {d?.details[0]['power']&& <div>Power {d?.details[0]['power']}</div>}   
                 </div> 
                {d.discount_percent!=="0" &&
                <div style={{textAlign:'right',fontWeight:'normal',fontSize: '14px',marginBottom:'0%',paddingRight:'5px'}}>Discount {d?.discount_percent}%</div>}
                <div style={{textAlign:'right',fontWeight:'normal',fontSize: '14px',paddingRight:'5px'}}>SGST {d?.sgst}%</div>
                <div style={{textAlign:'right',fontWeight:'normal',fontSize: '14px',paddingRight:'5px'}}>CGST {d?.cgst}%</div>
              </td>
              <td align="right" style={{width: '15%', verticalAlign:'text-top'}}>
              {d.parts[0].hsn}
                 
              </td>
              <td align="right" style={{width: '7%',  verticalAlign:'text-top'}}>
                {d?.qty}
              </td>
              <td align="right" style={{width: '13%', verticalAlign:'text-top'}}>
              <div style={{verticalAlign:'text-top'}}>Rs  {(parseFloat(d?.item_price)).toFixed(2)}</div>
                
              </td>
              <td align="left" style={{textAlign:'right',width: '20%',verticalAlign:'top', position: 'relative'}}>
                <div style={{width:'100%',height:'100%',position:'absolute',top:'0',left:'0',zIndex:'99',display:'flex',flexDirection:'column',justifyContent:'space-between',paddingRight:'5px'}}>
                <div style={{fontWeight:'normal',fontSize: '14px'}}>Rs  {(parseFloat(d?.amount_without_gst)).toFixed(2)}</div>
                <div style={{fontWeight:'normal',fontSize: '14px'}}>
                <div style={{fontWeight:'normal',fontSize: '14px'}}>
                  {d.discount_percent!=="0" &&
                <div style={{fontWeight:'normal',fontSize: '14px' }}>-{(parseFloat(d?.discount_amount)).toFixed(2)} </div>}
                <div style={{fontWeight:'normal', fontSize: '14px' }}> {d?.sgst_amount}</div>
                <div style={{fontWeight:'normal', fontSize: '14px' }}> {d?.cgst_amount}</div>
                </div>
                </div>
              </div>
              </td>
            </tr>
          ))}
          
        </tbody>
      </table>
       
      <table style={{borderTop:'none',borderBottom:'none'}}> 
        <tbody align="left" style={{textAlign:'right',fontWeight:'bold',verticalAlign:'text-top'}}>
        <tr>
          <th style={{textAlign:'left',width: '35%',border:'none', borderCollapse: 'collapse' }}>
            {sales.discount_amount!=="0.00" &&
            <div style={{paddingLeft:'5px'}}>Total Discount Amount</div>}
            <div style={{paddingLeft:'5px'}}>Total SGST Amount</div>
            <div style={{paddingLeft:'5px'}}>Total CGST Amount</div></th>  
          <th style={{textAlign:'right',border:'none', borderCollapse: 'collapse'}} >
          {sales.discount_amount!=="0.00" &&
          <div style={{paddingRight:'5px'}}>Rs  -{sales?.discount_amount}</div>}
          <div style={{paddingRight:'5px'}}>Rs  {sales?.sgst_amount}</div>
          <div style={{paddingRight:'5px'}}>Rs  {sales?.cgst_amount}</div></th> 
        </tr>
        <tr>
          <th style={{textAlign:'left',width: '35%',borderRight:'none', borderCollapse: 'collapse',borderBottom:'none'}}><div style={{paddingLeft:'5px'}}>Total Amount</div><div style={{paddingLeft:'5px'}}>Round Off</div><div style={{fontSize: '18px', fontWeight: 'bold',paddingLeft:'5px'}}>Final Amount</div></th>  
          <th style={{textAlign:'right',borderLeft:'none', borderCollapse: 'collapse',borderBottom:'none'}} ><div style={{paddingRight:'5px'}}>Rs  {sales.total_amount}</div><div style={{paddingRight:'5px'}}>Rs  {(Math.round(sales?.total_amount)-Number(sales?.total_amount)).toFixed(2)}</div><div style={{fontSize: '18px', fontWeight: 'bold',paddingRight:'5px'}}>Rs  {Math.round(sales?.total_amount).toFixed(2)}</div></th> 
        </tr>
        </tbody>
      </table>
      <div>
        <table style={{textAlign:'left'}}>
        <tr style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
        <div style={{paddingTop: '5px',paddingLeft: '5px'}}>Chargable Amount(in words)</div>
        <div style={{ paddingLeft:'5px'}}>INR {NumberToWordConverter.convert(isNaN(sales.total_amount)?0:Number(Math.round(sales.total_amount)).toFixed(2))}</div>
        </tr>
        
        <th style={{width: '40%',border:'none',textAlign:'left',verticalAlign:'text-bottom', borderCollapse: 'collapse'}}>
        
          <div style={{position:'relative',display:'inline-flex',left:'540px',top:'70px'}}>Authorised Signatory</div>
          
            <div style={{display:'inherit',paddingLeft: '5px'}}>Company's Bank Details</div>
            <tr>
              <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                <tr >
                  <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                    <div>Bank Name</div>
                    <div>A/c No. </div>
                    <div>IFS Code </div>
                  </th>
                  <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                    <div style={{ fontWeight: 'bold'}}>: {company?.bank_name}</div>
                    <div style={{ fontWeight: 'bold'}}>: {company?.account_no}</div>
                    <div style={{ fontWeight: 'bold'}}>: {company?.ifsc_code}</div>
                  </th>
                </tr>
              </th>
            </tr>
          </th>
          </table>
        <div style={{paddingTop: '5px'}}>
        <table style={{border:'none',textAlign:'left',paddingTop: '10px', borderCollapse: 'collapse',borderTop:'none'}}>
        <tr style={{width: '100%',borderLeft:'none', borderCollapse: 'collapse'}}>
          <th style={{width: '60%',border:'none',textAlign:'left', borderCollapse: 'collapse'}}>            
            <div>Declaration</div>
             Valid only for 15 days
          </th>
          
        </tr>
      </table>
      </div>
      </div>
      <div style={{marginTop: '30px', textAlign: 'center', width: '100%'}}>This is system generated.</div>
      </PDFExport>
      </div>
    </Root>
    <div style={{textAlign: 'center', marginTop: '10px'}}>
      <Pdf targetRef={ref}>
        {({ toPdf }) => <Button color="success" variant="contained" onClick={() => {
            if (pdfExportComponent.current) {
              pdfExportComponent.current.save();
            }
          }}>Download</Button>}
      </Pdf>
    </div>
   
    </>
  );
}
