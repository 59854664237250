import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mocks_
import account from '../../_mocks_/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import sidebarConfig from './SidebarConfig';
import {LoginContext} from '../../context/logincontext';
import { isAdminRole,isServiceCenter } from '../../utils/helper';
import { API_URL} from '../../constant';
import axios from 'axios';
import { includes, isEmpty } from 'lodash';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  // height:'70px',
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: '#5dad56'
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const context = useContext(LoginContext)
  const [sideConfig, setSideConfig] = useState([])
  const [userName, setUserName] = useState('')
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  useEffect(()=>{
    let finalSideBarData = sidebarConfig
    const isActive = context?.user?.company?.is_active
    
    async function get(){
      const res= await axios.get(`${API_URL}/dealer`)
      if(res.data.status === 'success'){
        let childDealers = res?.data?.data
        if(isAdminRole(context.user)){
          setSideConfig(finalSideBarData)
        }
        else if(!isAdminRole(context?.user) && isActive){
          finalSideBarData = sidebarConfig.filter(array => (array.title !== 'Admin' && array.title !== 'System User Reports'))
          const vehicleDetailSideBar= finalSideBarData.find(array=>(array.title === 'Vehicle Details'))
          const vehicleChildData = vehicleDetailSideBar?.children.filter(array=> array.title !== 'Vehicle List')
          finalSideBarData = isServiceCenter(context?.user) ? finalSideBarData : finalSideBarData.filter(array => {if(array.title === 'Vehicle Details'){array.children = vehicleChildData};return array})
            if(isEmpty(childDealers)){
              finalSideBarData = finalSideBarData.filter(array => array.title !=='Dealer Reports')
            }
            if(!context?.user?.is_show_ledger){
              const sideConfigData = finalSideBarData.find(array=>(array.title === 'Loan'))
              const childData = sideConfigData?.children.filter(array=>(!includes(['Loan Ledger','Rc Ledger','Security Ledger'],array.title)))
              finalSideBarData = finalSideBarData.filter(array => {if(array.title === 'Loan'){array.children = childData};return array})
            }
        }
        else if(!(isActive)){
          finalSideBarData=[]
        }
        setSideConfig(finalSideBarData)
      }
    }
    get()
    setUserName(context?.user?.user_name)
  },[context.user])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box >
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{fontSize:'18px', color: '#fff', fontWeight:'bold' }}>
                {userName}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'black', fontWeight:'normal',fontSize:'15px' }}>
              {context?.user?.company.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      <NavSection navConfig={sideConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: '#000000e0' }, 
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#000000e0',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
