import {filter} from 'lodash';
import {sentenceCase} from 'change-case';
import {useEffect,useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Link
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead,UserListToolbar,UserMoreMenu} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL, STATE, TYPE} from '../constant';
import Styles from '../css/filter.module.css'
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import moment from 'moment'


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'type',label: 'Type',alignRight: false},
  {id: 'created_at',label: 'Date',alignRight: false},
  {id: 'vendor_name',label: 'Supplier Name',alignRight: false},
  {id: 'po_number',label: 'Po Number',alignRight: false},
  {id: 'total_qty',label: 'Total Quantity',alignRight: false},
  {id: 'total_amount',label: 'Total Amount',alignRight: false},
  {id: 'state',label: 'State',alignRight: false}
];

// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
  if(b[orderBy] < a[orderBy]) {
    return -1;
  }
  if(b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order,orderBy) {
  return order === 'desc'
    ? (a,b) => descendingComparator(a,b,orderBy)
    : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
  const stabilizedThis = array?.map((el,index) => [el,index]);
  stabilizedThis?.sort((a,b) => {
    const order = comparator(a[0],b[0]);
    if(order !== 0) return order;
    return a[1] - b[1];
  });
  if(query) {
    return filter(array,(_user) => _user?.vendor_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
let vendorName = ''
let poNumber=''
let type=''

export default function PurchaseList() {
  const [page,setPage] = useState(0);
  const [value,setValue] = useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [order,setOrder] = useState('asc');
  const [selected,setSelected] = useState([]);
  const [orderBy,setOrderBy] = useState('name');
  const [filterName,setFilterName] = useState('');
  const [rowsPerPage,setRowsPerPage] = useState(10);
  const navigate=useNavigate()
  const handleRequestSort = (event,property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/purchase`);
      setValue(res?.data?.data);
      setFilteredValue(res.data.data)
    }
    if(isMobile){
      TABLE_HEAD.length = 3;
    }else{
      TABLE_HEAD.length = 7;
    }
    get()
  },[]);
  const handleChangePage = (event,newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value,10));
    setPage(0);
  };


  const handleFilter = (e) => {
    if(e.target.name === 'vendor_name'){
      vendorName = e.target.value
    }
    else if(e.target.name === 'po_number'){
      poNumber=e.target.value
    }
    else if(e.target.name === 'type'){
      type=e.target.value
    }
    filteredValue = value.filter(v => ((v.vendor_name || '').toLowerCase()).includes((vendorName).toLowerCase()) && ((v.po_number || '').toLowerCase()).includes((poNumber).toLowerCase())&& ((v.type || '').toLowerCase()).includes((type).toLowerCase()))
    setFilteredValue([...filteredValue])
  };

  const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(filteredValue,getComparator(order,orderBy));
  const isUserNotFound = value.length === 0;

  const download=(type,id)=>{
    switch (type) {
      case "order":navigate(`/dashboard/download_purchase_order/${id}`)
        break;
        case "bill":navigate(`/dashboard/invoice/${id}`)
        break;
      default:
        break;
    }
  }


  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Purchase List
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/purchase" underline="none" style={{paddingRight:'10px'}}>
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-bag"></i> Add Purchase Order</LoadingButton>
          </Link>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/purchase_bill" underline="none">
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-bag-plus"></i> Add  Bill</LoadingButton>
          </Link>
          </Stack>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{minWidth: 100}}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                <TableRow>
                <TableCell align="left"><TextField className={Styles.filter} name = 'type' onChange={handleFilter} autoComplete='off' /></TableCell>
                <TableCell> </TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name = 'vendor_name' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='po_number' onChange={handleFilter} autoComplete='off' /></TableCell>
                  </TableRow>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v,index)=>{
                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                          role="checkbox"
                        //   selected={isItemSelected}
                        //   aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                            //   checked={isItemSelected}
                              onChange={(event) => handleClick(event, v.po_number)}
                            />
                          </TableCell> */}
                          <TableCell style={{width:'130px'}}>
                           {v?.type==='return' ? <Button color="error" variant="contained" size="small" style={{marginLeft: '4px',color: 'white',width: '95px'}}> {v?.type?.toUpperCase()} </Button>: v?.type === 'order' ?<Button variant="contained" size="small" style={{marginLeft: '4px',color: 'white',backgroundColor: '#00B4D8',width: '95px'}} onClick={()=>download("order",v?.purchase_id)}> {v?.type?.toUpperCase()} </Button> : <Button color="success" variant="contained" size="small" style={{marginLeft: '4px',color: 'white',width: '95px'}} onClick={()=>download("bill",v?.purchase_id)}> {v?.type?.toUpperCase()} </Button> }
                           </TableCell>
                           <TableCell align="left" style={{width:'130px'}}>{moment(v?.created_at).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left" style={{width:'250px'}}>{v?.vendor_name}</TableCell>
                          <TableCell align="left" style={{width:'170px'}}>{v?.po_number}</TableCell>
                          {!isMobile && <>
                            <TableCell align="left" style={{width:'100px'}}>{v?.total_qty}</TableCell>
                          <TableCell align="left">{v?.total_amount}</TableCell>
                          <TableCell align="left">{v?.state}</TableCell>
                          </>}
                          <TableCell align="right">
                          {v?.state===STATE.DRAFT && v?.type===TYPE.ORDER && <UserMoreMenu linkTo={`/dashboard/purchase_order/${v?.purchase_id}/edit`}
                             view={`/dashboard/purchasedetail/${v?.purchase_id}`}
                             invoice={`/dashboard/download_purchase_order/${v?.purchase_id}`}/> }
                            {v?.state===STATE.DRAFT && v?.type !==TYPE.ORDER && <UserMoreMenu linkTo={`/dashboard/purchase_list/${v?.purchase_id}/edit`}
                             view={`/dashboard/purchasedetail/${v?.purchase_id}`}/> }
                            {v?.state===STATE.SUBMIT  && v.type===TYPE.ORDER && <UserMoreMenu invoice={`/dashboard/download_purchase_order/${v?.purchase_id}`}
                             convertBill={`/dashboard/purchase_list/${v?.purchase_id}/edit`} />}
                             {v?.state===STATE.SUBMIT && v?.type === TYPE.BILL && <UserMoreMenu invoice={`/dashboard/invoice/${v?.purchase_id}`}
                             list={`/dashboard/purchasedetail/${v?.purchase_id}`}
                             linkTo={`/dashboard/purchase_list/${v?.purchase_id}/edit`}
                             transaction={`/dashboard/transactions/${v?.purchase_id}/purchase`} />}
                             {v?.state===STATE.SUBMIT && v?.type === TYPE.RETURN && <UserMoreMenu
                            list={`/dashboard/purchasedetail/${v?.purchase_id}`} 
                            transaction={`/dashboard/transactions/${v?.purchase_id}/purchase`}
                            />}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{py: 3}}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5,10,25]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
