import * as yup from 'yup';
import {useContext,useState} from 'react';
import {Link as RouterLink,useNavigate} from 'react-router-dom';
import {useFormik,Form,FormikProvider} from 'formik';
import axios from 'axios';
import {get} from 'lodash'


// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import {API_URL,MOBILE_REGEX} from '../../../constant';
import {LoginContext} from '../../../context/logincontext';
import CustomizedSnackbars from '../../../pages/Alert/CustomizedSnackbars';



// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const context = useContext(LoginContext)
  const navigate = useNavigate()
  const [showPassword,setShowPassword] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [otpSubmitted, setOtpSubmitted] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [message,setMeassage] = useState('')
  const [severity,setSeverity] = useState('')
  const [isOpen,setIsOpen] = useState(false)
  const LoginSchema = yup.object().shape({
    mobile: yup.string().matches(MOBILE_REGEX,'Phone number is not valid').required('field cannot be empty'),
    password: yup.string().required('Password is required')
  });
  const resetPasswordSchema = yup.object().shape({
    mobile: yup.string().matches(MOBILE_REGEX,'Phone number is not valid').required('Please enter registered mobile number'),
  });
  const formik = useFormik({
    initialValues: {
      mobile: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (value) => {
      try {
        const response = await axios.post(`${API_URL}/login`,value);
        if(response.data.success === true) {
          context.mobile = value.mobile
          context.user = response.data.data
          navigate('/dashboard/app',{replace: true});
        } else if(response.data.success === false) {
          setMeassage('Incorrect Username or Password')
          setSeverity('info')
          setIsOpen(true);
        }
      } catch(err) {
        setMeassage('Something went wrong')
        setSeverity('error')
        setIsOpen(true);
        // alert(get(err,'error.message','Something went wrong'))
      }
    }
  });
  const ResetPassword = useFormik({
    initialValues: {
      mobile: '',
      otp: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (value) => {
      if(otpSubmitted){
        if(value.password === value.confirm_password){
          setConfirmPassword(false)
          const res = await axios.post(`${API_URL}/reset_password`,value)
          if(res.data.status === 'success'){
            navigate('/dashboard/app',{replace: true});
          }
        }else{
          setConfirmPassword(true)
        }
      }else if(otpSent){
        try{
          const res = await axios.post(`${API_URL}/reset_password`,value)
          if(res.data.status === 'success'){
            setOtpSubmitted(true)
            setOtpSent(false)
          }else{
            setMeassage('Enter Incorrect otp ')
            setSeverity('info')
            setIsOpen(true);
          }
        }
        catch(error){
            setMeassage('Enter Incorrect otp ')
            setSeverity('info')
            setIsOpen(true);
        }
      }else{
        try {
          const res = await axios.post(`${API_URL}/reset_password`,value)
          if(res.data.status === 'success'){
            setOtpSent(true)
          }
        } catch (error) {
          setMeassage('user not registered with this mobile')
          setSeverity('info')
          setIsOpen(true);
        }
      }
    }
  });

  const {errors,touched,values,isSubmitting,handleSubmit,getFieldProps} = formik;



  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const closeAlert = () => {
    setIsOpen(false);
    setMeassage('')
    setSeverity('')
  };

  return (
    <>
      {isOpen && <CustomizedSnackbars open={isOpen} onClose={closeAlert} message={message} severity={severity} close={closeAlert}/>}
      {!props.resetPassword &&
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                autoComplete="off"
                name="mobile"
                inputProps={{maxLength:10}}
                label="Mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
              <TextField
                fullWidth
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                name="password"
                label="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                label="Remember me"
              />

              <Link component={RouterLink} variant="subtitle2" to="/resetpassword" underline="hover">
                Forgot password?
              </Link>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{mt: 2}}
            >
              Login
            </LoadingButton>
          </Form>
        </FormikProvider>
      }
      {props.resetPassword &&
        <FormikProvider value={ResetPassword}>
          <Form autoComplete="off" noValidate onSubmit={ResetPassword.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                disabled={otpSent || otpSubmitted}
                fullWidth
                autoComplete="off"
                name="mobile"
                label="Mobile"
                inputProps={{maxLength: 10}}
                value={ResetPassword.values.mobile}
                onChange={ResetPassword.handleChange}
                error={ResetPassword.touched.mobile && Boolean(ResetPassword.errors.mobile)}
                helperText={ResetPassword.touched.mobile && ResetPassword.errors.mobile}
              />
              {otpSent &&
                <TextField
                  fullWidth
                  autoComplete="off"
                  type={'number'}
                  name="otp"
                  label="Enter OTP"
                  value={ResetPassword.values.otp}
                  onChange={ResetPassword.handleChange}
                />
              }
              {otpSubmitted &&
              <>
                <TextField
                fullWidth
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                name="password"
                label="Password"
                value={ResetPassword.values.password}
                onChange={ResetPassword.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
                <TextField
                fullWidth
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                name="confirm_password"
                label="Confirm Password"
                value={ResetPassword.values.confirm_password}
                onChange={ResetPassword.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={confirmPassword}
                helperText={confirmPassword && 'password does not match'}
              />
              </>
              }
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={ResetPassword.isSubmitting}
              sx={{mt: 2}}
            >
              Set Password
            </LoadingButton>
          </Form>
        </FormikProvider>
      }
    </>
  );
}
