import { Box, Grid, IconButton, Table, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { upperCase } from "lodash";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


export default function DownloadFile(props){
    // props = {data,download,onClose} // data=[all docType where filePath is not empty]

const download = (type,click) =>{
    const result = props.download(type) // return download link url
    return result
}
return (
    <div>
        <Box sx={{m: 2}}>
            <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={16} sm={11}>
                    <h1 style={{marginBottom:'20px',fontFamily:'Times New Roman'}}> Download Files Here</h1>
                        <Table>
                            <TableHead>
                                <TableRow>
                                        {props?.data?.map(type =>{
                                            return(
                                            <Grid container boxShadow={false} spacing={2} justifyContent='center' columns={{ xs: 1, sm:24 }}>
                                                <Grid item xs={12}>
                                                    <Typography color="text.primary" paddingBottom={1} >
                                                        {upperCase(type)}  
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TableCell>
                                                    <Tooltip title={<img src={download(type,'view')} alt={type} />} >    
                                                            <IconButton onClick={()=>window.open(download(type,'download'),'_blank')} >    
                                                                <CloudDownloadIcon color="secondary" sx={{ fontSize: 40 }} /> 
                                                            </IconButton>    
                                                        </Tooltip>
                                                    </TableCell>
                                                </Grid>
                                            </Grid>
                                            );
                                        })
                                        }
                                </TableRow>
                            </TableHead>
                        </Table>
                </Grid>
            </Grid>
        </Box>
    </div>
  );
}