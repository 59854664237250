import fileDefault from '../upload/cloud.png';
import filePdf from '../upload/pdf.png';
import filePng from '../upload/Png.png';
import fileJpg from '../upload/jpg1.png';

export const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    png: filePng,
    jpeg:fileJpg
}