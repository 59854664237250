import Footer from "./Footer";

export default function ContactUs(){
    return(<>
        <div style={{justifyContent:'center',paddingLeft:'300px',paddingTop:'300px',paddingBottom:'300px',paddingRight:'300px'}}>
                <div style={{boxSizing:'border-box',display:'block',fontSize:'14px',lineHeight:1}}>
                    <div style={{paddingBottom:'16px', paddingTop:'16px', paddingRight:'16px',position:'relative',display:'block'}}>
                        <span style={{fontSize:'24px',color:'darkgray',lineHeight:'36px',display:'block'}}>
                            <h3 style={{color:'green',borderBottom:'2px solid',paddingBottom:'3px',fontSize:'28px',fontWeight:720,lineHeight:1}}>Contact Us</h3>
                        </span>
                        <span style={{fontSize:'14px',color:'black',display:'block'}}></span>
                    </div>
                    <div style={{justifyContent:'space-between',color:'black',fontSize:'24px'}}>
                    Address
                    </div>
                    <div style={{justifyContent:'space-between',color:'black',fontSize:'18px',paddingTop:'10px'}}>
                    Ascend Capital, 601-610,
                    </div>
                    <div style={{justifyContent:'space-between',color:'black',fontSize:'18px',paddingTop:'10px'}}>
                    4th Floor, Anchor Mall,
                    </div>
                    <div style={{justifyContent:'space-between',color:'black',fontSize:'18px',paddingTop:'10px'}}>
                    Civil Lines, Jaipur 302006
                    </div>
                    <div style={{justifyContent:'space-between',color:'black',fontSize:'18px',paddingTop:'10px'}}>
                        Mobile : 9773355846
                    </div>
                    <span style={{fontSize:'14px',color:'black',display:'block'}}></span>
                    <div style={{justifyContent:'space-between',color:'black',fontSize:'18px',paddingTop:'10px'}}>
                        Email : accounts@lcgmtech.com.
                    </div>
                </div>
            </div>
    <Footer />
    </>)
}