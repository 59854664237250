// material
import {Box,Grid,Container,Typography,ToggleButtonGroup,ToggleButton} from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
  AppOrderTimeline,
  AppConversionRates,
  AppWebsiteVisits,
} from '../sections/@dashboard/app';
import {Link} from 'react-router-dom';
import {useContext} from 'react';
import {LoginContext} from '../context/logincontext';
import Company from './EditCompany'
import { isAdminRole } from '../utils/helper';
import NetBalance from '../sections/@dashboard/app/NetBalance';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const context = useContext(LoginContext)
  const isActive = context?.user?.company?.is_active
  return (
    <Page>
      <Container maxWidth="xl">
        <Box sx={{pb: 5}}>
          <Typography variant="h4">Welcome {context.user.user_name}</Typography>
        </Box>
        {(isActive) &&
          <Grid container spacing={3}>
              <Grid item xs={12} sm={5} md={2.4}>
              <Link to={'/dashboard/leads'} style={{textDecoration: 'none'}}><AppItemOrders /></Link>
            </Grid>
            <Grid item xs={12} sm={5} md={2.4}>
              <Link to={'/dashboard/sales_list'} style={{textDecoration: 'none'}}><AppWeeklySales /></Link>
            </Grid>
            <Grid item xs={12} sm={5} md={2.4}>
              <Link to={'/dashboard/purchase_list'} style={{textDecoration: 'none'}}><AppNewUsers /></Link>
            </Grid>
           
            <Grid item xs={12} sm={5} md={2.4}>
              <Link to={'/dashboard/products'} style={{textDecoration: 'none'}}><AppBugReports /></Link>
            </Grid>
            <Grid item xs={12} sm={5} md={2.4}>
              <Link to={'/dashboard/loan_ledger'} style={{textDecoration: 'none'}}><NetBalance /></Link>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <AppOrderTimeline title={'Purchase Timeline'} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <AppOrderTimeline title={'Sales Timeline'} />
            </Grid>
            {isAdminRole(context?.user)  &&
        <>
            <Grid item xs={12} md={6} lg={6}>
              <AppConversionRates title={'Top 5 Dealers'} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <AppWebsiteVisits title={'Top 3 Dealer'} />
            </Grid>
            </>
            }
          </Grid>
        }
       
      </Container>
      {!isActive &&
        <Company />
      }
    </Page>
  );
}
