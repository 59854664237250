import * as yup from 'yup';
import {useNavigate,useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {MenuItem,Select,TextField,Typography,FormControl,InputLabel,LinearProgress,styled,Autocomplete} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL,NumberToWordConverter} from '../constant';
import {useContext,useEffect,useState} from 'react';
import {LoginContext} from '../context/logincontext';
import Iconify from '../components/Iconify';
import Styles from '../css/dates.module.css'
import {isEmpty, isNaN} from 'lodash';
import ConfirmDialog from '../components/confirmDialog';
import moment from 'moment';
// ----------------------------------------------------------------------


const validationSchema = yup.object({

});

const Input = styled('input')({
  display: 'none',
});

export default function AddTransaction(props) {
  let {id,type} = useParams()
  const context = useContext(LoginContext)
  const navigate = useNavigate();
  const [userData] = useState(context.user);
  const [docs,setDocs] = useState(undefined)
  const [progress,setProgress] = useState({
    doc: 0,
  })
  const [userCompanyId,setUserCompanyId] = useState()
  const [financers,setFinancers] = useState([])
  const [suppliers,setSuppliers] = useState([])
  const [customers,setCustomers] = useState([])
  const [insurer,setInsurer] = useState([])
  const [menu,setMenu] = useState([])
  const [counterParty,setCounterParty] = useState([])
  const [formvalue,setFormValue] = useState({})
  const [open,setOpen] = useState(false)
  const [partyName,setPartyName] = useState()
  const [returnType,setReturnType] = useState()
  const [otherPartyIds,setOtherPartyIds] = useState({
    financer_id: null,
    insurer_id: null,
    customer_id: null,
    vendor_id: null
  })
  const formik = useFormik({
    initialValues: {
      type: type,
      amount: '',
      payment_mode: '',
      txn_date: moment().format('YYYY-MM-DD'),
      txn_ref_no: '',
      ref_id: id || null,
      remarks: '',
      other_party_name: '',
      other_party_type: '',
      created_by: userData.user_id,
      user_id: userData.user_id,
      transaction_type: '',
      other_party_id: '',
      category: ''
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });


  async function submitForm(value) {
    if(!isEmpty(partyName)) {
      value.counter_party_id = partyName?.counter_party_id
      value.counter_party_name = partyName?.name
    }
    if(!value.other_party_id) {
      value.other_party_id = null
    }
    const data = new FormData();
    const files = []
    if(!isEmpty(docs)) {
      for(let doc of docs) {
        files.push({fileType: "transaction_document",file: doc})
      }
    }
    data.append('data',JSON.stringify(value));
    files.forEach((file) => {
      data.append(file.fileType,file.file);
    })
    let response
    if(id && type === 'expense'){
      response = await axios.put(`${API_URL}/transaction/${id}`,data);
    }
    else{
      response = await axios.post(`${API_URL}/transaction`,data);
    }
    if(response.data.status === 'success') {
      if(type === 'expense'){
        navigate('/dashboard/expense_list',{replace: true});
      }
      else if(type) {
        navigate(`/dashboard/transactions/${id}/${type}`,{replace: true});
      }
      else {
        navigate('/dashboard/all_transactions',{replace: true});
      }
    }
  }
  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/financer`);
      if(res.data.status === 'success') {
        const financerData = res?.data?.data
        setFinancers([...financerData])
      }
      const res1 = await axios.get(`${API_URL}/vendor`);
      if(res1.data.status === 'success') {
        const supplierData = res1?.data?.data
        setSuppliers([...supplierData])
      }
      const res2 = await axios.get(`${API_URL}/data/leads`);
      if(res2.data.status === 'success') {
        const customerData = res2?.data?.data
        setCustomers([...customerData])
      }
      const res3 = await axios.get(`${API_URL}/insurers`);
      if(res3.data.status === 'success') {
        const insurerData = res3?.data?.data
        setInsurer([...insurerData])
      }
      if(type === 'purchase') {
        const response = await axios.get(`${API_URL}/purchase/data/${id}`);
        if(response.data.status === 'success') {
          const id = response.data.data[0].user_company.user_company_id;
          setUserCompanyId(id)
          setReturnType(response.data.data[0].type)
          setOtherPartyIds({
            financer_id: response?.data?.data[0]?.financer_id,
            insurer_id: response?.data?.data[0]?.insurer_id,
            customer_id: response?.data?.data[0]?.lead?.lead_id,
            vendor_id: response?.data?.data[0]?.vendor?.vendor_id
          })
          formik.setFieldValue('other_party_id',response.data.data[0].vendor.vendor_id || null)
          formik.setFieldValue('other_party_type','Vendor')
        }
      }
      else if(type === 'expense' && id){
        const data = await axios.get(`${API_URL}/transaction/${id}`);
        if(data.data.status === 'success') {
          const transactionData = data?.data?.data
          setPartyName({
            counter_party_id:transactionData?.counter_party_id,
            name:transactionData?.counter_party_name
          })
          formik.setFieldValue('category',transactionData?.category)
          formik.setFieldValue('amount',transactionData?.amount)
          formik.setFieldValue('payment_mode',transactionData?.payment_mode)
          formik.setFieldValue('txn_date',transactionData?.txn_date ? transactionData.txn_date : null)
          formik.setFieldValue('remarks',transactionData?.remarks)
          formik.setFieldValue('txn_ref_no',transactionData?.txn_ref_no)
          setDocs(transactionData?.transaction_document);
        }
      }
       else {
        const response = await axios.get(`${API_URL}/sales/data/${id}`);
        if(response.data.status === 'success') {
          const id = response.data.data[0].user_company.user_company_id;
          setUserCompanyId(id)
          setReturnType(response.data.data[0].type)
          setOtherPartyIds({
            financer_id: response?.data?.data[0]?.financer_id,
            insurer_id: response?.data?.data[0]?.insurer_id,
            customer_id: response?.data?.data[0]?.lead?.lead_id,
            vendor_id: response?.data?.data[0]?.vendor?.vendor_id
          })
          formik.setFieldValue('other_party_id',response.data.data[0].lead.lead_id || null)
          formik.setFieldValue('other_party_type','Customer')
        }
      }
    }
    if(id) {
      get()
    }
  },[]);
  const uploadDocuments = (e,type) => {
    if(type) {
      setDocs(Object.values(e.target.files))
      e.target.files.length > 0 ? setProgress({...progress,doc: 100}) : setProgress({...progress,doc: 0})
    }
  }
  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/counter_party`);
      if(res.data.status === 'success') {
        setCounterParty([...res.data.data])
      }
    }
    get()
  },[]);
  useEffect(() => {
    if(partyName) {
      const data = counterParty.find(data => data.counter_party_id === partyName?.counter_party_id)
      formik.setFieldValue('category',data.category)
    }
  },[partyName])

  useEffect(() => {
    if(financers && suppliers && insurer && customers) {
      switch(formik.values.other_party_type) {
        case 'Financer':
          formik.setFieldValue('other_party_id',otherPartyIds?.financer_id)
          setMenu(() => financers.map(value => <MenuItem value={value?.financer_id}>{value?.financer_name}</MenuItem>))
          break;
        case 'Vendor':
          formik.setFieldValue('other_party_id',otherPartyIds?.vendor_id)
          setMenu(() => suppliers.map(value => <MenuItem value={value?.vendor_id}>{value?.vendor_name}</MenuItem>))
          break;
        case 'Insurer':
          formik.setFieldValue('other_party_id',otherPartyIds?.insurer_id)
          setMenu(() => insurer.map(value => <MenuItem value={value?.insurer_id}>{value?.insurer_name}</MenuItem>))
          break;
        case 'Customer':
          formik.setFieldValue('other_party_id',otherPartyIds?.customer_id)
          setMenu(() => customers.map(value => <MenuItem value={value?.lead_id}>{value?.name}</MenuItem>))
          break;
        default:
          break;
      }
    }
  },[formik.values.other_party_type])
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handlePartyChange = (name) => {
    setPartyName(name)
  }
  const createNewParty = () => {
    navigate('/dashboard/counter_party',{replace: true});
  }
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{paddingLeft: '20px'}} variant="h4" gutterBottom>
        {type === 'expense' ? id ? 'Edit Expense' : 'Add Expense' : 'Add Transaction'}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={11} sm={6}>
              {type === 'expense' ?
                <Autocomplete
                  id="counter_party_id"
                  name='counter_party_name'
                  options={counterParty}
                  getOptionLabel={(option) => option?.name || ''}
                  noOptionsText={<LoadingButton onClick={createNewParty}>Add Counter Party Name</LoadingButton>}
                  renderInput={(params) => <TextField {...params} label="Counter Party Name" />}
                  onChange={(event,newValue) => {
                    handlePartyChange(newValue)
                  }}
                  value={partyName || {}}
                  error={formik.touched.counter_party_name && Boolean(formik.errors.counter_party_name)}
                  helpertext={formik.touched.counter_party_name && formik.errors.counter_party_name}
                />
                :
                <TextField
                  select
                  fullWidth
                  labelId="transaction_type"
                  id="transaction_type"
                  name="transaction_type"
                  label="Transaction Type"
                  value={formik.values.transaction_type}
                  onChange={formik.handleChange}
                  error={formik.touched.transaction_type && Boolean(formik.errors.transaction_type)}
                  helpertext={formik.touched.transaction_type && formik.errors.transaction_type}
                >
                  <MenuItem value={'Credit'}>Credit</MenuItem>
                  <MenuItem value={'Debit'}>Debit</MenuItem>
                </TextField>
              }
            </Grid>
            {type === 'expense' &&
              <Grid item xs={11} sm={6}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="category"
                  type={'text'}
                  label="Category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  error={formik.touched.category && Boolean(formik.errors.category)}
                />
              </Grid>
            }
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="amount"
                type={'number'}
                label="Amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={Boolean(formik.values.amount) && NumberToWordConverter.convert(formik.values.amount)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="payment_mode">Payment Mode</InputLabel>
                <Select
                  fullWidth
                  labelId='payment_mode'
                  id="payment_mode"
                  name="payment_mode"
                  label="Payment Mode"
                  value={formik.values.payment_mode}
                  onChange={formik.handleChange}
                  error={formik.touched.payment_mode && Boolean(formik.errors.payment_mode)}
                  helpertext={formik.touched.payment_mode && formik.errors.payment_mode}
                >
                  <MenuItem value={'UPI'}>UPI</MenuItem>
                  <MenuItem value={'CASH'}>CASH</MenuItem>
                  <MenuItem value={'CHEQUE'}>CHEQUE</MenuItem>
                  <MenuItem value={'NEFT'}>NEFT</MenuItem>
                  <MenuItem value={'IMPS'}>IMPS</MenuItem>
                  <MenuItem value={'DD'}>DD</MenuItem>
                  <MenuItem value={'NACH'}>NACH</MenuItem>
                  {(type==='Sales' && returnType==='return') && <MenuItem value={'CREDIT NOTE'}>CREDIT NOTE</MenuItem>}
                  {(type==='purchase'&& returnType==='return') && <MenuItem value={'DEBIT NOTE'}>DEBIT NOTE</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="txn_date"
                type={'Date'}
                className={formik.values.txn_date === null && Styles.dates}
                label="Transaction Date"
                value={formik.values.txn_date}
                onChange={formik.handleChange}
                error={formik.touched.txn_date && Boolean(formik.errors.txn_date)}
                helperText={formik.touched.txn_date && formik.errors.txn_date}
              />
            </Grid>
            {type !== 'expense' &&
              <>
                <Grid item xs={11} sm={6}>
                  <TextField
                    select
                    fullWidth
                    id="other_party_type"
                    name="other_party_type"
                    label="Other Party Type"
                    value={formik.values.other_party_type}
                    onChange={formik.handleChange}
                    error={formik.touched.other_party_type && Boolean(formik.errors.other_party_type)}
                    helpertext={formik.touched.other_party_type && formik.errors.other_party_type}
                  >
                    <MenuItem value={'Customer'}>Customer</MenuItem>
                    <MenuItem value={'Insurer'}>Insurer</MenuItem>
                    <MenuItem value={'Financer'}>Financer</MenuItem>
                    <MenuItem value={'Vendor'}>Vendor</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={11} sm={6}>
                  <TextField
                    select
                    fullWidth
                    id="other_party_id"
                    name="other_party_id"
                    label="Other Party Name"
                    value={formik.values.other_party_id}
                    onChange={formik.handleChange}
                    error={formik.touched.other_party_id && Boolean(formik.errors.other_party_id)}
                    helpertext={formik.touched.other_party_id && formik.errors.other_party_id}
                  >{menu}
                  </TextField>
                </Grid>
              </>
            }
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="txn_ref_no"
                type={'text'}
                label="Reference No."
                value={formik.values.txn_ref_no}
                onChange={formik.handleChange}
                error={formik.touched.txn_ref_no && Boolean(formik.errors.txn_ref_no)}
                helperText={formik.touched.txn_ref_no && formik.errors.txn_ref_no}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="remarks"
                type={'text'}
                label="Remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helperText={formik.touched.remarks && formik.errors.remarks}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{textAlign: 'center',marginTop: '20px'}}>
              <div style={{display: 'flex',justifyContent: 'center'}}>
                <label htmlFor="doc">
                  <Input accept="image/*,application/pdf" id="doc" type="file" multiple onChange={(e) => uploadDocuments(e,"doc")} />
                  <Iconify icon="entypo:upload" sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                </label>
                <div>
                  <LinearProgress variant='determinate' value={progress.doc} sx={{width: '200px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                  <Typography>Upload Payment Receipt</Typography>
                </div>
              </div>
              <div>
                {docs?.map(doc => <span style={{fontSize: '10px'}}>{doc.name}, </span>)}
              </div>
            </Grid>

          </Grid>
        </Box>
        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}