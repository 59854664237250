import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
  const vertical= 'top'
  const horizontal= 'center'
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={props.open}  onClose={props.close} anchorOrigin={{vertical,horizontal}}>
        <Alert onClose={props.close} severity={props.severity} sx={{ width: '100%' }}>
          {props.message} !
        </Alert>
      </Snackbar>
    </Stack>
  );
}
