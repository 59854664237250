// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import {BaseOptionChartStyle} from './components/charts/BaseOptionChart';
import LoginContextProvider from './context/logincontext';

// ----------------------------------------------------------------------

export default function App(props) {
  return (
    <LoginContextProvider>
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <Router pathName={props.pathName} />
        </ThemeConfig>
    </LoginContextProvider>
  );
}
