import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
/// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL, STATE, TYPE} from '../constant';
import {LoginContext} from '../context/logincontext';
import { LoadingButton } from '@mui/lab';
import CsvDownloader from 'react-csv-downloader';
import { Box } from '@mui/system';
import moment from 'moment'
import Styles from '../css/dates.module.css'
// ----------------------------------------------------------------------

const PURCHASE_HEAD = [
    { id: 'vendor_name', label: 'Supplier Name', alignRight: false },
    { id: 'po_number', label: 'Po Number', alignRight: false },
    { id: 'total_qty', label: 'Total Quantity', alignRight: false },
    { id: 'amount', label: 'Amount Without Gst', alignRight: false },
    { id: 'gst_amount', label: 'Gst Amount', alignRight: false },
    { id: 'total_amount', label: 'Total Amount', alignRight: false },
    { id: 'updated_at', label: 'Purchase Date', alignRight: false },
   
  ];

  const PURCHASE_HEAD1 = [
    { id: 'vendor_name', displayName: 'Supplier Name'},
    { id: 'po_number', displayName: 'Po Number'},
    { id: 'total_qty', displayName: 'Total Quantity'},
    { id: 'amount', displayName: 'Amount Without Gst'},
    { id: 'igst_amount', displayName: 'IGST'},
    { id: 'cgst_amount', displayName: 'CGST'},
    { id: 'sgst_amount', displayName: 'SGST'},
    { id: 'total_amount', displayName: 'Total Amount'},
   
  ];

  const SALES_HEAD = [
    {id: 'customer_name', label: 'Customer Name', alignRight: false },
    { id: 'invoice_number', label: 'Invoice Number', alignRight: false },
    { id: 'totel_items', label: 'Total Items', alignRight: false },
    { id: 'amount_without_gst', label: 'Amount Without Gst', alignRight: false },
    { id: 'discount_amount', label: 'Discount Amount', alignRight: false },
    { id: 'gst_amount', label: 'Gst Amount', alignRight: false },
    { id: 'total_amount', label: 'Total Amount', alignRight: false },
    { id: 'updated_at', label: 'Sales Date', alignRight: false },
   
  ];

  const SALES_HEAD1 = [
    {id: 'customer_name', displayName: 'Customer Name',},
    { id: 'invoice_number', displayName: 'Invoice Number',},
    { id: 'totel_items', displayName: 'Total Items',},
    { id: 'amount_without_gst', displayName: 'Amount Without Gst',},
    { id: 'discount_amount', displayName: 'Discount Amount',},
    { id: 'igst_amount', displayName: 'IGST'},
    { id: 'cgst_amount', displayName: 'CGST'},
    { id: 'sgst_amount', displayName: 'SGST'},
    { id: 'total_amount', displayName: 'Total Amount',},
   
  ];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => ((_user?.po_number) || (_user?.invoice_number))?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DealerInventory() {
    const {id,type,company_id} = useParams()
  const context = useContext(LoginContext)
  const [page, setPage] = useState(0);
  const[value,setValue]=useState([]);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sales,setSales] = useState([])
  const [filteredSales,setFilteredSales] = useState([])
  const [ purchase , setPurchase] = useState([])
  const [filteredPurchase, setFilteredPurchase] = useState([])
  const [fromDate , setFromDate]=useState(null)
  const [toDate , setToDate] = useState(null)
  const [dealer,setDealer] = useState()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };

  const handleChange = (e) => {
    if(e.target.name === 'from_date'){
      setFromDate(e.target.value)
    }else{
      setToDate(e.target.value)
    }
  }

  const getDataByDate = () => {
    const fDate = new Date(fromDate).getTime()
    const tDate = new Date(toDate).getTime()
    if(type==='purchase'){
      const filterData = purchase.filter(data => (fDate <= (new Date(data.updated_at)).getTime() && (new Date(data.updated_at)).getTime() <=  tDate))
      setValue([...filterData])
    }else{
      const filterData = sales.filter(data => (fDate <= (new Date(data.updated_at)).getTime() && (new Date(data.updated_at)).getTime() <=  tDate))
      setValue([...filterData])
    }
  }
  useEffect(() => {
    async function get() {
      const res= await axios.get(`${API_URL}/dealer`);
      if(res.data.status==='success'){
        let dealerData=res?.data?.data
        dealerData = dealerData.filter(d=>d.company_id===Number(company_id))
        dealerData = dealerData[0].system_users
        dealerData = dealerData.filter(d=>d.user_id===Number(id))
        dealerData = dealerData[0]
        setDealer(dealerData)
        if(type === 'sales'){
          setSales(dealerData?.sales)
          setValue(dealerData?.sales)
        }else{
          setPurchase(dealerData?.purchases)
          setValue(dealerData?.purchases)
        }
      }
    //   setValue(res.data.data);
    }
    get()
  },[type]);
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - value.length) : 0;

   const filteredUsers = applySortFilter(value, getComparator(order, orderBy), filterName);
  const isUserNotFound = value.length === 0;
  

  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {(type==='sales')? 'Sales Report' : 'Purchase Reports'} Of {dealer?.user_name}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='left'>
          <Grid item xs={4} sm={2.5}>
          <TextField
            id="from_date"
            type="date"
            autoComplete='off'
            name="from_date"
            label="From Date"
            className={fromDate === null && Styles.dates}
            onChange={handleChange}
            value={fromDate}
          />
          </Grid>
          <Grid item xs={4} sm={2.5}>
          <TextField
            id="to_date"
            type="date"
            autoComplete='off'
            name="to_date"
            label="To Date"
            className={toDate === null && Styles.dates}
            onChange={handleChange}
            value={toDate}
          />
          </Grid>
          <Grid item xs={4} sm={1.8}>
          <LoadingButton size="large" type="submit" variant="contained" onClick={getDataByDate}><span>Get Report</span></LoadingButton>
          </Grid>
          <Grid item xs={4} sm={4}>
          <CsvDownloader
          filename={`${dealer?.user_name}/${type}`}
          extension=".csv"
          separator=";"
          wrapColumnChar=""
          columns={(type==='sales')? SALES_HEAD1 : PURCHASE_HEAD1}
          datas={(type==='sales')? value : value}>
          <LoadingButton size="large" color='secondary' type="submit" variant="contained"><i className="bi bi-bar-chart-fill"></i>Export Report</LoadingButton>
          </CsvDownloader>
          </Grid>
          </Grid>
          </Box>
        
        <Card>
          <UserListToolbar
            isOpen={true}
            title={type==='sales' ? 'Search Invoice Number':'Search Po Number'}
            name={type==='sales' ? 'Search Invoice Number.......':'Search Po Number.......'}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
            {(type==='purchase') && <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={PURCHASE_HEAD}
                  rowCount={purchase?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v,index)=>{

                  
                  /*{filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, role, status, company, avatarUrl, isVerified } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;} */

                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                          role="checkbox"
                        //   selected={isItemSelected}
                        //   aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                            //   checked={isItemSelected}
                              onChange={(event) => handleClick(event, v.po_number)}
                            />
                          </TableCell> */}
                          <TableCell align="left">{v?.vendor_name}</TableCell>
                          <TableCell align="left">{v?.po_number}</TableCell>
                          <TableCell align="left">{v?.total_qty}</TableCell>
                          <TableCell align="left">{v?.amount}</TableCell>
                          <TableCell align="left">{Number(v?.igst_amount)+Number(v?.cgst_amount)+Number(v?.sgst_amount)}</TableCell>
                          <TableCell align="left">{v.total_amount}</TableCell>
                          <TableCell align="left">{moment(v.updated_at).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">
                            {/* <Label
                              variant="ghost"
                              color={(status === 'banned' && 'error') || 'success'}
                            >
                              {sentenceCase(status)}
                            </Label> */}
                          </TableCell>

                          {/* <TableCell align="right">
                          <UserMoreMenu list={`/dashboard/purchasedetail/${v.purchase_id}`}/>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
                }
            {(type==='sales') && <Table size='small'>
                <UserListHead
                  order={order}
                  label='Sales List'
                  orderBy={orderBy}
                  headLabel={SALES_HEAD}
                  rowCount={sales?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v,index)=>{

                  
                  /*{filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, role, status, company, avatarUrl, isVerified } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;} */

                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                        >
                          <TableCell align="left">{v.customer_name}</TableCell>
                          <TableCell align="left">{v.invoice_number}</TableCell>
                          <TableCell align="left">{v.totel_items}</TableCell>
                          <TableCell align="left">{v.amount_without_gst}</TableCell>
                          <TableCell align="left">{v.discount_amount}</TableCell>
                          <TableCell align="left">{Number(v?.igst_amount)+Number(v?.cgst_amount)+Number(v?.sgst_amount)}</TableCell>
                          <TableCell align="left">{v.total_amount}</TableCell>
                          <TableCell align="left">{moment(v.updated_at).format('DD-MM-YYYY')}</TableCell>
                          {/* <TableCell align="right">
                          <UserMoreMenu list={`/dashboard/sales_detail/${v.sales_id}`}/>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            }
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
