import React,{useContext,useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Badge, IconButton, LinearProgress,Tooltip,Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL,FILE_UPLOAD_TYPE, LEAD_TYPE} from '../../../../constant';
import ConfirmDialog from '../../../../components/confirmDialog';
import {LoadingButton} from '@mui/lab';
import {LeadContext} from '../../../../context/leadContext';
import Iconify from '../../../../components/Iconify';
import {isEmpty, upperFirst} from 'lodash';
import {useSnackbar} from 'notistack';
import { useFormik } from 'formik';
import { withStyles } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';

const Input = styled('input')({
    display: 'none',
});

const styles = {
    tooltip: {
        maxWidth:'max-content',
        backgroundColor: "black"
    }
};

const CustomTooltip = withStyles(styles)(Tooltip);

function Index(props) {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {leadId,setAlignment,setMeassage,setIsOpen,setSeverity,customerRelationData, setCustomerRelationData,setUploadWarning} = useContext(LeadContext)
    const [leadData,setLeadData] = useState({})
    const [open,setOpen] = useState(false)
    const [formvalue,setFormValue] = useState({})
    const [bankStatement,setBankStatement] = useState(undefined)
    const [electricityBill,setElectricityBill] = useState(undefined)
    const [aadharCard,setAadhaarCard] = useState(undefined)
    const [panCard,setPanCard] = useState(undefined)
    const [kyc,setKYC] = useState(undefined)
    const [voterId,setVoterId] = useState(undefined)
    const [drivingLicense,setDrivingLicense] = useState(undefined)
    const [vehicleInsurance,setVehicleInsurance] = useState(undefined)
    const [vehicleRc,setVehicleRc] = useState(undefined)
    const [fiReport,setFiReport] = useState(undefined)
    const [vehicleInvoice,setVehicleInvoice] = useState(undefined)
    const [salesPhotos,setSalesPhotos] = useState(undefined)
    const [agreementPhoto,setAgreementPhoto] = useState(undefined)
    const [nachPhoto,setNachPhoto] = useState(undefined)
    const [profilePhoto,setProfilePhoto] = useState(undefined)
    const [salesObservationSheet,setSalesObservationSheet] = useState(undefined)
    const [vehiclePhoto,setVehiclePhoto] = useState(undefined)
    const [otherDocuments,setOtherDocuments] = useState(undefined)
    const [relationDocs,setRelationDocs] = useState([])
    const [text, setText] = useState('')
    const [submitButtonName, setSubmitButtonName] = useState('')
    const [closeButtonName, setCloseButtonName] = useState('')
    const [vehicleQuotation,setVehicleQuotation] = useState(undefined)
    const [agreement,setAgreement] = useState(undefined)
    const [isSubmit, setIsSubmit] = useState(true)
    const [progress,setProgress] = useState({
        bankStatement: 0,
        electricityBill: 0,
        aadharCard: 0,
        panCard: 0,
        kyc: 0,
        voterId: 0,
        drivingLicense: 0,
        vehicleRc: 0,
        fiReport: 0,
        salesPhotos: 0,
        vehicleInvoice: 0,
        vehicleInsurance: 0,
        agreementPhoto: 0,
        nachPhoto: 0,
        profilePhoto: 0,
        salesObservationSheet: 0,
        vehiclePhoto: 0,
        otherDocuments: 0,
        vehicleQuotation: 0,
        agreement:0
    });
    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
        },
        onSubmit: async (value) => {
            if(!props?.name){
                if((isEmpty(leadData?.aadhaar_file_path))){
                    if(!isEmpty(aadharCard)){
                        setOpen(true)
                    }else{
                        enqueueSnackbar(`Upload Aadhaar card documents !`,{variant: 'warning',anchorOrigin: {vertical: 'top',horizontal: 'center'}});
                    }
                }else if((isEmpty(leadData?.pan_file_path))){
                    if(!isEmpty(panCard)){
                        setOpen(true)
                    }else{
                        enqueueSnackbar(`Upload Pan card documents !`,{variant: 'warning',anchorOrigin: {vertical: 'top',horizontal: 'center'}});
                    }
                }else{
                    setOpen(true)
                }
            }else{
                setOpen(true)
            }
            
        }
    });

    async function get(id) {
        const res = await axios.get(`${API_URL}/leads/${id}`);
        if(res?.data?.status === 'success'){
          setLeadData(res?.data?.data);
          setBankStatement(undefined)
          setElectricityBill(undefined)
          setAadhaarCard(undefined)
          setPanCard(undefined)
          setKYC(undefined)
          setVoterId(undefined)
          setDrivingLicense(undefined)
          setVehicleInsurance(undefined)
          setVehicleRc(undefined)
          setFiReport(undefined)
          setVehicleInvoice(undefined)
          setSalesPhotos(undefined)
          setAgreementPhoto(undefined)
          setNachPhoto(undefined)
          setProfilePhoto(undefined)
          setSalesObservationSheet(undefined)
          setVehiclePhoto(undefined)
          setOtherDocuments(undefined)
          setVehicleQuotation(undefined)
          setAgreement(undefined)
        }
    }

    useEffect(() => {
        if(!props?.name){
            if((leadData?.aadhaar_file_path?.length>0 || leadData?.is_aadhaar_verified===true) && (leadData?.pan_file_path?.length>0 || leadData?.is_pan_verified) && leadData?.bank_statement_path?.length>0 && leadData?.electricity_bill_path?.length>0){
                setUploadWarning(false)
            }
            else{
                setUploadWarning(true)
            }
        }
    },[leadData]);

    async function submitForm() {

        const data = new FormData();
        const files = []
        if(!isEmpty(bankStatement)) {
            for(let doc of bankStatement) {
                files.push({fileType: FILE_UPLOAD_TYPE.BANK_STATEMENT,file: doc})
            }
        }
        if(!isEmpty(electricityBill)) {
            for(let doc of electricityBill) {
                files.push({fileType: FILE_UPLOAD_TYPE.ELECTRICITY_BILL,file: doc})
            }
        }
        if(!isEmpty(aadharCard)) {
            for(let doc of aadharCard) {
                files.push({fileType: FILE_UPLOAD_TYPE.AADHAR,file: doc})
            }
        }

        if(!isEmpty(panCard)) {
            for(let doc of panCard) {
                files.push({fileType: FILE_UPLOAD_TYPE.PAN,file: doc})
            }
        }
        if(!isEmpty(kyc)) {
            for(let doc of kyc) {
                files.push({fileType: FILE_UPLOAD_TYPE.OTHER_KYCS,file: doc})
            }
        }
        if(!isEmpty(voterId)) {
            for(let doc of voterId) {
                files.push({fileType: FILE_UPLOAD_TYPE.VOTER_ID,file: doc})
            }
        }
        if(!isEmpty(drivingLicense)) {
            for(let doc of drivingLicense) {
                files.push({fileType: FILE_UPLOAD_TYPE.DRIVING_LICENCE,file: doc})
            }
        }
        if(!isEmpty(vehicleInvoice)) {
            for(let doc of vehicleInvoice) {
                files.push({fileType: FILE_UPLOAD_TYPE.VEHICLE_INVOICE,file: doc})
            }
        }
        if(!isEmpty(vehicleRc)) {
            for(let doc of vehicleRc) {
                files.push({fileType: FILE_UPLOAD_TYPE.VEHICLE_RC,file: doc})
            }
        }
        if(!isEmpty(fiReport)) {
            for(let doc of fiReport) {
                files.push({fileType: FILE_UPLOAD_TYPE.FI_REPORT,file: doc})
            }
        }
        if(!isEmpty(vehicleInsurance)) {
            for(let doc of vehicleInsurance) {
                files.push({fileType: FILE_UPLOAD_TYPE.VEHICLE_INSURANCE,file: doc})
            }
        }
        if(!isEmpty(salesPhotos)) {
            for(let doc of salesPhotos) {
                files.push({fileType: FILE_UPLOAD_TYPE.SALES_PHOTOS,file: doc})
            }
        }
        if(!isEmpty(agreementPhoto)) {
            for(let doc of agreementPhoto) {
                files.push({fileType: FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS,file: doc})
            }
        }
        if(!isEmpty(nachPhoto)) {
            for(let doc of nachPhoto) {
                files.push({fileType: FILE_UPLOAD_TYPE.NACH,file: doc})
            }
        }
        if(!isEmpty(profilePhoto)) {
            for(let doc of profilePhoto) {
                files.push({fileType: FILE_UPLOAD_TYPE.PROFILE_PHOTO,file: doc})
            }
        }
        if(!isEmpty(salesObservationSheet)) {
            for(let doc of salesObservationSheet) {
                files.push({fileType: FILE_UPLOAD_TYPE.SALES_OBSERVATION_SHEET,file: doc})
            }
        }
        if(!isEmpty(vehiclePhoto)) {
            for(let doc of vehiclePhoto) {
                files.push({fileType: FILE_UPLOAD_TYPE.VEHICLE_PHOTOS,file: doc})
            }
        }
        if(!isEmpty(otherDocuments)) {
            for(let doc of otherDocuments) {
                files.push({fileType: FILE_UPLOAD_TYPE.OTHER_DOCS,file: doc})
            }
        }
        if(!isEmpty(vehicleQuotation)) {
            for(let doc of vehicleQuotation) {
                files.push({fileType: FILE_UPLOAD_TYPE.VEHICLE_QUOTATION,file: doc})
            }
        }
        if(!isEmpty(agreement)) {
            for(let doc of agreement) {
                files.push({fileType: FILE_UPLOAD_TYPE.AGREEMENT,file: doc})
            }
        }
        if(!isEmpty(files)){
            files.forEach((file) => {
                data.append(file.fileType,file.file);
            })
            const body = (isEmpty(props.name) || !customerRelationData.relation_id) ? {} : !isEmpty(props.data) ? {...props.data} : {...customerRelationData}
            data.append('data',JSON.stringify({...body}))
            if(leadId || props.relation_id || customerRelationData.relation_id) {
                const response = await axios.post(`${API_URL}/lead/update/${leadId ? leadId : 0}`,data);
                if(response.data.status === 'success') {
                    if(leadId){
                        setOpen(false)
                        get(leadId)
                        enqueueSnackbar('Documents successfully saved !',{variant: 'success',anchorOrigin: {vertical: 'top',horizontal: 'center'}});
                        const saveLeadData = response?.data?.data
                        setLeadData(saveLeadData)
                            if(!isEmpty(saveLeadData?.aadhaar_file_path) && !isEmpty(saveLeadData?.pan_file_path)){
                                if(!saveLeadData?.los_lead_id){
                                    enqueueSnackbar('Congratulations minimum requirement completed for loan profile',{variant: 'info',anchorOrigin: {vertical: 'top',horizontal: 'center'}});
                                    setText('Do you want to check loan eligibility ?')
                                    setCloseButtonName('No')
                                    setSubmitButtonName('Yes')
                                    setOpen(true)
                                }else{
                                    setText('Do you want to go summary page ?')
                                    setCloseButtonName('No')
                                    setSubmitButtonName('Yes')
                                    setOpen(true)
                                }
                            }
                        
                        // navigate(`/dashboard/lead/${leadId}/status`)
                    }else{
                        const fileData = response.data.data.data
                        for(let dataFile of fileData){
                            if(dataFile.doc_type === FILE_UPLOAD_TYPE.AADHAR){
                                setCustomerRelationData({...customerRelationData,doc_id:dataFile?.doc_id,aadhaar_file_path:dataFile.file_path[0]})
                                setLeadData({...leadData,aadhaar_file_path:dataFile.file_path[0]})
                            }else if(dataFile.doc_type === FILE_UPLOAD_TYPE.PAN){
                                setCustomerRelationData({...customerRelationData,doc_id:dataFile?.doc_id,pan_file_path:dataFile.file_path[0]})
                                setLeadData({...leadData,profile_photo:dataFile.file_path[0]})
                            }else if(dataFile.doc_type === FILE_UPLOAD_TYPE.PROFILE_PHOTO){
                                setCustomerRelationData({...customerRelationData,doc_id:dataFile?.doc_id,profile_photo:dataFile.file_path[0]})
                                setLeadData({...leadData,profile_photo:dataFile.file_path[0]})
                            }else if(dataFile.doc_type === FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS){
                                setCustomerRelationData({...customerRelationData,doc_id:dataFile?.doc_id,agreement_photo:dataFile.file_path[0]})
                                setLeadData({...leadData,agreement_photo:dataFile.file_path[0]})
                            }else if(dataFile.doc_type === FILE_UPLOAD_TYPE.NACH){
                                setCustomerRelationData({...customerRelationData,doc_id:dataFile?.doc_id,nach_file_path:dataFile.file_path[0]})
                                setLeadData({...leadData,nach_file_path:dataFile.file_path[0]})
                            }else if(dataFile.doc_type === FILE_UPLOAD_TYPE.OTHER_DOCS){
                                setCustomerRelationData({...customerRelationData,doc_id:dataFile?.doc_id,other_doc_file_path:dataFile.file_path[0]})
                                setLeadData({...leadData,other_doc_file_path:dataFile.file_path[0]})
                            }
                        }
                        enqueueSnackbar('Documents successfully saved !',{variant: 'success',anchorOrigin: {vertical: 'top',horizontal: 'center'}});
                        props.onClose(false)
                    }
                }
            }
             else {
                setMeassage('First fill the profile details')
                setSeverity('info')
                setIsOpen(true)
                setAlignment('details')
            }
        }else{
            props.onClose(false)
            enqueueSnackbar('Empty files upload any file then subimt !',{variant: 'info',anchorOrigin: {vertical: 'top',horizontal: 'center'}});
        }
    }
    const handleBankStatement = (e) => {
        setBankStatement(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,bankStatement: 100}) : setProgress({...progress,bankStatement: 0})
        setIsSubmit(false)
    }
    const handleElectricityBill = (e) => {
        setElectricityBill(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,electricityBill: 100}) : setProgress({...progress,electricityBill: 0})
        setIsSubmit(false)
    }
    const handleAadhaarCard = (e) => {
        setAadhaarCard(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,aadharCard: 100}) : setProgress({...progress,aadharCard: 0})
        setIsSubmit(false)
    }
    const handlePanCard = (e) => {
        setPanCard(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,panCard: 100}) : setProgress({...progress,panCard: 0})
        setIsSubmit(false)

    }

    const handleVoterId = (e) => {
        setVoterId(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,voterId: 100}) : setProgress({...progress,voterId: 0})
        setIsSubmit(false)
    }
    const handleDrivingLicense = (e) => {
        setDrivingLicense(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,drivingLicense: 100}) : setProgress({...progress,drivingLicense: 0})
        setIsSubmit(false)
    }
    const handleVehicleRc = (e) => {
        setVehicleRc(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,vehicleRc: 100}) : setProgress({...progress,vehicleRc: 0})
        setIsSubmit(false)
    }
    const handleFiReport = (e) => {
        setFiReport(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,fiReport: 100}) : setProgress({...progress,fiReport: 0})
        setIsSubmit(false)
    }
    const handleVehicleInvoice = (e) => {
        setVehicleInvoice(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,vehicleInvoice: 100}) : setProgress({...progress,vehicleInvoice: 0})
    }
    const handleSalesPhotos = (e) => {
        setSalesPhotos(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,salesPhotos: 100}) : setProgress({...progress,salesPhotos: 0})
        setIsSubmit(false)
    }
    const handleVehicleInsurance = (e) => {
        setVehicleInsurance(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,vehicleInsurance: 100}) : setProgress({...progress,vehicleInsurance: 0})
        setIsSubmit(false)
    }

    const handleAgreementPhoto = (e) => {
        setAgreementPhoto(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,agreementPhoto: 100}) : setProgress({...progress,agreementPhoto: 0})
        setIsSubmit(false)
    }
    const handleNachPhoto = (e) => {
        setNachPhoto(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,nachPhoto: 100}) : setProgress({...progress,nachPhoto: 0})
        setIsSubmit(false)
    }
    const handleProfilePhoto = (e) => {
        setProfilePhoto(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,profilePhoto: 100}) : setProgress({...progress,profilePhoto: 0})
        setIsSubmit(false)
    }
    const handleSalesObservationSheet = (e) => {
        setSalesObservationSheet(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,salesObservationSheet: 100}) : setProgress({...progress,salesObservationSheet: 0})
        setIsSubmit(false)
    }
    const handleVehiclePhoto = (e) => {
        setVehiclePhoto(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,vehiclePhoto: 100}) : setProgress({...progress,vehiclePhoto: 0})
        setIsSubmit(false)
    }
    const handleOtherDocuments = (e) => {
        setOtherDocuments(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,otherDocuments: 100}) : setProgress({...progress,otherDocuments: 0})
        setIsSubmit(false)
    }

    const handleVehicleQuotation = (e) => {
        setVehicleQuotation(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,vehicleQuotation: 100}) : setProgress({...progress,vehicleQuotation: 0})
        setIsSubmit(false)
    }
    const handleAgreement = (e) => {
        setAgreement(Object.values(e.target.files))
        e.target.files.length > 0 ? setProgress({...progress,agreement: 100}) : setProgress({...progress,agreement: 0})
        setIsSubmit(false)
    }

    const closeDialog = () => {
        setOpen(false);
        setText('')
        setCloseButtonName('')
        setSubmitButtonName('')
    };

    const submitFormValue = () => {
        submitForm(formvalue)
    };

    const getLeadData = async (leadId) => {
        let data = await axios.get(`${API_URL}/leads/${leadId}`)
        if(data.data.status === 'success') {
            data = data?.data?.data
            setLeadData(data)
        }
    }

    useEffect(() => {
        if(leadId && isEmpty(props?.name)) {
            getLeadData(leadId)
        }else if(!isEmpty(props?.name)){
            if(isEmpty(customerRelationData)){
                setCustomerRelationData({...props?.data})
                setRelationDocs(props?.data?.customer_docs)
            }
        }
    },[leadId,props])
    
    useEffect(() => {
        return () => {
            setLeadData({})
        }
    },[])

    const getFileDownLoad = (type) => {
        if(!props?.relation_id){
            return `${API_URL}/lead/${leadData.lead_id}/docType/${type}/download`
        }else{
            return`${API_URL}/loan/${props.loan_id}/relation/${props.relation_id}/${type}`
        }
    }

    const data = leadData?.docs?.map((v) => {
        return v.doc_type
    })

   const isCheckFilePath = (docType) =>{
        if(!isEmpty(customerRelationData?.customer_docs)){
            const docsData = [...customerRelationData?.customer_docs].find(docs=>{if(docs.doc_type === docType){return docs}})
            if(!isEmpty(docsData)){
                return true
            }else{
                return false
            }
        }
    }

    const datas={
        ...leadData,
        to_los:true
    }
    delete datas.lead_id; 
    const checkEligibility=async()=>{
        if(!leadData?.los_lead_id){
            const response = await axios.post(`${API_URL}/lead/update/${leadId}`,{data: JSON.stringify(datas)});
            if(response?.data?.status==='success'){
                setText('')
                setCloseButtonName('')
                setSubmitButtonName('')
                enqueueSnackbar('We have notified Ascend capital. They will contact you soon.',{variant: 'info',anchorOrigin: {vertical: 'top',horizontal: 'center'}});
                navigate(`/dashboard/lead/${leadId}/status`)
            }
        }else{
            navigate(`/dashboard/lead/${leadId}/status`)    
        }
      }

    const fileRemove = (fileType,doc) =>{
        if(fileType === FILE_UPLOAD_TYPE.AADHAR){
            const fileData = [...aadharCard]
            fileData.splice(fileData.indexOf(doc), 1);
            setAadhaarCard(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.PAN){
            const fileData = [...panCard]
            fileData.splice(fileData.indexOf(doc), 1);
            setPanCard(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.BANK_STATEMENT){
            const fileData = [...bankStatement]
            fileData.splice(fileData.indexOf(doc), 1);
            setBankStatement(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.ELECTRICITY_BILL){
            const fileData = [...electricityBill]
            fileData.splice(fileData.indexOf(doc), 1);
            setElectricityBill(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.OTHER_KYCS){
            const fileData = [...kyc]
            fileData.splice(fileData.indexOf(doc), 1);
            setKYC(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.VOTER_ID){
            const fileData = [...voterId]
            fileData.splice(fileData.indexOf(doc), 1);
            setVoterId(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.DRIVING_LICENCE){
            const fileData = [...drivingLicense]
            fileData.splice(fileData.indexOf(doc), 1);
            setDrivingLicense(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.VEHICLE_INSURANCE){
            const fileData = [...vehicleInsurance]
            fileData.splice(fileData.indexOf(doc), 1);
            setVehicleInsurance(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.VEHICLE_RC){
            const fileData = [...vehicleRc]
            fileData.splice(fileData.indexOf(doc), 1);
            setVehicleRc(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.FI_REPORT){
            const fileData = [...fiReport]
            fileData.splice(fileData.indexOf(doc), 1);
            setFiReport(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.VEHICLE_INVOICE){
            const fileData = [...vehicleInvoice]
            fileData.splice(fileData.indexOf(doc), 1);
            setVehicleInvoice(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.SALES_PHOTOS){
            const fileData = [...salesPhotos]
            fileData.splice(fileData.indexOf(doc), 1);
            setSalesPhotos(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS){
            const fileData = [...agreementPhoto]
            fileData.splice(fileData.indexOf(doc), 1);
            setAgreementPhoto(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.NACH){
            const fileData = [...nachPhoto]
            fileData.splice(fileData.indexOf(doc), 1);
            setNachPhoto(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.PROFILE_PHOTO){
            const fileData = [...profilePhoto]
            fileData.splice(fileData.indexOf(doc), 1);
            setProfilePhoto(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.SALES_OBSERVATION_SHEET){
            const fileData = [...salesObservationSheet]
            fileData.splice(fileData.indexOf(doc), 1);
            setSalesObservationSheet(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.VEHICLE_PHOTOS){
            const fileData = [...vehiclePhoto]
            fileData.splice(fileData.indexOf(doc), 1);
            setVehiclePhoto(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.OTHER_DOCS){
            const fileData = [...otherDocuments]
            fileData.splice(fileData.indexOf(doc), 1);
            setOtherDocuments(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.VEHICLE_QUOTATION){
            const fileData = [...vehicleQuotation]
            fileData.splice(fileData.indexOf(doc), 1);
            setVehicleQuotation(fileData)
        }else if(fileType === FILE_UPLOAD_TYPE.AGREEMENT){
            const fileData = [...agreement]
            fileData.splice(fileData.indexOf(doc), 1);
            setAgreement(fileData)
        }
    }

    return (
        <>
            <ConfirmDialog text={text} closeButton={closeButtonName} submitButton={submitButtonName} open={open} onClose={closeDialog} submit={()=>text ? checkEligibility() : submitFormValue()} close={closeDialog} />
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{flexGrow: 1,p: 2}}>
                    <Grid container style={{paddingLeft:100}} spacing={2} justifyContent='left' columns={{xs: 1,sm: 3}}>
                        <>
                            <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                <CustomTooltip
                                    title={
                                        !isEmpty(aadharCard) ? <div style={{width:400}}>
                                        {aadharCard?.map(doc=> {
                                            return ( 
                                            <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.AADHAR,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                    {upperFirst(doc?.name)}
                                                </Badge>
                                            </div>)}) 
                                        }
                                    </div>
                                    : ''
                                    }>
                                    <div style={{display: 'flex',justifyContent: 'center'}}>
                                        <label htmlFor="aadhaar_file_path">
                                            <Input accept="image/*,application/pdf" id="aadhaar_file_path" type="file" multiple onChange={handleAadhaarCard} />
                                            {
                                                !aadharCard ? (leadData?.aadhaar_file_path?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.AADHAR)) ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    :
                                                    <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                            }

                                        </label>
                                        <div>
                                            <LinearProgress variant='determinate' value={progress.aadharCard} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                            <Typography style={{textAlign: 'center',fontSize: '15px'}}>Upload Aadhaar * {(leadData?.aadhaar_file_path?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.AADHAR)) && <i class="bi bi-check-circle" style={{marginRight: '10px',color: 'green'}}></i>}</Typography>

                                        </div>
                                        {(leadData?.aadhaar_file_path || isCheckFilePath(FILE_UPLOAD_TYPE.AADHAR)) ?
                                            <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.AADHAR),'_blank')} />
                                            :
                                            <div style={{marginLeft:'35px'}}></div>
                                        }
                                    </div>
                                </CustomTooltip>
                            </Grid>
                            <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                <CustomTooltip
                                    title={
                                        !isEmpty(panCard) ? <div style={{width:400}}>
                                        {panCard?.map(doc=> {
                                            return ( 
                                            <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.PAN,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                    {upperFirst(doc?.name)}
                                                </Badge>
                                            </div>)}) 
                                        }
                                    </div>
                                    : ''
                                    }
                                >
                                    <div style={{display: 'flex',justifyContent: 'center'}}>
                                        <label htmlFor="pan_file_path">
                                            <Input accept="image/*,application/pdf" id="pan_file_path" type="file" multiple onChange={handlePanCard} />
                                            {
                                                !panCard ? (leadData?.pan_file_path?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.PAN)) ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    :
                                                    <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                            }

                                        </label>
                                        <div>
                                            <LinearProgress variant='determinate' value={progress.panCard} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                            <Typography style={{textAlign: 'center',fontSize: '15px'}}>Upload Pan * {(leadData?.pan_file_path?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.PAN)) && <i class="bi bi-check-circle" style={{marginRight: '10px',color: 'green'}}></i>}</Typography>
                                        </div>
                                        {(leadData?.pan_file_path || isCheckFilePath(FILE_UPLOAD_TYPE.PAN)) ?
                                            <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.PAN),'_blank')} />
                                            :
                                            <div style={{marginLeft:'35px'}}></div>
                                        }
                                    </div>
                                </CustomTooltip>
                            </Grid>

                            {isEmpty(props.name) && <>
                            <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                <CustomTooltip
                                    title={
                                        !isEmpty(bankStatement) ? <div style={{width:400}}>
                                        {bankStatement?.map(doc=> {
                                            return ( 
                                            <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.BANK_STATEMENT,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                    {upperFirst(doc?.name)}
                                                </Badge>
                                            </div>)}) 
                                        }
                                    </div>
                                    : ''
                                    }
                                >
                                    <div style={{display: 'flex',justifyContent: 'center'}}>
                                        <label htmlFor="bank_statement">
                                            <Input accept="image/*,application/pdf" id="bank_statement" type="file" multiple onChange={handleBankStatement} />
                                            {
                                                !bankStatement ? leadData?.bank_statement_path?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    :
                                                    <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                            }
                                        </label>
                                        <div>
                                            <LinearProgress variant='determinate' value={progress.bankStatement} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                            <Typography style={{textAlign: 'center',fontSize: '15px'}}>Upload Bank Statement *{leadData?.bank_statement_path?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>}</Typography>
                                        </div>
                                        {leadData?.bank_statement_path ?
                                            <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.BANK_STATEMENT),'_blank')} />
                                            :
                                            <div style={{marginLeft:'35px'}}></div>
                                        }
                                    </div>
                                </CustomTooltip>
                            </Grid>
                            <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                <CustomTooltip
                                    title={
                                        !isEmpty(electricityBill) ? <div style={{width:400}}>
                                        {electricityBill?.map(doc=> {
                                            return ( 
                                            <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.ELECTRICITY_BILL,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                    {upperFirst(doc?.name)}
                                                </Badge>
                                            </div>)}) 
                                        }
                                    </div>
                                    : ''
                                    }
                                >
                                    <div style={{display: 'flex',justifyContent: 'center'}}>
                                        <label htmlFor="electricity_bill">
                                            <Input accept="image/*,application/pdf" id="electricity_bill" type="file" multiple onChange={handleElectricityBill} />
                                            {
                                                !electricityBill ? leadData?.electricity_bill_path?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    :
                                                    <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                            }
                                        </label>
                                        <div>
                                            <LinearProgress variant='determinate' value={progress.electricityBill} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                            <Typography style={{textAlign: 'center',fontSize: '15px'}}>Upload Electricity Bill *{leadData?.electricity_bill_path?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>}</Typography>
                                        </div>
                                        {leadData?.electricity_bill_path ?
                                            <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.ELECTRICITY_BILL),'_blank')} />
                                            :
                                            <div style={{marginLeft:'35px'}}></div>
                                        }
                                    </div>
                                </CustomTooltip>
                            </Grid>
                            </>}
                            {!props?.name && leadData?.lead_type === LEAD_TYPE.L5 && leadData?.loan_id && <>
                            <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                <CustomTooltip
                                    title={
                                        !isEmpty(vehicleQuotation) ? <div style={{width:400}}>
                                        {vehicleQuotation?.map(doc=> {
                                            return ( 
                                            <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.VEHICLE_QUOTATION,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                    {upperFirst(doc?.name)}
                                                </Badge>
                                            </div>)}) 
                                        }
                                    </div>
                                    : ''
                                    }
                                >
                                    <div style={{display: 'flex',justifyContent: 'center'}}>
                                        <label htmlFor="vehicle_quotation">
                                            <Input accept="image/*,application/pdf" id="vehicle_quotation" type="file" multiple onChange={handleVehicleQuotation} />
                                            {
                                                !vehicleQuotation ? leadData?.vehicle_quotation?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    :
                                                    <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                            }
                                        </label>
                                        <div>
                                            <LinearProgress variant='determinate' value={progress.vehicleQuotation} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                            <Typography style={{textAlign: 'center',fontSize: '15px'}}>Upload Quotation {leadData?.vehicle_quotation?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>}</Typography>
                                        </div>
                                        {leadData?.vehicle_quotation ?
                                            <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.VEHICLE_QUOTATION),'_blank')} />
                                            :
                                            <div style={{marginLeft:'35px'}}></div>
                                        }
                                    </div>
                                </CustomTooltip>
                            </Grid>
                            <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                <CustomTooltip
                                    title={
                                        !isEmpty(agreement) ? <div style={{width:400}}>
                                        {agreement?.map(doc=> {
                                            return ( 
                                            <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.AGREEMENT,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                    {upperFirst(doc?.name)}
                                                </Badge>
                                            </div>)}) 
                                        }
                                    </div>
                                    : ''
                                    }
                                >
                                    <div style={{display: 'flex',justifyContent: 'center'}}>
                                        <label htmlFor="agreement">
                                            <Input accept="image/*,application/pdf" id="agreement" type="file" multiple onChange={handleAgreement} />
                                            {
                                                !agreement ? leadData?.agreement?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    :
                                                    <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                            }
                                        </label>
                                        <div>
                                            <LinearProgress variant='determinate' value={progress.agreement} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                            <Typography style={{textAlign: 'center',fontSize: '15px'}}>Upload Agreement {leadData?.agreement?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>}</Typography>
                                        </div>
                                        {leadData?.agreement ?
                                            <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.AGREEMENT),'_blank')} />
                                            :
                                            <div style={{marginLeft:'35px'}}></div>
                                        }
                                    </div>
                                </CustomTooltip>
                            </Grid>
                            </>}
                            <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                <CustomTooltip
                                    title={
                                        !isEmpty(drivingLicense) ? <div style={{width:400}}>
                                        {drivingLicense?.map(doc=> {
                                            return ( 
                                            <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.DRIVING_LICENCE,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                    {upperFirst(doc?.name)}
                                                </Badge>
                                            </div>)}) 
                                        }
                                    </div>
                                    : ''
                                    }
                                >
                                    <div style={{display: 'flex',justifyContent: 'center'}}>
                                        <label htmlFor="driving_license">
                                            <Input accept="image/*,application/pdf" id="driving_license" type="file" multiple onChange={handleDrivingLicense} />
                                            {
                                                !drivingLicense ? (leadData?.driving_license_file_path?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.DRIVING_LICENCE)) ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    :
                                                    <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                            }

                                        </label>
                                        <div>
                                            <LinearProgress variant='determinate' value={progress.drivingLicense} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                            <Typography style={{textAlign: 'center',fontSize: '15px'}}>Driving License  {(leadData?.driving_license_file_path?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.DRIVING_LICENCE)) && <i class="bi bi-check-circle" style={{color: 'green'}}></i>}</Typography>
                                        </div>
                                        {(leadData?.driving_license_file_path || isCheckFilePath(FILE_UPLOAD_TYPE.DRIVING_LICENCE)) ?
                                            <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.DRIVING_LICENCE),'_blank')} />
                                            :
                                            <div style={{marginLeft:'35px'}}></div>
                                        }
                                    </div>
                                </CustomTooltip>
                            </Grid>
                            <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                <CustomTooltip
                                    title={
                                        !isEmpty(voterId) ? <div style={{width:400}}>
                                        {voterId?.map(doc=> {
                                            return ( 
                                            <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.VOTER_ID,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                    {upperFirst(doc?.name)}
                                                </Badge>
                                            </div>)}) 
                                        }
                                    </div>
                                    : ''
                                    }
                                >
                                    <div style={{display: 'flex',justifyContent: 'center'}}>
                                        <label htmlFor="voter_id">
                                            <Input accept="image/*,application/pdf" id="voter_id" type="file" multiple onChange={handleVoterId} />
                                            {
                                                !voterId ? (leadData?.voter_id_file_path?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.VOTER_ID)) ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    :
                                                    <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                    : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                            }

                                        </label>
                                        <div>
                                            <LinearProgress variant='determinate' value={progress.voterId} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                            <Typography style={{textAlign: 'center',fontSize: '15px'}}>Voter Id {(leadData?.voter_id_file_path?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.VOTER_ID)) && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                        </div>
                                        {(leadData?.voter_id_file_path || isCheckFilePath(FILE_UPLOAD_TYPE.VOTER_ID)) ?
                                            <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.VOTER_ID),'_blank')} />
                                            :
                                            <div style={{marginLeft:'35px'}}></div>
                                        }
                                    </div>
                                </CustomTooltip>
                            </Grid>

                            {leadData?.loan_id && <>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(vehicleRc) ? <div style={{width:400}}>
                                            {vehicleRc?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.VEHICLE_RC,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="vehicle_rc">
                                                <Input accept="image/*,application/pdf" id="vehicle_rc" type="file" multiple onChange={handleVehicleRc} />
                                                {
                                                    !vehicleRc ? leadData?.vehicle_rc?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }
                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.vehicleRc} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Vehicle RC {leadData?.vehicle_rc?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {leadData?.vehicle_rc ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.VEHICLE_RC),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(fiReport) ? <div style={{width:400}}>
                                            {fiReport?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.FI_REPORT,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        {/* <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="fi_report">
                                                <Input accept="image/*,application/pdf" id="fi_report" type="file" multiple onChange={handleFiReport} />
                                                {
                                                    !fiReport ? leadData?.fi_report?.length > 0  ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }
                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.fiReport} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Fi Report {leadData?.fi_report?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {leadData?.fi_report ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.FI_REPORT),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div> */}
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(vehicleInvoice) ? <div style={{width:400}}>
                                            {vehicleInvoice?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.VEHICLE_INVOICE,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="vehicle_invoice">
                                                <Input accept="image/*,application/pdf" id="vehicle_invoice" type="file" multiple onChange={handleVehicleInvoice} />
                                                {
                                                    !vehicleInvoice ? leadData?.vehicle_invoice?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }
                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.vehicleInvoice} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Vehicle Invoice {leadData?.vehicle_invoice?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {leadData?.vehicle_invoice ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.VEHICLE_INVOICE),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(vehicleInsurance) ? <div style={{width:400}}>
                                            {vehicleInsurance?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.VEHICLE_INSURANCE,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="vehicle_insurance">
                                                <Input accept="image/*,application/pdf" id="vehicle_insurance" type="file" multiple onChange={handleVehicleInsurance} />
                                                {
                                                    !vehicleInsurance ? leadData?.vehicle_insurance?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }
                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.vehicleInsurance} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Vehicle Insurance {leadData?.vehicle_insurance?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {leadData?.vehicle_insurance ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.VEHICLE_INSURANCE),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(salesPhotos) ? <div style={{width:400}}>
                                            {salesPhotos?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.SALES_PHOTOS,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="sales_photos">
                                                <Input accept="image/*,application/pdf" id="sales_photos" type="file" multiple onChange={handleSalesPhotos} />
                                                {
                                                    !salesPhotos ? leadData?.sales_photos?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }
                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.salesPhotos} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Sales Photo {leadData?.sales_photos?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {leadData?.sales_photos ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.SALES_PHOTOS),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(salesObservationSheet) ? <div style={{width:400}}>
                                            {salesObservationSheet?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.SALES_OBSERVATION_SHEET,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="sales_observation_sheet">
                                                <Input accept="image/*,application/pdf" id="sales_observation_sheet" type="file" multiple onChange={handleSalesObservationSheet} />
                                                {
                                                    !salesObservationSheet ? leadData?.sales_observation_sheet?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }

                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.salesObservationSheet} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>FI Report {leadData?.sales_observation_sheet?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {leadData?.sales_observation_sheet ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.SALES_OBSERVATION_SHEET),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(vehiclePhoto) ? <div style={{width:400}}>
                                            {vehiclePhoto?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.VEHICLE_PHOTOS,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="vehicle_photo">
                                                <Input accept="image/*,application/pdf" id="vehicle_photo" type="file" multiple onChange={handleVehiclePhoto} />
                                                {
                                                    !vehiclePhoto ? leadData?.vehicle_photos?.length > 0 ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }

                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.vehiclePhoto} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Vehicle Photo {leadData?.vehicle_photos?.length > 0 && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {leadData?.vehicle_photos ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.VEHICLE_PHOTOS),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                            </>}
                            {(leadData?.loan_id || !isEmpty(props.name) ) && <>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(agreementPhoto) ? <div style={{width:400}}>
                                            {agreementPhoto?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="agreement_photo">
                                                <Input accept="image/*,application/pdf" id="agreement_photo" type="file" multiple onChange={handleAgreementPhoto} />
                                                {
                                                    !agreementPhoto ? (leadData?.agreement_photo?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS)) ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }

                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.agreementPhoto} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Agreement Photo {(leadData?.agreement_photo?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS)) && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {(leadData?.agreement_photo || isCheckFilePath(FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS)) ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(nachPhoto) ? <div style={{width:400}}>
                                            {nachPhoto?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.NACH,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="nach_photo">
                                                <Input accept="image/*,application/pdf" id="nach_photo" type="file" multiple onChange={handleNachPhoto} />
                                                {
                                                    !nachPhoto ? (leadData?.nach?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.NACH)) ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }

                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.nachPhoto} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Nach{(leadData?.nach?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.NACH)) && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {(leadData?.nach || isCheckFilePath(FILE_UPLOAD_TYPE.NACH)) ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.NACH),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(profilePhoto) ? <div style={{width:400}}>
                                            {profilePhoto?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.PROFILE_PHOTO,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="profile_photo">
                                                <Input accept="image/*,application/pdf" id="profile_photo" type="file" multiple onChange={handleProfilePhoto} />
                                                {
                                                    !profilePhoto ? (leadData?.profile_photo?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.PROFILE_PHOTO)) ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }

                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.profilePhoto} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Profile Photo {(leadData?.profile_photo?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.PROFILE_PHOTO)) && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {(leadData?.profile_photo || isCheckFilePath(FILE_UPLOAD_TYPE.PROFILE_PHOTO)) ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.PROFILE_PHOTO),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid style={{minWidth:300, maxWidth:300}} item xs={1}>
                                    <CustomTooltip
                                        title={
                                            !isEmpty(otherDocuments) ? <div style={{width:400}}>
                                            {otherDocuments?.map(doc=> {
                                                return ( 
                                                <div style={{color:'white',margin:5,fontSize:15,width:'space-between',paddingTop:5}}>
                                                    <Badge style={{width:'space-between',wordBreak:'break-all',padding:5}} badgeContent={<IconButton onClick={()=>fileRemove(FILE_UPLOAD_TYPE.OTHER_DOCS,doc)} style={{color:'red'}}><CancelIcon style={{paddingRight:2}} color="red" /></IconButton>}>
                                                        {upperFirst(doc?.name)}
                                                    </Badge>
                                                </div>)}) 
                                            }
                                        </div>
                                        : ''
                                        }
                                    >
                                        <div style={{display: 'flex',justifyContent: 'center'}}>
                                            <label htmlFor="other_documents">
                                                <Input accept="image/*,application/pdf" id="other_documents" type="file" multiple onChange={handleOtherDocuments} />
                                                {
                                                    !otherDocuments ? (leadData?.other_documents?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.OTHER_DOCS)) ? <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        :
                                                        <Iconify icon="entypo:upload" sx={{color: 'red',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                        : <Iconify icon="entypo:upload" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                                                }
                                            </label>
                                            <div>
                                                <LinearProgress variant='determinate' value={progress.otherDocuments} sx={{width: '205px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                                                <Typography style={{textAlign: 'center',fontSize: '15px'}}>Other Documents {(leadData?.other_documents?.length > 0 || isCheckFilePath(FILE_UPLOAD_TYPE.OTHER_DOCS)) && <i class="bi bi-check-circle" style={{color: 'green'}}></i>} </Typography>
                                            </div>
                                            {(leadData?.other_documents || isCheckFilePath(FILE_UPLOAD_TYPE.OTHER_DOCS)) ?
                                                <Iconify icon="entypo:download" sx={{color: 'blue',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.OTHER_DOCS),'_blank')} />
                                                :
                                                <div style={{marginLeft:'35px'}}></div>
                                            }
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                            </>}
                        </>
                    </Grid>
                </Box>
                <Box sx={{m: 2}}>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={8} sm={6}>
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                disabled={isSubmit}
                                variant="contained"
                            >
                                Submit
                            </LoadingButton>

                        </Grid>
                    </Grid>
                </Box>
            </form>
        </>
    )
}

export default Index