import {useRef,useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {Menu,MenuItem,IconButton,ListItemIcon,ListItemText, Button} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen,setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {width: 200,maxWidth: '100%'}
        }}
        anchorOrigin={{vertical: 'top',horizontal: 'right'}}
        transformOrigin={{vertical: 'top',horizontal: 'right'}}
      >
        {props.status &&
           <MenuItem component={RouterLink} to={props.status} sx={{color: 'text.secondary'}}>
             <ListItemIcon>
               <Iconify icon="eva:edit-fill" width={24} height={24} />
             </ListItemIcon>
             <ListItemText primary="Check Status" primaryTypographyProps={{variant: 'body2'}} />
           </MenuItem>
          }
          {props.linkTo &&
          <MenuItem component={RouterLink} to={props.linkTo} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.details &&
          <MenuItem component={RouterLink} to={props.details} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit Profile" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.documents &&
          <MenuItem component={RouterLink} to={props.documents} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit Documents" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.address &&
          <MenuItem component={RouterLink} to={props.address} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit Other KYC" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.convertInvoice &&
          <MenuItem component={RouterLink} to={props.convertInvoice} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Create Invoice" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.convertBill &&
          <MenuItem component={RouterLink} to={props.convertBill} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Create Bill" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.checkLoan &&
          <MenuItem component={RouterLink} to={props.view} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Check Loan Eligibility" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.invoice &&
          <MenuItem component={RouterLink} to={props.invoice} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Download" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.cancel &&
          <MenuItem component={RouterLink} to={props.cancel} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Cancel Invoice" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.list &&
          <MenuItem component={RouterLink} to={props.list} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View Details" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.insurance &&
          <MenuItem component={RouterLink} to={props.insurance} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Insurance Detail" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.transaction &&
          <MenuItem component={RouterLink} to={props.transaction} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Transactions" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>}
        {props.purchase &&
          <MenuItem component={RouterLink} to={props.purchase} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View Purchase" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.sales &&
          <MenuItem component={RouterLink} to={props.sales} sx={{color: 'text.secondary'}}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View Sales" primaryTypographyProps={{variant: 'body2'}} />
          </MenuItem>
        }
        {props.debit_note &&
           <MenuItem component={RouterLink} to={props.debit_note} sx={{color: 'text.secondary'}}>
             <ListItemIcon>
               <Iconify icon="eva:edit-fill" width={24} height={24} />
             </ListItemIcon>
             <ListItemText primary="Download Debit Note" primaryTypographyProps={{variant: 'body2'}} />
           </MenuItem>
          }
        
        {props.credit_note &&
           <MenuItem component={RouterLink} to={props.credit_note} sx={{color: 'text.secondary'}}>
             <ListItemIcon>
               <Iconify icon="eva:edit-fill" width={24} height={24} />
             </ListItemIcon>
             <ListItemText primary="Download Credit Note" primaryTypographyProps={{variant: 'body2'}} />
           </MenuItem>
          }
          
          {props.nach &&
           <MenuItem component={RouterLink} to={props.nach} sx={{color: 'text.secondary'}}>
             <ListItemIcon>
               <Iconify icon="eva:edit-fill" width={24} height={24} />
             </ListItemIcon>
             <ListItemText primary="E-Nach" primaryTypographyProps={{variant: 'body2'}} />
           </MenuItem>
          }

          {props.delete &&
           <MenuItem component={RouterLink} onClick={()=>{setIsOpen(false);props.delete()}} to={'#'} sx={{color: 'text.secondary'}}>
             <ListItemIcon>
               <Iconify icon="fluent:delete-16-filled" color={'red'} width={24} height={24} />
             </ListItemIcon>
             <ListItemText primary="Delete" primaryTypographyProps={{variant: 'body2'}} />
           </MenuItem>
          }
          {props.onDealerMapping &&
           <MenuItem component={RouterLink} onClick={()=>{setIsOpen(false);props.onDealerMapping()}} to={'#'} sx={{color: 'text.secondary'}}>
             <ListItemIcon>
               <Iconify icon="mdi:people-add" color={'blue'} width={24} height={24} />
             </ListItemIcon>
             <ListItemText primary="Dealer Mapping" primaryTypographyProps={{variant: 'body2'}} />
           </MenuItem>
          }


        {/* <MenuItem sx={{ color: 'text.secondary' }}>
           <ListItemIcon>
             <Iconify icon="eva:trash-2-outline" width={24} height={24} />
           </ListItemIcon>
           <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
         </MenuItem> */}

      </Menu>
    </>
  );
}
