import {filter, includes, isEmpty, upperCase} from 'lodash';
import {sentenceCase} from 'change-case';
import {useContext,useEffect,useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
/// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Link,
  Box,
  CircularProgress,
  Tooltip,
  IconButton,
  Alert
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead,UserListToolbar,UserMoreMenu} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL, LOAN_SANCTIONED_AND_ABOVE} from '../constant';
import {LoginContext} from '../context/logincontext';
import Styles from '../css/filter.module.css'
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import { CircularProgressProps } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { useSnackbar } from 'notistack';
import ConfirmDialog from '../components/confirmDialog';
import moment from 'moment';

// ----------------------------------------------------------------------

let TABLE_HEAD = [
  {id: 'button',alignLeft: true},
  {id: 'name',label: 'Customer'},
  {id: 'mobile',label: 'Mobile'},
  {id: 'type',label: 'Lead Type'},
  {id: 'loan_id',label: 'Loan Id'},
  {id: 'state',label: 'State'},
  {id: 'created_at',label: 'Created At'},
  {id: 'source',label: 'Source'},
  {id: 'deal_condition',label: 'Deal Condition'},
  {id: 'vehicle_name',label: 'Vehicle Name'},
  {id: 'tentative_purchase_date',label: 'Purchase Date'},
  {id: 'srink',label: <Tooltip title='Unable to see any lead or a lead is missing. Click on button'><Button><CachedIcon style={{paddingRight:'5px',fontSize:'30px'}} />{!isMobile && 'Refresh Lead'}</Button></Tooltip>},
];
// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
  if(b[orderBy] < a[orderBy]) {
    return -1;
  }
  if(b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order,orderBy) {
  return order === 'desc'
    ? (a,b) => descendingComparator(a,b,orderBy)
    : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
  const stabilizedThis = array?.map((el,index) => [el,index]);
  stabilizedThis?.sort((a,b) => {
    const order = comparator(a[0],b[0]);
    if(order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

let name=''
let mobile=''
let email=''
let aadhaarNo=''
let city=''
let vehicleName=''
let leadType=''
let loanId=''
let createdAt=''
let state=''
let source=''
let dealCondition=''

export default function LeadList() {
  const context = useContext(LoginContext)
  const [page,setPage] = useState(0);
  const [value,setValue] = useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [order,setOrder] = useState('asc');
  const [selected,setSelected] = useState([]);
  const [orderBy,setOrderBy] = useState('name');
  const [filterName,setFilterName] = useState('');
  const [rowsPerPage,setRowsPerPage] = useState(10);
  const [alert,setAlert]=useState([])
  const [losLeads,setLosLead] = useState([])
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open,setOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [leadData,setLeadData] = useState('')

  const handleRequestSort = (event,property) => {
    if(property ==='srink'){
      setOpen(true)
    }else{
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  async function srinkAllLead(){
    setOpen(false)
    const dealerId = context?.user.company.dealer_id
    const res = await axios.get(`${API_URL}/losdata/leads/${dealerId}`);
    if(res.data.status === 'success'){
      setLosLead(res?.data?.data)
      enqueueSnackbar('Successfully updated to see any lead or a lead is missing', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
    }else{
      enqueueSnackbar('Something went worng', { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
    }
  }

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };
   
  useEffect(() => {
    if(isMobile){
      TABLE_HEAD=[TABLE_HEAD[0],TABLE_HEAD[1],TABLE_HEAD[3],TABLE_HEAD[5],TABLE_HEAD[6]];
    }else{
      TABLE_HEAD.length=13;
    }
    get()
   
  },[losLeads,isMobile]);

  async function get() {
    const res = await axios.get(`${API_URL}/data/leads`);
    const leadData=[...res?.data?.data]
    setValue(leadData);
    setFilteredValue(leadData)
  }
  
  useEffect(() => {
      const listAlert = [...alert]
       for(const lead of value){
          if((lead?.dob!==null && lead?.aadhaar_no!==null &&  (lead?.aadhaar_file_path!==null || lead?.is_aadhaar_verified===true) && (lead?.pan_file_path!==null || lead?.is_pan_verified===true) && lead?.bank_statement_path!==null && lead?.electricity_bill_path!==null)) {
            listAlert[lead.lead_id] = false
          }else{
            listAlert[lead.lead_id]=true
          }
          setAlert(listAlert)
      }
  }, [value])

  const handleClick = (event,name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if(selectedIndex === -1) {
      newSelected = newSelected.concat(selected,name);
    } else if(selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if(selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0,-1));
    } else if(selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0,selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event,newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value,10));
    setPage(0);
  };

 

  const handleFilter = (e) => {
    if(e.target.name === 'name'){
      name = e.target.value
    }
    else if(e.target.name === 'mobile'){
      mobile =e.target.value
    }
    else if(e.target.name === 'email'){
      email =e.target.value
    }
    else if(e.target.name === 'aadhaar_no'){
      aadhaarNo =e.target.value
    }
    else if(e.target.name === 'city'){
      city =e.target.value
    }
    else if(e.target.name === 'vehicle_name'){
      vehicleName =e.target.value
    }
    else if(e.target.name === 'type'){
      leadType =e.target.value
    }else if(e.target.name === 'loan_id'){
      loanId = e.target.value
    }else if(e.target.name === 'created_at'){
      createdAt = moment(e.target.value).format('DD-MM-YYYY')
    }
    else if(e.target.name === 'state'){
      state = e.target.value
    }
    else if(e.target.name === 'source'){
      source = e.target.value
    }
    else if(e.target.name === 'deal_condition'){
      dealCondition = e.target.value
    }
    const filteredData = value.filter(v => ((v?.name || '').toLowerCase()).includes((name).toLowerCase()) && ((v?.mobile || '').toLowerCase()).includes((mobile).toLowerCase()) && ((v?.email || '').toLowerCase()).includes((email).toLowerCase()) && ((v?.aadhaar_no || '').toLowerCase()).includes((aadhaarNo).toLowerCase()) && ((v?.city || '').toLowerCase()).includes((city).toLowerCase()) && ((v?.vehicle_name || '').toLowerCase()).includes((vehicleName).toLowerCase()) && ((v?.lead_type || '').toLowerCase()).includes((leadType).toLowerCase()) && ((String(v?.loan_id) || '').toLowerCase()).includes((String(loanId)).toLowerCase()) && ((String(moment(v?.created_at).format('DD-MM-YYYY')) || '').toLowerCase()).includes((String(createdAt)).toLowerCase()) && ((v?.state || '').toLowerCase()).includes((state).toLowerCase()) && ((v?.source || '').toLowerCase()).includes((source).toLowerCase()) && ((v?.deal_condition || '').toLowerCase()).includes((dealCondition).toLowerCase()))
    setFilteredValue([...filteredData])
  };

  const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(filteredValue,getComparator(order,orderBy));
  const isUserNotFound = value.length === 0;

  const redirect=(id)=>{
    navigate(`/dashboard/lead/${id}/status`)
  }

  const closeDialog = () => {
    setOpen(false);
    setIsDelete(false)
};

const onDisableLead = async()=>{
  setIsDelete(false)
  const res = await axios.get(`${API_URL}/leads/${leadData.lead_id}`);
  if(res?.data?.status === 'success'){
    const leadDetails = res?.data?.data
    if(!includes(LOAN_SANCTIONED_AND_ABOVE,leadDetails?.state)){
      const result = await axios.get(`${API_URL}/lead/${leadData?.lead_id}/disable`);
      if(result?.data?.status === 'success'){
        get()
        enqueueSnackbar(`Successfully Delete of Lead`, { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
      }
    }else{
      enqueueSnackbar(`Can not be deleted of lead your file move to ${upperCase(leadDetails?.state)}`, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
    }
  }
}

  return (
    <Page>
      <ConfirmDialog text={isDelete ? 'Do you want to be delete of lead' : 'Unable to see any lead or a lead is missing. Press Ok'} open={open || isDelete} onClose={()=>closeDialog()} submit={()=>{isDelete ? onDisableLead() : srinkAllLead()}} close={()=>closeDialog()} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customer List
          </Typography>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/lead" underline="none">
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i> Add Customer</LoadingButton>
          </Link>
        </Stack>
      <Card sx={{width:'auto'}}>
      <Scrollbar>
            <TableContainer>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableRow>
                  <TableCell></TableCell>
                  
                  <TableCell align="center" padding='5px'><TextField type={'search'} variant='standard' name='name' onChange={handleFilter} autoComplete='off' /></TableCell>
                  {!isMobile && <>
                    <TableCell align="center" padding='5px'><TextField type={'search'} name='mobile' variant='standard' onChange={handleFilter} autoComplete='off' /></TableCell>
                  </>}
                  <TableCell align="center" padding='5px'><TextField type={'search'} name='type' variant='standard' onChange={handleFilter} autoComplete='off' /></TableCell>
                  {!isMobile && <>
                    <TableCell align="center" padding='5px'><TextField type={'search'} name='loan_id' variant='standard' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="center" padding='5px'><TextField type={'search'} name='state' variant='standard' onChange={handleFilter} autoComplete='off' /></TableCell>
                  </>}
                  <TableCell align="center" padding='5px'><TextField type={'search'} name='created_at' variant='standard' onChange={handleFilter} autoComplete='off' /></TableCell>
                  {!isMobile && <>
                    <TableCell align="center" padding='5px'><TextField type={'search'} name='source' variant='standard' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="center" padding='5px'><TextField type={'search'} name='deal_condition' variant='standard' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="center" padding='5px'><TextField type={'search'} name='vehicle_name' variant='standard' onChange={handleFilter} autoComplete='off' /></TableCell>
                  </>}
                  <TableCell></TableCell>
                </TableRow>
                <TableBody>
                  {filteredUsers?.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)?.map((v,index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      //   selected={isItemSelected}
                      //   aria-checked={isItemSelected}
                      >                     
                       <TableCell align="left" padding='none'>
                          {alert[v.lead_id] ? <>
                          <Tooltip title="Incomplete Profile"  >    
                            <IconButton aria-label="incomplete_profile" onClick={()=>redirect(v.lead_id)}>    
                                <Alert severity="error" style={{paddingRight:0}}/>    
                            </IconButton>    
                          </Tooltip>
                          </>
                          :
                          <Tooltip title="Complete Profile"  >    
                            <IconButton aria-label="complete_profile" onClick={()=>redirect(v.lead_id)}>    
                              <Alert severity="success" style={{paddingRight:0}}/>  
                            </IconButton>    
                          </Tooltip>
                          }
                        </TableCell>
                        <TableCell align="center" padding='none'>
                          {v?.name}
                        </TableCell>
                        {!isMobile && <>
                          <TableCell align="center" padding='none'>{v?.mobile}</TableCell>
                        </>}
                        <TableCell align="center" padding='none'>{v?.lead_type}</TableCell>
                        {!isMobile && <>
                          <TableCell align="center" padding='none'>
                            {v?.loan_id || ''}
                          </TableCell>
                          <TableCell align="center" padding='none'>
                            {v?.state || ''}
                          </TableCell>
                        </>}
                        <TableCell align="center" padding='none'>
                            {moment(v?.created_at).format('DD-MM-YYYY') || ''}
                          </TableCell>
                          {!isMobile && <>
                            <TableCell align="center" padding='none'>
                            {v?.source || ''}
                          </TableCell>
                          <TableCell align="center" padding='none'>
                            {v?.deal_condition || ''}
                          </TableCell>
                          <TableCell align="center" padding='none'>
                            {v?.vehicle_name || ''}
                          </TableCell>
                          <TableCell align="center" padding='none'>
                            {v?.tentative_purchase_date || ''}
                          </TableCell></>}
                          
                        <TableCell align="right">
                          <UserMoreMenu status={`/dashboard/lead/${v?.lead_id}/status`}
                            details={`/dashboard/lead/${v?.lead_id}/details`} 
                            documents={`/dashboard/lead/${v?.lead_id}/documents`}
                            address={`/dashboard/lead/${v?.lead_id}/address`}
                            // checkLoan={true} view={`/dashboard/VerifyLead/${v?.lead_id}`}
                            nach={`/dashboard/nach/${v?.lead_id}`} 
                            delete={()=>{setLeadData(v); setIsDelete(true)}}/>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{py: 3}}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10,20,30]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </Scrollbar>
      </Card>
    </Page>
  );
}
