import {Navigate,useRoutes} from 'react-router-dom';
import react,{useContext, useEffect} from 'react'
// layouts
import DashboardLayout from './layouts/dashboard';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
// import Products from './pages/Products';

import NotFound from './pages/Page404';
import AddUser from './pages/AddUser';
import PartList from './pages/PartList';
import CompanyList from './pages/CompanyList';
import UserList from './pages/UserList';
import PurchaseList from './pages/PurchaseList';
import PurchaseDetail from './pages/PurchaseDetail';
import AddCompany from './pages/AddCompany';
import AddParts from './pages/AddParts';
import AddPurchase from './pages/AddPurchase';
import EditPurchase from './pages/EditPurchase';
import EditCompany from './pages/EditCompany';
import EditUser from "./pages/EditUser";
import EditParts from "./pages/EditParts";
import ViewUser from './pages/ViewUser';
import ViewCompany from './pages/ViewCompany';
import ViewParts from './pages/ViewPart';
import AddLead from './pages/AddLead/index';
import EditLead from './pages/EditLead';
import LeadList from './pages/LeadList';
import Invoice from './pages/PurchaseInvoice';
import SalesInvoice from './pages/SalesInvoice';
import AddVendor from './pages/AddVendor';
import EditVendor from './pages/EditVendor';
import VendorList from './pages/VendorList';
import AddUserCompany from './pages/AddUserCompany';
import EditUserCompany from './pages/EditUserCompany';
import AddSales from './pages/AddSales';
import SalesDetail from './pages/SalesDetail';
import SalesList from './pages/SalesList';
import EditSales from './pages/EditSales';
import {LoginContext} from './context/logincontext'
import VerifyLead from './pages/VerifyLead';
import {isAdminRole} from './utils/helper';
import AddFinancer from './pages/AddFinancer';
import EditFinancer from './pages/EditFinancer';
import FinancerList from './pages/FinancerList';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import DownloadQuotation from './pages/DownloadQuotation';
import DownloadPurchaseOrder from './pages/DownloadPurchaseOrder';
import AddSalesQuotation from './pages/SalesQuotation';
import AddPurchaseBill from './pages/PurchaseBill';
import AddInsurer from './pages/AddInsurer';
import SalesInsuranceDetail from './pages/SalesInsuranceDetails'
import InsurancerList from './pages/InsurancerList';
import LoanLedger from './pages/LoanLedger';
import EditQuotation from './pages/EditQuotation';
import AddTransaction from './pages/AddTransaction';
import TransactionList from './pages/TransactionList';
import EditTransaction from './pages/EditTransaction';
import ReportList from './pages/ReportList';
import DealerInventory from './pages/DealerInventory';
import DebitNote from './pages/DownloadDebitNote';
import CounterParty from './pages/CounterParty';
import CounterPartyList from './pages/CounterPartyList'
import CreditNote from './pages/DownloadCreditNote';
import ExpenseList from './pages/ExpenseList';
import LoanList from './pages/LoanList';
import NachForm from './pages/NachForm';
import Status from './pages/Status';
import ChildCompanyReports from './pages/ChildCompanyReports';
import DropFileInput from './pages/upload/upload';
import VehicleDetails from './pages/VehicleDetails/index';
import AddVehicleDetails from './pages/VehicleDetails/AddVehicleDetails/index';
import SystemUserReports from './pages/SystemUserReport';
import PrivacyPolicy from './layouts/dashboard/PrivacyPolicy';
import moment from 'moment';
import axios from 'axios';
import { API_URL, CATEGORY } from './constant';
import Terms from './layouts/dashboard/Terms';
import ContactUs from './layouts/dashboard/ContactsUs';
import RcLedger from './pages/RcLedger';
import SecurityLedger from './pages/SecurityLedger';
import VehicleList from './pages/VehicleList';
import NachStatus from './pages/NachStatus';
import TvrDetails from './pages/TvrDetails';
// ----------------------------------------------------------------------

export default function Router(props) {
  const login = useContext(LoginContext)
  const userId = login.user.user_id
  const IsActive = login.user.is_active
  const isAdmin = isAdminRole(login.user)
  const lastSeen = moment().format()
  useEffect(()=>{
    async function get(){
      await axios.post(`${API_URL}/system_user/update/${userId}`,{last_seen:lastSeen});
    }
    if(userId){
      get()
    }
  },[lastSeen])

  const privateRoutes = [
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        // { path: 'app', element: IsActive ? <DashboardApp />:<UserHome /> },
        {path: 'app',element: <DashboardApp />},
        // { path: 'products', element:  <Products />},
        {path: 'products',element: <PartList />},
        {path: 'companies',element: isAdmin ? <CompanyList /> : <Navigate to="/dashboard/app" />},
        {path: 'users',element: isAdmin ? <UserList /> : <Navigate to="/dashboard/app" />},
        {path: 'purchase_list',element: <PurchaseList />},
        {path: 'purchasedetail/:id',element: <PurchaseDetail />},
        {path: 'user',element: isAdmin ? <AddUser /> : <Navigate to="/dashboard/app" />},
        {path: 'company',element: isAdmin ? <AddCompany /> : <Navigate to="/dashboard/app" />},
        {path: 'product',element: <AddParts />},
        {path: 'purchase',element: <AddPurchase />},
        {path: 'purchase_bill',element: <AddPurchaseBill />},
        {path: 'purchase_list/:id/edit',element: <EditPurchase type='bill'/>},
        {path: 'company/:id/edit',element: isAdmin ? <EditCompany /> : <Navigate to="/dashboard/app" />},
        {path: 'users/:id/edit',element: isAdmin ? <EditUser /> : <Navigate to="/dashboard/app" />},
        {path: 'users/:id',element: isAdmin ? <ViewUser /> : <Navigate to="/dashboard/app" />},
        {path: 'product/:id/edit',element: <EditParts />},
        {path: 'companies/:id',element: <ViewCompany />},
        {path: 'product/:id',element: <ViewParts />},
        {path: 'lead',element: <AddLead />},
        {path: 'lead/:id/:type',element: <AddLead />},
        {path: 'leads',element: <LeadList />},
        {path: 'supplier',element: <AddVendor />},
        {path: 'supplier/:id/edit',element: <EditVendor />},
        {path: 'suppliers',element: <VendorList />},
        {path: 'invoice/:id',element: <Invoice />},
        {path: 'user_company',element: <AddUserCompany />},
        {path: 'user_company/:id/edit',element: <EditUserCompany />},
        {path: 'sales_detail/:id',element: <SalesDetail />},
        {path: 'sales_list',element: <SalesList />},
        {path: 'add_sales_quotation',element: <EditSales type='quotation'/>},
        {path: 'quotation/:id/edit',element: <EditSales type='quotation' />},
        {path: 'addsales',element: <EditSales type='invoice' />},
        {path: 'sales/:id/edit',element: <EditSales type='invoice' />},
        {path: 'sales_vehicle_details/:vehicle_id',element: <EditSales type='invoice' />},
        {path: 'salesInvoice/:id',element: <SalesInvoice />},
        // {path: 'VerifyLead/:id',element: <VerifyLead />},
        {path: 'financer',element: <AddFinancer />},
        {path: 'financer/:id/edit',element: <EditFinancer />},
        {path: 'financers',element: <FinancerList />},
        {path: 'insurer',element: <AddInsurer />},
        {path: 'insurer/:id/edit',element: <AddInsurer />},
        {path: 'sales_insurance_detail/:id',element: <SalesInsuranceDetail />},
        {path: 'insurers',element: <InsurancerList />},
        {path: 'download_purchase_order/:id',element: <DownloadPurchaseOrder />},
        {path: 'download_quotation/:id',element: <DownloadQuotation />},
        {path: 'loan_ledger',element: <LoanLedger />},
        {path: 'purchase_order/:id/edit',element: <EditPurchase type='order'/>},
        {path: 'transaction/:id/:type',element: <AddTransaction />},
        {path: 'transaction/:id/edit/:type',element: <EditTransaction />},
        {path: 'all_transactions',element: <TransactionList />},
        {path: 'financer_transactions',element: <TransactionList other_type='Financer' />},
        {path: 'supplier_transactions',element: <TransactionList other_type='Vendor' />},
        {path: 'transactions/:id/:type',element: <TransactionList />},
        {path: 'dealer_company_reports',element: <ChildCompanyReports />},
        {path: 'reports/:company_id',element: <ReportList />},
        {path: 'dealer_inventory/:company_id/:type/:id',element: <DealerInventory />},
        {path: 'debit_note/:id',element: <DebitNote/>},
        {path: 'counter_party',element: <CounterParty />},
        {path: 'counter_party/:id/edit',element: <CounterParty/>},
        {path: 'counter_parties',element: <CounterPartyList />},
        {path: 'expense/:type',element: <AddTransaction type='expense'/>},
        {path: 'expense/:id/:type/edit',element: <AddTransaction type='expense'/>},
        {path: 'credit_note/:id',element: <CreditNote/>},
        {path: 'expense_list',element: <ExpenseList/>},
        {path: 'loan_list/:state',element: <LoanList/>},
        // {path: 'quicklead',element: <QuickLead/>},
        {path: 'nach/:id',element: <NachForm/>},
        {path: 'lead/:id/status',element: <Status/>},
        {path: 'upload',element: <DropFileInput/>},
        {path: 'vehicle_details/:type',element: <VehicleDetails/>},
        {path: 'add_vehicle_details',element: <AddVehicleDetails/>},
        {path: 'edit_vehicle_details/:id',element: <AddVehicleDetails/>},
        {path: 'reports',element: isAdmin ? <SystemUserReports/>: <Navigate to="/dashboard/app" />},
        {path: 'dsa',element: isAdmin ? <CompanyList type={CATEGORY.DSA} /> : <Navigate to="/dashboard/app" />},
        {path: 'dsa/:id/dsa_client',element: isAdmin ? <CompanyList type={'dsa_client'} /> : <Navigate to="/dashboard/app" />},
        {path: 'rc_ledger',element: <RcLedger />},
        {path: 'security_ledger',element: <SecurityLedger />},
        {path: 'vehicle_list',element: <VehicleList />},
        {path: 'lead/:id/nach',element: <NachStatus/>},
        {path: 'lead/:id/tvr',element: <TvrDetails/>}
      ]
    }
  ]

  const publicRoutes = [
    {path: '/login',element: <Login pathName={props.pathName} />},
    {path: '/resetpassword',element: <ResetPassword />},
    {path: '/register',element: <Register />},
    {path: '/404',element: <NotFound />},
    {path: '*',element: <Navigate to="/login" />},
    {path: '/privacy_policy',element: <PrivacyPolicy />},
    {path: '/terms_and_conditions',element: <Terms />},
    {path: '/contact_us',element: <ContactUs />},
  ]

  const allRoutes = userId ? [...privateRoutes,...publicRoutes] : publicRoutes

  return useRoutes(allRoutes);
}
