import {Link as RouterLink,useNavigate} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Card,Stack,Link,Container,Typography} from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import {LoginForm} from '../sections/authentication/login';
import AuthSocial from '../sections/authentication/AuthSocial';
import {LoginContext} from '../context/logincontext';
import {useEffect,useContext} from 'react';
import {isEmpty} from 'lodash';
import axios from 'axios'
import {API_URL} from '../constant';
import Footer from '../layouts/dashboard/Footer';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2,0,2,2)
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12,0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate()
  const context = useContext(LoginContext)
  useEffect(() => {
    if(!isEmpty(context?.user)) {
      navigate('/dashboard/app')
    }
  },[context.user]);

  return (
    <>
    <RootStyle title="Login">
      <AuthLayout>
      </AuthLayout>

      <SectionStyle sx={{display: {xs: 'none',md: 'flex'}}}>
        <img src="/static/illustrations/illustration_login.png" alt="login" />
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{mb: 5}}>
            <Typography variant="h4" gutterBottom>
              Reset Password
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>Enter your details below.</Typography>
          </Stack>
          <LoginForm resetPassword={true} />
          <Typography
            variant="body2"
            align="center"
            sx={{
              mt: 3,
              display: {sm: 'none'}
            }}
          >
            Don’t have an account?&nbsp;
            <Link variant="subtitle2" component={RouterLink} to="register" underline="hover">
              Get started
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
    <Footer />
    </>
  );
}
