import {filter} from 'lodash';
import {sentenceCase} from 'change-case';
import {useEffect,useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Link,
  Dialog,
  ClickAwayListener
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead,UserListToolbar,UserMoreMenu} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL, CATEGORY} from '../constant';
import Styles from '../css/filter.module.css'
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';

import UploadCsvData from './UploadCsvData'

// ----------------------------------------------------------------------

let TABLE_HEAD = [
  {id: 'name',label: 'Name',alignRight: false},
  {id: 'primary_mobile',label: 'Mobile',alignRight: false},
  {id: 'address',label: 'Address',alignRight: false},
  {id: 'primary_email',label: 'Primary Email',alignRight: false},
  {id: 'city',label: 'City',alignRight: false},
  {id: 'website',label: 'Website',alignRight: false},
  {id: 'cin',label: 'Cin',alignRight: false},
];

// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
  if(b[orderBy] < a[orderBy]) {
    return -1;
  }
  if(b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order,orderBy) {
  return order === 'desc'
    ? (a,b) => descendingComparator(a,b,orderBy)
    : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
  const stabilizedThis = array?.map((el,index) => [el,index]);
  stabilizedThis?.sort((a,b) => {
    const order = comparator(a[0],b[0]);
    if(order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

  let name=''
  let address=''
  let primaryEmail=''
  let primaryMobile=''
  let city=''
  let website=''
  let cin=''



export default function CompanyList(props) {
  let {id} = useParams()
  const [page,setPage] = useState(0);
  const [value,setValue] = useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [order,setOrder] = useState('asc');
  const [selected,setSelected] = useState([]);
  const [orderBy,setOrderBy] = useState('name');
  const [filterName,setFilterName] = useState('');
  const [rowsPerPage,setRowsPerPage] = useState(10);

  const [open, setOpen] = useState(false)
  const [dealerMappingData, setDealerMappingData] = useState([])
  const [dsaDealer, setDsaDealer] = useState('')

  const handleRequestSort = (event,property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/company`);
      if(res?.data?.status === 'success'){
        const companyData = res?.data?.data
        if(props?.type === CATEGORY.DSA){
          const dsaData = companyData?.filter(data => data?.category === CATEGORY.DSA)
          setValue(dsaData);
          setFilteredValue(dsaData)
        }else if(props?.type === 'dsa_client'){
          const dsaId = id
          const dsaClientData = companyData?.filter(data => data?.dsa_id === Number(dsaId))
          setValue(dsaClientData);
          setFilteredValue(dsaClientData)
        }
        else{
          setValue(companyData);
          setFilteredValue(companyData)
        }
      }
    }
    if(isMobile){
      TABLE_HEAD.length = 2
      TABLE_HEAD = [...TABLE_HEAD,{id: 'city',label: 'City',alignRight: false}]
    }
    else{
      TABLE_HEAD.length = 7
    }
    get()
  },[props]);

  const handleClick = (event,name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if(selectedIndex === -1) {
      newSelected = newSelected.concat(selected,name);
    } else if(selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if(selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0,-1));
    } else if(selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0,selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event,newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value,10));
    setPage(0);
  };


  const handleFilter = (e) => {
    if(e.target.name === 'name'){
      name = e.target.value
    }
    else if(e.target.name === 'address'){
      address =e.target.value
    }
    else if(e.target.name === 'primary_email'){
      primaryEmail =e.target.value
    }
    else if(e.target.name === 'primary_mobile'){
      primaryMobile =e.target.value
    }
    else if(e.target.name === 'city'){
      city =e.target.value
    }
    else if(e.target.name === 'website'){
      website =e.target.value
    }
    else if(e.target.name === 'cin'){
      cin =e.target.value
    }
    filteredValue = value.filter(v => ((v?.name || '').toLowerCase()).includes((name).toLowerCase()) && ((v?.address || '').toLowerCase()).includes((address).toLowerCase()) && ((v?.primary_email || '').toLowerCase()).includes((primaryEmail).toLowerCase()) && ((v?.primary_mobile || '').toLowerCase()).includes((primaryMobile).toLowerCase()) && ((v?.city || '').toLowerCase()).includes((city).toLowerCase()) && ((v?.website || '').toLowerCase()).includes((website).toLowerCase()) && ((v?.cin || '').toLowerCase()).includes((cin).toLowerCase()))
    setFilteredValue([...filteredValue])
  };

  const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(filteredValue,getComparator(order,orderBy));
  const isUserNotFound = value.length === 0;

  const onDealerMapping = async (data) =>{
    let response = await axios.post(`${API_URL}/dsa/${dsaDealer?.company_id}/dealer_mapping`,data);
    if(response?.data?.status === 'success') {
      return {status : 'success'}
    }
  }


  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {props?.type === CATEGORY.DSA ? 'DSA Client List' : props?.type === 'dsa_client' ? 'DSA Mapping Client List' : 'Client List'}
          </Typography>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/company" underline="none">
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-building"></i> Add Client</LoadingButton>
          </Link>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{minWidth: 100}}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  <TableRow>
                    <TableCell align="left"><TextField className={Styles.filter} name='name' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='primary_mobile' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='address' onChange={handleFilter} autoComplete='off' /></TableCell>
                    {!isMobile && <>
                    <TableCell align="left"><TextField className={Styles.filter} name='primary_email' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='city' onChange={handleFilter} autoComplete='off' /></TableCell>
                    </>}
                    
                    {!isMobile && <>
                    <TableCell align="left"><TextField className={Styles.filter} name='website' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='cin' onChange={handleFilter} autoComplete='off' /></TableCell>
                    </>}
                  </TableRow>
                  {filteredUsers.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage).map((v,index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      //   selected={isItemSelected}
                      //   aria-checked={isItemSelected}
                      >
                        <TableCell align="left" paddingLeft='5px'>
                        {props?.type === CATEGORY.DSA ?
                        <Link variant="subtitle2" component={RouterLink} to={`/dashboard/dsa/${v?.company_id}/dsa_client`} underline="none">
                          {v?.name}
                        </Link>
                        :
                        <div>{v?.name}</div>
                        }
                        </TableCell>
                        <TableCell align="left" paddingLeft='5px'>{v?.primary_mobile}</TableCell>
                        {!isMobile && <>
                        {/* <TableCell align="left">{v.company_type}</TableCell> */}
                        <TableCell align="left" paddingLeft='5px'>{v?.address}</TableCell>
                        <TableCell align="left" paddingLeft='5px'>{v?.primary_email}</TableCell>
                        {/* <TableCell align="left">{v.secondary_mobile}</TableCell> */}
                        </>}
                        <TableCell align="left" paddingLeft='5px'>{v?.city}</TableCell>
                        {!isMobile && <>
                        <TableCell align="left" paddingLeft='5px'>{v?.website}</TableCell>
                        <TableCell align="left" paddingLeft='5px'>{v?.cin}</TableCell>
                        </>}
                        {/* <TableCell align="left">
                          <Label
                              variant="ghost"
                              color={(status === 'banned' && 'error') || 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                        </TableCell> */}

                        <TableCell align="right">
                          {props?.type === CATEGORY.DSA ?
                            <UserMoreMenu linkTo={`/dashboard/company/${v.company_id}/edit`}
                            onDealerMapping={()=>{setDsaDealer(v);setOpen(true)}}
                          />
                          :
                          <UserMoreMenu linkTo={`/dashboard/company/${v.company_id}/edit`}
                          />
                          }
                          
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{py: 3}}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5,10,25]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog
         id={`dealer_mapping`}
         open={open}
         anchorEl={open}
         placement="bottom-start"
         maxWidth={'lg'}
         fullWidth
         onClose={()=> {setDsaDealer('');setOpen(false)}}
       >
         <ClickAwayListener id={`dealer_mapping`} name={`dealer_mapping`} onClickAway={() => {setDsaDealer('');setOpen(false)}}>
            <UploadCsvData dsaName={dsaDealer?.name} onClose={()=>{setDsaDealer('');setOpen(false)}} submit={(data)=> onDealerMapping(data)} />
         </ClickAwayListener>
      </Dialog>

    </Page>
  );
}
