import { Autocomplete, Box, Card, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { get, isEmpty, upperCase } from "lodash";
import { useEffect, useRef, useState } from "react";
import uploadImg from '../pages/upload/cloud.png';
import CancelIcon from '@mui/icons-material/Cancel';
import { ImageConfig } from '../pages/upload/config';
import LinearProgress from '@mui/material/LinearProgress';
import { LoadingButton } from "@mui/lab";
import ConfirmDialog from "../components/confirmDialog";
import { useSnackbar } from "notistack";
import { FILE_UPLOAD_TYPE } from "../constant";

export default function FileUpload(props){
    // props = {submit,docType,onClose}
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');
    const wrapperRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [filePath, setFilePath] = useState([])
    const [docType, setDocType] = useState(props?.doc_type)
    const [progress,setProgress] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setDocType(props?.doc_type)
      },[props.doc_type]);

    const uploadDocs = (e,type) =>{
        let files=e.target.files
        if(!isEmpty(files)){
            for (let i = 0; i < files.length; i++) {
                let file=files[i]
                let imagePreview=URL.createObjectURL(file)
                files[i].preview=imagePreview
            }
            // if(files.type==="application/pdf"){
            //     delete files.preview
            // }
        }
        let updatedFile;
        let isDoc = false
        if(!isEmpty(filePath)){
            updatedFile = filePath.map(file=>{if(file?.fileType === type){isDoc = true; file.file = [...file?.file,...files]} return file})
            if(!isDoc){
                updatedFile = [{fileType : type, file : [...files]},...filePath]
            }
        }else{
            updatedFile = [{fileType : type, file : [...files]}]
        }
        setFilePath([...updatedFile]);
        setInterval(() => {
          setProgress(100);
          }, 1000);
    }

    const fileRemove = (fileData,type) => {
        let files = []
        for(const file of filePath){
            if(file?.fileType === type){
                const fileList = [...file.file]
                const updatedList = [...fileList];
                updatedList.splice(fileList.indexOf(fileData), 1);
                files.push({fileType : type, file : [...updatedList]})
            }else{
                files.push(file)
            }
        }
        setFilePath([...files]);
        
    }

    const onSubmit = async() =>{
        const result = await props.submit(filePath) // return {status:'success'} and filePath is Array :[{fileType:docTpye,file:filePath}]
        setOpen(false)
        if(get(result,'status','') === 'success'){
            enqueueSnackbar('Successfuly Save File', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
            setIsSubmit(false)
            props.onClose(false)
        }
    }

    const closeDialog = () =>{
        setOpen(false)
        setIsSubmit(false)
    }

return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={()=>onSubmit()} close={closeDialog} />
        <Box sx={{m: 2}}>
            <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={8} sm={6}>
                <h1 style={{marginBottom:'20px',fontFamily:'Times New Roman'}}> Upload Files Here</h1>
                        <div>
                            <Autocomplete
                                id="doc_type"
                                name='doc_type'
                                options={(props?.docType).sort()}
                                disabled={props?.doc_type}
                                style={{position:'relative',maxWidth:'275px',minWidth:'275px',borderRadius:'20px',textAlign:'center',justifyContent:'center',backgroundColor:'var(input-bg)'}}
                                getOptionLabel={(option) =>option === FILE_UPLOAD_TYPE.FI_REPORT ? upperCase('fi_report') : upperCase(option)}
                                renderInput={(params) => <TextField required {...params} label="File Type" />}
                                value={docType}
                                onChange={(event, newValue) => {
                                    setDocType(newValue)
                                }}
                            />
                        </div>
                        <div
                            style={{position:'relative',maxWidth:'275px',height:'200px',minWidth:'275px',borderRadius:'10px',display:'flex',textAlign:'center',justifyContent:'center',backgroundColor:'var(input-bg)',paddingBottom:20}}
                            ref={wrapperRef}
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDrop={onDrop}
                        >
                            <div style={{textAlign:'center',fontWeight:'500px'}}>
                                <img src={uploadImg} style={{blockSize:150}} alt="" />
                                {isEmpty(docType) ? <p style={{color:'red'}}>Select documents type</p> : <p>Drag & Drop your files here</p>}
                            </div>
                        <input disabled={isEmpty(docType)} accept={docType === FILE_UPLOAD_TYPE.TVR_RECORDING ? ".amr, .aac, .mp4, .wav, .aiff, .mp3, .wma" :docType === FILE_UPLOAD_TYPE.PAN ? "image/*": "image/*,application/pdf"} enterKeyHint={'enter'} style={{opacity: 0,position: "absolute",top: 0,left:0,width: "100%",height:'100%',cursor: "pointer"}} type="file" multiple onChange={(e)=>uploadDocs(e,docType)}/>
                        </div>
                    
                </Grid>
            </Grid>
            <Box sx={{m: 2}}>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item xs={8} sm={6}>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            disabled={isEmpty(filePath)}
                            loading={isSubmit}
                            onClick={()=>{setIsSubmit(true);setOpen(true)}}
                        >
                        Submit
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
            {filePath?.map(item=>{
                return(
                    <>
                    <div style={{textAlign:'center'}}>{upperCase(item?.fileType)}</div>
                    <div style={{justifyContent: 'center'}}>
                        {item?.file?.map((file,index)=>{
                            return (
                                <div>
                                    <Tooltip 
                                    title={<img src={file?.preview} alt={file?.name} />
                                    } >
                                    <div key={index} style={{position:'relative',display:'flex',marginBottom:'10px',padding:'15px',borderRadius:'20px',width:'95%',backgroundColor:'lightsteelblue'}}>
                                        <IconButton onClick={()=>fileRemove(file,item?.fileType)} style={{color:'red'}}>
                                            <CancelIcon color="red" />
                                        </IconButton>
                                        <img style={{width:'50px',marginRight:'10px',height:'48px'}} src={ImageConfig[file.type.split('/')[1]] || ImageConfig['default']} alt="" />
                                        <div style={{wordBreak:'break-all'}}>
                                        <LinearProgress variant="determinate" value={progress} />
                                        <p style={{fontSize:15}}>{file.name}</p>
                                        </div>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="text.secondary">{progress}%</Typography>
                                        </Box>
                                    </Box>
                                    </div>
                                    </Tooltip>
                                </div>
                            );
                        })}
                    </div>
                    </>
                );
            })}
        </Box>
    </div>
  );
}