// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Link, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// component
import Iconify from '../../../components/Iconify';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, STATE, TYPE } from '../../../constant';
import { LoadingButton } from '@mui/lab';
import {  Link as RouterLink } from 'react-router-dom';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 1352831;

export default function AppNewUsers() {
  const [total,setTotal]=useState(0)

  useEffect(()=>{
    async function get() {
      const res= await axios.get(`${API_URL}/purchase`);
      let data = res.data.data
      data = data.filter(d=> d?.type === TYPE.BILL && d?.state === STATE?.SUBMIT)
     setTotal(data.length);
    }
    get()
    },[])
  
  return (
    <RootStyle>
      <IconWrapperStyle>
      <i className="bi bi-cart4" style={{fontSize: '30px'}}></i>
      </IconWrapperStyle>
      <div style={{display:'inline-flex'}}>
      <Typography variant="h3">{fShortenNumber(total)}</Typography><Typography variant="subtitle2" sx={{ opacity: 0.72 }} style={{paddingTop:'15px',paddingLeft:'10px'}}>
        Weekly Purchase
      </Typography>
      </div>
      <Link style={{display:'block'}} variant="subtitle1"  component={RouterLink} to="/dashboard/purchase_bill" underline="none">
     <LoadingButton style={{color:'#005249',backgroundColor:'inherit',border:'none',boxShadow:'0 0 10px #005249'}}>Add Purchase</LoadingButton>
      </Link>
     
    </RootStyle>
  );
}
