import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { ImageConfig } from '../upload/config'; 
import uploadImg from '../upload/cloud.png';
import { Card, Grid } from '@mui/material';
import { Box } from '@mui/system';

export default function DropFileInput (props) {
    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const [show,setShow]=useState(true)
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');





    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            const updatedList = [...fileList, newFile];
            setFileList(updatedList);
            props.onFileChange(updatedList);
        }
    }

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    }

    return (
        <div style={{textAlign:"-webkit-center"}}>
           {!show && <h1 style={{marginBottom:'20px',fontFamily:'Times New Roman'}}> Preview Your Documents</h1>}
            {show && <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <Card style={{maxWidth:'600px',height:'390px',minWidth:'300px'}}>
                <h1 style={{marginBottom:'20px',fontFamily:'Times New Roman'}}> Upload Files Here</h1>
            <div
                style={{position:'relative',maxWidth:'500px',height:'300px',minWidth:'300px',border:"2px dashed lightsteelblue",borderRadius:'20px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'var(input-bg)'}}
                ref={wrapperRef}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div style={{textAlign:'center',fontWeight:'600px',padding:'10px'}}>
                    <img src={uploadImg} alt="" />
                    <p>Drag & Drop your files here</p>
                </div>
                <input style={{opacity: 0,position: "absolute",top: 0,left:0,width: "100%",height:'100%',cursor: "pointer"}} type="file" value="" multiple onChange={onFileDrop}/>
            </div>
            </Card>
            </Grid>
            </Grid>
            </Box>
            }
            {
                fileList.length > 0 ? (
                    <div style={{marginTop:'30px'}}>
                        <p style={{marginBotton:'20px'}}>
                            Ready to upload
                        </p>
                        {
                            fileList.map((item, index) => ( 
                                <>
                                {!show &&<select style={{width:'240px',borderRadius:'7px',height:'25px',marginBottom:'5px',backgroundColor:'white'}}>
                                <option value="volvo">Aadhaar</option>
                                <option value="saab">Pan</option>
                                <option value="mercedes">Bank Statement</option>
                               </select> }
                                 <div key={index} style={{position:'relative',display:'flex',marginBottom:'10px',padding:'15px',borderRadius:'20px',width:'400px',backgroundColor:'lightsteelblue'}}>
                               
                                    <img style={{width:'50px',marginRight:'20px'}} src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" />
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                                        <p>Name-{item.name}</p>
                                        <p>Size-{item.size}B</p>
                                    </div>
                                    <span 
                                    style={{width:'40px',height:'40px',borderRadius:'50%',display:'flex',alignItems:'center',justifyContent:'center',position:'absolute',right:'10px',top:'50%',transform:'translateY(-50%)',cursor:'pointer',transition:'opacity 0.3s ease',backgroundColor:'white'}}
                                    onClick={() => fileRemove(item)}>x</span>
                                </div>
                                </>
                            ))
                        }
                    </div>
                ) : null
            }

        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
                style={{width:'170px'}}
                size="large"
                type="submit"
                variant="contained"
                onClick={()=>setShow(false)}
                // loading={formik.isSubmitting}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
           
        </div>
    );

   
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func
}

