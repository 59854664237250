import React, { useState, useMemo} from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const containsText = (text, searchText) =>
  text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function SelectOptionSearch(props) {
    //.....props = {submit,label_name,search_name,data}
    const allOptions = props?.data
    const [selectedOption, setSelectedOption] = useState({firm_name:'select dealer name'});
    const [searchText, setSearchText] = useState("");
    const displayedOptions = useMemo(
        () => allOptions.filter((option) => containsText(option?.firm_name, searchText)),
        [searchText,props]
    );
    const handleChange = (data)=>{
      props.submit(data?.dealer_id)
      setSelectedOption(data)
    }

  return (
    <Box>
      <FormControl fullWidth style={{verticalAlign:'inherit'}}>
        <InputLabel id="search-select-label">{props?.label_name}</InputLabel>
        <Select
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{ autoFocus: true }}
          labelId="search-select-label"
          id="search-select"
          value={selectedOption?.firm_name}
          label={props?.label_name}
          onChange={(e) => handleChange({...e.target.value})}
          onClose={() => setSearchText("")}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          renderValue={() => selectedOption?.firm_name}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder={`${props?.search_name}.......`}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {(displayedOptions || allOptions).map((option, i) => (
            <MenuItem key={i} value={option}>
            {option?.firm_name}
            </MenuItem>
            
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
