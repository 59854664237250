import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Autocomplete, Button, CircularProgress, Fab, Input, MenuItem, Select, styled, Table, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import {API_URL} from '../constant';
import { LoginContext } from '../context/logincontext';
// ----------------------------------------------------------------------
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { green } from '@mui/material/colors';
import { isEmpty } from 'lodash';
 
const validationSchema = yup.object({
  part_name: yup.string().required('field cannot be empty'),
  brand: yup.string().required('field cannot be empty'),
  price: yup.string().required('field cannot be empty'),
  min_qty: yup.string().required('field cannot be empty'),
  units: yup.string().required('field cannot be empty'),
  gst_percentage: yup.string().required('field cannot be empty')
});

export default function SalesInsuranceDetail() {
  const { id } = useParams()
  const context = useContext(LoginContext)
  const[userData,setUserData]=useState(context.user);
  const navigate = useNavigate();
  const[vendor,setValue]=useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingEndorsement, setLoadingEndorsement] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef();
  const[insuranceDoc , setInsuranceDoc]=useState(undefined)
  const[endorsement , setEndorsement]=useState(undefined)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const[SalesData,setSalesData]=useState({});
  const [insurances, setInsurances] = useState([]);
  const [insurance, setInsurance] = useState({});

  
  
  const formik = useFormik({
    initialValues: {
      insurance_policy:'',
      endorsement:'',
      user_id:userData.user_id,
    },
    // validationSchema,
    onSubmit: async (value) => {
      if(SalesData?.insurer){
        value.insurer_id=SalesData?.insurer?.insurer_id
        value.insurer_name=SalesData?.insurer?.insurer_name
      }
      else{
        value.insurer_id=insurance?.insurer_id
        value.insurer_name=insurance?.insurer_name
        SalesData.insurer_id = insurance?.insurer_id
        SalesData.insurer_name = insurance?.insurer_name
      }
      
      value.sales_id=SalesData.sales_id
      const insurerData = {saleData:SalesData,saleDetailData:{}}
      const data = new FormData();
      const files = []
     
      if(!isEmpty(insuranceDoc)) {
        for(let doc of insuranceDoc) {
          files.push({fileType: "insurance_policy",file: doc})
        }
      }else{
        return alert('Upload Insurance Policy')
      }
      if(!isEmpty(endorsement)) {
        for(let doc of endorsement) {
          files.push({fileType: "endorsement",file: doc})
        }
      }else{
        return alert('Upload Endorsement Policy')
      }
      data.append('data', JSON.stringify(value));
      files.forEach((file) => {
        data.append(file.fileType, file.file);
      })
      
      setIsSubmitting(true)
     
      const response = await axios.put(`${API_URL}/sales_insurance/${id}`, data);
      const res = await axios.post(`${API_URL}/sales/${id}`, insurerData);
      if(res.data.status === 'success' && response.data.status === 'success'){
        navigate('/dashboard/sales_list', { replace: true });
      }
    },
  });
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

const getData = async () => {
  
    const data = await axios.get(`${API_URL}/sales_insurances/${id}`)
    if(data?.data?.status === 'success'){
         const salesData=data?.data?.data
         setSalesData({...salesData})
    }
    const insurance = await axios.get(`${API_URL}/insurers`);
      setInsurances([...insurance?.data?.data]);
  
}

useEffect(()=>{
  getData()
  return () => {
      clearTimeout(timer.current);
    };
  },[])
  const handleEndorsement = (e) => {
    if (!loadingEndorsement) {
      setEndorsement(Object.values(e.target.files))
      setSuccess(false);
      setLoadingEndorsement(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoadingEndorsement(false);
      }, 1000);
    }
  }

  const handleInsurance = (e) => {
    
    if (!loading) {
      setInsuranceDoc(Object.values(e.target.files))
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 1000);
    }
  }

  const createNewInsurance = () => {
    navigate('/dashboard/insurer', { replace: true });
  }

  const handleInsuranceName = (insurance) => {
    if (insurance) {
      setInsurance(insurance)
      formik.setFieldValue('insurer_name', insurance.insurer_name)
    }

  }
  
  return (
    <div>
      <Typography variant="h4" gutterBottom>
              Sales Insurance Detail
      </Typography>
      <form onSubmit={formik.handleSubmit}>
      <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2} justifyContent='left'>
      <Grid item xs={20} sm={6}>
        <Table>

            <tr >
            <th style={{ width: '100%', border: 'none', textAlign: 'left', verticalAlign: 'text-bottom' }}>
                <th style={{width:'20%', border: 'none', color: 'green' }}>
                <div>Invoice No. </div>
                <div>Customer </div>
                <div>Mobile No. </div>
                <div>Address </div>
                </th>
                <th style={{width:'80%' ,border: 'none', textAlign: 'left', color: 'black' }}>
                <div style={{ fontWeight: 'bold' }}>: {SalesData?.invoice_number}</div>
                <div style={{ fontWeight: 'bold' }}>: {SalesData?.lead?.name}</div>
                <div style={{ fontWeight: 'bold' }}>: {SalesData?.lead?.mobile}</div>
                <div style={{ fontWeight: 'bold' }}>: {SalesData?.lead?.address}</div>
                </th>
            </th>

            </tr>
        </Table>
        </Grid>
        <Grid item xs={20} sm={6}>
        {SalesData.insurer ? <Table>

            <tr >
            <th style={{ width: '100%', border: 'none', textAlign: 'left', verticalAlign: 'text-bottom',justifyContent:'space-around' }}>
                <th style={{width:'45%', border: 'none', color: 'green' }}>
                <div>Insurer </div>
                <div>Point of Contact </div>
                <div>Mobile No.</div>
                </th>
                <th style={{width:'80%', border: 'none', textAlign: 'left', color: 'black' }}>
                <div style={{ fontWeight: 'bold' }}>: {SalesData?.insurer?.insurer_name}</div>
                <div style={{ fontWeight: 'bold' }}>: {SalesData?.insurer?.poc}</div>
                <div style={{ fontWeight: 'bold' }}>: {SalesData?.insurer?.mobile}</div>
                </th>
            </th>

            </tr>
        </Table>
        :
        <Autocomplete
          id="insurer_name"
          name='insurer_name'
          options={insurances}
          getOptionLabel={(option) => option?.insurer_name}
          noOptionsText={<LoadingButton onClick={createNewInsurance} >Add Insurer</LoadingButton>}
          renderInput={(params) => <TextField {...params} label="Insurer Name" />}
          // value={lead?.name}
          onChange={(event, newValue) => {
            handleInsuranceName(newValue)
          }}
          error={formik.touched.insurer_name && Boolean(formik.errors.insurer_name)}
          helpertext={formik.touched.insurer_name && formik.errors.insurer_name}
        />}
        </Grid>
         <Grid item xs={20} sm={6}>
                  
                    
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ m: 1, position: 'relative' }}>
                        <Fab
                          aria-label="save"
                          color="primary"
                          sx={buttonSx}
                          onClick={handleInsurance}
                        >
                          {(success && Boolean(insuranceDoc)) ? <CheckIcon /> : <SaveIcon />}
                        </Fab>
                        { (loading && Boolean(insuranceDoc))&& (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: -6,
                              left: -6,
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                      <Box sx={{ m: 1, position: 'relative' }}>
                      <label htmlFor="insurance_policy">
                        <Input accept="image/*,application/pdf" id="insurance_policy" multiple type="file" onChange={handleInsurance} />
                        <Button
                          variant="contained"
                          sx={buttonSx}
                          disabled={loading}
                          onClick={handleInsurance}
                        >
                          Upload Insurance Policy
                        </Button>
                        </label>
                        {(loading && Boolean(insuranceDoc)) && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    
                </Grid>
                <Grid item xs={20} sm={6}>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ m: 1, position: 'relative' }}>
                        <Fab
                          aria-label="save"
                          color="primary"
                          sx={buttonSx}
                          onClick={handleEndorsement}
                        >
                          {(success && Boolean(endorsement)) ? <CheckIcon /> : <SaveIcon />}
                        </Fab>
                        {(loadingEndorsement && Boolean(endorsement)) && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: -6,
                              left: -6,
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                      <Box sx={{ m: 1, position: 'relative' }}>
                      <label htmlFor="endorsement">
                        <Input accept="image/*,application/pdf" id="endorsement" multiple type="file" onChange={handleEndorsement} />
                        <Button
                          variant="contained"
                          sx={buttonSx}
                          disabled={loadingEndorsement}
                          onClick={handleEndorsement}
                        >
                          Upload Endorsement
                        </Button>
                        </label>
                        {(loadingEndorsement && Boolean(endorsement)) && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  
                </Grid> 
      </Grid>
    </Box>
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={8} sm={6}>
              <LoadingButton
               fullWidth
               size="large"
               type="submit"
               variant="contained"
               loading={formik.isSubmitting}
              >
              Submit
              </LoadingButton>
              </Grid>
            </Grid>
          </Box>
      </form>
    </div>
  );
}
