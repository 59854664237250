import * as yup from 'yup';
import {styled} from '@mui/material/styles';
import {useContext,useEffect,useState} from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {isEmpty} from 'lodash';
// material
import {LinearProgress, TextField,ToggleButton,ToggleButtonGroup,Typography,} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuItem from '@mui/material/MenuItem';
import VerifiedIcon from '@mui/icons-material/Verified';

/// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, FILE_UPLOAD_TYPE} from '../constant';
import {LoginContext} from '../context/logincontext';
import Styles from '../css/dates.module.css'
import Iconify from '../components/Iconify';
import moment from 'moment';
import ConfirmDialog from '../components/confirmDialog';

// ----------------------------------------------------------------------
const phoneRegExp = /^([0-9]){10}?$/
const aadhaarRegex = /^([0-9]){4}([0-9]){4}([0-9]){4}?$/
const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
const validationSchemaDetails = yup.object({
  mobile: yup.string().matches(phoneRegExp,'Phone number is not valid').required('field cannot be empty'),
  name: yup.string().required('field cannot be empty'),
  dob: yup.date().required('This field is required'),
  gender: yup.number().required('This field is required'),
  lead_type: yup.string().required('This field is required'),
 
  
});
const validationSchemaAddress = yup.object({
  aadhaar_no: yup.string().matches(aadhaarRegex,'Aadhaar number is not valid').required('This field is required'),
   pan: yup.string().matches(panRegex,'Pan number is not valid').required('This field is required'),
   address: yup.string().required('This field is required'),
   occupation_type: yup.string().required('This field is required'),
   residential_status: yup.string().required('This field is required'),
   monthly_income: yup.number().required('This field is required'),
   city:yup.string().required('This field is required'),
 });
const Input = styled('input')({
  display: 'none',
});
export default function EditLead(props) {
  const {id} = useParams()
  const [panVerified,setPanVerified] = useState(false)
  const [aadharVerified,setAadharVerified] = useState(false)
  const [bankStatement,setBankStatement] = useState(undefined)
  const [electricityBill,setElectricityBill] = useState(undefined)
  const [aadharCard,setAadhaarCard] = useState(undefined)
  const [panCard,setPanCard] = useState(undefined)
  const [kyc,setKYC] = useState(undefined)
  const [isSubmitting,setIsSubmitting] = useState(false)
  const [leadData, setLeadData] = useState({})
  const navigate = useNavigate();
  const context = useContext(LoginContext)
  const [alignment,setAlignment] = useState('details');
  const [pin, setPin] =  useState(undefined)
  const [progress,setProgress] = useState({
    bankStatement: 0,
    electricityBill: 0,
    aadharCard: 0,
    panCard: 0,
    kyc: 0
  });
  const [open, setOpen] = useState(false)
  const [formvalue, setFormValue] = useState({})
  const [part, setPart] = useState([])
  const [dobWarning,setDobWarning] = useState(true)
  const [addressWarning,setAddressWarning] = useState(true)
  const [aadharPath,setAadhaarPath] = useState(true)
  const [panPath,setPanPath] = useState(true)
  const [uploadWarning,setUploadWarning] = useState(false)
  const [valid ,setVaild] = useState(validationSchemaDetails)
  
  const formik = useFormik({
    initialValues: {
      name: '',
      mobile: '',
      email: '',
      aadhaar_no: '',
      pan: '',
      address: '',
      city: '',
      location: '',
      tentative_purchase_date: '',
      vehicle_name: '',
      dob: '',
      occupation_type: '',
      residential_status: '',
      exshowroom_price: '',
      monthly_income: '',
      gender: '',
      user_id: context?.user?.user_id,
      company_id: context?.user?.company_id,
      dealer_id: context?.user?.company?.dealer_id,
      lead_type: '',
      loan_amount_requested:''
    },
    validationSchema : valid,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    } 
  });
  async function submitForm(value){
    console.log(uploadWarning);
      if(alignment === 'address'){
        if(pin.length > 5){
          value.pin_code = pin
        }
      }
      if(value.dob !== null){
        setDobWarning(false)
        }
      if(value.address !== null){
        setAddressWarning(false)
        }
      if(leadData?.aadhar_file_path?.length>0 || leadData?.pan_file_path?.length > 0){
        setUploadWarning(true)
      }
      if(alignment === 'documents' && !aadharCard){
        return alert("Upload Aadhar Card")
      }
      if(alignment === 'documents' && !panCard){
        return alert("Upload Pan Card")
      }

      const data = new FormData();
      const files = []
      if(!isEmpty(bankStatement)) {
        for(let doc of bankStatement) {
          files.push({fileType: FILE_UPLOAD_TYPE.BANK_STATEMENT,file: doc})
        }
      }
      if(!isEmpty(electricityBill)) {
        for(let doc of electricityBill) {
          files.push({fileType: FILE_UPLOAD_TYPE.ELECTRICITY_BILL,file: doc})
        }
      }
      if(!isEmpty(aadharCard)) {
        for(let doc of aadharCard) {
          files.push({fileType: FILE_UPLOAD_TYPE.AADHAR,file: doc})
        }
      }
     
      if(!isEmpty(panCard)) {
        for(let doc of panCard) {
          files.push({fileType: FILE_UPLOAD_TYPE.PAN,file: doc})
        }
      }
     
      if(!isEmpty(kyc)) {
        for(let doc of kyc) {
          files.push({fileType: FILE_UPLOAD_TYPE.OTHER_KYCS,file: doc})
        }
      }
      data.append('data',JSON.stringify(value));
      files.forEach((file) => {
        data.append(file.fileType,file.file);
      })
      setIsSubmitting(true)
      const response = await axios.post(`${API_URL}/lead/update/${id}`,data);
      if(response.data.status === 'success') {
        
        if(alignment === 'address'){
          setAlignment('documents')
          setIsSubmitting(false)
        }
        else if(alignment === 'details'){
          setVaild(validationSchemaAddress)
          setAlignment('address')
          setIsSubmitting(false)
        }
        else if(alignment === 'documents'){
          navigate('/dashboard/leads',{replace: true});
        }
      } else {
        setIsSubmitting(false)
      }
    
  }
 
  const getData = async () => {
    const data = await axios.get(`${API_URL}/leads/${id}`)
    const datas = data.data.data;
    if(data?.data?.status === 'success') {
      formik.setFieldValue('name',datas.name ? datas.name : null)
      formik.setFieldValue('mobile',datas.mobile ? datas.mobile : null)
      formik.setFieldValue('email',datas.email ? datas.email : null)
      formik.setFieldValue('aadhaar_no',datas.aadhaar_no ? datas.aadhaar_no : null)
      formik.setFieldValue('pan',datas.pan ? datas.pan : null)
      formik.setFieldValue('address',datas.address ? datas.address : null)
      formik.setFieldValue('city',datas.city ? datas.city : '')
      formik.setFieldValue('location',datas.location ? datas.location : null)
      formik.setFieldValue('tentative_purchase_date',datas.tentative_purchase_date ? datas.tentative_purchase_date : null) 
      formik.setFieldValue('vehicle_name',datas.vehicle_name ? datas.vehicle_name : null)
      formik.setFieldValue('dob',datas.dob? datas.dob : null)
      formik.setFieldValue('occupation_type',datas.occupation_type ? datas.occupation_type : null)
      formik.setFieldValue('residential_status',datas.residential_status ? datas.residential_status : null)
      formik.setFieldValue('monthly_income',datas.monthly_income ? datas.monthly_income : null)
      formik.setFieldValue('gender',datas.gender ? datas.gender : null)
      formik.setFieldValue('lead_type',datas.lead_type ? datas.lead_type : null)
      formik.setFieldValue('loan_amount_requested',datas.loan_amount_requested ? datas.loan_amount_requested : null)
    }
    if(datas?.dob !== null){
      setDobWarning(false)
      }
    if(datas?.address !== null){
      setAddressWarning(false)
      }
   
    const params = new URLSearchParams(window.location.search)
    const[key]=params
    if(key[0]==="category" && key[1]==="details"){
      setAlignment('details')
    }
    else if(key[1]==="documents"){
      setAlignment('documents')
    }
    else{
      setAlignment('address')
    }
    setLeadData({...data.data.data})
    setPanVerified(datas?.is_pan_verified)
    setAadharVerified(datas?.is_aadhaar_verified)
    setPin(datas?.pin_code)
    setAadhaarPath(leadData?.aadhaar_file_path)
    setPanPath(leadData?.pan_file_path)
    
  }
  useEffect(() => {
    getData()
  },[])
 

  const handleBankStatement = (e) => {
    setBankStatement(Object.values(e.target.files))
    e.target.files.length > 0 ? setProgress({...progress,bankStatement: 100}) : setProgress({...progress,bankStatement: 0})
  }
  const handleElectricityBill = (e) => {
    setElectricityBill(Object.values(e.target.files))
    e.target.files.length > 0 ? setProgress({...progress,electricityBill: 100}) : setProgress({...progress,electricityBill: 0})
  }
  const handleAadhaarCard = (e) => {
    setAadhaarCard(Object.values(e.target.files))
    e.target.files.length > 0 ? setProgress({...progress,aadharCard: 100}) : setProgress({...progress,aadharCard: 0})
  }
  const handlePanCard = (e) => {
    setPanCard(Object.values(e.target.files))
    e.target.files.length > 0 ? setProgress({...progress,panCard: 100}) : setProgress({...progress,panCard: 0})
  }
  const handleKYCDocument = (e) => {
    setKYC(Object.values(e.target.files))
    e.target.files.length > 0 ? setProgress({...progress,kyc: 100}) : setProgress({...progress,kyc: 0})
  }

  const handleChange = (event,newAlignment) => {
    if(!isEmpty(newAlignment)){
      setAlignment(newAlignment);
      setIsSubmitting(false)
    }
    else{
      setAlignment('details');
      setIsSubmitting(false)
    }
  }
  const handlePinChange = async (e) => {
    setPin(e.target.value)
    if((e.target.value).length > 5){
      const response = await axios.get(`${API_URL}/get_city_by_pin/${e.target.value}`);
      if(response.data.status === 'success'){
        formik.setFieldValue('city',response?.data?.data?.district_name)
      }
    }else{
      formik.setFieldValue('city','')
    }
  }
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  useEffect(() => {
    async function get() {
      const res= await axios.get(`${API_URL}/parts`);
      setPart([...res?.data?.data]);
    }
    get()
  },[]);
  useEffect(()=>{
    if(formik.values.vehicle_name){
      const exshowroomPrice = part?.find(parts => parts.part_name === formik.values.vehicle_name)
      formik.setFieldValue('exshowroom_price', exshowroomPrice?.price) 
    }
  },[formik.values.vehicle_name])
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography variant="h4" gutterBottom>
        Edit Customer
      </Typography>
      <ToggleButtonGroup
        color="primary"
        size="medium"
        value={alignment}
        defaultValue={'details'}
        exclusive
        fullWidth
        style={{paddingLeft: '20px',paddingRight: '20px'}}
        onChange={handleChange}
      >
        <ToggleButton value="details">{dobWarning  ? <i class="bi bi-exclamation-circle" style={{marginRight:'10px',color:'red'}}></i> : <i class="bi bi-check-circle" style={{marginRight:'10px',color:'green'}}></i>} Profile Details</ToggleButton>
        <ToggleButton value="address"> { addressWarning  ? <i class="bi bi-exclamation-circle" style={{marginRight:'10px',color:'red'}}></i>: <i class="bi bi-check-circle" style={{marginRight:'10px',color:'green'}}></i>}KYC Details</ToggleButton>
        <ToggleButton value="documents">{ !uploadWarning ? <i class="bi bi-exclamation-circle" style={{marginRight:'10px',color:'red'}}></i>: <i class="bi bi-check-circle" style={{marginRight:'10px',color:'green'}}></i>}Upload Documents</ToggleButton>
      </ToggleButtonGroup>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            {(alignment === 'details') && <>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="name"
                type="text"
                label="Name *"
                inputProps={{style: {textTransform: "uppercase"}}}
                onBlur={() => formik.setFieldValue('name',(formik.values.name).toUpperCase())}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="mobile"
                inputProps={{maxLength: 10}}
                label="Mobile Number *"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="gender"
                id="gender"
                name="gender"
                label="Gender *"
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helpertext={formik.touched.gender && formik.errors.gender}
              >
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="dob"
                type={'date'}
                label="DOB *"
                className={formik.values.dob === null && Styles.dates}
                value={formik.values.dob}
                onChange={formik.handleChange}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="lead_type"
                id="lead_type"
                name="lead_type"
                label="Lead Type *"
                value={formik.values.lead_type}
                onChange={formik.handleChange}
                error={formik.touched.lead_type && Boolean(formik.errors.lead_type)}
                helpertext={formik.touched.lead_type && formik.errors.lead_type}
              >
                <MenuItem value={'E-Rickshaw'}>E-Rickshaw</MenuItem>
                <MenuItem value={'E-Bike'}>E-Bike</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                select
                autoComplete='off'
                fullWidth
                name="vehicle_name"
                label="Vehicle Name *"
                value={formik.values.vehicle_name}
                onChange={formik.handleChange}
                error={formik.touched.vehicle_name && Boolean(formik.errors.vehicle_name)}
                helperText={formik.touched.vehicle_name && formik.errors.vehicle_name}
              >{part?.map(parts=><MenuItem key={parts.part_id} value={parts.part_name}>{parts.part_name}</MenuItem>)
              }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                autoComplete='off'
                type={'number'}
                inputProps={{maxLength: 12}}
                name="exshowroom_price"
                label="Ex-Showroom Price *"
                value={formik.values.exshowroom_price}
                onChange={formik.handleChange}
                error={formik.touched.exshowroom_price && Boolean(formik.errors.exshowroom_price)}
                helperText={formik.touched.exshowroom_price && formik.errors.exshowroom_price}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="tentative_purchase_date"
                type={'date'}
                label="Tentative Purchase Date"
                className={formik.values.tentative_purchase_date === null && Styles.dates}
                value={formik.values.tentative_purchase_date}
                onChange={formik.handleChange}
                error={formik.touched.tentative_purchase_date && Boolean(formik.errors.tentative_purchase_date)}
                helperText={formik.touched.tentative_purchase_date && formik.errors.tentative_purchase_date}
              />
            </Grid>
            </>}
            {(alignment === 'address') && <>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="email"
                type="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                autoComplete='off'
                inputProps={{maxLength: 12}}
                name="aadhaar_no"
                label="Aadhaar number *"
                value={formik.values.aadhaar_no}
                onChange={formik.handleChange}
                error={formik.touched.aadhaar_no && Boolean(formik.errors.aadhaar_no)}
                helperText={formik.touched.aadhaar_no && formik.errors.aadhaar_no}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                autoComplete='off'
                name="pan"
                label="Pan Number *"
                inputProps={{maxLength: 10,style: {textTransform: "uppercase"}}}
                onBlur={() => formik.setFieldValue('pan',(formik.values.pan).toUpperCase())}
                value={formik.values.pan}
                onChange={formik.handleChange}
                error={formik.touched.pan && Boolean(formik.errors.pan)}
                helperText={formik.touched.pan && formik.errors.pan}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="address"
                label="Address *"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                label="Pin Code *"
                inputProps={{maxLength: 6}}
                value={pin}
                onChange={handlePinChange}
                error={formik.touched.pin_code && Boolean(formik.errors.pin_code)}
                helperText={formik.touched.pin_code && formik.errors.pin_code}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="city"
                label="City *"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="occupation_type"
                id="occupation_type"
                name="occupation_type"
                label="Occupation Type *"
                value={formik.values.occupation_type}
                onChange={formik.handleChange}
                error={formik.touched.occupation_type && Boolean(formik.errors.occupation_type)}
                helpertext={formik.touched.occupation_type && formik.errors.occupation_type}
              >
                <MenuItem value={'BUSINESS'}>BUSINESS</MenuItem>
                <MenuItem value={'SALARIED'}>SALARIED</MenuItem>
                <MenuItem value={'OTHERS'}>OTHERS</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={11} sm={6}>
              <TextField
                select
                fullWidth
                labelId="residential_status"
                id="residential_status"
                name="residential_status"
                label="Residential Status *"
                value={formik.values.residential_status}
                onChange={formik.handleChange}
                error={formik.touched.residential_status && Boolean(formik.errors.residential_status)}
                helpertext={formik.touched.residential_status && formik.errors.residential_status}
              >
                <MenuItem value={'rented'}>Rented</MenuItem>
                <MenuItem value={'own house'}>Own House</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="loan_amount_requested"
                label="Loan Amount Requested *"
                value={formik.values.loan_amount_requested}
                onChange={formik.handleChange}
                error={formik.touched.loan_amount_requested && Boolean(formik.errors.loan_amount_requested)}
                helperText={formik.touched.loan_amount_requested && formik.errors.loan_amount_requested}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                autoComplete='off'
                inputProps={{maxLength: 12}}
                name="monthly_income"
                type={'number'}
                label="Monthly Income *"
                value={formik.values.monthly_income}
                onChange={formik.handleChange}
                error={formik.touched.monthly_income && Boolean(formik.errors.monthly_income)}
                helperText={formik.touched.monthly_income && formik.errors.monthly_income}
              />
            </Grid>
            </>}
          </Grid>
        </Box>
        {(alignment === 'documents') && <>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='center' columns={{xs: 1,sm: 5}}>
            
              <>
                <Grid item xs={1}>
                  <div style={{display: 'flex',justifyContent: 'center'}}>
                    <label htmlFor="bank_statement">
                      <Input accept="image/*,application/pdf" id="bank_statement" type="file" multiple onChange={handleBankStatement} />
                      <Iconify icon="entypo:upload" sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                    </label>
                    <div>
                      <LinearProgress variant='determinate' value={progress.bankStatement} sx={{width: '160px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                      <Typography style={{fontSize:'15px'}}>Upload Bank Statement {leadData?.bank_statement_path?.length > 0 && <CheckCircleIcon sx={{fontSize: 20}} color="success" />}</Typography>
                      {bankStatement?.map(doc => <span style={{fontSize: '10px',paddingLeft: '50px'}}>{doc.name}, </span>)}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div style={{display: 'flex',justifyContent: 'center'}}>
                    <label htmlFor="electricity_bill">
                      <Input accept="image/*,application/pdf" id="electricity_bill" type="file" multiple onChange={handleElectricityBill} />
                      <Iconify icon="entypo:upload" sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                    </label>
                    <div>
                      <LinearProgress variant='determinate' value={progress.electricityBill} sx={{width: '160px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                      <Typography style={{fontSize:'15px'}}>Upload Electricity Bill {leadData?.electricity_bill_path?.length > 0 && <CheckCircleIcon sx={{fontSize: 20}} color="success" />}</Typography>
                      {electricityBill?.map(doc => <span style={{fontSize: '10px',paddingLeft: '50px'}}>{doc.name}, </span>)}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div style={{display: 'flex',justifyContent: 'center'}}>
                    <label htmlFor="aadhaar_file_path">
                      <Input accept="image/*,application/pdf" id="aadhaar_file_path" type="file" multiple onChange={handleAadhaarCard} />
                      <Iconify icon="entypo:upload" sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                    </label>
                    
                    <div>
                      <LinearProgress variant='determinate' value={progress.aadharCard} sx={{width: '160px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                      <Typography style={{textAlign: 'center',fontSize:'15px'}}>Upload Aadhaar * {leadData?.aadhaar_file_path?.length > 0 && <CheckCircleIcon sx={{fontSize: 20}} color="success" />}</Typography>
                      {aadharCard?.map(doc => <span style={{fontSize: '10px',paddingLeft: '50px'}}>{doc.name} </span>)}{(aadharVerified===true) && <VerifiedIcon sx={{fontSize: 20}} style={{color:'blue'}}/>}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div style={{display: 'flex',justifyContent: 'center'}}>
                    <label htmlFor="pan_file_path">
                      <Input accept="image/*,application/pdf" id="pan_file_path" type="file" multiple onChange={handlePanCard} />
                      <Iconify icon="entypo:upload" sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                    </label>
                    <div>
                      <LinearProgress variant='determinate' value={progress.panCard} sx={{width: '160px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                      <Typography style={{textAlign: 'center',fontSize:'15px'}}>Upload Pan * {leadData?.pan_file_path?.length > 0 && <CheckCircleIcon sx={{fontSize: 20}} color="success" />}</Typography>
                      {panCard?.map(doc => <span style={{fontSize: '10px',paddingLeft: '50px'}}>{doc.name}, </span>)}{(panVerified===true) && <VerifiedIcon sx={{fontSize: 20}} style={{color:'blue'}}/>}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div style={{display: 'flex',justifyContent: 'center'}}>
                    <label htmlFor="other_kyc_file_path">
                      <Input accept="image/*,application/pdf" id="other_kyc_file_path" type="file" multiple onChange={handleKYCDocument} />
                      <Iconify icon="entypo:upload" sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                    </label>
                    <div>
                      <LinearProgress variant='determinate' value={progress.kyc} sx={{width: '160px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                      <Typography style={{textAlign: 'center',fontSize:'15px'}}>Upload KYC {leadData?.other_kyc_file_path?.length > 0 && <CheckCircleIcon sx={{fontSize: 20}} color="success" />}</Typography>
                      {kyc?.map(doc => <span style={{fontSize: '10px',paddingLeft: '50px'}}>{doc.name}, </span>)}
                    </div>
                  </div>
                </Grid>
              </>
            
            
           
          </Grid>
        </Box>
        </>}
        {(alignment !=='documents' || (bankStatement || electricityBill || panCard || kyc || aadharCard)) &&
        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
          }
      </form>
    </div>
  );
}