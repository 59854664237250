import {filter} from 'lodash';
import {sentenceCase} from 'change-case';
import {useEffect,useState} from 'react';
import {Link as RouterLink,useNavigate,useParams} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  Divider
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead,UserListToolbar,UserMoreMenu} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL,TYPE} from '../constant';
import ConfirmDialog from '../components/confirmDialog';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'item_name',label: 'Item Name',alignRight: false},
  {id: 'qty',label: 'Quantity',alignRight: false},
  {id: 'item_price',label: 'Item Price',alignRight: false},
  {id: 'amount_without_gst',label: 'Amount Without Gst',alignRight: false},
  {id: 'cgst',label: 'CGST',alignRight: false},

  {id: 'sgst',label: 'SGST',alignRight: false},
  {id: 'gst_amount',label: 'GST Amount',alignRight: false},
  {id: 'total_amount',label: 'Total Amount',alignRight: false},

];

// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
  if(b[orderBy] < a[orderBy]) {
    return -1;
  }
  if(b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order,orderBy) {
  return order === 'desc'
    ? (a,b) => descendingComparator(a,b,orderBy)
    : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
  const stabilizedThis = array.map((el,index) => [el,index]);
  stabilizedThis.sort((a,b) => {
    const order = comparator(a[0],b[0]);
    if(order !== 0) return order;
    return a[1] - b[1];
  });
  if(query) {
    return filter(array,(_user) => _user?.item_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SalesDetail() {
  const {id} = useParams();
  const [page,setPage] = useState(0);
  const [value,setValue] = useState([]);
  const [order,setOrder] = useState('asc');
  const [selected,setSelected] = useState([]);
  const [orderBy,setOrderBy] = useState('name');
  const [filterName,setFilterName] = useState('');
  const [rowsPerPage,setRowsPerPage] = useState(5);
  const [SalesData,setSalesData] = useState({});
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState(false)
  const [openReturn, setOpenReturned] = useState(false)
  
  const navigate = useNavigate()
  const getFileDownLoad = (type) => {

    return `${API_URL}/lead/${SalesData?.sales_insurance?.sales_id}/docType/${type}/download`
  }

  const handleRequestSort = (event,property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/sales_detail/${id}`);
      setValue([...res.data.data]);
      const data = await axios.get(`${API_URL}/sales_insurances/${id}`)
      if(data?.data?.status === 'success') {
        const salesData = data?.data?.data
        setSalesData({...salesData})
      }
    }
    get()
  },[]);

  const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(value,getComparator(order,orderBy),filterName);
  const isUserNotFound = value.length === 0;

  async function submitForm() {
    if(open){
      const state={state:'cancelled'}
      const data = {saleData: state,saleDetailData: value}
      const res = await axios.post(`${API_URL}/sales/${id}`,data)
      if(res?.data?.status === 'success') {
        
        navigate('/dashboard/sales_list')
      }
    }
    else if(openReturn){
  const type = {prevType: 'invoice',type: 'return'}
    const data = {saleData: type,saleDetailData: value}
    const res = await axios.post(`${API_URL}/sales/${id}`,data)
    if(res?.data?.status === 'success') {
      
      navigate('/dashboard/sales_list')
    }
  }
  }

  const createReturn =() => {
    setOpenReturned(true)
     submitForm()
  }

 
   const closeDialog = () => {
     setOpen(false);
     setOpenReturned(false)
   }; 

   const cancelled=()=>{
    setTitle(true)
     setOpen(true)
     submitForm()
   }


  return (
    <Page>
      <ConfirmDialog  text="Do You Want To Return" open={openReturn} onClose={closeDialog} submit={createReturn} close={closeDialog} />
      <ConfirmDialog  text="Do You Want To Cancel Invoice" open={open} onClose={closeDialog} submit={cancelled} close={closeDialog} />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sales Detail
          </Typography>
        </Stack>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='left'>
            <Grid item xs={40} sm={6}>
              <Table style={{justifyContent: 'left'}}>

                <tr >
                  <th style={{width: '100%',border: 'none',textAlign: 'left',verticalAlign: 'text-bottom',justifyContent: 'left'}}>
                    <th style={{width: 'auto',border: 'none',color: 'green'}}>
                      <div style={{width: 'max-content'}}>Invoice Number </div>
                      <div style={{width: 'max-content'}}>Total Quantity </div>
                      <div style={{width: 'max-content'}}>Total GST Amount </div>
                      <div style={{width: 'max-content'}}>Total Amount</div>
                    </th>
                    <th style={{width: 'auto',border: 'none',textAlign: 'left',color: 'black'}}>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.invoice_number}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.totel_items}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {Number(SalesData?.igst_amount) + Number(SalesData?.cgst_amount) + Number(SalesData?.sgst_amount)}</div>
                      <div style={{fontWeight: 'bold'}}>: {SalesData?.total_amount}</div>
                    </th>
                  </th>

                </tr>
              </Table>
            </Grid>
           {SalesData?.insurer && <Grid item xs={40} sm={6}>
              <Table style={{justifyContent: 'right'}}>

                <tr >
                  <th style={{width: '100%',border: 'none',textAlign: 'left',verticalAlign: 'text-bottom'}}>
                    <th style={{width: 'auto',border: 'none',color: 'green'}}>
                      <div style={{width: 'max-content'}}>Insurer Name </div>
                      <div style={{width: 'max-content'}}>Point Of Contact </div>
                      <div style={{width: 'max-content'}}>Mobile No. </div>
                      <div style={{width: 'max-content'}}>Address </div>
                    </th>
                    <th style={{width: 'auto',border: 'none',textAlign: 'left',color: 'black'}}>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.insurer?.insurer_name}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.insurer?.poc}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.insurer?.mobile}</div>
                      <div style={{fontWeight: 'bold'}}>: {SalesData?.insurer?.address}</div>
                    </th>
                  </th>

                </tr>
              </Table>
            </Grid>}
            <Grid item xs={40} sm={6}>
              <Table style={{justifyContent: 'left'}}>

                <tr >
                  <th style={{width: '100%',border: 'none',textAlign: 'left',verticalAlign: 'text-bottom',justifyContent: 'left'}}>
                    <th style={{width: 'auto',border: 'none',color: 'green'}}>
                      <div style={{width: 'max-content'}}>Customer Name </div>
                      <div style={{width: 'max-content'}}>Mobile No. </div>
                      <div style={{width: 'max-content'}}>Address </div>
                    </th>
                    <th style={{width: 'auto',border: 'none',textAlign: 'left',color: 'black'}}>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.lead?.name}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.lead?.mobile}</div>
                      <div style={{fontWeight: 'bold'}}>: {SalesData?.lead?.address}</div>
                    </th>
                  </th>

                </tr>
              </Table>
            </Grid>
            {SalesData?.financer && <Grid item xs={40} sm={6}>
              <Table style={{justifyContent: 'right'}}>

                <tr >
                  <th style={{width: '100%',border: 'none',textAlign: 'left',verticalAlign: 'text-bottom'}}>
                    <th style={{width: 'auto',border: 'none',color: 'green'}}>
                      <div style={{width: 'max-content'}}>Financer Name </div>
                      <div style={{width: 'max-content'}}>Mobile No. </div>
                      <div style={{width: 'max-content'}}>Address </div>
                    </th>
                    <th style={{width: 'auto',border: 'none',textAlign: 'left',color: 'black'}}>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.financer?.financer_name}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {SalesData?.financer?.mobile}</div>
                      <div style={{fontWeight: 'bold'}}>: {SalesData?.financer?.address}</div>
                    </th>
                  </th>

                </tr>
              </Table>
            </Grid>}
          </Grid>
        </Box>

        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='left'>
            <Grid item xs={20} sm={6}>
              <Box sx={{display: 'flex',alignItems: 'center'}}>
                <Box sx={{m: 1,position: 'relative'}}>
                  <Button
                    variant="contained"
                    // sx={buttonSx}
                    // disabled={loading}
                    onClick={() => window.open(getFileDownLoad('insurance_policy'),'_blank')}
                  >
                    Download Insurance Policy
                  </Button>
                </Box>
              </Box>

            </Grid>
            <Grid item xs={20} sm={6}>
              <Box sx={{display: 'flex',alignItems: 'center'}}>
                <Box sx={{m: 1,position: 'relative'}}>
                  <Button
                    variant="contained"
                    // sx={buttonSx}
                    // disabled={loading}
                    onClick={() => window.open(getFileDownLoad('endorsement'),'_blank')}
                  >
                    Download Endorsement
                  </Button>
                </Box>
              </Box>

            </Grid>
          </Grid>
        </Box>

        <Card>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={value.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage).map((v,index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    tabIndex={-1}
                    role="checkbox"
                  >
                    <TableCell align="left">{v.item_name}</TableCell>
                    <TableCell align="left">{v.qty}</TableCell>
                    <TableCell align="left">{v.item_price}</TableCell>
                    <TableCell align="left">{v.amount_without_gst}</TableCell>
                    <TableCell align="left">{v.cgst}</TableCell>
                    <TableCell align="left">{v.sgst}</TableCell>
                    <TableCell align="left">{v.gst_amount}</TableCell>
                    <TableCell align="left">{v.total_amount}</TableCell>
                    <TableCell align="left">
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{height: 53 * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{py: 3}}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </Card>
      </Container>
      <Grid container spacing={2} justifyContent='center' columns={{ xs: 2, sm:2 }} style={{paddingTop:'10px'}}>
      {/* <Grid item xs={0.5} justifyContent='center'>
        <Button  variant="contained"  onClick={()=>setOpen(true)}>
          View Vehicle Details
        </Button>
        </Grid> */}
        <Grid item xs={0.5} justifyContent='left'>
        {SalesData.type !== 'return' &&
        <Button  variant="contained" onClick={()=>setOpenReturned(true)}>
          Create Return 
        </Button>
        }
        </Grid>
        <Grid item xs={0.5} justifyContent='center'>
        <Button  variant="contained"  onClick={()=>setOpen(true)}>
          Cancel Invoice
        </Button>
        </Grid>
      </Grid>
    </Page>
  );
}
