import * as yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// material
import { TextField, Typography, FormControl, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MenuItem from '@mui/material/MenuItem';

// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Iconify from '../components/Iconify';
import {API_URL, NACH_BANK, NACH_STATUS} from '../constant';
import ConfirmDialog from '../components/confirmDialog';
import { LoginContext } from '../context/logincontext'
import { isEmpty } from 'lodash';

axios.defaults.withCredentials = true
// ----------------------------------------------------------------------

const validationSchema = yup.object({
  relation_id:yup.number().required('field cannot be empty'),
  nach_type:yup.string().required('field cannot be empty'),
  bank_name: yup.string().required('field cannot be empty'),
  account_no: yup.string().required('field cannot be empty'),
});

export default function NachForm() {
  const context = useContext(LoginContext)
  const {id} =useParams()
  const[bank,SetBank]=useState([])
  const[lead,SetLead]=useState()
  const[name,SetName]=useState()
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const [userData,setUserData]=useState(context.user);
  const[members,setMembers]=useState([])
  const [disable, setDisable] = useState(false)
  const formik = useFormik({
    initialValues: {
      account_no: '',
      bank_account_type: '',
      maximum_amount:'',
      loan_id:'',
      relation_id:null,
      loan_type:'',
      name:'',
      account_name_in_nach_status:'',
      name_in_pdc_cheque:'',
      bank_name:'',
      status:'pending'
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  async function submitForm(value){
    formik.values.loan_id=lead.loan_id;
    formik.values.loan_type=lead.lead_type;
    const memberDetails = members?.filter(member=>member.relation_id===value.relation_id) 
    formik.values.name= !isEmpty(memberDetails) ? memberDetails[0].name:''
    try{
      const response = await axios.post(`${API_URL}/loan/${lead.loan_id}/add_nach`, formik.values);
      if(response?.data?.status === 'success'){
        setOpen(false)
        setDisable(true)
        alert('Added Successfully')
      }
    }
    catch(err){
      return err
    } 
  }

  useEffect(() => {
    async function getLeadData(){
    const res= await axios.get(`${API_URL}/leads/${id}`)
    if(res?.data?.status === 'success'){
      SetLead(res?.data?.data);
      SetName(res?.data.data?.name)
    }
    } 
    getLeadData()
  },[id]);

  useEffect(() => {
    async function get(){
      const res= await axios.get(`${API_URL}/banks/activeENach`)
      if(res?.data?.status === 'success'){
        SetBank(res?.data?.data);
      }
    }
    async function getLoanMembers(){ 
      if(lead){
        const res= await axios.get(`${API_URL}/customer/loan/${lead?.loan_id}/loan_members`)
        if(res?.data?.status === 'success'){
          setMembers(res?.data?.data);
        }
      } 
    }
    get()
    getLoanMembers()
  },[lead]);

  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{paddingLeft:'20px',marginTop:5}} variant="h4" gutterBottom>
        Add New Nach Status
      </Typography>
      <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='center'> 
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="relation_id"
                id="relation_id"
                name="relation_id"
                label="Member *"
                value={formik.values.relation_id}
                onChange={formik.handleChange}
                error={formik.touched.relation_id && Boolean(formik.errors.relation_id)}
                helpertext={formik.touched.relation_id && formik.errors.relation_id}
              >{  
                members?.map((member,i)=>{
                  return <MenuItem key={i} value={member.relation_id}>{`${member.name}-${member.relation_type}-${member.relation}`}</MenuItem>
                })
              }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="nach_type"
                id="nach_type"
                name="nach_type"
                label="NACH Type *"
                value={formik.values.nach_type}
                onChange={formik.handleChange}
                error={formik.touched.nach_type && Boolean(formik.errors.nach_type)}
                helpertext={formik.touched.nach_type && formik.errors.nach_type}
              >
                {
                  Object.keys(NACH_BANK).map((type,i)=>{
                    return <MenuItem value={type}>{type}</MenuItem>
                  })
                }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="bank_name"
                id="bank_name"
                name="bank_name"
                label="Bank Name *"
                value={formik.values.bank_name}
                onChange={formik.handleChange}
                error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
                helpertext={formik.touched.bank_name && formik.errors.bank_name}
              >{  
                bank?.map((v)=>{
                  return <MenuItem value={v.name}>{v.name}</MenuItem>
                })
              }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              type={'text'}
              inputProps={{ maxLength: 18}}
              name="account_no"
              label="Bank Account Number"
              value={formik.values.account_no}
              onChange={formik.handleChange}
              error={formik.touched.account_no && Boolean(formik.errors.account_no)}
              helperText={formik.touched.account_no && formik.errors.account_no}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              name="umrn"
              label="UMRN"
              value={formik.values.umrn}
              onChange={formik.handleChange}
              error={formik.touched.umrn && Boolean(formik.errors.umrn)}
              helperText={formik.touched.umrn && formik.errors.umrn}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="bank_account_type"
                id="bank_account_type"
                name="bank_account_type"
                label="Account Type *"
                value={formik.values.bank_account_type}
                onChange={formik.handleChange}
                error={formik.touched.bank_account_type && Boolean(formik.errors.bank_account_type)}
                helpertext={formik.touched.bank_account_type && formik.errors.bank_account_type}
              >
                <MenuItem value={'savings'}>Savings</MenuItem>
                <MenuItem value={'current'}>Current</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              name="account_name_in_nach_status"
              label="Account Name In Nach Status"
              value={formik.values.account_name_in_nach_status}
              onChange={formik.handleChange}
              error={formik.touched.account_name_in_nach_status && Boolean(formik.errors.account_name_in_nach_status)}
              helperText={formik.touched.account_name_in_nach_status && formik.errors.account_name_in_nach_status}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              name="name_in_pdc_cheque"
              label="Name In PDC cheque"
              value={formik.values.name_in_pdc_cheque}
              onChange={formik.handleChange}
              error={formik.touched.name_in_pdc_cheque && Boolean(formik.errors.name_in_pdc_cheque)}
              helperText={formik.touched.name_in_pdc_cheque && formik.errors.name_in_pdc_cheque}
            />
            </Grid>
            {/* <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="status"
                id="status"
                name="status"
                label="NACH Status"
                value={formik.values.status}
                onChange={formik.handleChange}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helpertext={formik.touched.status && formik.errors.status}
              >
               {
                  Object.keys(NACH_STATUS).map((type,i)=>{
                    return <MenuItem value={type}>{type}</MenuItem>
                  })
                }
              </TextField>
            </Grid> */}
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={disable}
              loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        </form>
    </div>
  );
}
