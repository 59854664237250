import { AppBar, Box, Button, Grid, Link, Toolbar, Typography } from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import React, { useState } from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import moment from 'moment'

const Footer = () => {
    const [isContact,setIsContact] = useState(false);
    return(
    <>
        <AppBar position="static" elevation={0} component="footer" style={{backgroundColor:"#000000"}}>
        <Grid container spacing={0.5} justifyContent='center' columns={{ xs: 4, sm: 55 }} paddingBottom={5}>
                    {/* <Grid item xs={10}>
                        <Toolbar>
                            <Typography variant="caption">About EvCred</Typography>
                        </Toolbar>
                    </Grid> */}
                    <Grid item xs={10}>
                        <Toolbar>
                        <Link underline="none" variant="subtitle2" component={RouterLink} to="/privacy_policy">
                            <Typography fontSize={20} variant="caption">Privacy</Typography>
                        </Link>
                        </Toolbar>
                    </Grid>
                    <Grid item xs={10}>
                        <Toolbar>
                        <Link underline="none" variant="subtitle2" component={RouterLink} to="/terms_and_conditions">
                            <Typography fontSize={20} variant="caption">Terms & Conditions</Typography>
                        </Link>
                        </Toolbar>
                    </Grid>
                    <Grid item xs={10}>
                        <Toolbar>
                        <Link underline="none" variant="subtitle2" component={RouterLink} to="/contact_us">
                            <Typography fontSize={20} variant="caption">Contact Us</Typography>
                        </Link>
                        </Toolbar>
                    </Grid>
                    {/* <Grid item xs={10}>
                        <Toolbar>
                            <Typography fontSize={20} variant="caption">
                                <Button style={{fontSize:20}} onClick={()=>setIsContact(isContact ? false : true)}>{isContact ? <div style={{paddingTop:'12px'}}>Contact Us</div> : 'Contact Us'}</Button>
                                {isContact && <>
                                    <div>9773355846</div>
                                    <div>accounts@lcgmtech.com.</div>
                                </>}
                            </Typography>
                        </Toolbar>
                    </Grid> */}
                    
                    {/* <Grid item xs={15}>
                        <Toolbar>
                            <Typography variant="caption">Copyright © {moment().format('YYYY')} EvCred</Typography>
                        </Toolbar>
                    </Grid> */}
                    {/* <Grid item xs={10}>
                        <Toolbar>
                            <Typography variant="caption"><InstagramIcon /></Typography>
                        </Toolbar>
                    </Grid> */}
                </Grid>
            
        </AppBar>
    </>);
}
export default Footer;