import * as yup from 'yup';
import {Link as RouterLink, useNavigate,useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {Autocomplete,Button,Card,ClickAwayListener,Container,Dialog,FormControl,IconButton,InputLabel,Link,MenuItem,Popper,Select,Stack,styled,Table,TableBody,TableCell,TableContainer,TableRow,TextField,Tooltip,Typography,useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, NumberToWordConverter} from '../constant';
import {useContext,useEffect,useState} from 'react';
import Page from '../components/Page';
import DeleteIcon from '@mui/icons-material/Delete';
import {LoginContext} from '../context/logincontext';
import {FormatLineSpacing} from '@mui/icons-material';
import ConfirmDialog from '../components/confirmDialog';

//details ----------------------------------------------------------------------
const StyledPopper = styled(Popper)(({theme}) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
    }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128"
}));

const validationSchema = yup.object({
  po_number: yup.string().required('field cannot be empty'),
 
});


var QTY = [];
var IGST = [];
var CGST = [];
var SGST = [];
var AMOUNT = [];
var TAXAMOUNT = [];
var TOTAL = [];
var DIS = [];
var current_qty=[0];
var min_qty=[];
export default function AddSalesQuotation() {
  const context = useContext(LoginContext)
  const[userData,setUserData]=useState(context.user);
  const [state,setState] = useState('')
  const [qty,SetQty] = useState(0);
  const [igst,SetIGst] = useState(0);
  const [cgst,SetCGst] = useState(0);
  const [sgst,SetSGst] = useState(0);
  const [taxAmount,setTaxAmount] = useState(0);
  const [item_price,SetAmount] = useState(0);
  const [finalAmount,SetFinalAmount] = useState(0);
  const [disc,setDisc] = useState(0);
  const [discAmount,setDiscAmount] = useState(0);
  const [leads,setLeads] = useState([]);
  const [part,setParts] = useState([]);
  const [lead,setLead] = useState({});
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const [inputList,setInputList] = useState([{item_id: '',item_name: '',qty: 0,item_price: 0,amount_without_gst: 0,discount_percent: 0,discount_amount: 0,taxable_amount: 0,igst: 0,cgst: 0,sgst: 0,igst_amount: 0,cgst_amount: 0,sgst_amount: 0,gst_amount: 0,total_amount: 0,details: [{motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: ''}],open: 0,qtyError: 0}]);
  const navigate = useNavigate();
 
  //details
  const [anchorEl,setAnchorEl] = useState(null);
  const [opendetails, setOpendetails]=useState(false)
  const theme = useTheme();
  let data=[{}]

  const formik = useFormik({
    initialValues: {
      invoice_number: '',
      remarks: '',
      name: '',
      customer_address: '',
      mobile: '',
      pan: '',
      aadhaar_no: '',
    },

    onSubmit: async (value) => {
      
      value.type='quotation'
      value.totel_items = qty
      value.amount_without_gst = item_price
      value.igst_amount = igst
      value.cgst_amount = cgst
      value.sgst_amount = sgst
      value.company_id = 1;
      value.customer_id = lead.lead_id
      value.user_id = userData.user_id
      value.discount_percent = disc
      value.discount_amount = discAmount
      value.taxable_amount = taxAmount
      value.total_amount = finalAmount
      value.customer_name = lead.name
      value.state = state
      
       data = {
        saleData: value,
        saleDetailData: [...inputList]
      }
      setFormValue(data)
      setOpen(true)
    },
  });

      async function submitForm(data) {
        
      try{
          const response = await axios.post(`${API_URL}/sales`, data);
          if (response.data.status === 'success') {
            navigate('/dashboard/sales_list', { replace: true });
          }
       }
      catch(err){
        alert('Out Of Stock! Please Check Quantity? or Enter different Quotation number')
      }
    }
    
  const handleLeadChange = (lead) => {
    if(lead) {
      formik.setFieldValue('name',lead.name)
      setOpendetails(true)
      setLead(lead)
      formik.setFieldValue('customer_address',lead.address)
    }
  }
  useEffect(()=>{
    const getAutoInvoiceNumber = async () => {
      const invoiceNumber = await axios.get(`${API_URL}/sales_invoice/quotation`)
      console.log(invoiceNumber);
      if(invoiceNumber.data.status === 'success'){
      formik.setFieldValue('invoice_number', (invoiceNumber.data.data + 1).toString()) 
      }
    }
    getAutoInvoiceNumber()
  },[])
  

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/data/leads`);
      setLeads([...res?.data?.data]);
      const partsResp = await axios.get(`${API_URL}/parts`);
      setParts(partsResp?.data?.data)
    }
    get()
  },[]);
  const getDiscountAmount = () => {
    var sum = 0
    DIS.map(v => {
      sum = sum + Number(v)
    })
    setDiscAmount(sum.toFixed(2))
  }
  const getSum = () => {
    var sum = 0
    QTY.map(v => {
      sum = sum + Number(v)
    })
    SetQty(sum)
  }
  const getTotalAmount = () => {
    var sum = 0
    TOTAL.map(v => {
      sum = sum + Number(v)
    })
    SetFinalAmount(sum.toFixed(2))
  }
  const getTaxAmount = () => {
    var sum = 0
    TAXAMOUNT.map(v => {
      sum = sum + Number(v)
    })
    setTaxAmount(sum.toFixed(2))
  }
  const getAmount = () => {
    var sum = 0
    AMOUNT.map(v => {
      sum = sum + Number(v)
    })
    SetAmount(sum.toFixed(2))
  }
  const getG = () => {
    var sum1 = 0
    IGST.map(v => {
      sum1 = sum1 + Number(v)
    })
    sum1 = sum1.toFixed(2)

    var sum2 = 0
    CGST.map(v => {
      sum2 = sum2 + Number(v)
    })
    sum2 = sum2.toFixed(2)

    var sum3 = 0
    SGST.map(v => {
      sum3 = sum3 + v
    })
    sum3 = sum3.toFixed(2)
    SetIGst(sum1)
    SetCGst(sum2)
    SetSGst(sum3)
  }

  // handle input change
  const partValue = []
  const handleInputChange = (e,index) => {
    const {name,value} = e.target;
    const list = [...inputList];
    if(name !== 'motor_no' && name !== 'chassis_no' && name !== 'battery_no' && name !== 'color' && name !== 'controller_no' && name !== 'power') {
      list[index][name] = value;
    }

    if(e.target.name === 'item_name') {
      let part1 = part.filter(p => p.part_name === e.target.value);
      partValue[index] = part1[0]
      const ld = [...inputList]
      ld[index]['qtyError'] = 0
      ld[index]['qty'] = 1
      ld[index]['user_id'] = userData.user_id
      ld[index]['item_id'] = isNaN(Number(partValue[index]['part_id'])) ? 0 : Number(partValue[index]['part_id'])
      ld[index]['igst'] = isNaN(parseFloat(partValue[index]['igst'])) ? 0 : parseFloat(partValue[index]['igst'])
      ld[index]['cgst'] = isNaN(parseFloat(partValue[index]['gst_percentage'])) ? 0 : parseFloat(partValue[index]['gst_percentage']) / 2
      ld[index]['sgst'] = isNaN(parseFloat(partValue[index]['gst_percentage'])) ? 0 : parseFloat(partValue[index]['gst_percentage']) / 2
      ld[index]['item_price'] = partValue[index]['price']
      min_qty[index] = isNaN(parseFloat(partValue[index]['min_qty'])) ? 0 :  Number(partValue[index]['min_qty'])
      current_qty[index] = isNaN(parseFloat(partValue[index]['qty'])) ? 0 :  Number(partValue[index]['qty'])
      setInputList(ld);
      if(current_qty[index] < ld[index]['qty']){
        list[index]['qtyAlert']=1
      }
      else{
        list[index]['qtyAlert']=0
        if(current_qty[index] - Number(ld[index]['qty']) <= min_qty[index]) {
          list[index]['qtyError'] = 1
        }
        else {
          list[index]['qtyError'] = 0
        }
      }
    }
  else if(e.target.name === 'qty') {
    if(current_qty[index] < Number(e.target.value)){
        list[index]['qtyAlert']=1
    }
    else{
      list[index]['qtyAlert']=0
      if(current_qty[index] - Number(e.target.value) <= min_qty[index]) {
        list[index]['qtyError'] = 1
      }
      else {
        list[index]['qtyError'] = 0
      }
    }
  }
  else if(name == 'motor_no' || name == 'chassis_no' || name == 'battery_no' || name == 'color' || name == 'controller_no' || name == 'power') {
    list[index]['details'][0][name] = e.target.value
    //setInputList(sd)
  }
    
    const l = [...inputList]
    var qty = []
    qty[index] = list[index]['qty']
    qty[index] = isNaN(qty[index]) ? 0 : Number(qty[index])

    QTY[index] = qty[index]
    getSum()
    var ta = 0
    ta = isNaN(parseFloat(l[index]['item_price'])) ? 0 : Number(l[index]['item_price'])
    var id = (qty[index] * ta).toFixed(2)
    l[index]['amount_without_gst'] = id
    list[index]['amount_without_gst'] = l[index]['amount_without_gst']
    AMOUNT[index] = id
    getAmount()
    var discount = isNaN(parseFloat(list[index]['discount_percent'])) ? 0 : Number(list[index]['discount_percent'])
    setDisc(discount)
    var txAmount = (id - (id * discount / 100)).toFixed(2)
    TAXAMOUNT[index] = Number(txAmount)
    getTaxAmount();
    var discountAmount = id - txAmount
    list[index]['discount_amount'] = discountAmount.toFixed(2)
    DIS[index] = discountAmount
    getDiscountAmount()
    list[index]['taxable_amount'] = txAmount
    var igst = isNaN(parseFloat(l[index]['igst'])) ? 0 : parseFloat(l[index]['igst'])
    var cgst = isNaN(parseFloat(l[index]['cgst'])) ? 0 : parseFloat(l[index]['cgst'])
    var sgst = isNaN(parseFloat(l[index]['sgst'])) ? 0 : parseFloat(l[index]['sgst'])
    list[index]['igst_amount'] = parseFloat((txAmount * igst / 100)).toFixed(2)
    list[index]['cgst_amount'] = parseFloat((txAmount * cgst / 100)).toFixed(2)
    list[index]['sgst_amount'] = parseFloat((txAmount * sgst / 100)).toFixed(2)
    list[index]['gst_amount'] = parseFloat((txAmount * cgst / 100) + (txAmount * igst / 100) + (txAmount * sgst / 100)).toFixed(2)
    list[index]['total_amount'] = (Number(txAmount) + Number(list[index]['gst_amount'])).toFixed(2)
    var finalAmount = Number(list[index]['taxable_amount']) + Number(list[index]['gst_amount'])
    TOTAL[index] = finalAmount
    getTotalAmount()
    IGST[index] = parseFloat((txAmount * igst / 100))
    CGST[index] = parseFloat((txAmount * cgst / 100))
    SGST[index] = parseFloat((txAmount * sgst / 100))
    getG()

    setInputList(list);

  };


  const createNewCustomer = () => {
    navigate('/dashboard/lead',{replace: true});
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index,1);
    QTY.splice(index,1);
    getSum()
    AMOUNT.splice(index,1);
    getAmount()
    TAXAMOUNT.splice(index,1);
    getTaxAmount()
    TOTAL.splice(index,1);
    getTotalAmount()
    DIS.splice(index,1);
    getDiscountAmount()
    IGST.splice(index,1);
    CGST.splice(index,1);
    SGST.splice(index,1);
    getG()
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList,{item_id: '',item_name: '',qty: 0,item_price: 0,amount_without_gst: 0,discount_percent: 0,discount_amount: 0,taxable_amount: 0,igst: 0,cgst: 0,sgst: 0,igst_amount: 0,cgst_amount: 0,sgst_amount: 0,total_amount: 0,details: [{motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: ''}],open: 0,qtyError: 0,qtyAlert:0}]);
  };

  //details
  const handleClick = (event,i) => {
    const list = [...inputList];
    list[i]['open'] = 1
    setInputList(list)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (i) => {
    if(anchorEl) {
      anchorEl.focus();
    }
    const list = [...inputList];
    list[i]['open'] = 0
    setInputList(list)
    setAnchorEl(null);
  };

  const openAnchorEL = Boolean(anchorEl);

  const submitFormValue = () => {
   submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  }; 

  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{paddingLeft:'20px'}} variant="h4"  gutterBottom>
        Add Sales Quotation
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='left'>
            <Grid item xs={11} sm={4}>
              <Autocomplete
                id="name"
                name='name'
                options={leads}
                getOptionLabel={(option) => option?.name}
                noOptionsText={<LoadingButton  onClick={createNewCustomer} >Add Customer</LoadingButton>}
                renderInput={(params) => <TextField {...params} label="Customer Name *" />}
                // value={lead?.name}
                onChange={(event,newValue) => {
                  handleLeadChange(newValue)
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helpertext={formik.touched.name && formik.errors.name}
              />
            </Grid>
            
            {opendetails &&
            <Grid item xs={20} sm={8}>
              {/* <div style={{color:'red'}}>Customer Name : {lead?.name}</div>
              <div>Mobile No.: {lead?.mobile}</div>
              <div>Address : {lead?.address}</div> */}
              <Table>
                
              <tr >
                <th style={{width: '40%',border:'none',textAlign:'left',verticalAlign:'text-bottom'}}>
                <th style={{border:'none',color:'green'}}>
                <div>Customer Name </div>
                <div>Mobile No. </div>
                <div>Address </div>
              </th>
              <th style={{border:'none',textAlign:'left',color:'black'}}>
                <div style={{ fontWeight: 'bold'}}>: {lead?.name}</div>
                <div style={{ fontWeight: 'bold'}}>: {lead?.mobile}</div>
                <div style={{ fontWeight: 'bold'}}>: {lead?.address}</div>
              </th>
                </th>
              
                </tr>
              </Table>
            </Grid>}
            </Grid>
            </Box>
            <Box sx={{flexGrow: 1,p: 2}}>
            <Grid container spacing={2} justifyContent='left'>
            <Grid item xs={11} sm={4}>
              <TextField
                fullWidth
                id="invoice_number"
                name="invoice_number"
                label="Quotation Number *"
                value={formik.values.invoice_number}
                onChange={formik.handleChange}
                error={formik.touched.invoice_number && Boolean(formik.errors.invoice_number)}
                helpertext={formik.touched.invoice_number && formik.errors.invoice_number}
              />
            </Grid>
           
            <Grid item xs={11} sm={4}>
              <TextField
                autoComplete='off'
                fullWidth
                type='text'
                id='remarks'
                name="remarks"
                label="Remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helperText={formik.touched.remarks && formik.errors.remarks}
              />
            </Grid>
          </Grid>
        </Box>
        {inputList.map((x,i) => {
          return (
            <div>
              <Box sx={{flexGrow: 1,p: 2}}>
                <Grid container spacing={0.5} justifyContent='left' columns={{ xs: 4, sm: 85 }}>
                  <Grid item xs={20} sx={{mt:1}}>
                    {/* <label>Part Name</label> */}
                    <FormControl fullWidth>
                      <InputLabel id="part_name">Part Name *</InputLabel>
                      <Select
                        fullWidth
                        id='item_name'
                        name="item_name"
                        label="Item Name"
                        value={x.item_name}
                        onChange={e => handleInputChange(e,i)}
                        error={formik.touched.item_name && Boolean(formik.errors.item_name)}
                        helpertext={formik.touched.item_name && formik.errors.item_name}
                      >
                        {part.map((p,i) => {
                          return (
                            <MenuItem value={p.part_name}>{p.part_name}</MenuItem>
                          );
                        })}
                        <MenuItem>
                        <Link variant="subtitle2" component={RouterLink} to="/dashboard/product" underline="none">
                        <LoadingButton >Add Model</LoadingButton>
                        </Link>
                        </MenuItem>
                      </Select>
                      <Tooltip title='Enter Chassis No., Battery No. etc' ><IconButton color="secondary" id={`details${[i]}`} name={`details${i}`} onClick={(e) => handleClick(e,i)} style={{fontSize: '15px'}}>Additional Details</IconButton></Tooltip>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5.5} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'number'}
                      name="qty"
                      label="Qty"
                      value={x.qty}
                      onChange={e => handleInputChange(e,i)}
                      error={Boolean(inputList[i]['qtyAlert']) && `Alert`}
                      helperText={(Boolean(inputList[i]['qtyError']) || Boolean(inputList[i]['qtyAlert'])) && `Available quantity = ${current_qty[i]}`}
                    />
                  </Grid>
                  <Grid item xs={8} sx={{mt: 1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="item_price"
                      label="Rate"
                      value={x.item_price}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.item_price && Boolean(formik.errors.item_price)}
                      helperText={Boolean(x.item_price) && NumberToWordConverter.convert(x.item_price)}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="amount_without_gst"
                      label="Amount"
                      value={x.amount_without_gst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.amount_without_gst && Boolean(formik.errors.amount_without_gst)}
                      helperText={Boolean(x.amount_without_gst) && NumberToWordConverter.convert(x.amount_without_gst)}
                    />
                  </Grid>
                  <Grid item xs={5.5} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="discount_percent"
                      label="Discount %"
                      value={x.discount_percent}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.discount_percent && Boolean(formik.errors.discount_percent)}
                      helperText={formik.touched.discount_percent && formik.errors.discount_percent}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="taxable_amount"
                      label="Taxable Amount"
                      value={x.taxable_amount}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.taxable_amount && Boolean(formik.errors.taxable_amount)}
                      helperText={Boolean(x.taxable_amount) && NumberToWordConverter.convert(x.taxable_amount)}
                    />
                  </Grid>
                  {/* <Grid item xs={5.5} >
                    <TextField
                    autoComplete='off'
                    fullWidth
                    type={'text'}
                    name="igst"
                    label="IGST%"
                    value={x.igst}
                    onChange={e => handleInputChange(e, i)}
                    error={formik.touched.igst && Boolean(formik.errors.igst)}
                    helperText={formik.touched.igst && formik.errors.igst}
                    />
                    </Grid> */}
                  <Grid item xs={5.5} sx={{mt:1}} >
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="sgst"
                      label="SGST%"
                      value={x.sgst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.sgst && Boolean(formik.errors.sgst)}
                      helperText={formik.touched.sgst && formik.errors.sgst}
                    />
                  </Grid>
                  <Grid item xs={5.5} sx={{mt:1}} >
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="cgst"
                      label="CGST%"
                      value={x.cgst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.cgst && Boolean(formik.errors.cgst)}
                      helperText={formik.touched.cgst && formik.errors.cgst}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="total_amount"
                      label="Final Amount"
                      value={x.total_amount}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.total_amount && Boolean(formik.errors.total_amount)}
                      helperText={Boolean(x.total_amount) && NumberToWordConverter.convert(x.total_amount)}
                    />
                  </Grid>
                {/* Details...................................................................... */}
                  {/* <Grid item xs={3}>
                    <Tooltip title='Add Model Details' ><IconButton color="primary" id={`details${[i]}`} name={`details${i}`} onClick={(e) => handleClick(e,i)} style={{fontSize: '30px'}}>+</IconButton></Tooltip>
                  </Grid> */}
                  {Boolean(inputList[i]['open']) &&
                    <div>

                      <Dialog
                        id={`details${[i]}`}
                        open={openAnchorEL}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                      >
                        <ClickAwayListener id={`details${i}`} name={`details${i}`} onClickAway={() => handleClose(i)}>
                          <Box
                            sx={{
                              borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                                }`,
                              padding: '10px 38px',
                              fontWeight: 600,
                            }}
                          >
                            <Grid container id={`details${i}`} spacing={0.5} justifyContent='center' columns={20}>
                              <Grid item xs={20}>
                                <IconButton color="secondary" id={`details${[i]}`} name={`details${i}`} style={{fontSize: '18px'}}>Additional Details</IconButton>
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name='motor_no'
                                  label="Motor No."
                                  value={x.details[0].motor_no}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.motor_no && Boolean(formik.errors.motor_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="chassis_no"
                                  label="Chassis No."
                                  value={x.details[0].chassis_no}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.chassis_no && Boolean(formik.errors.chassis_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  multiline
                                  maxRows={10}
                                  name="battery_no"
                                  label="Battery No."
                                  value={x.details[0].battery_no}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.battery_no && Boolean(formik.errors.battery_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="color"
                                  label="Color"
                                  value={x.details[0].color}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.color && Boolean(formik.errors.color)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="controller_no"
                                  label="Controller No."
                                  value={x.details[0].controller_no}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.controller_no && Boolean(formik.errors.controller_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="power"
                                  label="Power"
                                  value={x.details[0].power}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.power && Boolean(formik.errors.power)}

                                />
                              </Grid>
                              <Grid item xs={20}>
                              <Tooltip title='Submit Model Details' ><LoadingButton color="primary" variant="contained" aria-label="submit" onClick={() => handleClose(i)} style={{fontSize: '15px'}}>Submit</LoadingButton></Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </ClickAwayListener>
                      </Dialog>
                    </div>
                  }
                  <Grid item xs={5}>
                  {inputList.length !== 1 && <Tooltip title='Remove Model' ><IconButton color="primary" aria-label="delete" onClick={() => handleRemoveClick(i)} style={{fontSize: '40px'}}><DeleteIcon /></IconButton></Tooltip>}
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{m: 0}}>
                {inputList.length - 1 === i && <Tooltip title='Add More Model' ><IconButton color="primary" aria-label="add to shopping cart" onClick={handleAddClick} style={{fontSize: '40px'}}>+</IconButton></Tooltip>}
              </Box>
            </div>
          );
        })}
        <Box sx={{m: 2}}>
        <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={8} sm={4}>
          <LoadingButton
          fullWidth
          size="large"
          type="submit"
          name='draft'
          onClick={()=>setState('draft')}
          variant="contained"
          loading={formik.isSubmitting}
          >
          Save As Draft
          </LoadingButton>
          </Grid>
          <Grid item xs={8} sm={6}>
          <LoadingButton
          fullWidth
          size="large"
          type="submit"
          name='submit'
          onClick={()=>setState('submit')}
          variant="contained"
          loading={formik.isSubmitting}
          >
          Submit
          </LoadingButton>
          </Grid>
      </Grid>
        </Box>
      </form>
      <Page>
        <Container>
          <Typography variant="h4"  gutterBottom>
            Total Order
          </Typography>
          <Card>
            <div style={{minHeight: '10px',backgroundColor: '#f7f7f7',border: '1px solid #000'}}>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total Quantity</div>
                <div>{qty}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Amount</div>
                <div>{item_price}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Discount</div>
                <div>{discAmount}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Taxable Amount</div>
                <div>{taxAmount}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total CGST</div>
                <div>{cgst}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total SGST</div>
                <div>{sgst}</div>
              </div>
              {/* <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total IGST</div>
                <div>{igst}</div>
              </div> */}
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Final Amount</div>
                <div>{finalAmount}</div>
              </div>
            </div>
          </Card>
        </Container>
      </Page>
    </div>
  );
}