import React, {useContext, useEffect, useState} from "react";
import { styled } from '@mui/system';
import Pdf from "react-to-pdf";
import moment from "moment";
import { Button } from '@mui/material';
import { useParams } from "react-router-dom";
import { API_URL, NumberToWordConverter } from "../constant";
import axios from "axios";
import { LoginContext } from "../context/logincontext";
import { PDFExport } from "@progress/kendo-react-pdf";

function createData(S_NO, Product_Name, H_N, Qty, Rate, CGST, SGST,IGST,Amount) {
  return { S_NO, Product_Name, H_N, Qty, Rate, CGST, SGST,IGST, Amount};
}
const ref = React.createRef();



const data = [
  {
    id: 1,
    product_name: 'Bolt',
    h_n: 12,
    qty: 1,
    rate: 20,
    cgst: 14,
    sgst: 12,
  }
]



const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #000;
  }
  td,
  th {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    text-align: left;
  }
  th {
    height: 50px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    padding: 0 2px
  }
  td {
    padding-left: 5px;
    font-size: 16px
  }
`;

const tableHead = [<th>S.No</th>, <th>Description of Goods</th>, <th>HSN/SAC</th>,<th>Quantity</th>,<th>Rate</th>,<th>Amount</th>]

export default function Invoice(props) {
  const { id } = useParams()
  const [round,setRound]=useState()
  const [invoiceData, setInvoiceData] = useState([])
  const [totalData, setTotalData] = useState({})
  const [vendor, setVendor] = useState({})
  const [company, setUserCompany] = useState({})
  useEffect(()=>{
    const get = async () => {
      const data = await axios.get(`${API_URL}/purchase/data/${id}`)
      const res= data.data.data
      setRound(Math.round(res[0].total_amount))
      setTotalData(res[0])
      setInvoiceData(res[0].purchase_details)
      setVendor(res[0].vendor)
      const UserId = res[0].user_id
      const CompanyData= await axios.get(`${API_URL}/user/company/${UserId}`)
      setUserCompany(CompanyData.data.data)
    }
    get()
  },[])
  const todayDate = moment().format('D-MMMM-YYYY');
  const pdfExportComponent = React.useRef(null);
  return (
    <>
    <Root ref={ref} sx={{ maxWidth: '100%', width: 800, margin: 'auto' }}>
      <div style={{textAlign: 'center', fontWeight: 'bold', color: '#000', paddingTop: '30px', paddingBottom: '10px', fontSize: '20px'}}>Purchase Bill</div>
      <div style={{ paddingLeft: '50px', paddingRight: '50px'}}>
      <PDFExport paperSize="a4"  scale={0.7} margin="1cm" ref={pdfExportComponent} fileName={`${totalData.po_number}.pdf`}>
      <table style={{borderBottom:'none'}}>
        <thead>
          <tr>
            <th style={{width: '60%',borderBottom:'none'}}>
              <div style={{display: 'flex', minHeight: '200px', paddingTop: '5px', paddingLeft: '10px'}}>
              {/* <div><img src='https://ascendcap.in/images/ascend_logo.png'  alt="logo" height='auto' width='150px'  />{} </div> */}
              <div style={{fontSize:'15px'}}>
                <div style={{textAlign: 'left',paddingTop: '0px' , fontWeight: 'bold',fontSize:'18px'}}>{company?.name}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Mobile No. : {company?.primary_mobile}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Email Id : {company?.primary_email}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Address : {company?.address}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>Website : {company?.website}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>GSTIN: {company?.gstin}</div>
                <div style={{textAlign: 'left',paddingTop: '5px'}}>PAN: {company?.pan}</div>
              </div>
              </div>
            </th>
            <table style={{border:'none'}}>
            <tr style={{border:'none',borderBottom:'1px solid'}}>
            <td style={{border: '1px solid #000' ,textAlign:'center', borderCollapse: 'collapse',borderLeft:'none',borderBottom:'none',borderTop:'none'}}><div>Purchase Bill No.</div>
                    <div style={{fontWeight: 'bold'}}>{totalData.po_number}</div></td>
              <td style={{border: 'none',textAlign:'center', borderCollapse: 'collapse',borderTop:'none'}}><div>Dated</div>
                    <div style={{fontWeight: 'bold'}}>{todayDate}</div></td>
            </tr>
            </table>
            <div style={{paddingLeft: '10px',fontSize:'15px'}}>
                <div style={{paddingTop: '0px',textAlign:'left'}}> Supplier Name : {vendor.vendor_name}</div>
                <div style={{paddingTop: '5px',textAlign:'left'}}>Mobile : {vendor.primary_mobile}</div>
                <div style={{paddingTop: '5px',textAlign:'left'}}> GSTIN: {vendor.gstin}</div>
                <div style={{paddingTop: '5px',textAlign:'left'}}>Address : {vendor.address}</div>
              </div>
          </tr>
        </thead>    
        </table>
        
      <table> 
        <thead>
          <tr>
            {tableHead.map(head=>head)}
          </tr>
        </thead>
        <tbody>
          {invoiceData.map((d,i) => (
            <tr key={d.id}>
              <td align="right" style={{width: '5%'}}>
                {i + 1}
              </td>
              <td align="right" style={{width: '30%',verticalAlign:'text-top',fontWeight:'bold',wordBreak: 'break-word'}}>
                {d?.part_name}
                {/* <div style={{textAlign:'right',fontWeight:'normal'}}>Discount {d?.discount_percent}%</div> */}
                <div style={{textAlign:'right',fontWeight:'normal', fontSize: '12px',paddingRight:'5px'}}>SGST {d?.sgst}%</div>
                <div style={{textAlign:'right',fontWeight:'normal', fontSize: '12px',paddingRight:'5px'}}>CGST {d?.cgst}%</div>
              </td>
              <td align="right" style={{width: '15%', verticalAlign:'text-top'}}>
               {d.parts[0].hsn}
              </td>
              <td align="right" style={{width: '10%', verticalAlign:'text-top'}}>
              <div style={{verticalAlign:'text-top'}}>{d?.total_qty}</div>
                
              </td>
              <td align="right" style={{width: '15%',  verticalAlign:'text-top'}}>
              Rs {parseFloat(d?.amount)?.toFixed(2)}
              </td>
              
              <td align="left" style={{textAlign:'right',fontWeight:'bold',verticalAlign:'text-top',paddingRight:'5px'}}>
              Rs {parseFloat(d?.total_amount).toFixed(2)}
                {/* <div style={{fontWeight:'normal'}}>-{parseFloat(d?.discount_amount).toFixed(2)} </div> */}
                <div style={{fontWeight:'normal', fontSize: '12px'}}> {d?.sgst_amount}</div>
                <div style={{fontWeight:'normal', fontSize: '12px'}}> {d?.cgst_amount}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table style={{borderTop:'none'}}> 
        <tbody align="left" style={{textAlign:'right',fontWeight:'bold',verticalAlign:'text-top'}}>
        <tr>
          <th style={{textAlign:'left',width: '35%',border:'none', borderCollapse: 'collapse'}}> <div style={{paddingLeft:'5px'}}>Total SGST</div><div style={{paddingLeft:'5px'}}>Total CGST</div></th>  
          <th style={{textAlign:'right',border:'none', borderCollapse: 'collapse'}} ><div style={{paddingRight:'2px'}}>Rs {totalData?.sgst_amount}</div><div style={{paddingRight:'2px'}}>Rs {totalData?.cgst_amount}</div></th> 
        </tr>
        <tr>
          <th style={{textAlign:'left',width: '35%',borderRight:'none'}}><div style={{paddingLeft:'5px'}}>Total Amount</div><div style={{paddingLeft:'5px'}}>Round Off</div><div style={{fontSize: '18px', fontWeight: 'bold',paddingLeft:'5px'}}>Final Amount</div></th>  
          <th style={{textAlign:'right',borderLeft:'none'}} ><div>Rs {totalData?.total_amount}</div><div style={{paddingRight:'5px'}}>Rs {(round-totalData.total_amount).toFixed(2)}</div><div style={{fontSize: '18px', fontWeight: 'bold'}}>Rs {round?.toFixed(2)}</div></th> 
        </tr>
        </tbody>
      </table>
      <div>
        <table style={{textAlign:'left',borderTop:'none'}}>
        <tr style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
        <div style={{paddingTop: '5px',paddingLeft: '5px'}}>Chargable Amount(in words)</div>
        <div style={{ paddingLeft:'5px'}}>INR {NumberToWordConverter.convert(isNaN(round)?0:Number(round))}</div>
        </tr>
        <th style={{width: '40%',border:'none',textAlign:'left',verticalAlign:'text-bottom', borderCollapse: 'collapse'}}>
        
          <div style={{position:'relative',display:'inline-flex',left:'540px',top:'70px'}}>Authorised Signatory</div>
          
            <div style={{display:'inherit',paddingLeft: '5px'}}>Company's Bank Details</div>
            <tr>
              <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                <tr >
                  <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                    <div>Bank Name</div>
                    <div>A/c No. </div>
                    <div>IFS Code </div>
                  </th>
                  <th style={{border:'none',textAlign:'left', borderCollapse: 'collapse'}}>
                    <div style={{ fontWeight: 'bold'}}>: {company?.bank_name}</div>
                    <div style={{ fontWeight: 'bold'}}>: {company?.account_no}</div>
                    <div style={{ fontWeight: 'bold'}}>: {company?.ifsc_code}</div>
                  </th>
                </tr>
              </th>
            </tr>
          </th>
          </table>
        <div style={{paddingTop: '5px'}}>
        <table style={{border:'none',textAlign:'left',paddingTop: '10px', borderCollapse: 'collapse'}}>
        <tr style={{width: '100%',borderLeft:'none'}}>
          <th style={{width: '60%',border:'none',textAlign:'left', borderCollapse: 'collapse'}}>            
            <div>Declaration</div>
            We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.
          </th>
          
        </tr>
      </table>
      </div>
      </div>
      </PDFExport>
      <div style={{marginTop: '30px', textAlign: 'center', width: '100%'}}>This is system generated.</div>
      </div>
    </Root>
    <div style={{textAlign: 'center', marginTop: '10px'}}>
      <Pdf targetRef={ref} filename={`${totalData.po_number}.pdf`}>
        {({ toPdf }) => <Button color="success" variant="contained" onClick={() => {
            if (pdfExportComponent.current) {
              pdfExportComponent.current.save();
            }
          }}>Download</Button>}
      </Pdf>
    </div>
    </>
  );
}