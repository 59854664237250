import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { Box, Button, ClickAwayListener, Dialog, DialogActions, DialogTitle, IconButton, Link, Stack, TextField, Tooltip, useTheme } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../constant';
import image from '../../photos/bike.jpg'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink, useParams} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoginContext } from '../../context/logincontext';
import { isMobile } from 'react-device-detect';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { UserListToolbar } from '../../sections/@dashboard/user';
import { filter } from 'lodash';

const Img = styled('img')({
  margin: '0px', 
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function applySortFilter(array, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    if (query) {
        // const x = query.split('-')
        // let partName =x[0] ? x[0] : ''
        // let color = x[1] ? x[1] : ''
        // let chassisNo = x[2] ? x[1] : ''
        // let power = x[3] ? x[3] : ''
      return filter(array, (_user) => _user?.chassis_no?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

function Index(props) {
    const {type} = useParams()
    const [value , setValue] = useState([])
    const[open,setOpen] = useState(false)
    const [details,setDetails] = useState({part_id:'',motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: ''});
    const { enqueueSnackbar } = useSnackbar();
    const context = React.useContext(LoginContext)
    const[userData,setUserData]=useState(context.user);
    const [filterName, setFilterName] = useState('');

    async function submitForm(data) {
        setOpen(false)
        const vehicleData = {
            ...data,
            user_id:userData.user_id
        }
        let response = await axios.post(`${API_URL}/vehicle_details`,vehicleData);
        if(response.data.status === 'success') {
          if(response?.data?.data?.error){
            enqueueSnackbar(response.data.data.error, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
          }else{
            enqueueSnackbar('Successfully updated vehicle details.', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
            setDetails({part_id:'',motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: ''})
          }
        }
    }

    useEffect(() => {
        async function get() {
          const res= await axios.get(`${API_URL}/vehicle_details`);
          if(res.data.status === 'success'){
            const data = res?.data?.data
            if(type === 'sales'){
                const vehicleData = data.filter(d=>d.sales_id)
                setValue([...vehicleData]);
            }else{
                const vehicleData = data.filter(d=>!d.sales_id)
                setValue([...vehicleData]);
            }
          }
        }
        get()
      },[details,type],props.open);

    const handleInputChange = (e) => {
    const {name,value} = e.target;
    const list = {...details};
        list[name] = value;
        setDetails({...list})
    }

    const submitData = (data)=>{
        setOpen(false)
        submitForm(data)
    }

    const handleClick = (data) => {
    setDetails(data)
    setOpen(true)
    };

    const handleClose = () => {
    setDetails({part_id:'',motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: ''})
    setOpen(false)
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
      };

      const filteredUsers = applySortFilter(value, filterName);
    
  return (
      <form>
    <Box sx={{flexGrow: 1,p: 2}}>
        <Stack direction="row" alignItems="center" mb={1}>
        <Typography style={{paddingLeft:'20px'}} variant="h4" gutterBottom>
            Vehicle Details
        </Typography>
        <UserListToolbar
            isOpen={true}
            title={'Search Vehicle by Chassis number'}
            name='Enter chassis number......'
            // numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        </Stack>
       
        <Grid container spacing={2} justifyContent='center'>
            {filteredUsers.map(data =>{
                return (
                    <Grid item sm={6}>
                        <Paper
                        sx={{
                            p: 2,
                            margin: '0px', 
                            maxWidth: 1000,
                            height:isMobile ? 400 : 250,
                            flexGrow: 1,
                            backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                        }}
                        >
                        <Grid container spacing={2}>
                            {/* <Grid item>
                                <ButtonBase sx={{ width: 100, height: 100 }}>
                                    <Img alt="complex" src={image} />
                                </ButtonBase>
                            </Grid> */}
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={0.1}>
                                    <Grid item xs>
                                        <Typography paddingTop={'25px'} full variant="subtitle1" component="div">
                                            {data?.part_name}
                                        </Typography>
                                        <Typography variant="body2" paddingBottom={'0px'} >
                                            Brand: {data?.brand}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" component="div" paddingTop={'15px'}>
                                    <CurrencyRupeeIcon  style={{paddingTop:'10px'}} />{ data?.price}
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                            <Grid container spacing={0.1} justifyContent='center'>
                                {/* <Grid item sm={6}>
                                    <Typography variant="body2" paddingBottom={'0px'} paddingLeft={'2px'} color="text.secondary">
                                        HSN: {data?.part?.hsn}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography variant="body2" paddingBottom={'0px'} color="text.secondary">
                                    Model Id: {data?.part?.product_id}
                                    </Typography>
                                </Grid> */}
                                <Grid item sm={6}>
                                    <Typography variant="body2" paddingBottom={'0px'} color="text.secondary">
                                        Motor Number: {data?.motor_no}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography variant="body2" paddingBottom={'0px'} color="text.secondary">
                                        Chassis Number: {data?.chassis_no}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography variant="body2" paddingBottom={'0px'} color="text.secondary">
                                        Battery Number: {data?.battery_no}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography variant="body2" paddingBottom={'0px'} color="text.secondary">
                                        Controller Number: {data?.controller_no}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography variant="body2" paddingBottom={'0px'} color="text.secondary">
                                        Power : {data?.power}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography variant="body2" paddingBottom={'0px'} color="text.secondary">
                                        Color: {data?.color}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {props.open ?
                            <>
                            <Button onClick={()=>props.submit(data.id)}>Select</Button>
                            <Button onClick={props.close}>Cancel</Button>
                            </>
                            :
                            <Typography sx={{ cursor: 'pointer' }} variant="body2" paddingBottom={'0px'} >
                            <Link underline="none" component={RouterLink} to={`/dashboard/edit_vehicle_details/${data.id}`}><Button>Edit</Button></Link>
                            <Tooltip title={'Veiw Vehicle Details'}><Link underline="none" component={RouterLink} to={data.sales_id ? `/dashboard/sales_detail/${data.sales_id}` : `/dashboard/purchasedetail/${data.purchase_id}`}><Button>Details</Button></Link></Tooltip>
                            { data?.purchase_id && <Tooltip title='View Vehicle Purchase Details'><Link underline="none" component={RouterLink} to={`/dashboard/purchasedetail/${data.purchase_id}`}><h><LocalMallIcon  style={{paddingTop:'10px', color: 'green'}} /></h></Link></Tooltip>}
                            { data?.sales_id ? <Tooltip title='View Vehicle Sales Details'><Link underline="none" component={RouterLink} to={`/dashboard/sales_detail/${data.sales_id}`}><h><Brightness1Icon style={{paddingTop:'10px', color: 'red'}} />Sold Out</h></Link></Tooltip>
                            :
                            <><Link underline="none" component={RouterLink} to={`/dashboard/sales_vehicle_details/${data.id}`}><Button>Create Sales</Button></Link> 
                            <h><Brightness1Icon style={{paddingTop:'10px', color:'green'}} /> Available</h></>}
                            </Typography>
                            }
                        </Paper>
                    </Grid>
                )
            })}
        </Grid>
        <Dialog
            fullWidth
            open={open}
            keepMounted
            // TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'md'}
            >
            <DialogTitle style={{height:'50px'}}> {'Add Vehicle Details'}</DialogTitle>
            <DialogActions disableSpacing={true}>
            <Grid container spacing={1} justifyContent='center' columns={20}>
            <Grid item xs={10}>
            <TextField
                id={`details`}
                fullWidth
                autoComplete='off'
                type={'text'}
                name='motor_no'
                label="Motor No."
                value={details?.motor_no}
                onChange={e => handleInputChange(e)}
            //   error={formik.touched.motor_no && Boolean(formik.errors.motor_no)}

            />
            </Grid>
            <Grid item xs={10}>
            <TextField
                id={`details`}
                fullWidth
                autoComplete='off'
                type={'text'}
                name="chassis_no"
                label="Chassis No."
                value={details?.chassis_no}
                onChange={e => handleInputChange(e)}
            //   error={formik.touched.chassis_no && Boolean(formik.errors.chassis_no)}

            />
            </Grid>
            <Grid item xs={10}>
            <TextField
                id={`details`}
                fullWidth
                autoComplete='off'
                type={'text'}
                multiline
                maxRows={10}
                name="battery_no"
                label="Battery No."
                value={details?.battery_no}
                onChange={e => handleInputChange(e)}
            //   error={formik.touched.battery_no && Boolean(formik.errors.battery_no)}

            />
            </Grid>
            <Grid item xs={10}>
            <TextField
                id={`details`}
                fullWidth
                autoComplete='off'
                type={'text'}
                name="color"
                label="Color"
                value={details?.color}
                onChange={e => handleInputChange(e)}
            //   error={formik.touched.color && Boolean(formik.errors.color)}

            />
            </Grid>
            <Grid item xs={10}>
            <TextField
                id={`details`}
                fullWidth
                autoComplete='off'
                type={'text'}
                name="controller_no"
                label="Controller No."
                value={details?.controller_no}
                onChange={e => handleInputChange(e)}
            //   error={formik.touched.controller_no && Boolean(formik.errors.controller_no)}

            />
            </Grid>
            <Grid item xs={10}>
            <TextField
                id={`details`}
                fullWidth
                autoComplete='off'
                type={'text'}
                name="power"
                label="Power"
                value={details?.power}
                onChange={e => handleInputChange(e)}
            //   error={formik.touched.power && Boolean(formik.errors.power)}

            />
            </Grid>
            <Stack direction="row" paddingTop={'20px'} alignItems="center" justifyContent="space-between" mb={5}>
                <Tooltip title='Submit Model Details' ><LoadingButton color="primary" variant="contained" aria-label="submit" onClick={() => submitData(details)} style={{fontSize: '15px'}}>Submit</LoadingButton></Tooltip>
                <div style={{paddingLeft:'30px'}}><Button onClick={handleClose} color='error' variant="contained">Cancel</Button></div>
            </Stack>
        </Grid>
        </DialogActions>
    </Dialog>
    </Box>
    </form>
  );
}

export default Index
