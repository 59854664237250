import * as yup from 'yup';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import {useEffect,useState} from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import publicIp from 'public-ip';
// material
import {TextField,Typography,} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
/// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, FILE_UPLOAD_TYPE} from '../constant';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Iconify from '../components/Iconify';
import Styles from '../css/dates.module.css'
import moment from 'moment';
// ----------------------------------------------------------------------
const phoneRegExp = /^([0-9]){10}?$/
const aadhaarRegex = /^([0-9]){4}([0-9]){4}([0-9]){4}?$/
const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
const validationSchema = yup.object({
    mobile: yup.string().matches(phoneRegExp,'Phone number is not valid').required('field cannot be empty'),
    aadhaar_no: yup.string().matches(aadhaarRegex,'Aadhaar number is not valid').required('field cannot be empty'),
    pan: yup.string().matches(panRegex,'Pan number is not valid').required('field cannot be empty'),
    name: yup.string().required('field cannot be empty'),
    address: yup.string().required('field cannot be empty'),
    vehicle_name: yup.string().required('field cannot be empty'),
    dob: yup.date().required('This field is required'),
    occupation_type: yup.string().required('This field is required'),
    residential_status: yup.string().required('This field is required'),
    exshowroom_price: yup.number().required('This field is required'),
    monthly_income: yup.number().required('This field is required'),
    gender: yup.number().required('This field is required'),
});

const Input = styled('input')({
    display: 'none',
});
export default function EditLead(props) {
    const {id} = useParams()
    const [panVerified,setPanVerified] = useState(false)
    const [aadharVerified,setAadharVerified] = useState(false)
    const [leadData,setLeadData] = useState({})
    const [otp,setOpt] = useState(undefined)
    const [verifyAadhar,setVerifyAadhar] = useState(false)
    const [verifyPanButton,setVerifyPanButton] = useState(false)
    const [isOtpSend,setIsOtpSend] = useState(false)
    const [inCorrectOtp,setInCorrectOtp] = useState(false)
    const [downloadBankStatement,setDownloadBankStatement] = useState(false)
    const [downloadElectricityBill,setDownloadElectricityBill] = useState(false)
    const [showAadhaarAlert,setShowAadharAlert] = useState(false)
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            email: '',
            aadhaar_no: '',
            pan: '',
            address: '',
            city: '',
            location: '',
            tentative_purchase_date: '',
            vehicle_name: '',
            dob: '',
            occupation_type: '',
            residential_status: '',
            exshowroom_price: '',
            monthly_income: '',
            gender: ''
        },
        validationSchema,
        onSubmit: async (value) => {
            const response = await axios.post(`${API_URL}/lead/update/${id}`,value);
            if(response.data.status === 'success') {
                navigate('/dashboard/leads',{replace: true});
            }
        },
    });

    const getData = async () => {
        const data = await axios.get(`${API_URL}/leads/${id}`)
        if(data?.data?.status === 'success') {
            const datas = data.data.data;
            formik.setFieldValue('name',datas.name ? datas.name : null)
            formik.setFieldValue('mobile',datas.mobile ? datas.mobile : null)
            formik.setFieldValue('email',datas.email ? datas.email : null)
            formik.setFieldValue('aadhaar_no',datas.aadhaar_no ? datas.aadhaar_no : null)
            formik.setFieldValue('pan',datas.pan ? datas.pan : null)
            formik.setFieldValue('address',datas.address ? datas.address : null)
            formik.setFieldValue('city',datas.city ? datas.city : null)
            formik.setFieldValue('location',datas.location ? datas.location : null)
            formik.setFieldValue('tentative_purchase_date',datas.tentative_purchase_date ? datas.tentative_purchase_date : null) 
            formik.setFieldValue('vehicle_name',datas.vehicle_name ? datas.vehicle_name : null)
            formik.setFieldValue('dob',datas.dob? moment(datas.dob).format('YYYY-MM-DD') : null)
            formik.setFieldValue('occupation_type',datas.occupation_type ? datas.occupation_type : null)
            formik.setFieldValue('residential_status',datas.residential_status ? datas.residential_status : null)
            formik.setFieldValue('exshowroom_price',datas.exshowroom_price ? datas.exshowroom_price : null)
            formik.setFieldValue('monthly_income',datas.monthly_income ? datas.monthly_income : null)
            formik.setFieldValue('gender',datas.gender ? datas.gender : null)
            setPanVerified(datas?.is_pan_verified)
            setAadharVerified(datas?.is_aadhaar_verified)
            setLeadData({...datas})
        }
    }
    useEffect(() => {
        getData()
    },[])
    const verifyPan = async () => {
        setVerifyPanButton(true)
        const pan = await axios.post(`${API_URL}/verify_pan/${id}`)
        if(pan.data.status === 'success') {
            setPanVerified(true)
        }
    }

    const verifyAadhaar = async () => {
        setVerifyAadhar(true)
        if(!aadharVerified) {
            const ipaddress = await publicIp.v4()
            const data = {
                aadhar: leadData.aadhaar_no,
                mobile: leadData.mobile,
                name: leadData.name,
                ip_address: ipaddress,
                id: leadData.los_lead_id,
                lead_id: id,
                type: 'aadhar'
            }
            if(otp) {
                data.otp = otp
            }
            const aadharVerify = await axios.post(`${API_URL}/verify_aadhaar`,data)
            if(aadharVerify.data.status === 'success') {
                if(otp) {
                    navigate(`/dashboard/VerifyLead/${id}`,{replace: true})
                } else {
                    if(aadharVerify.data.data.is_aadhaar_verified) {
                        navigate(`/dashboard/VerifyLead/${id}`,{replace: true})
                    } else {
                        setIsOtpSend(true)
                    }
                }
            }
        } else {
            setInCorrectOtp(true)
            setShowAadharAlert(true)
            setTimeout(() => {
                setShowAadharAlert(false)
            },2000)
        }
    }
    const optStyle = {
        position: 'fixed',
        background: '#eee',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '99',
        padding: '100px',
        borderRadius: '10px'
    }
    const getFileDownLoad = (type) => {
        return `${API_URL}/lead/${leadData.lead_id}/docType/${type}/download`
    }
    const ColorButton = styled(Button)(({theme}) => ({
        color: '#fff',
        backgroundColor: '#0089ff',
        '&:hover': {
            backgroundColor: '#0275d8',
        },
    }));
    return (
        <div>
            {isOtpSend &&
                <div style={{...optStyle,height: '100%',width: '100%',zIndex: 10,backgroundColor: '#000000c7'}}>
                    <div style={optStyle}>
                        <div>
                            <Typography variant="h5" gutterBottom sx={{textAlign: 'center'}}>
                                Enter The OTP
                            </Typography>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                name="otp"
                                type="number"
                                inputProps={{maxLength: 6}}
                                value={otp}
                                onChange={(e) => {
                                    setOpt(e.target.value)
                                    setInCorrectOtp(false)
                                }}
                            />
                            {inCorrectOtp &&
                                <div style={{textAlign: 'center',color: 'red'}}>Enter correct OTP</div>
                            }
                            <div style={{display: 'flex'}}>
                                <Button variant="contained" sx={{mt: 1,boxShadow: 'none',alignSelf: 'center',display: 'block',marginLeft: 'auto',marginRight: 'auto',width: 'max-content'}} onClick={verifyAadhaar}>
                                    Submit
                                </Button>
                                <Button variant="contained" sx={{mt: 1,boxShadow: 'none',background: 'red',alignSelf: 'center',display: 'block',marginLeft: 'auto',marginRight: 'auto',width: 'max-content'}} onClick={() => setIsOtpSend(false)}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Typography variant="h4" gutterBottom>
                Verify Customer
            </Typography>
            {leadData.tenure && leadData.emi && leadData.sanctioned_amount &&
                <>
                    <Grid container spacing={2} justifyContent='space-around' columns={{xs: 1,sm: 3}}>
                        <Grid item>
                            <ColorButton variant="contained" style={{boxShadow: 'none', borderRadius: '0px', background:'#000'}}>
                                <div style={{fontWeight: 'bolder'}}>{leadData.final_loan_amount ? "Loan Ammount : " : "Sanction Ammount : "} </div>
                                <div style={{fontWeight: 'bolder'}}>{leadData.final_loan_amount ? leadData.final_loan_amount : leadData.sanctioned_amount}</div>
                            </ColorButton>
                        </Grid>
                        <Grid item>
                        <ColorButton variant="contained" style={{boxShadow: 'none',  borderRadius: '0px',background:'#000'}}>
                                <div style={{fontWeight: 'bolder'}}>{leadData.final_emi ? 'Final EMI : ' : 'EMI : '} </div>
                                <div style={{fontWeight: 'bolder'}}>{leadData.final_emi ? leadData.final_emi : leadData.emi}</div>
                            </ColorButton>
                        </Grid>
                        <Grid item>
                        <ColorButton variant="contained" style={{boxShadow: 'none',  borderRadius: '0px', background:'#000'}}>
                                <div style={{fontWeight: 'bolder'}}>{leadData.final_tenure ? 'Final Tenure : ' : 'Tenure : '} </div>
                                <div style={{fontWeight: 'bolder'}}>{leadData.final_tenure ? leadData.final_tenure : leadData.tenure}</div>
                            </ColorButton>
                        </Grid>
                    </Grid>
                </>
            }
            {showAadhaarAlert &&
                <Stack sx={{width: '100%'}} spacing={2}>
                    <Alert severity="success"><strong>Your Aadhaar is already verified !</strong></Alert>
                </Stack>
            }
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{flexGrow: 1,p: 2}}>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="name"
                                type="text"
                                label="Lead Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="mobile"
                                type="number"
                                label="Mobile Number"
                                value={formik.values.mobile}
                                onChange={formik.handleChange}
                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                helperText={formik.touched.mobile && formik.errors.mobile}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="email"
                                type="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="address"
                                label="Address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6} style={{position: 'relative'}}>
                            <TextField
                                disabled={true}
                                fullWidth
                                autoComplete='off'
                                inputProps={{maxLength: 12}}
                                name="aadhaar_no"
                                label="Aadhaar number"
                                value={formik.values.aadhaar_no}
                                onChange={formik.handleChange}
                                error={formik.touched.aadhaar_no && Boolean(formik.errors.aadhaar_no)}
                                helperText={formik.touched.aadhaar_no && formik.errors.aadhaar_no}
                            />
                            {aadharVerified &&
                                <CheckCircleIcon style={{position: 'absolute',right: 0,zIndex: 9,top: '50%',transform: 'translate(-10%, -30%)'}} sx={{fontSize: 40}} color="success" />
                            }
                            {!aadharVerified &&
                                <ColorButton disabled={verifyAadhar} variant="contained" sx={{mt: 1,boxShadow: 'none',alignSelf: 'center',display: 'block',marginLeft: 'auto',marginRight: 'auto',width: 'max-content'}} style={{position: 'absolute',right: 0,zIndex: 9,top: '50%',transform: 'translate(-10%, -30%)'}} onClick={verifyAadhaar}>
                                    Verify Aadhar
                                </ColorButton>
                            }
                        </Grid>
                        <Grid item xs={11} sm={6} style={{position: 'relative'}}>
                            <TextField
                                disabled={true}
                                fullWidth
                                autoComplete='off'
                                name="pan"
                                label="Pan Number"
                                inputProps={{maxLength: 10,style: {position: 'relative'}}}
                                value={formik.values.pan}
                                onChange={formik.handleChange}
                                error={formik.touched.pan && Boolean(formik.errors.pan)}
                                helperText={formik.touched.pan && formik.errors.pan}
                            />
                            {panVerified &&
                                <CheckCircleIcon style={{position: 'absolute',right: 0,zIndex: 9,top: '50%',transform: 'translate(-10%, -30%)'}} sx={{fontSize: 40}} color="success" />
                            }
                            {!panVerified &&
                                <ColorButton disabled={verifyPanButton} variant="contained" sx={{mt: 1,boxShadow: 'none',alignSelf: 'center',display: 'block',marginLeft: 'auto',marginRight: 'auto',width: 'max-content'}} style={{position: 'absolute',right: 0,zIndex: 9,top: '50%',transform: 'translate(-10%, -30%)'}} onClick={verifyPan}>
                                    Verify Pan
                                </ColorButton>
                            }
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="city"
                                label="City"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="vehicle_name"
                                label="Vehicle Name"
                                value={formik.values.vehicle_name}
                                onChange={formik.handleChange}
                                error={formik.touched.vehicle_name && Boolean(formik.errors.vehicle_name)}
                                helperText={formik.touched.vehicle_name && formik.errors.vehicle_name}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="tentative_purchase_date"
                                type={'date'}
                                className={formik.values.tentative_purchase_date === null && Styles.dates}
                                label="Tentative Purchase Date"
                                value={formik.values.tentative_purchase_date}
                                onChange={formik.handleChange}
                                error={formik.touched.tentative_purchase_date && Boolean(formik.errors.tentative_purchase_date)}
                                helperText={formik.touched.tentative_purchase_date && formik.errors.tentative_purchase_date}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="dob"
                                type={'date'}
                                className={formik.values.dob === null && Styles.dates}
                                label="DOB"
                                value={formik.values.dob}
                                onChange={formik.handleChange}
                                error={formik.touched.dob && Boolean(formik.errors.dob)}
                                helperText={formik.touched.dob && formik.errors.dob}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                autoComplete='off'
                                fullWidth
                                name="location"
                                label="Location"
                                type={'location'}
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                error={formik.touched.location && Boolean(formik.errors.location)}
                                helperText={formik.touched.location && formik.errors.location}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                fullWidth
                                select
                                labelId="occupation_type"
                                id="occupation_type"
                                name="occupation_type"
                                label="Occupation Type"
                                value={formik.values.occupation_type}
                                onChange={formik.handleChange}
                                error={formik.touched.occupation_type && Boolean(formik.errors.occupation_type)}
                                helpertext={formik.touched.occupation_type && formik.errors.occupation_type}
                            >
                                <MenuItem value={'BUSINESS'}>BUSINESS</MenuItem>
                                <MenuItem value={'SALARIED'}>SALARIED</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                select
                                fullWidth
                                labelId="residential_status"
                                id="residential_status"
                                name="residential_status"
                                label="Residential Status"
                                value={formik.values.residential_status}
                                onChange={formik.handleChange}
                                error={formik.touched.residential_status && Boolean(formik.errors.residential_status)}
                                helpertext={formik.touched.residential_status && formik.errors.residential_status}
                            >
                                <MenuItem value={'rented'}>Rented</MenuItem>
                                <MenuItem value={'own house'}>Own House</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                fullWidth
                                select
                                labelId="gender"
                                id="gender"
                                name="gender"
                                label="Gender"
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                                error={formik.touched.gender && Boolean(formik.errors.gender)}
                                helpertext={formik.touched.gender && formik.errors.gender}
                            >
                                <MenuItem value={1}>Male</MenuItem>
                                <MenuItem value={0}>Female</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                fullWidth
                                autoComplete='off'
                                inputProps={{maxLength: 12}}
                                name="exshowroom_price"
                                label="Ex-Showroom Price"
                                value={formik.values.exshowroom_price}
                                onChange={formik.handleChange}
                                error={formik.touched.exshowroom_price && Boolean(formik.errors.exshowroom_price)}
                                helperText={formik.touched.exshowroom_price && formik.errors.exshowroom_price}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                disabled={true}
                                fullWidth
                                autoComplete='off'
                                inputProps={{maxLength: 12}}
                                name="monthly_income"
                                label="Monthly Income"
                                value={formik.values.monthly_income}
                                onChange={formik.handleChange}
                                error={formik.touched.monthly_income && Boolean(formik.errors.monthly_income)}
                                helperText={formik.touched.monthly_income && formik.errors.monthly_income}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
            <Grid container spacing={2} justifyContent='center' columns={{xs: 1,sm: 2}}>
                    {leadData?.bank_statement_path &&
                        <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                            Download Bank Statement
                            <Iconify icon="entypo:download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.BANK_STATEMENT),'_blank')} />
                        </Grid>

                    }
                    {leadData?.electricity_bill_path &&
                        <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                            Download Electricity Bill <Iconify icon="entypo:download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.ELECTRICITY_BILL),'_blank')} />
                        </Grid>
                    }
                    {leadData?.aadhaar_file_path &&
                        <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                            Download Aadhaar <Iconify icon="entypo:download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.AADHAR),'_blank')} />
                        </Grid>
                    }
                    {leadData?.pan_file_path &&
                        <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                            Download Pan <Iconify icon="entypo:download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.PAN),'_blank')} />
                        </Grid>
                    }
                    {leadData?.other_kyc_file_path &&
                        <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                            Download Kyc Document <Iconify icon="entypo:download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad(FILE_UPLOAD_TYPE.OTHER_KYCS),'_blank')} />
                        </Grid>
                    }
            </Grid>
        </div>
    );
}