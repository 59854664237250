import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// material
import { TextField,  IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Iconify from '../components/Iconify';
import {API_URL} from '../constant';
// ----------------------------------------------------------------------
const phoneRegExp = /^([0-9]){10}?$/
const aadhaarRegex = /^([0-9]){4}([0-9]){4}([0-9]){4}?$/
const panRegex  = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
const validationSchema = yup.object({
  user_name: yup.string().required('please select gender'),
  mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('field cannot be empty'),
  aadhaar_no: yup.string().matches(aadhaarRegex, 'Aadhaar number is not valid').required('field cannot be empty'),
  pan: yup.string().matches(panRegex, 'Pan number is not valid').required('field cannot be empty'),
  dob: yup.date().required('field cannot be empty'),
  gender: yup.number().required('please select gender'),
  password: yup.string().min(8 ,'password should not be less than 8').required('field cannot be empty')
});

export default function ViewUser() {
    const { id } = useParams()
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      user_name: '',
      mobile: '',
      password: '',
      gender: '',
      dob: '',
      aadhaar_no: '',
      pan: ''
    },
    validationSchema,
    onSubmit: async (value) => {
      const response = await axios.post(`${API_URL}/system_user/update/${id}`, value);
      if(response.data.status === 'success'){
        navigate('/dashboard/userlist', { replace: true });
      }
    },
  });
  const getData = async () => {
    const data = await axios.get(`${API_URL}/system_user/${id}`)
    if(data?.data?.status === 'success'){
        const datas = data.data.data;
        formik.setFieldValue('user_name', datas.user_name)
        formik.setFieldValue('mobile', datas.mobile)
        formik.setFieldValue('password', datas.password)
        formik.setFieldValue('gender', datas.gender)
        formik.setFieldValue('dob', datas.dob)
        formik.setFieldValue('aadhaar_no', datas.aadhaar_no)
        formik.setFieldValue('pan', datas.pan)
    }
  
}
  useEffect(()=>{
  getData()
  },[])
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <div>
      <Typography variant="h4" gutterBottom>
              View System User
      </Typography>
      <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="user_name"
              label="Name"
              disabled={true}
              value={formik.values.user_name}
              onChange={formik.handleChange}
              error={formik.touched.user_name && Boolean(formik.errors.user_name)}
              helperText={formik.touched.user_name && formik.errors.user_name}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="mobile"
              label="Mobile"
              disabled={true}
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password"
              disabled={true}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
              <Select
              fullWidth
              labelId="Gender"
              id="gender"
              name="gender"
              label="Gender"
              disabled={true}
              value={formik.values.gender}
              onChange={formik.handleChange}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              helpertext={formik.touched.gender && formik.errors.gender}
              >
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
            id="dob"
            type="date"
            autoComplete='off'
            //defaultValue="2017-05-24"
            fullWidth
            name="dob"
            label="Date Of Birth"
            disabled={true}
            value={formik.values.dob}
            onChange={formik.handleChange}
            error={formik.touched.dob && Boolean(formik.errors.dob)}
            helperText={formik.touched.dob && formik.errors.dob}
          />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              inputProps={{ maxLength: 12 }}
              name="aadhaar_no"
              label="Aadhaar Number"
              disabled={true}
              value={formik.values.aadhaar_no}
              onChange={formik.handleChange}
              error={formik.touched.aadhaar_no && Boolean(formik.errors.aadhaar_no)}
              helperText={formik.touched.aadhaar_no && formik.errors.aadhaar_no}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              name="pan"
              label="Pan Number"
              disabled={true}
              inputProps={{ maxLength: 10 }}
              value={formik.values.pan}
              onChange={formik.handleChange}
              error={formik.touched.pan && Boolean(formik.errors.pan)}
              helperText={formik.touched.pan && formik.errors.pan}
            />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
