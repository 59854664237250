import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
// material
import { Button, TableCell, TextField, Typography} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MenuItem from '@mui/material/MenuItem';

// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, FILE_UPLOAD_TYPE, NumberToWordConverter} from '../constant';
import ConfirmDialog from '../components/confirmDialog';
import { LoginContext } from '../context/logincontext'
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { isEmpty, upperCase } from 'lodash';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// ----------------------------------------------------------------------

export default function FiForm(props) {
  const context = useContext(LoginContext)
  const { enqueueSnackbar } = useSnackbar();
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const [userData,setUserData]=useState(context.user);
  const [files,setFiles] = useState([])
  const [fiFormData,setFiFormData] = useState({})
  const formik = useFormik({
    initialValues: {
        address_matched: '',
        remarks: '',
        staff_id: userData?.los_staff_id,
        capture_time: moment().format('YYYY-MM-DD HH:mm:ss'),
        offlineSave: true,
        loan_id: props?.data?.loan_id,
        property_feedback: '',
        monthly_income: '',
        type: '',
        purpose: 'fi',
        relation_id: 0,
        latitude : '',
        longitude : '',
        fi_photo_doc_id : '',
    },

    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  
  async function submitForm(value){
    const data = new FormData();
    if(!isEmpty(files)){
      data.append(FILE_UPLOAD_TYPE.FI_PHOTOS,...files);
    }
    let formData = {...value}
    if(fiFormData?.form_id){
      formData = {...formData,form_id : fiFormData?.form_id}
    }
    data.append('data',JSON.stringify({...formData}))
    try{
      const response = await axios.post(`${API_URL}/fi_form`, data);
      if(response?.data?.status === 'success'){
        enqueueSnackbar('Fi Form successfuly save', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
        props.onClose()
      }else{
        enqueueSnackbar(response?.data?.data?.message, { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
      // props.onClose()
      }
    }
    catch(err){
      enqueueSnackbar(err?.message, { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
      props.onClose()
    }
  }

  async function get(staffId,loanId){
    const res= await axios.get(`${API_URL}/staff/${staffId}/loan/${loanId}/fi_form_details`)
    if(res?.data?.status === 'success'){
      const fiFormDetails = res?.data?.data
      setFiFormData(fiFormDetails);
      formik.setFieldValue('address_matched',fiFormDetails?.address_matched)
      formik.setFieldValue('remarks',fiFormDetails?.remarks)
      formik.setFieldValue('property_feedback',fiFormDetails?.property_feedback)
      formik.setFieldValue('monthly_income',fiFormDetails?.monthly_income)
      formik.setFieldValue('type',fiFormDetails?.type)
      formik.setFieldValue('latitude',fiFormDetails?.latitude)
      formik.setFieldValue('longitude',fiFormDetails?.longitude)
      formik.setFieldValue('fi_photo_doc_id',fiFormDetails?.fi_photo_doc_id)
    }
  }

  useEffect(() => {
    if(userData?.los_staff_id){
      if(props?.data?.loan_id){
        get(userData?.los_staff_id,props?.data?.loan_id)
      }
    }
  },[]);

  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const uploadFIle = (e) =>{
    setFiles(Object.values(e.target.files))
  }

  return (
    <div style={{margin:50}}>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{textAlign:'center'}} variant="h4" gutterBottom>
              Fi Form
      </Typography>
      <Grid container  style={{paddingLeft:50,paddingTop:30}} boxShadow={false} spacing={2} justifyContent='center' columns={{ xs: 3, sm: 54 }}>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                Name : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.name}</h4>
            </Typography></TableCell>
        </Grid>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                Lead Type : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.lead_type}</h4>
            </Typography></TableCell>
        </Grid>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                Loan Id : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.loan_id}</h4>
            </Typography></TableCell>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                required
                labelId="type"
                id="type"
                name="type"
                label="FI Type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helpertext={formik.touched.type && formik.errors.type}
              >
                <MenuItem value={'home'}>Home</MenuItem>
                <MenuItem value={'work'}>Work</MenuItem>
                <MenuItem value={'patta_verification'}>Patta Verification</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
                fullWidth
                select
                required
                labelId="address_matched"
                id="address_matched"
                name="address_matched"
                label="Address Matched"
                value={formik.values.address_matched}
                onChange={formik.handleChange}
                error={formik.touched.address_matched && Boolean(formik.errors.address_matched)}
                helpertext={formik.touched.address_matched && formik.errors.address_matched}
              >
                <MenuItem value={'true'}>Yes</MenuItem>
                <MenuItem value={'false'}>No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                required
                type={'number'}
                labelId="monthly_income"
                id="monthly_income"
                name="monthly_income"
                label="Monthly Income"
                value={formik.values.monthly_income}
                onChange={formik.handleChange}
                error={formik.touched.monthly_income && Boolean(formik.errors.monthly_income)}
                helperText={Boolean(formik.values?.monthly_income) && NumberToWordConverter.convert(formik.values?.monthly_income)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
                fullWidth
                select
                required
                labelId="property_feedback"
                id="property_feedback"
                name="property_feedback"
                label="FI Feedback"
                value={formik.values.property_feedback}
                onChange={formik.handleChange}
                error={formik.touched.property_feedback && Boolean(formik.errors.property_feedback)}
                helpertext={formik.touched.property_feedback && formik.errors.property_feedback}
              >
                <MenuItem value={'positive'}>Positive</MenuItem>
                <MenuItem value={'negative'}>Negative</MenuItem>
                <MenuItem value={'need_discussion'}>Need Discussion</MenuItem>
                <MenuItem value={'not_applicable'}>Not Applicable</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6} style={{position: 'relative'}}>
              <TextField
                fullWidth
                required={fiFormData?.file_url ? false : true}
                InputLabelProps={{ shrink: true}}
                placeholder='Fi Photos'
                type={'file'}
                name="fi_photos"
                label="FI Photos"
                onChange={(e)=>uploadFIle(e)}
              />
              {fiFormData?.file_url && <Button onClick={() => window.open(fiFormData?.file_url ,'_blank')} style={{position: 'absolute',right:'1%',zIndex: 9,bottom:'-20%',transform: 'translate(-10%, -30%)'}}><CheckCircleIcon  sx={{fontSize: 40}} color="success" /></Button>}
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                type={'number'}
                labelId="latitude"
                id="latitude"
                name="latitude"
                label="Latitude"
                value={formik.values.latitude}
                onChange={formik.handleChange}
                error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                helpertext={formik.touched.latitude && formik.errors.latitude}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                type={'number'}
                labelId="longitude"
                id="longitude"
                name="longitude"
                label="Longitude"
                value={formik.values.longitude}
                onChange={formik.handleChange}
                error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                helpertext={formik.touched.longitude && formik.errors.longitude}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                required
                labelId="remarks"
                id="remarks"
                name="remarks"
                label="Remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helpertext={formik.touched.remarks && formik.errors.remarks}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
