import React, {createContext, useState} from 'react'

export const LeadContext = createContext()

function LeadContextProvider(props) {
    const [leadId, setLeadId] = useState(undefined)
    const [alignment, setAlignment] = useState('details')
    const [message,setMeassage] = useState('')
    const [severity,setSeverity] = useState('')
    const [isOpen,setIsOpen] = useState(false)
    const [customerRelationData, setCustomerRelationData] = useState({})
    const [uploadWarning,setUploadWarning] = useState(true)
   
  return (
    <LeadContext.Provider value={{
        leadId,setLeadId, alignment, setAlignment,message,setMeassage,severity,setSeverity,isOpen,setIsOpen,customerRelationData, setCustomerRelationData,uploadWarning,setUploadWarning
    }}
    >{props.children}
    </LeadContext.Provider>
  )
}

export default LeadContextProvider