import {Link as RouterLink, useNavigate,useParams} from 'react-router-dom';
import {Autocomplete ,Link,MenuItem,Popper,Select,Stack,styled,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField,Tooltip,Typography,useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL} from '../../../constant';
import {useContext,useEffect,useState} from 'react';
import {LoginContext} from '../../../context/logincontext';
import ConfirmDialog from '../../../components/confirmDialog';
import { useSnackbar } from 'notistack';
import { includes } from 'lodash';

//details ----------------------------------------------------------------------

export default function Index() {
  const {id} = useParams()
  const context = useContext(LoginContext)
  const[userData,setUserData]=useState(context.user);
  const[parts,setParts] = useState([])
  const [part,setPart] = useState({})
  const [details,setDetails] = useState({part_id:'',part_name:'',brand:'',price:'',cgst_percentage:'',sgst_percentage:'',motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: '',description:''});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  async function submitForm(data) {
    const vehicleData = {
      ...data,
      user_id:userData.user_id
  }
  let response = await axios.post(`${API_URL}/vehicle_details`,vehicleData);
  if(response.data.status === 'success') {
    if(response.data.data.error){
      enqueueSnackbar(response.data.data.error, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
    }else{
      enqueueSnackbar(id ? 'Successfully updated vehicle details.' : 'Successfully added vehicle details.', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
      setDetails({part_id:'',part_name:'',brand:'',price:'',cgst_percentage:'',sgst_percentage:'',motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: '',description:''})
      navigate('/dashboard/vehicle_details/list',{replace: true});
    }
  }
  }

  useEffect(() => {
    async function get() {
      const partsResp = await axios.get(`${API_URL}/parts`);
      if(partsResp.data.status === 'success'){
        const partData = partsResp?.data?.data
        const parts = partData.filter(part => includes(['L3','L5'],part.category))
        setParts([...parts])
      }
    }
    get()
    if(id){
      getVehicleData(id)
    }else{
      setDetails({part_id:'',part_name:'',brand:'',price:'',cgst_percentage:'',sgst_percentage:'',motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: '',description:''})
    }
  },[id]);

  const getVehicleData = async(id) =>{
    const result = await axios.get(`${API_URL}/vehicle_detail/${id}`)
    if(result.data.status === 'success'){
      const vehicleData = result?.data?.data
      setPart({...vehicleData})
      setDetails({...vehicleData})
    }
  }

  const handleInputChange = (e) => {
    const {name,value} = e.target;
    const list = {...details};
    list[name] = value;
    setDetails(list);
  };

  const handlePartChanges = (part) =>{
    if(part !== null){
      setPart({...part})
      const detail = {motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: '',description:''}
      const list = {...detail,...part}
      list['cgst_percentage'] = part?.gst_percentage/2;
      list['sgst_percentage'] = part?.gst_percentage/2;
      setDetails(list);
    }else{
      setDetails({part_id:'',part_name:'',brand:'',price:'',cgst_percentage:'',sgst_percentage:'',motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: '',description:''})
    }
  }

  const submitFormValue = () => {
    if(details.part_id){
      submitForm(details)
    }else{
      enqueueSnackbar("Model Name is empity", { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
    }
     setOpen(false);
   };
 
   const closeDialog = () => {
     setOpen(false);
   }; 


  return (
    <div>
    <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography style={{paddingLeft:'20px'}} variant="h4" gutterBottom>
        {id ? "Edit Vehicle Details" : "Add Vehicle Details"}
      </Typography>
    </Stack>
    <Box sx={{m: 2}}>
        <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={8} sm={6}>
        <Autocomplete
          fullWidth
          id="part_name"
          name='part_name'
          options={parts}
          getOptionLabel={(option) => option?.part_name || ''}
          noOptionsText={<Link variant="subtitle2" component={RouterLink} to="/dashboard/product" underline="none">
          <LoadingButton >Add Model</LoadingButton>
          </Link>}
          renderInput={(params) => <TextField {...params} label="Model Name" />}
          value={part || {}}
          onChange={(event,newValue) => {
              handlePartChanges(newValue)
          }}
          />
          {/* <Table><TableHead><TableRow><TableCell>Model Name : <h style={{color:'green'}}>{part.part_name}</h></TableCell><TableCell>Model Id : <h style={{color:'green'}}>{part.product_id}</h></TableCell><TableCell>Brand : <h style={{color:'green'}}>{part.brand}</h></TableCell><TableCell>Price : <h style={{color:'green'}}>{part.price}</h></TableCell></TableRow></TableHead></Table> */}
        </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="price"
                label="Selling Price *"
                value={details.price}
                onChange={e => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="cgst_percentage"
                label="CGST %"
                value={details.cgst_percentage}
                onChange={e => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="sgst_percentage"
                label="SGST %"
                value={details.sgst_percentage}
                onChange={e => handleInputChange(e)}
              />
            </Grid>
        <Grid item xs={8} sm={6}>
          <TextField
              fullWidth
              id={`details`}
              autoComplete='off'
              type={'text'}
              name='motor_no'
              label="Motor No."
              value={details.motor_no}
              onChange={e => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextField
              fullWidth
              id={`details`}
              autoComplete='off'
              type={'text'}
              name="chassis_no"
              label="Chassis No."
              value={details.chassis_no}
              onChange={e => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextField
              fullWidth
              id={`details`}
              autoComplete='off'
              type={'text'}
              multiline
              maxRows={10}
              name="battery_no"
              label="Battery No."
              value={details.battery_no}
              onChange={e => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextField
              fullWidth
              id={`details`}
              autoComplete='off'
              type={'text'}
              name="color"
              label="Color"
              value={details.color}
              onChange={e => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextField
              fullWidth
              id={`details`}
              autoComplete='off'
              type={'text'}
              name="controller_no"
              label="Controller No."
              value={details.controller_no}
              onChange={e => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextField
              fullWidth
              id={`details`}
              autoComplete='off'
              type={'text'}
              name="power"
              label="Power"
              value={details.power}
              onChange={e => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            autoComplete='off'
            fullWidth
            type={'text'}
            name="description"
            label="Description"
            value={details.description}
            onChange={e => handleInputChange(e)}

          />
            </Grid>
        </Grid>
    </Box>
    <Box sx={{m: 2}}>
        <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={8} sm={6}>
        <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={()=>setOpen(true)}
            >
            Submit
            </LoadingButton>
        </Grid>
        </Grid>
    </Box>
    </div>
  );
}