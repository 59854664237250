
import { useEffect, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL} from '../constant';
import { filter } from 'lodash';
import ConfirmDialog from '../components/confirmDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'part_name', label: 'Part Name', alignRight: false },
  { id: 'total_qty', label: 'Total Quantity', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'igst', label: 'IGST%', alignRight: false },
  { id: 'cgst', label: 'CGST%', alignRight: false },
  { id: 'sgst', label: 'SGST%', alignRight: false },
  { id: 'igst_amount', label: 'SGST Amount', alignRight: false },
  { id: 'cgst_amount', label: 'SGST Amount', alignRight: false },
  { id: 'sgst_amount', label: 'SGST Amount', alignRight: false },
  { id: 'gst_amount', label: 'Total GST Amount', alignRight: false },
  { id: 'total_amount', label: 'Total Amount', alignRight: false }
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user?.part_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}



export default function PurchaseDetail() {
  const { id } = useParams()
  const [page, setPage] = useState(0);
  const[value,setValue]=useState([]);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false)
  const navigate=useNavigate()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };

  useEffect(() => {
    async function get() {
      const res= await axios.get(`${API_URL}/purchase/data/${id}`);
      setValue(res.data.data[0]);
    }
    get()
  },[]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - value?.purchase_details?.length) : 0;
  const filteredUsers = applySortFilter(value?.purchase_details, getComparator(order, orderBy), filterName);
  const isUserNotFound = value?.purchase_details?.length === 0;

  async function submitForm(){
   
    const type = {prevType:'bill',type:'return'}
    const data={purchase:type,purchaseDetail:value?.purchase_details}
    const res=await axios.post(`${API_URL}/purchase/update/${id}`,data)
    if(res?.data?.status === 'success'){
        navigate('/dashboard/purchase_list')
    }
  }
  
  const createReturn =() => {
    setOpen(true)
     submitForm()
  }

 
   const closeDialog = () => {
     setOpen(false);
   };

  return (
    <Page>
      <ConfirmDialog open={open} onClose={closeDialog} submit={createReturn} close={closeDialog} />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Purchase Detail
          </Typography>
        </Stack>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='left'>
            <Grid item xs={40} sm={6}>
              <Table style={{justifyContent: 'left'}}>

                <tr >
                  <th style={{width: '100%',border: 'none',textAlign: 'left',verticalAlign: 'text-bottom',justifyContent: 'left'}}>
                    <th style={{width: 'auto',border: 'none',color: 'green'}}>
                      <div style={{width: 'max-content'}}>PO Number </div>
                      <div style={{width: 'max-content'}}>Total Quantity </div>
                      <div style={{width: 'max-content'}}>Total GST Amount </div>
                      <div style={{width: 'max-content'}}>Total Amount</div>
                    </th>
                    <th style={{width: 'auto',border: 'none',textAlign: 'left',color: 'black'}}>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {value?.po_number}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {value?.total_qty}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {Number(value?.sgst_amount) + Number(value?.igst_amount) + Number(value?.cgst_amount)}</div>
                      <div style={{fontWeight: 'bold'}}>: {value?.total_amount}</div>
                    </th>
                  </th>

                </tr>
              </Table>
            </Grid>
            <Grid item xs={40} sm={6}>
              <Table style={{justifyContent: 'left'}}>

                <tr >
                  <th style={{width: '100%',border: 'none',textAlign: 'left',verticalAlign: 'text-bottom',justifyContent: 'left'}}>
                    <th style={{width: 'auto',border: 'none',color: 'green'}}>
                      <div style={{width: 'max-content'}}>Vendor Name </div>
                      <div style={{width: 'max-content'}}>Mobile No. </div>
                      <div style={{width: 'max-content'}}>Address </div>
                    </th>
                    <th style={{width: 'auto',border: 'none',textAlign: 'left',color: 'black'}}>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {value?.vendor?.vendor_name}</div>
                      <div style={{width: 'max-content',fontWeight: 'bold'}}>: {value?.vendor?.primary_mobile}</div>
                      <div style={{fontWeight: 'bold'}}>: {value?.vendor?.address}</div>
                    </th>
                  </th>

                </tr>
              </Table>
            </Grid>
          </Grid>
        </Box>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value?.purchase_details?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((v,index)=>{
                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              onChange={(event) => handleClick(event, v.po_number)}
                            />
                          </TableCell> */}
                           
                          <TableCell align="left">{v.part_name}</TableCell>
                          <TableCell align="left">{v.total_qty}</TableCell>
                          <TableCell align="left">{v.amount}</TableCell>
                          <TableCell align="left">{v.igst}</TableCell>
                          <TableCell align="left">{v.cgst}</TableCell>
                          <TableCell align="left">{v.sgst}</TableCell>
                          <TableCell align="left">{v.igst_amount}</TableCell>
                          <TableCell align="left">{v.cgst_amount}</TableCell>
                          <TableCell align="left">{v.sgst_amount}</TableCell>
                          <TableCell align="left">{v.gst_amount}</TableCell>
                          <TableCell align="left">{v.total_amount}</TableCell>
                          <TableCell align="left">
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={value?.purchase_details?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {value?.type !== 'return' &&
      <Grid item xs={20} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative',left:'450px' }}>
            <Button
                variant="contained"
                // sx={buttonSx}
                // disabled={loading}
                onClick={()=>setOpen(true)}
              >
                Create Return
              </Button>
            </Box>
          </Box>
      </Grid>
      }
    </Page>
  );
}
