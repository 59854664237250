// material
import {alpha,styled} from '@mui/material/styles';
import {Card,Typography,Link} from '@mui/material';
// utils
import {fShortenNumber} from '../../../utils/formatNumber';
//
import Iconify from '../../../components/Iconify';
import {useContext,useEffect,useState} from 'react';
import axios from 'axios';
import {API_URL} from '../../../constant';
import {LoginContext} from '../../../context/logincontext'
import {  Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5,0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({theme}) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark,0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 1723315;

export default function AppItemOrders() {
  const [total,setTotal] = useState(0)
  const context = useContext(LoginContext)
  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/data/leads`);
      const data = res?.data?.data
      setTotal(data?.length);
    }
    get()
  },[context.user])
  return (
    <RootStyle>
      <IconWrapperStyle>
      <i className="bi bi-people-fill" style={{fontSize: '30px'}}></i>
      </IconWrapperStyle>
      <div style={{display:'inline-flex'}}>
      <Typography variant="h3">{fShortenNumber(total)}</Typography><Typography variant="subtitle1" sx={{opacity: 0.72}} style={{paddingTop:'15px',paddingLeft:'10px'}}>
        Customer Lead
      </Typography>      
      </div>
      <Link style={{display:'block'}} variant="subtitle1" component={RouterLink} to="/dashboard/lead" underline="none">
     <LoadingButton style={{color:'#7A4F01',backgroundColor:'inherit',border:'none',boxShadow:'0 0 10px #7A4F01'}}>Quick Lead</LoadingButton>
      </Link>
    </RootStyle>
  );
}
