import * as yup from 'yup';
import {Link as RouterLink, useNavigate,useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {Autocomplete,Button,ButtonBase,Card,ClickAwayListener,Container,Dialog,FormControl,IconButton,InputLabel,Link,MenuItem,Paper,Popper,Select,Stack,styled,Table,TableBody,TableCell,TableContainer,TableRow,TextField,Tooltip,Typography,useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, NumberToWordConverter, TYPE} from '../constant';
import {useContext,useEffect,useRef,useState} from 'react';
import Page from '../components/Page';
import DeleteIcon from '@mui/icons-material/Delete';
import {LoginContext} from '../context/logincontext';
import { isEmpty, omit } from 'lodash';
import ConfirmDialog from '../components/confirmDialog';
import CustomizedSnackbars from './Alert/CustomizedSnackbars';
import { useSnackbar } from 'notistack';
import image from '../photos/bike.jpg'
import VehicleList from '../pages/VehicleDetails/index'

const Img = styled('img')({
  margin: '0px', 
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

//details ----------------------------------------------------------------------
const StyledPopper = styled(Popper)(({theme}) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
    }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128"
}));

// const validationSchema = yup.object({
//   po_number: yup.string().required('field cannot be empty'),
//   purchase_from: yup.string().required('field cannot be empty'),
//   remarks: yup.string().required('field cannot be empty'),
//   total_qty: yup.string().required('field cannot be empty'),
//   item_price: yup.string().required('field cannot be empty'),
//   gst_percentage: yup.string().required('field cannot be empty')
// });
const TABLE_HEAD = [
  {id: 'total_qty',label: 'Total Quantity',alignRight: false},
  {id: 'amount',label: 'Amount',alignRight: false},
  {id: 'igst_amount',label: 'IGST Amount',alignRight: false},
  {id: 'cgst_amount',label: 'CGST Amount',alignRight: false},
  {id: 'sgst_amount',label: 'SGST Amount',alignRight: false},
  {id: 'total_amount',label: 'Total Amount',alignRight: false},
];
  var current_qty=[0];
  var min_qty=[];

export default function EditSales(props) {
  const {id,vehicle_id} = useParams()
  const context = useContext(LoginContext)
  const[userData,setUserData]=useState(context.user);
  const [state,setState] =  useState('')
  const [leads,setLeads] = useState([]);
  const [parts,setParts] = useState([]);
  const [lead,setLead] = useState({});
  const [inputList,setInputList] = useState([{vehicle_id:'',item_id: '',item_name: '',qty:0,item_price:0,amount_without_gst:0,discount_percent:0,discount_amount:0,taxable_amount:0,igst:0,cgst:0,sgst:0,igst_amount:0,cgst_amount:0,sgst_amount:0,gst_amount:0,total_amount:0,details: [{motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: ''}],open:0,qtyError:0,qtyAlert:0,isvehicle:false}]);
  const navigate = useNavigate();
  const [input,setInput]=useState({qty:0,amount:0,discount:0,tax_amount:0,igst:0,cgst:0,sgst:0,total_amount:0})
  //details
  const [anchorEl,setAnchorEl] = useState(null);
  const [opendetails, setOpendetails]=useState(false)
  const [financer,setFinancer] = useState([]);
  const [financerName,SetFinancerName] = useState({});
  const theme = useTheme();
  const [type,setType] =  useState()
  const [insurances, setInsurances] = useState([]);
  const [insurance, setInsurance] = useState({});
  const [sales,setSalesData] =useState()
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const [message,setMeassage] = useState('')
  const [severity,setSeverity] = useState('')
  const [isOpen,setIsOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [part,setPart] = useState([{part_name:'',color:'',chassis_no:'',power:''}]);
  const wrapperRef = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');
  let data=[{}]

  const formik = useFormik({
    initialValues: {
      invoice_number: '',
      remarks: '',
      name: '',
      customer_address: '',
      mobile: '',
      pan: '',
      aadhaar_no: '',
    },

    onSubmit: async (value) => {
      value.type=props.type
      value.totel_items = input.qty
      value.amount_without_gst = input.amount
      value.igst_amount = input.igst
      value.cgst_amount = input.cgst
      value.sgst_amount = input.sgst
      value.customer_id = lead.lead_id
      value.user_id = userData.user_id
      value.discount_amount = input.discount
      value.taxable_amount = input.tax_amount
      value.total_amount = input.total_amount
      value.customer_name = lead.name
      value.state= state
      if(id){
        value.prevType = sales.type
      }
      if(props.type === TYPE.INVOICE){
      value.insurer_id = insurance.insurer_id
      value.insurer_name = insurance.insurer_name
      value.financer_id=financerName.financer_id
      value.financer_name = financerName.financer_name
      }
      data = {
        saleData: value,
        saleDetailData: [...inputList]
      }
      setFormValue(data)
      setOpen(true)
    },

  });

    async function submitForm(data) {
      try{
        let response
          if(id){
            response = await axios.post(`${API_URL}/sales/${id}`,data);
          }else{
            response = await axios.post(`${API_URL}/sales`,data);
          }
          if(response.data.status === 'success') {
            if(response.data.data.error){
              enqueueSnackbar(response.data.data.error, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
            }else{
              enqueueSnackbar('Successfully create sales details.', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
              navigate('/dashboard/sales_list',{replace: true});
            }
          }
      }
      catch(err){
        setMeassage('Something went worng?')
        setSeverity('error')
        setIsOpen(true);
      }
    }

  const handleLeadChange = (lead) => {
    if(lead) {
      formik.setFieldValue('name',lead.name)
      setOpendetails(true)
      setLead(lead)
      formik.setFieldValue('customer_address',lead.address)
    }
  }

  const handleInsuranceName = (insurance) => {
    if (insurance) {
      setInsurance(insurance)
      formik.setFieldValue('insurer_name', insurance.insurer_name)
    }

  }

  const createNewInsurance = () => {
    navigate('/dashboard/insurer', { replace: true });
  }

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/data/leads`);
      setLeads([...res?.data?.data]);
      
//...............create sales.................
      const partsResp = await axios.get(`${API_URL}/vehicle_details`);
      const parts = partsResp?.data?.data
      let partsData = []
      for(let data of parts){
        partsData.push({vehicle_id:data.id,...data,qty:1,details:[{motor_no: data?.motor_no,chassis_no: data?.chassis_no,battery_no: data?.battery_no,color: data?.color,controller_no: data?.controller_no,power: data?.power}]})
      }
      const product = partsData.filter(data => !data.sales_id || !id)
      setParts([...product])
//.......................................................
      const finance = await axios.get(`${API_URL}/financer`);
      setFinancer([...finance?.data?.data]);
      const insurance = await axios.get(`${API_URL}/insurers`);
      setInsurances([...insurance?.data?.data]);
    }
    const getAutoInvoiceNumber = async () => {
      const invoiceNumber = await axios.get(`${API_URL}/sales_invoice/${props.type}`)
      if(invoiceNumber.data.status === 'success'){
        formik.setFieldValue('invoice_number', invoiceNumber.data.data) 
      }
    }
    
    get()
    if(id){
      getData()
    }else if(vehicle_id){
      getAutoInvoiceNumber()
      vehicleData(0,vehicle_id)
    }
    else{
      getAutoInvoiceNumber()
      formik.resetForm()
      setLead({})
      SetFinancerName({})
      setInsurance({})
      setInputList([{vehicle_id:'',item_id: '',item_name: '',qty:0,item_price:0,amount_without_gst:0,discount_percent:0,discount_amount:0,taxable_amount:0,igst:0,cgst:0,sgst:0,igst_amount:0,cgst_amount:0,sgst_amount:0,gst_amount:0,total_amount:0,details: [{motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: ''}],open:0,qtyError:0,qtyAlert:0,isvehicle:false}]);
      setInput({qty:0,amount:0,discount:0,tax_amount:0,igst:0,cgst:0,sgst:0,total_amount:0})
      setPart([{part_name:'',color:'',chassis_no:'',power:''}])
    }
  },[id,props.type,vehicle_id]);

  const vehicleData = async (i,vehicle_id) => {
    let list = [...inputList]
    handleClose(i,'VehiclesList')
    const result = await axios.get(`${API_URL}/vehicle_detail/${vehicle_id}`)
    if(result.data.status === 'success'){
      const data = result?.data?.data
      handleInputChange({target:{name:'',value:''}},i,{...data,qty:1})
          list[i]['vehicle_id'] = vehicle_id
          list[i]['details']=[{motor_no: data?.motor_no,chassis_no: data?.chassis_no,battery_no: data?.battery_no,color: data?.color,controller_no: data?.controller_no,power: data?.power}]
    }
    setInputList(list)
  }

  const getData = async () => {
    const data = await axios.get(`${API_URL}/sales/${id}`)
    const data1 = await axios.get(`${API_URL}/sales_detail/${id}`)
    if(data?.data?.status === 'success'){
        const datas = data?.data?.data;
        setSalesData(datas)
        const datas1 = data1?.data?.data;
        const leadId=datas?.customer_id;
        const financerData={financer_id:datas?.financer_id,financer_name:datas?.financer_name}
        SetFinancerName(financerData)
        const insurerData ={insurer_id:datas?.insurer_id,insurer_name:datas?.insurer_name}
        setInsurance(insurerData)
        const leadData = await axios.get(`${API_URL}/leads/${leadId}`)
        setLead(leadData?.data?.data)
        setOpendetails(true)
        formik.setFieldValue('name', datas?.name)
        formik.setFieldValue('remarks', datas?.remarks)
        formik.setFieldValue('mobile', datas?.mobile)
        formik.setFieldValue('pan', datas?.pan)
        formik.setFieldValue('aadhaar_no', datas?.aadhaar_no)
        formik.setFieldValue('financer_name', datas?.financer_name)
        const invoiceNumber = await axios.get(`${API_URL}/sales_invoice/${props.type}`)
        if(datas?.type ===props.type){
          formik.setFieldValue('invoice_number', datas?.invoice_number)
        }
        else{
          formik.setFieldValue('invoice_number', invoiceNumber.data.data)
        }
        setType(datas.type);
        const inputData={
          qty:datas?.totel_items,
          amount:datas?.amount_without_gst,
          discount:datas?.discount_amount,
          tax_amount:datas?.amount_without_gst,
          igst:datas?.igst_amount,
          cgst:datas?.cgst_amount,
          sgst:datas?.sgst_amount,
          total_amount:datas?.total_amount
        }
        setInput(inputData)
        const saleDetail=[]
        let partData = []
        for(let item of datas1){
            const data=omit(item,['sales_detail_id','sales_id'])
            partData.push({part_name:item?.item_name,color:item.details[0].color,chassis_no:item.details[0].chassis_no,power:item.details[0].power})
            saleDetail.push(data)
        }
        setPart(partData)
        setInputList(saleDetail)
    }
}

  // handle input change
  const partValue = []
  const handleInputChange = async (e,index,partData) => {
    const {name,value} = e.target;
    const list = [...inputList];
    if(name !== 'motor_no' && name !== 'chassis_no' && name !== 'battery_no' && name !== 'color' && name !== 'controller_no' && name !== 'power') {
      list[index][name] = value;
    }
    if(!isEmpty(partData)) {
      const partList = [...part]
      partList[index]=partData
      setPart(partList)
      list[index]['isvehicle']=false
      partValue[index] = partData
      list[index]['vehicle_id'] = partValue[index]['vehicle_id']
      list[index]['item_name'] = partValue[index]['part_name']
      list[index]['qtyError'] = 0
      list[index]['qty'] = 1
      list[index]['user_id'] = userData.user_id
      list[index]['item_id'] = partValue[index]['part_id']
      list[index]['igst'] = isNaN(parseFloat(partValue[index]['igst'])) ? 0 : parseFloat(partValue[index]['igst'])
      list[index]['cgst'] = isNaN(parseFloat(partValue[index]['cgst_percentage'])) ? 0 : parseFloat(partValue[index]['cgst_percentage'])
      list[index]['sgst'] = isNaN(parseFloat(partValue[index]['sgst_percentage'])) ? 0 : parseFloat(partValue[index]['sgst_percentage'])
      list[index]['item_price'] = partValue[index]['price']
      min_qty[index] = isNaN(parseFloat(partValue[index]['min_qty'])) ? 0 :  Number(partValue[index]['min_qty'])
      current_qty[index] = isNaN(parseFloat(partValue[index]['qty'])) ? 0 :  Number(partValue[index]['qty'])
      list[index]['details'] = partValue[index]['details']
      if(current_qty[index] < list[index]['qty']){
        list[index]['qtyAlert']=1
      }
      else{
        list[index]['qtyAlert']=0
        if(current_qty[index] - Number(list[index]['qty']) <= min_qty[index]) {
          list[index]['qtyError'] = 1
        }
        else {
          list[index]['qtyError'] = 0
        }
      }
    }
    else if(e.target.name === 'qty') {
      const part1 = parts.filter(p => p.part_name === list[index]['item_name']);
      partValue[index] = part1[0]
      min_qty[index] = isNaN(parseFloat(partValue[index]['min_qty'])) ? 0 :  Number(partValue[index]['min_qty'])
      current_qty[index] = isNaN(parseFloat(partValue[index]['qty'])) ? 0 :  Number(partValue[index]['qty'])
      if(current_qty[index] < Number(e.target.value)){
          list[index]['qtyAlert']=1
      }
      else{
        list[index]['qtyAlert']=0
        if(current_qty[index] - Number(e.target.value) <= min_qty[index]) {
          list[index]['qtyError'] = 1
        }
        else {
          list[index]['qtyError'] = 0
        }
      }
    }
    else if(name === 'motor_no' || name === 'chassis_no' || name === 'battery_no' || name === 'color' || name === 'controller_no' || name === 'power') {
      list[index]['details'][0][name] = e.target.value
    }
    const qty = isNaN(parseFloat(list[index]['qty'])) ? 0 : Number(list[index]['qty'])
    const ta = isNaN(parseFloat(list[index]['item_price'])) ? 0 : Number(list[index]['item_price'])
    list[index]['amount_without_gst'] = (qty * ta).toFixed(2)
    var discount = isNaN(parseFloat(list[index]['discount_percent'])) ? 0 : Number(list[index]['discount_percent'])
    list[index]['discount_amount'] = (Number(list[index]['amount_without_gst']) * discount / 100).toFixed(2)
    list[index]['taxable_amount'] = Number(list[index]['amount_without_gst']) - Number(list[index]['discount_amount'])
    var txAmount = Number(list[index]['taxable_amount'])
    var igst = isNaN(parseFloat(list[index]['igst'])) ? 0 : Number(list[index]['igst'])
    var cgst = isNaN(parseFloat(list[index]['cgst'])) ? 0 : Number(list[index]['cgst'])
    var sgst = isNaN(parseFloat(list[index]['sgst'])) ? 0 : Number(list[index]['sgst'])
    list[index]['igst_amount'] = parseFloat((txAmount * igst / 100)).toFixed(2)
    list[index]['cgst_amount'] = parseFloat((txAmount * cgst / 100)).toFixed(2)
    list[index]['sgst_amount'] = parseFloat((txAmount * sgst / 100)).toFixed(2)
    list[index]['gst_amount'] = parseFloat((txAmount * cgst / 100) + (txAmount * igst / 100) + (txAmount * sgst / 100)).toFixed(2)
    list[index]['total_amount'] = (Number(txAmount) + Number(list[index]['gst_amount'])).toFixed(2)
    const result  = await sum(list)
    setInput(result)
    setInputList(list);
  };

  // handl click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index,1);
    sum(list)
    setInputList(list);
    
  };

const sum= async (listSum)=>{
 let qty=0,amount=0,discount=0,tax_amount=0,igst=0,cgst=0,sgst=0,total_amount=0
  listSum.forEach(list => {
    qty = qty + Number(list.qty)
    amount = amount + Number(list.amount_without_gst)
    discount = discount + Number(list.discount_amount)
    tax_amount = tax_amount + Number(list.taxable_amount)
    igst = igst + Number(list.igst_amount)
    cgst = cgst + Number(list.cgst_amount)
    sgst = sgst + Number(list.sgst_amount)
    total_amount = total_amount + Number(list.total_amount)
  })
  const data={
    qty:qty,
    amount:amount.toFixed(2),
    discount:discount.toFixed(2),
    tax_amount:tax_amount.toFixed(2),
    igst:igst.toFixed(2),
    cgst:cgst.toFixed(2),
    sgst:sgst.toFixed(2),
    total_amount:total_amount.toFixed(2)
  }
  return data
}
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList,{vehicle_id:'',item_id: '',item_name: '',qty:0,item_price:0,amount_without_gst:0,discount_percent:0,discount_amount:0,taxable_amount:0,igst:0,cgst:0,sgst:0,igst_amount:0,cgst_amount:0,sgst_amount:0,gst_amount:0,total_amount:0,details: [{motor_no: '',chassis_no: '',battery_no: '',color: '',controller_no: '',power: ''}],open:0,qtyError:0,qtyAlert:0,isvehicle:false}]);
  };

  //details
  const handleClick = (event,i,name) => {
    const list = [...inputList];
    if(name === 'Details'){
      list[i]['open'] = 1
    }else if(name === 'VehiclesList'){
      list[i]['isVehicle'] = true
    }
    setInputList(list)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (i,name) => {
    if(anchorEl) {
      anchorEl.focus();
    }
    const list = [...inputList];
    if(name === 'Details'){
      list[i]['open'] = 0
    }else if(name === 'VehiclesList'){
      list[i]['isVehicle'] = false
    }
    setInputList(list)
    setAnchorEl(null);
  };

  const handleFinancerName=(financer)=>{
    // formik.setFieldValue('financer_name',e.financer_name)
    SetFinancerName(financer)
  }

  const createNewFinancer=()=>{
    navigate('/dashboard/financer',{replace: true});
  }  

  const openAnchorEL = Boolean(anchorEl);

  const submitFormValue = () => {
    submitForm(formvalue)
     setOpen(false);
   };
 
   const closeDialog = () => {
     setOpen(false);
   }; 

   const createNewCustomer = () => {
    navigate('/dashboard/lead', { replace: true });
  }

  const closeAlert = () => {
    setIsOpen(false);
    setMeassage('')
    setSeverity('')
  };

  return (
    <div>
      {isOpen && <CustomizedSnackbars open={isOpen} onClose={closeAlert} message={message} severity={severity} close={closeAlert}/>}
       <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{paddingLeft:'20px'}} variant="h4" gutterBottom>
        {(props.type === TYPE.INVOICE) ? id ? 'Edit Sales Invoice' : 'Sales Invoice' : id ? 'Edit Sales Quotation' : 'Sales Quotation'}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='left'>
          {!id && 
            <Grid item xs={11} sm={4}>
              <Autocomplete
                id="name"
                name='name'
                options={leads}
                getOptionLabel={(option) => option?.name}
                noOptionsText={<LoadingButton onClick={createNewCustomer} >Add Customer</LoadingButton>}
                renderInput={(params) => <TextField {...params} label="Customer Name" />}
                // value={lead?.name}
                onChange={(event, newValue) => {
                  handleLeadChange(newValue)
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helpertext={formik.touched.name && formik.errors.name}
              />
            </Grid>}
            {opendetails &&
            <Grid item xs={20} sm={8}>
              <Table>
              <tr >
                <th style={{width:'40%',border:'none',textAlign:'left',verticalAlign:'text-bottom'}}>
                <th style={{border:'none',color:'green'}}>
                <div>Customer Name </div>
                <div>Mobile No. </div>
                <div>Address </div>
              </th>
              <th style={{border:'none',textAlign:'left',color:'black'}}>
                <div style={{ fontWeight: 'bold'}}>: {lead?.name}</div>
                <div style={{ fontWeight: 'bold'}}>: {lead?.mobile}</div>
                <div style={{ fontWeight: 'bold'}}>: {lead?.address}</div>
              </th>
                </th>
                </tr>
              </Table>
            </Grid>}
            </Grid>
            </Box>
            <Box sx={{flexGrow: 1,p: 2}}>
            <Grid container spacing={2} justifyContent='left'>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                id="invoice_number"
                name="invoice_number"
                label={(props.type === TYPE.INVOICE) ? "Invoice Number" : 'Quotation Number'}
                value={formik.values.invoice_number}
                onChange={formik.handleChange}
                error={formik.touched.invoice_number && Boolean(formik.errors.invoice_number)}
                helpertext={formik.touched.invoice_number && formik.errors.invoice_number}
              />
            </Grid>
            {(props.type === TYPE.INVOICE) && <>
            <Grid item xs={11} sm={6}>
              <Autocomplete
                id="financer_name"
                name='financer_name'
                options={financer}
                getOptionLabel={(option) => option?.financer_name || '' }
                noOptionsText={<LoadingButton onClick={createNewFinancer} >Add Financer</LoadingButton>}
                renderInput={(params) => <TextField name='financer_name' value={formik.values.financer_name} {...params} label="Financer Name"/>}
                onChange={(event, newValue) => {
                  handleFinancerName(newValue)
                }}
                value={financerName || {}}
                error={formik.touched.financer_name && Boolean(formik.errors.financer_name)}
                helpertext={formik.touched.financer_name && formik.errors.financer_name}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <Autocomplete
                id="insurer_name"
                name='insurer_name'
                options={insurances}
                getOptionLabel={(option) => option?.insurer_name || ''}
                noOptionsText={<LoadingButton onClick={createNewInsurance} >Add Insurer</LoadingButton>}
                renderInput={(params) => <TextField {...params} label="Insurer Name" />}
                value={insurance || {}}
                onChange={(event, newValue) => {
                  handleInsuranceName(newValue)
                }}
                error={formik.touched.insurer_name && Boolean(formik.errors.insurer_name)}
                helpertext={formik.touched.insurer_name && formik.errors.insurer_name}
              />
            </Grid>
            </>}
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                type='text'
                id='remarks'
                name="remarks"
                label="Remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helperText={formik.touched.remarks && formik.errors.remarks}
              />
            </Grid>
          </Grid>
        </Box>
        {inputList.map((x,i) => {
          return (
            <div >
              <Box sx={{flexGrow: 1,p: 2}}>
              { !x.item_name ? 
              <>
                 {/* <Card style={{maxWidth:'200px',height:'200px',minWidth:'50px'}}> */}
                  <h1 style={{marginBottom:'0px',fontFamily:'Times New Roman'}}> Vehicle Name</h1>
                 {/* <div
                      style={{position:'relative',maxWidth:'200px',height:'150px',minWidth:'50px',border:"2px dashed lightsteelblue",borderRadius:'20px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'var(input-bg)'}}
                      ref={wrapperRef}
                      onDragEnter={onDragEnter}
                      onDragLeave={onDragLeave}
                      onDrop={onDrop}
                  > */}
                      <Button onClick={(e) => handleClick(e,i,'VehiclesList')}>
                        <div style={{textAlign:'center',fontWeight:'30px',padding:'10px'}}>
                          {/* <Img alt="complex" src={image} /> */}
                          <p>Select Vehicle Name</p>
                      </div>
                    </Button>
                  {/* </div> */}
                  {/* </Card> */}
                  </>
                
              :
              <>
                <Grid container spacing={1} justifyContent='left' columns={{ xs: 4, sm: 100 }} paddingBottom={2}>
                  <Grid item xs={60}>
                    <Button onClick={(e) => handleClick(e,i,'VehiclesList')}><div style={{color:'black'}}>Vehicle Name : </div> {`${x.item_name}-${x.details[0].color}-${x.details[0].chassis_no}-${x.details[0].power}`}</Button>
                    {/* <FormControl fullWidth>
                      <Autocomplete
                        id="item_name"
                        name='item_name'
                        disabled={true}
                        options={parts}
                        getOptionLabel={(option) => option?.part_name ? `${option?.part_name}-${option?.color}-${option?.chassis_no}-${option?.power}` : ''}
                        noOptionsText={<Link variant="subtitle2" component={RouterLink} to="/dashboard/product" underline="none">
                        <LoadingButton >Add Model</LoadingButton>
                        </Link>}
                        renderInput={(params) => <TextField {...params} label="Model Name" />}
                        value={part[i] || {}}
                        onChange={(e, newValue) => {
                          handleInputChange(e,i,newValue)
                        }}
                        error={formik.touched.item_name && Boolean(formik.errors.item_name)}
                        helpertext={formik.touched.item_name && formik.errors.item_name}
                      />
                      
                    </FormControl> */}
                  </Grid>
                  <Grid item xs={15}>
                    <Tooltip title='Additional Details' ><IconButton color="secondary" id={`details${[i]}`} name={`details${i}`} onClick={(e) => handleClick(e,i,'Details')} style={{fontSize: '15px'}}>Additional Details</IconButton></Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={0.5} justifyContent='left' columns={{ xs: 4, sm: 65 }}>
                  {!vehicle_id && <><Grid item xs={5.5} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      disabled={true}
                      type={'number'}
                      name="qty"
                      label="Qty"
                      value={x.qty}
                      onChange={e => handleInputChange(e,i)}
                      error={Boolean(inputList[i]['qtyAlert']) && `Alert`}
                      helperText={(Boolean(inputList[i]['qtyError']) || Boolean(inputList[i]['qtyAlert'])) && `Available quantity = ${current_qty[i]}`}
                    />
                  </Grid></>}
                  <Grid item xs={8} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="item_price"
                      label="Rate"
                      value={x.item_price}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.item_price && Boolean(formik.errors.item_price)}
                      helperText={Boolean(x.item_price) && NumberToWordConverter.convert(x.item_price)}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="amount_without_gst"
                      label="Amount"
                      value={x.amount_without_gst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.amount_without_gst && Boolean(formik.errors.amount_without_gst)}
                      helperText={Boolean(x.amount_without_gst) && NumberToWordConverter.convert(x.amount_without_gst)}
                    />
                  </Grid>
                  <Grid item xs={5.5} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="discount_percent"
                      label="Discount %"
                      value={x.discount_percent}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.discount_percent && Boolean(formik.errors.discount_percent)}
                      helperText={formik.touched.discount_percent && formik.errors.discount_percent}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="taxable_amount"
                      label="Taxable Amount"
                      value={x.taxable_amount}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.taxable_amount && Boolean(formik.errors.taxable_amount)}
                      helperText={Boolean(x.taxable_amount) && NumberToWordConverter.convert(x.taxable_amount)}
                    />
                  </Grid>
                  {/* <Grid item xs={5.5} >
                    <TextField
                    autoComplete='off'
                    fullWidth
                    type={'text'}
                    name="igst"
                    label="IGST%"
                    value={x.igst}
                    onChange={e => handleInputChange(e, i)}
                    error={formik.touched.igst && Boolean(formik.errors.igst)}
                    helperText={formik.touched.igst && formik.errors.igst}
                    />
                    </Grid> */}
                  <Grid item xs={5.5} sx={{mt:1}} >
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="sgst"
                      label="SGST%"
                      value={x.sgst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.sgst && Boolean(formik.errors.sgst)}
                      helperText={formik.touched.sgst && formik.errors.sgst}
                    />
                  </Grid>
                  <Grid item xs={5.5} sx={{mt:1}} >
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="cgst"
                      label="CGST%"
                      value={x.cgst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.cgst && Boolean(formik.errors.cgst)}
                      helperText={formik.touched.cgst && formik.errors.cgst}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{mt:1}}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="total_amount"
                      label="Final Amount"
                      value={x.total_amount}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.total_amount && Boolean(formik.errors.total_amount)}
                      helperText={Boolean(x.total_amount) && NumberToWordConverter.convert(x.total_amount)}
                    />
                  </Grid>
                {/* Details...................................................................... */}
                  {/* <Grid item xs={3}>
                    <Tooltip title='Add Model Details' ><IconButton color="primary" id={`details${[i]}`} name={`details${i}`} onClick={(e) => handleClick(e,i)} style={{fontSize: '30px'}}>+</IconButton></Tooltip>
                  </Grid> */}
                  
                  <Grid item xs={5}>
                  {inputList.length !== 1 && <Tooltip title='Remove Model' ><IconButton color="primary" aria-label="delete" onClick={() => handleRemoveClick(i)} style={{fontSize: '40px'}}><DeleteIcon /></IconButton></Tooltip>}
                  </Grid>
                </Grid>
                </>
                }

                  {Boolean(inputList[i]['isVehicle']) &&
                    <div>

                      <Dialog
                        id={`vehicle${[i]}`}
                        open={inputList[i]['isVehicle']}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        maxWidth={'md'}
                        onClose={()=> handleClose(i,'VehiclesList')}
                      >
                        <ClickAwayListener id={`vehicle${i}`} name={`vehicle${i}`} onClickAway={(i) => handleClose(i,'VehiclesList')}>
                          <VehicleList open={Boolean(inputList[i]['isVehicle'])} onClose={() => handleClose(i,'VehiclesList')} submit={(vehicleId)=>vehicleData(i,vehicleId)} close={() => handleClose(i,'VehiclesList')}/>
                        </ClickAwayListener>
                      </Dialog>
                    </div>
                  }

                {Boolean(inputList[i]['open']) &&
                    <div>

                      <Dialog
                        id={`details${[i]}`}
                        open={inputList[i]['open']}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        onClose={()=>handleClose(i,'Details')}
                      >
                        <ClickAwayListener id={`details${i}`} name={`details${i}`} onClickAway={()=>handleClose(i,'Details')}>
                          <Box
                            sx={{
                              borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                                }`,
                              padding: '10px 38px',
                              fontWeight: 600,
                            }}
                          >
                            <Grid container id={`details${i}`} spacing={0.5} justifyContent='center' columns={20}>
                              <Grid item xs={20}>
                                <IconButton color="secondary" id={`details${[i]}`} name={`details${i}`} style={{fontSize: '18px'}}>Additional Details</IconButton>
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name='motor_no'
                                  label="Motor No."
                                  value={x.details[0].motor_no}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.motor_no && Boolean(formik.errors.motor_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="chassis_no"
                                  label="Chassis No."
                                  value={x.details[0].chassis_no}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.chassis_no && Boolean(formik.errors.chassis_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  multiline
                                  maxRows={10}
                                  name="battery_no"
                                  label="Battery No."
                                  value={x.details[0].battery_no}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.battery_no && Boolean(formik.errors.battery_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="color"
                                  label="Color"
                                  value={x.details[0].color}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.color && Boolean(formik.errors.color)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="controller_no"
                                  label="Controller No."
                                  value={x.details[0].controller_no}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.controller_no && Boolean(formik.errors.controller_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="power"
                                  label="Power"
                                  value={x.details[0].power}
                                  onChange={e => handleInputChange(e,i)}
                                  error={formik.touched.power && Boolean(formik.errors.power)}

                                />
                              </Grid>
                              <Grid item xs={20}>
                              <Tooltip title='Submit Model Details' ><LoadingButton color="primary" variant="contained" aria-label="submit" onClick={()=>handleClose(i,'Details')} style={{fontSize: '15px'}}>Submit</LoadingButton></Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </ClickAwayListener>
                      </Dialog>
                    </div>
                  }
              </Box>
              <Box sx={{m: 0}}>
                {!vehicle_id && inputList.length - 1 === i && <Tooltip title='Add More Model' ><IconButton color="primary" aria-label="add to shopping cart" onClick={handleAddClick} style={{fontSize: '40px'}}>+</IconButton></Tooltip>}
              </Box>
            </div>
          );
        })}
        <Box sx={{m: 2}}>
        <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={8} sm={4}>
          <LoadingButton
          fullWidth
          size="large"
          type="submit"
          name='draft'
          onClick={()=>setState('draft')}
          variant="contained"
          loading={formik.isSubmitting}
          >
          Save As Draft
          </LoadingButton>
          </Grid>
          <Grid item xs={8} sm={6}>
          <LoadingButton
          fullWidth
          size="large"
          type="submit"
          name='submit'
          onClick={()=>setState('submit')}
          variant="contained"
          loading={formik.isSubmitting}
          >
          Submit
          </LoadingButton>
          </Grid>
      </Grid>
        </Box>
      </form>
      <Page>
        <Container>
          <Typography variant="h4" color={'primary'} gutterBottom>
            Total Order
          </Typography>
          <Card>
            <div style={{minHeight: '10px',backgroundColor: '#f7f7f7',border: '1px solid #000'}}>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total Quantity</div>
                <div>{input.qty}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Amount</div>
                <div>{input.amount}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Discount</div>
                <div>{input.discount}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Taxable Amount</div>
                <div>{input.tax_amount}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total CGST</div>
                <div>{input.cgst}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total SGST</div>
                <div>{input.sgst}</div>
              </div>
              {/* <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total IGST</div>
                <div>{igst}</div>
              </div> */}
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Final Amount</div>
                <div>{input.total_amount}</div>
              </div>
            </div>
          </Card>
        </Container>
      </Page>
    </div>
  );
}