import {useNavigate} from 'react-router-dom';
import {Autocomplete, Button, Card, CircularProgress, Fab, Input, styled, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {API_URL} from '../constant';
import ConfirmDialog from '../components/confirmDialog';
import * as XLSX from 'xlsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { green } from '@mui/material/colors';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';


export default function UploadCsvData(props) {
    const timer = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false)
    const [csvData, setCsvData] = useState([]);
    const [file,setFile] = useState([])
    const [allDealers, setAllDealers] = useState([])
    const [selectDealer, setSelectDealer] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)
   
    let i=2
    const processData = (dataString) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
            const obj = {};
            for (let j = 0; j < headers.length; j++) {
                let d = row[j];
                if (d.length > 0) {
                if (d[0] == '"')
                    d = d.substring(1, d.length - 1);
                if (d[d.length - 1] == '"')
                    d = d.substring(d.length - 2, 1);
                }
                if (headers[j]) {
                obj[headers[j]] = d;
                }
            }

            // remove the blank rows
            if (Object.values(obj).filter(x => x).length > 0) {
                list.push(obj);
            }
            }
        }
        setCsvData(list);
    }
 
    const closeDialog = () => {
    setOpen(false);
    };

    const buttonSx = {
    ...(success && {
        bgcolor: green[500],
        '&:hover': {
        bgcolor: green[700],
        },
    }),
    };

    const handleCsvData = (e) => {
        if (!loading) {
            setFile(Object.values(e.target.files))
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                processData(data);
            };
            reader.readAsBinaryString(file);
            setSuccess(false);
            setLoading(true);
            timer.current = window.setTimeout(() => {
                setSuccess(true);
                setLoading(false);
            }, 1000);
        }
    }

    const getDealers = async () => {
        const result = await axios.get(`${API_URL}/los_all_dealer`)
        if(result?.data?.status === 'success') {
          setAllDealers(result?.data?.data)
        }
      }

    useEffect(() => {
        getDealers()
    return () => {
        clearTimeout(timer.current);
        };
    },[]);

    const onSubmit = async () =>{
        setIsSubmit(true)
        const result = await props.submit([...selectDealer,...csvData])
        if(result?.status === 'success'){
            enqueueSnackbar(`Successfuly mapped dealer data of DSA : ${props?.dsaName}`, { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
            setIsSubmit(false)
            props.onClose()
        }
    }

  return (
    <div style={{margin:10,justifyContent:'center'}}>
        <ConfirmDialog open={open} onClose={closeDialog} submit={console.log(`<<<<<datatta<<<<`,csvData)} close={closeDialog} />
        <div>
            <h1 style={{marginBottom:'10px',fontFamily:'Times New Roman',textAlign:'center'}}> Select  or Upload Dealer Mapping Data</h1>
            <div style={{margin:50,justifyContent:'center'}}>
                {props?.dsaName &&
                    <div style={{textAlign:'-webkit-center'}}>
                        <Typography style={{display:'flex',fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green"  gutterBottom>
                            DSA NAME: <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.dsaName}</h4>
                        </Typography>
                    </div>
                 }
                {/* <Autocomplete
                    multiple
                    id="dealer_id"
                    name='dealer_name'
                    options={allDealers ? allDealers : []}
                    getOptionLabel={(option) => option?.firm_name}
                    renderInput={(params) => <TextField {...params} label="Select Dealer Name" />}
                    value={selectDealer}
                    onChange={(event,newValue) => {
                    setSelectDealer(newValue)
                    }}
                /> */}
                <Box style={{paddingTop:20}} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Fab
                            aria-label="save"
                            color="primary"
                            sx={buttonSx}
                        >
                            {(success && Boolean(file)) ? <CheckIcon /> : <SaveIcon />}
                        </Fab>
                        {(loading && Boolean(file)) && (
                            <CircularProgress
                            size={68}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                zIndex: 1,
                            }}
                            />
                        )}
                    </Box>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <label htmlFor="file">
                            <Input accept="image/*,application/pdf" id="file" multiple type="file" onChange={handleCsvData} />
                            <p>Upload Dealer Mapping CSV Data</p>
                        </label>
                    </Box>
                </Box>
            </div>
            
            <Box sx={{m: 2}}>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item xs={8} sm={6}>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmit}
                            onClick={()=>{onSubmit()}}
                        >
                        Submit
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </div>
    </div>
  );
}
