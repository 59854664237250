import * as yup from 'yup';
import {Link as RouterLink,useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {Autocomplete,Button,Card,ClickAwayListener,Container,Dialog,FormControl,IconButton,InputLabel,Link,MenuItem,Select,Table,TextField,Tooltip,Typography, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, companyId,NumberToWordConverter} from '../constant';
import { useContext, useEffect, useState } from 'react';
import Page from '../components/Page';
import {LoginContext} from '../context/logincontext';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../components/confirmDialog';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

const validationSchema = yup.object({
  po_number: yup.string().required('field cannot be empty'),
  part_name: yup.array().required('field cannot be empty')
});

const TABLE_HEAD = [
  {id: 'total_qty',label: 'Total Quantity',alignRight: false},
  {id: 'amount',label: 'Amount',alignRight: false},
  {id: 'igst_amount',label: 'IGST Amount',alignRight: false},
  {id: 'cgst_amount',label: 'CGST Amount',alignRight: false},
  {id: 'sgst_amount',label: 'SGST Amount',alignRight: false},
  {id: 'total_amount',label: 'Total Amount',alignRight: false},
];

let QTY = [];
let IGST = [];
let CGST = [];
let SGST = [];
let AMOUNT = [];
export default function AddPurchase() {
  const context = useContext(LoginContext)
  const [userData,setUserData] = useState(context.user);
  const [state,setState] = useState('')
  const [open,setOpen] = useState(false)
  const [qty,SetQty] = useState(0);
  const [igst,SetIGst] = useState(0);
  const [cgst,SetCGst] = useState(0);
  const [sgst,SetSGst] = useState(0);
  const [amount,SetAmount] = useState(0);
  const navigate = useNavigate();
  const [vendors,setVendors] = useState([]);
  const [vendor,setVendor] = useState();
  const [part,setValuePart] = useState([]);
  const [inputList,setInputList] = useState([{part_id: '',part_name: '',total_qty: '0',amount: '0',igst: '0',cgst: '0',sgst: '0',igst_amount: '0',cgst_amount: '0',sgst_amount: '0',gst_amount: '0',total_amount: '0',details: [{ motor_no: '', chassis_no: '', battery_no: '', color: '', controller_no: '', power: '' }], open: 0, qtyError: 0 }]);
  const [formvalue, setFormValue] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  let data=[{}]
  const formik = useFormik({

    initialValues: {
      company_id: '',
      vendor_id: '',
      vendor_name: '',
      po_number: '',
      total_qty: '',
      amount: '',
      igst: '',
      cgst: '',
      sgst: '',
      total_amount: '',
      type: ''
    },
    //  validationSchema,
    onSubmit: async (value) => {
      const purchaseData = {
        user_id: userData.user_id,
        company_id: companyId,
        vendor_id: vendor.vendor_id,
        vendor_name: vendor.vendor_name,
        po_number: value.po_number,
        total_qty: qty,
        amount: amount,
        igst_amount: igst,
        cgst_amount: cgst,
        sgst_amount: sgst,
        total_amount: (parseFloat(amount) + parseFloat(igst) + parseFloat(cgst) + parseFloat(sgst)).toFixed(2),
        state: state,
        type: 'order'

      }
       data = {
        purchase: purchaseData,
        purchaseDetail: [...inputList]
      }
      setFormValue(data)
      setOpenDialog(true)
    },
  });

  async function submitForm(data) {
      try {
        const response = await axios.post(`${API_URL}/purchase`,data);
        if(response.data.status === 'success') {
          navigate('/dashboard/purchase_list',{replace: true});
        }
      }
       catch(error) {
        alert('Please Enter Different PO number')
      }
    }

  const handleVendorChange = (vendor) => {
    if(vendor) {
      formik.setFieldValue('vendor_name',vendor.vendor_name)
      setOpen(true)
      setVendor(vendor)
    }

  }

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/vendor`);
      setVendors(res.data.data);
      const partsResp = await axios.get(`${API_URL}/parts`);
      setValuePart(partsResp?.data?.data)
    }
    get()
  },[]);
  useEffect(()=>{
    const getAutoInvoiceNumber = async () => {
      const invoiceNumber = await axios.get(`${API_URL}/purchase_invoice/order`)
      if(invoiceNumber.data.status === 'success'){
        formik.setFieldValue('po_number', invoiceNumber.data.data) 
      }
    }
    getAutoInvoiceNumber()
  },[])
  const getSum = () => {
    let sum = 0
    QTY.map(v => {
      sum = sum + v
    })
    SetQty(sum)
  }
  const getAmount = () => {
    let sum = 0
    AMOUNT.map(v => {
      sum = sum + v
    })
    SetAmount(sum)
  }
  const getG = () => {
    let sum1 = 0
    IGST.map(v => {
      sum1 = sum1 + v
    })
    sum1 = Number(sum1).toFixed(2)

    let sum2 = 0
    CGST.map(v => {
      sum2 = sum2 + v
    })
    sum2 = Number(sum2).toFixed(2)

    let sum3 = 0
    SGST.map(v => {
      sum3 = sum3 + v
    })
    sum3 = Number(sum3).toFixed(2)
    SetIGst(sum1)
    SetCGst(sum2)
    SetSGst(sum3)
  }

  // handle input change
  const partValue = []
  const handleInputChange = (e,index) => {
    const {name,value} = e.target;
    const list = [...inputList];
    if (name !== 'motor_no' && name !== 'chassis_no' && name !== 'battery_no' && name !== 'color' && name !== 'controller_no' && name !== 'power') {
      list[index][name] = value;
    }
    list[index][name] = value
    if(e.target.name == 'part_name') {
      const part1 = part.filter(p => p.part_name == e.target.value);
      partValue[index] = part1[0]
      const ld = [...inputList]
      ld[index]['total_qty'] = 1
      ld[index]['user_id'] = userData.user_id
      ld[index]['part_id'] = partValue[index]['part_id']
      ld[index]['igst'] = isNaN(parseFloat(partValue[index]['igst'])) ? 0 : parseFloat(partValue[index]['igst'])
      ld[index]['cgst'] = isNaN(parseFloat(partValue[index]['gst_percentage'])) ? 0 : parseFloat(partValue[index]['gst_percentage']) / 2
      ld[index]['sgst'] = isNaN(parseFloat(partValue[index]['gst_percentage'])) ? 0 : parseFloat(partValue[index]['gst_percentage']) / 2
      ld[index]['amount'] = partValue[index]['price']
      ld[index]['company_id'] = companyId
      setInputList(ld);
    }
    else if (name === 'motor_no' || name === 'chassis_no' || name === 'battery_no' || name === 'color' || name === 'controller_no' || name === 'power') {
      list[index]['details'][0][name] = e.target.value
    }
    if(list[index]['total_qty'] && (list[index]['details'][0]['motor_no'] || list[index]['details'][0]['chassis_no'] || list[index]['details'][0]['controller_no'])){
      if(name === 'total_qty' && Number(list[index]['total_qty']) > 1){
        return enqueueSnackbar(` You have enter motor ,chassis or controller number , enter quantity 1 `, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
      }else if(Number(list[index]['total_qty']) !== 1){
        return enqueueSnackbar(` You have enter quantity grater then 1, so can not be enter  motor ,chassis or controller number `, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
      }
    }
    const l = [...inputList]

    var qty = []
    qty[index] = list[index]['total_qty']
    qty[index] = parseInt(qty[index])
    qty[index] = isNaN(qty[index]) ? 0 : qty[index]

    QTY[index] = qty[index]
    getSum()
    let ta = 0
    ta = isNaN(parseFloat(l[index]['amount'])) ? 0 : parseFloat(l[index]['amount'])
    let id = qty[index] * ta
    l[index]['total_amount'] = id
    list[index]['total_amount'] = l[index]['total_amount']
    AMOUNT[index] = id
    getAmount()
    let igst = isNaN(parseFloat(l[index]['igst'])) ? 0 : parseFloat(l[index]['igst'])
    let sgst = isNaN(parseFloat(l[index]['sgst'])) ? 0 : parseFloat(l[index]['sgst'])
    let cgst = isNaN(parseFloat(l[index]['cgst'])) ? 0 : parseFloat(l[index]['cgst'])

    list[index]['igst_amount'] = (Number(id * igst / 100)).toFixed(2)
    list[index]['sgst_amount'] = (Number(id * sgst / 100)).toFixed(2)
    list[index]['cgst_amount'] = (Number(id * cgst / 100)).toFixed(2)
    list[index]['gst_amount'] = Number(list[index]['igst_amount']) + Number(list[index]['sgst_amount']) + Number(list[index]['cgst_amount'])
    IGST[index] = Number(list[index]['igst_amount'])
    getG()
    SGST[index] = Number(list[index]['sgst_amount'])
    getG()
    CGST[index] = Number(list[index]['cgst_amount'])
    getG()
    setInputList(list);

  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index,1);
    QTY.splice(index,1);
    getSum()
    AMOUNT.splice(index,1)
    getAmount()
    IGST.splice(index,1)
    getG()
    SGST.splice(index,1)
    getG()
    CGST.splice(index,1)
    getG()
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList,{part_id: '',part_name: '',total_qty: '0',amount: '0',igst: '0',cgst: '0',sgst: '0',igst_amount: '0',cgst_amount: '0',sgst_amount: '0',gst_amount: '0',total_amount: '0',details: [{ motor_no: '', chassis_no: '', battery_no: '', color: '', controller_no: '', power: '' }], open: 0, qtyError: 0, qtyAlert: 0 }]);
  };

  const handleClick = (event, i) => {
    const list = [...inputList];
    list[i]['open'] = 1
    setInputList(list)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (i) => {
    if (anchorEl) {
      anchorEl.focus();
    }
    const list = [...inputList];
    list[i]['open'] = 0
    setInputList(list)
    setAnchorEl(null);
  };

  const openAnchorEL = Boolean(anchorEl);

  const createNewSupplier = () => {
    navigate('/dashboard/supplier',{replace: true});
  }

  const submitFormValue = () => {
    submitForm(formvalue)
     setOpenDialog(false);
   };
 
   const closeDialog = () => {
    setOpenDialog(false);
   }; 

  return (
    <div>
      <ConfirmDialog open={openDialog} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{paddingLeft: '20px'}} variant="h4" gutterBottom>
        Add Purchase Order
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        {open &&
          <Box sx={{flexGrow: 1,p: 2}}>
            <Grid container spacing={2} justifyContent='left'>
              <Grid item xs={48}>
                <Table>
                  <tr >
                    <th style={{width: '40%',border: 'none',textAlign: 'left',verticalAlign: 'text-bottom'}}>
                      <th style={{border: 'none',color: 'green'}}>
                        <div>Supplier Name </div>
                        <div>Mobile No. </div>
                        <div>Address </div>
                      </th>
                      <th style={{border: 'none',textAlign: 'left',color: 'black'}}>
                        <div style={{fontWeight: 'bold'}}>: {vendor?.vendor_name}</div>
                        <div style={{fontWeight: 'bold'}}>: {vendor?.primary_mobile}</div>
                        <div style={{fontWeight: 'bold'}}>: {vendor?.address}</div>
                      </th>
                    </th>
                  </tr>
                </Table>
              </Grid>
            </Grid>
          </Box>}
        <Box sx={{flexGrow: 1,p: 2}}>

          <Grid container spacing={{xs: 2,md: 3}} columns={{xs: 4,sm: 8,md: 12}} justifyContent='left'>
            <Grid item xs={2} sm={4} md={4}>
              <Autocomplete
                id="vendor_name"
                name='vendor_name'
                options={vendors}
                getOptionLabel={(option) => option?.vendor_name}
                noOptionsText={<LoadingButton onClick={createNewSupplier}>Add New Supplier</LoadingButton>}
                renderInput={(params) => <TextField {...params} label="Supplier Name" />}
                // value={formik.values.vendor_name}
                onChange={(event,newValue) => {
                  handleVendorChange(newValue)
                }}
                error={formik.touched.vendor_name && Boolean(formik.errors.vendor_name)}
                helpertext={formik.touched.vendor_name && formik.errors.vendor_name} />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              {/* <label>Purchase Order Number</label> */}
              <TextField
                autoComplete='off'
                fullWidth
                type={'text'}
                name="po_number"
                label='Purchase Order No. *'
                value={formik.values.po_number}
                onChange={formik.handleChange}
                error={formik.touched.po_number && Boolean(formik.errors.po_number)}
                helperText={formik.touched.po_number && formik.errors.po_number} />
            </Grid>
          </Grid>
        </Box>
        {inputList.map((x,i) => {
          return (
            <div style={{marginTop: '10px'}}>
              <Box sx={{flexGrow: 1,p: 2}}>
                <Grid container boxShadow={false} spacing={2} justifyContent='left' columns={{xs: 4,sm: 75}}>
                  <Grid item xs={18}>
                    {/* <label>Part Name</label> */}
                    <FormControl fullWidth>
                      <InputLabel id="part_name">Model Name *</InputLabel>
                      <Select
                        fullWidth
                        labelId="part_name"
                        id='part_name'
                        name="part_name"
                        label="Model Name"
                        value={x.part_name}
                        onChange={e => handleInputChange(e,i)}
                        error={formik.touched.part_name && Boolean(formik.errors.part_name)}
                        helpertext={formik.touched.part_name && formik.errors.part_name}>
                        {part.map((v,i) => {
                          return (
                            <MenuItem value={v.part_name}>{v.part_name}</MenuItem>
                          );
                        })}
                        <MenuItem>
                          <Link variant="subtitle2" component={RouterLink} to="/dashboard/product" underline="none">
                            <LoadingButton >Add Model</LoadingButton>
                          </Link>
                        </MenuItem>
                      </Select>
                      <Tooltip title='Additional Details' ><IconButton color="secondary" id={`details${[i]}`} name={`details${i}`} onClick={(e) => handleClick(e, i)} style={{ fontSize: '15px' }}>Additional Details</IconButton></Tooltip>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="total_qty"
                      label="Total Quantity"
                      value={x.total_qty}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.total_qty && Boolean(formik.errors.total_qty)}
                      helperText={formik.touched.total_qty && formik.errors.total_qty} />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="amount"
                      label="Price"
                      value={x.amount}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.amount && Boolean(formik.errors.amount)}
                      helperText={x.amount && NumberToWordConverter.convert(x.amount)} />
                  </Grid>
                  <Grid item xs={6} >
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="igst"
                      disabled={true}
                      label="IGST%"
                      value={x.igst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.igst && Boolean(formik.errors.igst)}
                      helperText={formik.touched.igst && formik.errors.igst} />
                  </Grid>
                  <Grid item xs={6} >
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="sgst"
                      label="SGST%"
                      value={x.sgst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.sgst && Boolean(formik.errors.sgst)}
                      helperText={formik.touched.sgst && formik.errors.sgst} />
                  </Grid>
                  <Grid item xs={6} >
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="cgst"
                      label="CGST%"
                      value={x.cgst}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.cgst && Boolean(formik.errors.cgst)}
                      helperText={formik.touched.cgst && formik.errors.cgst} />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="total_amount"
                      label="Amount"
                      value={x.total_amount}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.total_amount && Boolean(formik.errors.total_amount)}
                      helperText={x.total_amount && NumberToWordConverter.convert(x.total_amount)} />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      type={'text'}
                      name="gst_amount"
                      label="GST Amount"
                      value={x.gst_amount}
                      onChange={e => handleInputChange(e,i)}
                      error={formik.touched.gst_amount && Boolean(formik.errors.gst_amount)}
                      helperText={x.gst_amount && NumberToWordConverter.convert(x.gst_amount)} />
                  </Grid>
                  {Boolean(inputList[i]['open']) &&
                    <div>

                      <Dialog
                        id={`details${[i]}`}
                        open={openAnchorEL}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                      >
                        <ClickAwayListener id={`details${i}`} name={`details${i}`} onClickAway={() => handleClose(i)}>
                          <Box
                            sx={{
                              borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                                }`,
                              padding: '10px 38px',
                              fontWeight: 600,
                            }}
                          >
                            <Grid container id={`details${i}`} spacing={0.5} justifyContent='center' columns={20}>
                              <Grid item xs={20}>
                                <IconButton color="secondary" id={`details${[i]}`} name={`details${i}`} style={{ fontSize: '18px' }}>Additional Details</IconButton>
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name='motor_no'
                                  label="Motor No."
                                  value={x.details[0].motor_no}
                                  onChange={e => handleInputChange(e, i)}
                                  error={formik.touched.motor_no && Boolean(formik.errors.motor_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="chassis_no"
                                  label="Chassis No."
                                  value={x.details[0].chassis_no}
                                  onChange={e => handleInputChange(e, i)}
                                  error={formik.touched.chassis_no && Boolean(formik.errors.chassis_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  multiline
                                  maxRows={10}
                                  name="battery_no"
                                  label="Battery No."
                                  value={x.details[0].battery_no}
                                  onChange={e => handleInputChange(e, i)}
                                  error={formik.touched.battery_no && Boolean(formik.errors.battery_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="color"
                                  label="Color"
                                  value={x.details[0].color}
                                  onChange={e => handleInputChange(e, i)}
                                  error={formik.touched.color && Boolean(formik.errors.color)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="controller_no"
                                  label="Controller No."
                                  value={x.details[0].controller_no}
                                  onChange={e => handleInputChange(e, i)}
                                  error={formik.touched.controller_no && Boolean(formik.errors.controller_no)}

                                />
                              </Grid>
                              <Grid item xs={10}>
                                <TextField
                                  id={`details${i}`}
                                  autoComplete='off'
                                  type={'text'}
                                  name="power"
                                  label="Power"
                                  value={x.details[0].power}
                                  onChange={e => handleInputChange(e, i)}
                                  error={formik.touched.power && Boolean(formik.errors.power)}

                                />
                              </Grid>
                              <Grid item xs={20}>
                                <Tooltip title='Submit Model Details' ><LoadingButton color="primary" variant="contained" aria-label="submit" onClick={() => handleClose(i)} style={{ fontSize: '15px' }}>Submit</LoadingButton></Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </ClickAwayListener>
                      </Dialog>
                    </div>
                  }

                  <Grid item xs={5}>
                  {inputList.length !== 1 && <Tooltip title='Remove Model' ><IconButton color="primary" aria-label="delete" onClick={() => handleRemoveClick(i)} ><DeleteIcon /></IconButton></Tooltip>}
                </Grid>
                </Grid>
              </Box>
              <Box sx={{m: 0}}>
                {inputList.length - 1 === i && <Tooltip title='Add Model' ><IconButton color="primary" aria-label="add to shopping cart" onClick={handleAddClick} style={{fontSize: '40px'}}>+</IconButton></Tooltip>}
              </Box>
            </div>
          );
        })}

        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={4}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                name='draft'
                onClick={() => setState('draft')}
                variant="contained"
                loading={formik.isSubmitting}>
                Save As Draft
              </LoadingButton>
            </Grid>
            <Grid item xs={8} sm={6}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                name='submit'
                onClick={() => setState('submit')}
                variant="contained"
                loading={formik.isSubmitting}>
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
      <Page>
        <Container>
          <Typography variant="h4" gutterBottom>Total Order</Typography>
          <Card>
            <div style={{minHeight: '10px',backgroundColor: '#f7f7f7',border: '1px solid #000'}}>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total Quantity</div>
                <div>{qty}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Amount</div>
                <div>{amount}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total CGST</div>
                <div>{cgst}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total SGST</div>
                <div>{sgst}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total IGST</div>
                <div>{igst}</div>
              </div>
              <div style={{display: 'flex',justifyContent: 'space-between',padding: '5px'}}>
                <div>Total Amount</div>
                <div>{(Number(amount) + Number(igst) + Number(sgst) + Number(cgst)).toFixed(2)}</div>
              </div>
            </div>
          </Card>
        </Container>
      </Page>
    </div>
  );
}
