import * as yup from 'yup';
import {useNavigate,useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {MenuItem,Select,TextField,Typography,FormControl,InputLabel} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL,PAN_REGEX} from '../constant';
import {useContext,useEffect,useState} from 'react';
import {LoginContext} from '../context/logincontext';
import ConfirmDialog from '../components/confirmDialog';
// ----------------------------------------------------------------------

const phoneRegExp = /^([0-9]){10}?$/
const validationSchema = yup.object({
  name: yup.string().required('field cannot be empty'),
  category: yup.string().required('field cannot be empty'),
  //   point_of_contact: yup.string().required('field cannot be empty'),
  mobile: yup.string().matches(phoneRegExp,'Phone number is not valid').required('field cannot be empty'),
});

export default function EditFinancer() {
  const {id} = useParams()
  const context = useContext(LoginContext)
  const navigate = useNavigate();
  const [userData,setUserData] = useState(context.user);
  const [open, setOpen] = useState(false)
  const [formvalue, setFormValue] = useState({})
  const formik = useFormik({
    initialValues: {
      name: '',
      category: '',
      mobile: '',
      poc: '',
      remark: ''
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  async function submitForm(value){
    value.user_id = userData.user_id
    if(id) {
      const response = await axios.post(`${API_URL}/counter_party/update/${id}`,value);
      if(response.data.status === 'success') {
        navigate('/dashboard/counter_parties',{replace: true});
      }
    }
    else {
      const response = await axios.post(`${API_URL}/counter_party`,value);
      if(response.data.status === 'success') {
        navigate('/dashboard/counter_parties',{replace: true});
      }
    }
   }
  const getData = async () => {
    const data = await axios.get(`${API_URL}/counter_party/${id}`)
    if(data?.data?.status === 'success') {
      const datas = data.data.data;
      formik.setFieldValue('name',datas?.name)
      formik.setFieldValue('mobile',datas?.mobile)
      formik.setFieldValue('category',datas?.category)
      formik.setFieldValue('poc',datas?.poc)
      formik.setFieldValue('remark',datas?.remark)
    }
  }
  useEffect(() => {
    if(id) {
      getData()
    }
  },[])
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography variant="h4" gutterBottom>
        {!id ? 'Add Counter Party' : 'Edit Counter Party'}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="name"
                type={'text'}
                label="Counter Party Name *"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="poc"
                type={'text'}
                label="Point Of Contact"
                value={formik.values.poc}
                onChange={formik.handleChange}
                error={formik.touched.poc && Boolean(formik.errors.poc)}
                helperText={formik.touched.poc && formik.errors.poc}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="mobile"
                type={'number'}
                label="Mobile Number"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="category"
                type={'text'}
                label="Category"
                value={formik.values.category}
                onChange={formik.handleChange}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helperText={formik.touched.category && formik.errors.category}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="remark"
                type={'text'}
                label="Remarks"
                value={formik.values.remark}
                onChange={formik.handleChange}
                error={formik.touched.remark && Boolean(formik.errors.remark)}
                helperText={formik.touched.remark && formik.errors.remark}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}