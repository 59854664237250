import React, { forwardRef, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DialogContent, Slide } from '@mui/material';
import { isEmpty } from 'lodash';
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ConfirmDialog(props) {
  // props = {text,open,onClose,close,submit,closeButton,submitButton,subText[text]}
  
  const [isSubmit, setIsSubmit] = useState(false)

  const onSubmit = () =>{
    setIsSubmit(true)
    props.submit()
  }

  useEffect(() => {
    setIsSubmit(false)
  },[props]);

  return (
    <Dialog
    fullWidth
    open={props.open}
    keepMounted
    // TransitionComponent={Transition}
    onClose={props.onClose}
    aria-describedby="alert-dialog-slide-description"
    maxWidth={!isEmpty(props?.subText) ? 'sm' : 'xs'}
  >
    <DialogTitle > {props.text ? props.text : 'Do You Want To Submit this form'}</DialogTitle>
    {!isEmpty(props?.subText) && props?.subText.map(text=>{return(<DialogContent style={{paddingTop:'0px'}}>{text}</DialogContent>)})}
    <DialogActions>
      <Button disabled={isSubmit} onClick={props.close} color='error' variant="contained">{props?.closeButton || 'Cancel'}</Button>
      <Button disabled={isSubmit} onClick={onSubmit} variant="contained">{props?.submitButton || 'Ok'}</Button>
    </DialogActions>
  </Dialog>
  )
}

export default ConfirmDialog