import {filter, find} from 'lodash';
import {useEffect,useState} from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead} from '../sections/@dashboard/user';
import axios from 'axios';
import Styles from '../css/filter.module.css'
import { isMobile } from 'react-device-detect';
import moment from 'moment'
import {API_URL,VEHICLE_REPO_STATUS} from '../constant';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'loan_id',label: 'Loan Id',alignRight: false},
  {id: 'loan.customer_name',label: 'Customer Name',alignRight: false},
  {id: 'registration_number',label: 'Reg. No',alignRight: false},
  {id: 'parking_location',label: 'Parking Location',alignRight: false},
  {id: 'city',label: 'City',alignRight: false},
  {id: 'state',label: 'State',alignRight: false},
  {id: 'repo_date',label: 'Repo Date',alignRight: false},
  {id: 'not_to_be_sold_date',label: 'Not To Be Sold Date',alignRight: false},
  {id: 'repo_by_name',label: 'Repo By',alignRight: false}
];

// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
  if(b[orderBy] < a[orderBy]) {
    return -1;
  }
  if(b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order,orderBy) {
  return order === 'desc'
    ? (a,b) => descendingComparator(a,b,orderBy)
    : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
  const stabilizedThis = array?.map((el,index) => [el,index]);
  stabilizedThis?.sort((a,b) => {
    const order = comparator(a[0],b[0]);
    if(order !== 0) return order;
    return a[1] - b[1];
  });
  if(query) {
    return filter(array,(_user) => _user?.vendor_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
let loanId = ''
let customerName=''
let registrationNo=''
let location=''
let city=''
let repoDate=''
let repoBy=''
let state =''

export default function VehicleList() {
  const [page,setPage] = useState(0);
  const [value,setValue] = useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [order,setOrder] = useState('asc');
  const [orderBy,setOrderBy] = useState('name');
  const [filterName,setFilterName] = useState('');
  const [allStaff,setAllStaff] = useState([]);
  const [rowsPerPage,setRowsPerPage] = useState(25);

  const handleRequestSort = (event,property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    async function get() {
      const result = await axios.get(`${API_URL}/repo_details`);
      const allStaffs = await axios.get(`${API_URL}/staff/list`)
      setAllStaff(allStaffs?.data?.data)
      getRepoByName(allStaffs?.data?.data,result?.data?.data)
    }
    
    if(isMobile){
      TABLE_HEAD.length = 3;
    }else{
      TABLE_HEAD.length = 9;
    }
    get()
  },[]);

  
  const getRepoByName = (allStaff,repoData) => {
    let finalData = []
    for (let data of repoData) {
        let staffName = find(allStaff,{staff_id:data.repo_by})
        data = {...data,repo_by_name:staffName ? staffName.name:''}
        finalData.push(data)
    }
    setValue(finalData)
    setFilteredValue(finalData)
  };

  const handleChangePage = (event,newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value,10));
    setPage(0);
  };


  const handleFilter = (e) => {
    if(e.target.name === 'loan_id'){
        loanId = e.target.value
    }
    else if(e.target.name === 'customer_name'){
      customerName=e.target.value
    }
    else if(e.target.name === 'regno'){
        registrationNo=e.target.value
    }
    else if(e.target.name === 'parking_location'){
        location =e.target.value
    }
    else if(e.target.name === 'city'){
        city =e.target.value
    }
    else if(e.target.name === 'date'){
        repoDate =e.target.value
    }
    else if(e.target.name === 'repoBy'){
        repoBy =e.target.value
    }
    else if(e.target.name === 'state'){
        state =e.target.value
    }
    filteredValue = value.filter(v => ((String(v?.loan_id) || '').toLowerCase()).includes((loanId).toLowerCase()) && ((v?.loan?.customer_name || '').toLowerCase()).includes((customerName).toLowerCase()) && ((v?.registration_number || '').toLowerCase()).includes((registrationNo).toLowerCase()) && ((v?.vehicle_parking_city || '').toLowerCase()).includes((city).toLowerCase()) && ((v?.vehicle_parking_location || '').toLowerCase()).includes((location).toLowerCase()) && ((v?.repo_date || '')).includes((repoDate)) && ((v?.repo_by_name || '').toLowerCase()).includes((repoBy).toLowerCase()) && ((v?.state || '').toLowerCase()).includes((state).toLowerCase()))
    setFilteredValue([...filteredValue])
  };

  const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;
  const filteredUsers = applySortFilter(filteredValue,getComparator(order,orderBy));
  const isUserNotFound = value.length === 0;

  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Vehicle List
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{minWidth: 100}}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                <TableRow>
                    <TableCell align="left"><TextField className={Styles.filter} name = 'loan_id' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name = 'customer_name' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name = 'regno' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name = 'parking_location' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='city' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='state' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='date' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='repoBy' onChange={handleFilter} autoComplete='off' /></TableCell>
                  </TableRow>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v,index)=>{
                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          {!isMobile && <>
                            <TableCell align="left" style={{width:'180px'}}>{v?.loan_id}</TableCell>
                            <TableCell align="left" style={{width:'260px'}}>{v?.loan?.customer_name}</TableCell> 
                            <TableCell align="left" style={{width:'240px'}}>{v?.registration_number}</TableCell>  
                          </>}
                            <TableCell align="left" style={{width:'250px'}}>{v?.vehicle_parking_location}</TableCell>
                            <TableCell align="left" style={{width:'200px'}}>{v?.vehicle_parking_city || v?.other_parking_city}</TableCell>
                            <TableCell align="left" style={{width:'230px'}}>{v?.state}</TableCell>
                            <TableCell align="left" style={{width:'220px'}}>{moment(v?.repo_date).format('DD-MM-YYYY')}</TableCell>                  
                            <TableCell align="left" style={{width:'220px'}}>{v?.not_to_be_sold_date ? moment(v?.not_to_be_sold_date).format('DD-MM-YYYY') : ''}</TableCell>
                            <TableCell align="left" style={{width:'250px'}}>{v?.repo_by_name}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{py: 3}}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5,10,25]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
