import {useNavigate, useParams} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Alert, Button, Card, CardContent, IconButton, Stack,Table,TableCell,TableHead,TableRow,ToggleButton,ToggleButtonGroup,Tooltip,Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, FILE_UPLOAD_TYPE, RELATION_TYPE} from '../../../constant';
import {useContext,useEffect,useState} from 'react';
import {LoginContext} from '../../../context/logincontext'
import {includes, isEmpty, upperCase} from 'lodash';
import Iconify from '../../../components/Iconify';
import * as XLSX from 'xlsx';
import ProfileDetails from './ProfileDetails'
import KYCDeatails from './KYCDetails'
import UploadDocuments from './UploadDetails'
import {LeadContext} from '../../../context/leadContext';
import { useSearchParams } from 'react-router-dom';
import CustomizedSnackbars from '../../Alert/CustomizedSnackbars';
import { useSnackbar } from 'notistack';
import { firstLetetrUpperOfString } from '../../../utils/helper';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function Index(props) {
    let {id,type} = useParams()
    id = !isEmpty(props.name) ? props.id : id
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const context = useContext(LoginContext)
    const [searchParams] = useSearchParams();
    const {alignment,setAlignment,leadId,setLeadId,message,setMeassage,setSeverity,setIsOpen,severity,isOpen,customerRelationData, setCustomerRelationData,uploadWarning,setUploadWarning} = useContext(LeadContext)
    const [userData,setUserData] = useState(context.user)
    const [data,setData] = useState([]);
    const [file,setFile] = useState()
    const [leadData, setLeadData] = useState({})
    const [labelName , setLabelName] = useState('Customer')

  useEffect(() => {
    function getData() {
      const name = firstLetetrUpperOfString(props.name,'-')
      setLabelName(name)
      if(isEmpty(customerRelationData)){
        setCustomerRelationData({...props.data})
      }
    }
    if(props.name){
      getData()
    }
  },[props.name]);
    
    const getLeadData = async (leadId) => {
        const data = await axios.get(`${API_URL}/leads/${leadId}`)
        if(data.data.status === 'success') {
            setLeadData({...data.data.data})
        }
    }
    useEffect(() => {
        if(id){
            setLeadId(id)
        }else{
            setLeadId(undefined)
            setAlignment('details')
            setLeadData({})
        }
    },[id]);
    useEffect(()=>{
        if(leadId){
            getLeadData(leadId)
        }
    },[leadId, alignment])
    useEffect(() => {
        if(type){
            setAlignment(type)
        }else{
            setAlignment('details')
        }
    },[type]);

    useEffect(() => {
        if(!props?.name){
            if((leadData?.aadhaar_file_path?.length>0 || leadData?.is_aadhaar_verified===true) && (leadData?.pan_file_path?.length>0 || leadData?.is_pan_verified) && leadData?.bank_statement_path?.length>0 && leadData?.electricity_bill_path?.length>0){
                setUploadWarning(false)
            }
            else{
                setUploadWarning(true)
            }
        }else{
            if(!isEmpty(customerRelationData?.customer_docs)){
                const docsData = customerRelationData?.customer_docs
                const aadhaarData = docsData.find(docs=>{if(docs.doc_type === FILE_UPLOAD_TYPE.AADHAR){ return docs}})
                const panData = docsData.find(docs=>{if(docs.doc_type === FILE_UPLOAD_TYPE.PAN){ return docs}})
                if(!isEmpty(aadhaarData) && !isEmpty(panData)){
                    setUploadWarning(false)
                }else{
                    setUploadWarning(true)
                }
            }
        }
    },[leadData]);
///////////////////////////////Submit Csv Bulk Data Convert Data Process////////////////////
    const uploadCsvFile = async () => {
        let leadData = []
        for(let lead of data) {
            leadData.push({...lead,user_id: userData.user_id,company_id: userData.company_id})
        }
        try{
            const response = await axios.post(`${API_URL}/lead_bulk_data`,leadData);
            if(response.data.status === 'success') {
                enqueueSnackbar('Bulk customer`s data are successfully saved !', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                navigate('/dashboard/leads');
            }
        }
        catch(err){
            setIsOpen(true);
            setMeassage(`Check file Somethings went worng !`)
            setSeverity('error')
        }
        
    }

    const getFileDownLoad = (type) => {
        return `${API_URL}/csv_template/${type}/download`
    }

    const Input = styled('input')({
        display: 'none',
    });
    ///////////////////////////////Alignment Change////////////////////
    const handleChange = (event,newAlignment) => {
        if(!isEmpty(newAlignment)) {
            setAlignment(newAlignment);
        }
    }

    ///////////////////////////////Upload Csv Bulk Data Convert Data Process////////////////////

    let i = 2
    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for(let i = 1;i < dataStringLines.length;i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if(headers && row.length == headers.length) {
                const obj = {};
                for(let j = 0;j < headers.length;j++) {
                    let d = row[j];
                    if(d.length > 0) {
                        if(d[0] == '"')
                            d = d.substring(1,d.length - 1);
                        if(d[d.length - 1] == '"')
                            d = d.substring(d.length - 2,1);
                    }
                    if(headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if(Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        // const columns = headers.map(c => ({
        //   name: c,
        //   selector: c,
        // }));
        let leadData = []
        for (let lead of list){
            if(lead.name && lead.mobile){
                leadData.push(lead)
                i = i+1
            }else{
                enqueueSnackbar(`In Uploaded file row number : ${i} have some column is empty check ! `, { variant:'error' ,anchorOrigin:{vertical:'top',horizontal:'center'}});
                i = i+1
            }
        }
        setData(leadData);
    }

    // handle file upload
    const handleFileUpload = e => {
        setFile(Object.values(e.target.files))
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr,{type: 'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws,{header: 1});
            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    useEffect(() => {
            return () => {
                setAlignment('details')
                setLeadId(undefined)
                setLeadData({})
            }
    },[]);
    useEffect(()=>{
        const query = searchParams.get('category')
        if(query){
            setAlignment(query)
        }
      },[])

      const closeAlert = () => {
        setIsOpen(false);
        setMeassage('')
        setSeverity('')
    };

    const datas={
        ...leadData,
        to_los:true
    }
    delete datas.lead_id; 
    const checkEligibility=async()=>{
        const response = await axios.post(`${API_URL}/lead/update/${id}`,{data: JSON.stringify(datas)});
        if(response?.data?.status==='success'){
            navigate(`/dashboard/lead/${leadData?.lead_id}/status`)
        }
      }

    return (
        <div>
            {isOpen && <CustomizedSnackbars open={isOpen} onClose={closeAlert} message={message} severity={severity} close={closeAlert}/>}
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                {isEmpty(props.name) && leadData?.lead_id && 
                <Tooltip title="Profile Status">    
                    <Button style={{paddingBottom:20}} aria-label="profile" onClick={()=> navigate(`/dashboard/lead/${leadData?.lead_id}/status`)}>    
                        <ArrowBackIosNewIcon style={{paddingRight:0}}/>Go Back Summary    
                    </Button>    
                </Tooltip>}
                <Typography style={{paddingLeft: '20px',paddingTop:20}} variant="h4" gutterBottom>
                    {(id || props.relation_id) ? `Edit ${labelName || "Customer"}` : `Add ${labelName || 'Customer'}`}
                </Typography>
                {isEmpty(props.name) && <div style={{display: 'block',justifyContent: 'center'}}>
                    <Typography style={{textAlign: 'center',fontSize: '15px',paddingRight: '50px'}}><Iconify id={'Download Template'} icon="entypo:download" aria-label="download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',marginLeft: '30px',verticalAlign: 'middle'}} style={{marginRight: '10px'}} onClick={() => window.open(getFileDownLoad('leadCsvTemp'),'_blank')} /> Download Template</Typography>
                    {/* {( id && (leadData?.is_pan_verified || leadData?.is_aadhaar_verified || !isEmpty(leadData?.aadhaar_file_path) || !isEmpty(leadData?.pan_file_path))) && <LoadingButton disabled={leadData?.los_lead_id} size="large" color='secondary' variant="contained" style={{marginTop:'10px'}} onClick={checkEligibility}><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i> Check Loan Eligibility</LoadingButton>} */}
                </div>}
            </Stack>
            {(leadData?.lead_id || props?.name) && 
            <div style={{paddingLeft: '20px',paddingRight: '20px'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <Grid container boxShadow={false} spacing={2} justifyContent='left' columns={{ xs: 3, sm: 54 }}>
                        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
                            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                                Name : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {leadData?.name || customerRelationData?.name}</h4>
                            </Typography></TableCell>
                        </Grid>
                        <Grid item xs={18} style={{minidth:'20%'}}>
                            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex', fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                            Mobile : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {leadData?.mobile || customerRelationData?.mobile}</h4>
                            </Typography></TableCell>
                        </Grid>
                        {/* <Grid item xs={18} style={{minidth:'20%'}}>
                            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex', fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                            Address : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {leadData?.address || customerRelationData?.address}</h4>
                            </Typography></TableCell>
                        </Grid> */}
                        {leadData?.lead_id && <Grid item xs={18} style={{minidth:'20%'}}>
                            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex', fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                            Lead Type : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {leadData?.lead_type}</h4>
                            </Typography></TableCell>
                        </Grid>}
                        {props?.name && <Grid item xs={18} style={{minidth:'20%'}}>
                            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex', fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                            Relation Type : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {upperCase(customerRelationData?.relation)}</h4>
                            </Typography></TableCell>
                        </Grid>}
                        </Grid>
                    </TableRow>
                </TableHead>
            </Table>
            </div>}
            {!includes([RELATION_TYPE.NOMINEE, RELATION_TYPE.REFERENCE], props.name) ?
            <ToggleButtonGroup
                color="primary"
                size="medium"
                value={alignment}
                defaultValue={'details'}
                exclusive
                fullWidth
                style={{paddingLeft: '20px',paddingRight: '20px'}}
                onChange={handleChange}
            >
                <ToggleButton value="details">{!(leadData?.dob || props.data?.dob || customerRelationData?.dob) ? <i class="bi bi-exclamation-circle" style={{marginRight:'10px',color:'red'}}></i> : <i class="bi bi-check-circle" style={{marginRight:'10px',color:'green'}}></i>} Profile Details</ToggleButton>
                <ToggleButton value="address"> {!(leadData?.address || props.data?.city || customerRelationData?.city)? <i class="bi bi-exclamation-circle" style={{marginRight:'10px',color:'red'}}></i> : <i class="bi bi-check-circle" style={{marginRight:'10px',color:'green'}}></i>} KYC Details</ToggleButton>
                <ToggleButton value="documents">{uploadWarning?<i class="bi bi-exclamation-circle" style={{marginRight:'10px',color:'red'}}></i> : <i class="bi bi-check-circle" style={{marginRight:'10px',color:'green'}}></i>}  Upload Documents</ToggleButton>
            </ToggleButtonGroup>
            : <></>}
            <Box sx={{flexGrow: 1,p: 2}}>
                {!includes([RELATION_TYPE.NOMINEE, RELATION_TYPE.REFERENCE], props.name) ? 
                <Grid container spacing={2} justifyContent='center'>
                    {(alignment === 'details') && <>
                        <ProfileDetails {...props} />
                    </>}
                    {(alignment === 'address') && <>
                        <KYCDeatails {...props} />
                    </>}
                    {(alignment === 'documents') && <>
                        <UploadDocuments {...props} />
                    </>}
                </Grid>
                :
                <Grid container spacing={2} justifyContent='center'>
                    {(alignment === 'details') && <>
                        <ProfileDetails {...props} />
                    </>}
                </Grid>
                }
            </Box>
        </div>
    );
}