import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useEffect } from 'react';
import {API_URL} from '../constant';
// ----------------------------------------------------------------------
 
const validationSchema = yup.object({
  part_name: yup.string().required('field cannot be empty'),
  brand: yup.string().required('field cannot be empty'),
  price: yup.string().required('field cannot be empty'),
  min_qty: yup.string().required('field cannot be empty'),
  units: yup.string().required('field cannot be empty'),
  gst_percentage: yup.string().required('field cannot be empty')
});

export default function ViewParts() {
  const { id } = useParams()
  const navigate = useNavigate();
  
  const formik = useFormik({
    initialValues: {
      part_name: '',
      gst_percentage: '',
      units: '',
      min_qty: '',
      brand: '',
      price: ''
    },
    validationSchema,
    onSubmit: async (value) => {
      const response = await axios.post(`${API_URL}/parts/update/${id}`, value);
      if(response.data.status === 'success'){
        navigate('/dashboard/partlist', { replace: true });
      }
    },
  });
const getData = async () => {
    const data = await axios.get(`${API_URL}/part/${id}`)
    if(data?.data?.status === 'success'){
        const datas = data.data.data;
        formik.setFieldValue('part_name', datas.part_name)
        formik.setFieldValue('gst_percentage', datas.gst_percentage)
        formik.setFieldValue('units', datas.units)
        formik.setFieldValue('min_qty', datas.min_qty)
        formik.setFieldValue('brand', datas.brand)
        formik.setFieldValue('price', datas.price)
    }
  
}
useEffect(()=>{
getData()
},[])
  
  return (
    <div>
      <Typography variant="h4" gutterBottom>
              View Parts
      </Typography>
      <form onSubmit={formik.handleSubmit}>
      <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="part_name"
          label="Part Name"
          disabled={true}
          value={formik.values.part_name}
          onChange={formik.handleChange}
          error={formik.touched.part_name && Boolean(formik.errors.part_name)}
          helperText={formik.touched.part_name && formik.errors.part_name}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="gst_percentage"
          label="GST %"
          disabled={true}
          value={formik.values.gst_percentage}
          onChange={formik.handleChange}
          error={formik.touched.gst_percentage && Boolean(formik.errors.gst_percentage)}
          helperText={formik.touched.gst_percentage && formik.errors.gst_percentage}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="units"
          label="Units"
          disabled={true}
          value={formik.values.units}
          onChange={formik.handleChange}
          error={formik.touched.units && Boolean(formik.errors.units)}
          helperText={formik.touched.units && formik.errors.units}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="min_qty"
          label="min_qty"
          disabled={true}
          value={formik.values.min_qty}
          onChange={formik.handleChange}
          error={formik.touched.min_qty && Boolean(formik.errors.min_qty)}
          helperText={formik.touched.min_qty && formik.errors.min_qty}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="brand"
          label="Brand"
          disabled={true}
          value={formik.values.brand}
          onChange={formik.handleChange}
          error={formik.touched.brand && Boolean(formik.errors.brand)}
          helperText={formik.touched.brand && formik.errors.brand}
        />
        </Grid>
        <Grid item xs={11} sm={6}>
        <TextField
          autoComplete='off'
          fullWidth
          type={'text'}
          name="price"
          label="Price"
          disabled={true}
          value={formik.values.price}
          onChange={formik.handleChange}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
        </Grid>
      </Grid>
    </Box>
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={8} sm={6}>
              <LoadingButton
               fullWidth
               size="large"
               type="submit"
               variant="contained"
               loading={formik.isSubmitting}
              >
              Submit
              </LoadingButton>
              </Grid>
            </Grid>
          </Box>
      </form>
    </div>
  );
}
