import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { MenuItem, Select, TextField, Typography,FormControl,InputLabel} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, PAN_REGEX} from '../constant';
import { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../context/logincontext';
import ConfirmDialog from '../components/confirmDialog';
// ----------------------------------------------------------------------

const phoneRegExp = /^([0-9]){10}?$/
const validationSchema = yup.object({
  financer_name: yup.string().required('field cannot be empty'),
  address: yup.string().required('field cannot be empty'),
//   point_of_contact: yup.string().required('field cannot be empty'),
  mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('field cannot be empty'),
});

export default function EditFinancer() {
  const {id} = useParams()
  const context = useContext(LoginContext)
  const navigate = useNavigate();
  const[userData,setUserData]=useState(context.user);
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  
  const formik = useFormik({
    initialValues: {
      financer_name: '',
      point_of_contact:'',
      address:'',
      mobile:'',
      poc:''
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  async function submitForm(value) {
    value.user_id=userData.user_id
      const response = await axios.post(`${API_URL}/financer/update/${id}`, value);
      if(response.data.status === 'success'){
        navigate('/dashboard/financers', { replace: true });
      }
  }

  const getData = async () => {
    const data = await axios.get(`${API_URL}/financer/${id}`)
    if(data?.data?.status === 'success') {
      const datas = data.data.data;
      formik.setFieldValue('financer_name',datas.financer_name)
      formik.setFieldValue('mobile',datas.mobile)
      formik.setFieldValue('address',datas.address)  
      formik.setFieldValue('poc',datas.poc)  
      
    }
  }
  useEffect(() => {
    getData()
  },[])
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  }; 
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography variant="h4" gutterBottom>
              Edit Financer
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={11} sm={6}>
          <TextField
            autoComplete='off'
            fullWidth
            name="financer_name"
            type={'text'}
            label="Financer Name *"
            value={formik.values.financer_name}
            onChange={formik.handleChange}
            error={formik.touched.financer_name && Boolean(formik.errors.financer_name)}
            helperText={formik.touched.financer_name && formik.errors.financer_name}
          />
          </Grid>
          <Grid item xs={11} sm={6}>
          <TextField
            autoComplete='off'
            fullWidth
            name="address"
            type={'text'}
            label="Address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
          </Grid>
          <Grid item xs={11} sm={6}>
          <TextField
            autoComplete='off'
            fullWidth
            name="mobile"
            type={'number'}
            label="Mobile Number"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
          />
          </Grid> 
          <Grid item xs={11} sm={6}>
          <TextField
            autoComplete='off'
            fullWidth
            name="poc"
            type={'text'}
            label="Point Of Contact"
            value={formik.values.poc}
            onChange={formik.handleChange}
            error={formik.touched.poc && Boolean(formik.errors.poc)}
            helperText={formik.touched.poc && formik.errors.poc}
          />
          </Grid>   
        </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}