import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
// material
import { TextField,  IconButton, InputAdornment, Typography, FormControl, InputLabel, FormControlLabel, Switch, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Iconify from '../components/Iconify';
import { AADHAAR_REGEX, API_URL, MOBILE_REGEX, NAME_REGEX, PAN_REGEX } from '../constant';
import Styles from '../css/dates.module.css'
import ConfirmDialog from '../components/confirmDialog';
import { isEmpty } from 'lodash';
import CustomizedSnackbars from './Alert/CustomizedSnackbars';

axios.defaults.withCredentials = true
// ----------------------------------------------------------------------

const validationSchema = yup.object({
  user_name: yup.string().matches(NAME_REGEX, 'Enter the valid number').required('please select gender'),
  mobile: yup.string().matches(MOBILE_REGEX, 'Phone number is not valid').required('field cannot be empty'),
  aadhaar_no: yup.string().matches(AADHAAR_REGEX,'Aadhaar number is not valid').nullable(true),
  pan: yup.string().matches(PAN_REGEX, 'Pan number is not valid').nullable(true),
  dob: yup.date().required('field cannot be empty'),
  gender: yup.number().required('please select gender'),
  password: yup.string().min(8 ,'password should not be less than 8').required('field cannot be empty')
});

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const[company,SetCompany]=useState([])
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const [message,setMeassage] = useState('')
  const [severity,setSeverity] = useState('')
  const [isOpen,setIsOpen] = useState(false)
  const formik = useFormik({
    initialValues: {
      user_name: '',
      mobile: '',
      password: '',
      gender: '',
      dob:null,
      aadhaar_no:null,
      pan: '',
      company_id:'',
      is_active:false
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  async function submitForm(value){
    value.company_name = (company?.find(comp=> comp.company_id === value.company_id))?.name
      const response = await axios.post(`${API_URL}/system_user`, value);
      if(response.data.status === 'success'){
        if(!isEmpty(response.data.data)){
          setMeassage(response.data.data)
          setSeverity('info')
          setIsOpen(true);
        }else{
          navigate('/dashboard/users', { replace: true });
        }
      }
  }
  useEffect(() => {
    async function get(){
    const res= await axios.get(`${API_URL}/company`)
    if(res.data.status === 'success'){
      SetCompany(res.data.data);
    }
    }
    get()
  },[]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  const closeAlert = () => {
    setIsOpen(false);
    setMeassage('')
    setSeverity('')
  };

  return (
    <div>
      {isOpen && <CustomizedSnackbars open={isOpen} onClose={closeAlert} message={message} severity={severity} close={closeAlert}/>}
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography style={{paddingLeft:'20px'}} variant="h4" gutterBottom>
                Add System User
        </Typography>
        <FormControlLabel
          sx={{
            display: 'block',
          }}
          control={
            <Switch
              checked={formik.values.is_active}
              onChange={() => formik.setFieldValue('is_active',formik.values.is_active ? false : true)}
              name={formik.values.is_active ? 'Active' : 'InActive'}
              color="primary"
            />
          }
          label={formik.values.is_active ? 'Active' : 'InActive'}
        />
      </Stack>
      
      <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="user_name"
              label="Name *"
              value={formik.values.user_name}
              onChange={formik.handleChange}
              error={formik.touched.user_name && Boolean(formik.errors.user_name)}
              helperText={formik.touched.user_name && formik.errors.user_name}
            />
            </Grid>
            <Grid item xs={11} md={6}>
          <TextField
          select
          fullWidth
          id='company_id'
          name="company_id"
          label="Company Name *"
          onChange={formik.handleChange}
          error={formik.touched.company_id && Boolean(formik.errors.company_id)}
          helpertext={formik.touched.company_id && formik.errors.company_id}
          >
            {company?.map((v,i) => {
              return (
                <MenuItem  value={v.company_id}>{v.name} </MenuItem>
              );
            })}
          </TextField>
              </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              name="mobile"
              inputProps={{ maxLength: 10 }}
              label="Mobile *"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              autoComplete='off'
              fullWidth
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password *"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="gender">Gender *</InputLabel>
              <Select
              fullWidth
              labelId="gender"
              id="gender"
              name="gender"
              label="Gender *"
              value={formik.values.gender}
              onChange={formik.handleChange}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              helpertext={formik.touched.gender && formik.errors.gender}
              >
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
            id="dob"
            type="date"
            autoComplete='off'
            className={formik.values.dob === null && Styles.dates}
            fullWidth
            name="dob"
            label="Date Of Birth *"
            value={formik.values.dob}
            onChange={formik.handleChange}
            error={formik.touched.dob && Boolean(formik.errors.dob)}
            helperText={formik.touched.dob && formik.errors.dob}
          />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              type={'number'}
              inputProps={{ maxLength: 12 }}
              name="aadhaar_no"
              label="Aadhaar Number "
              value={formik.values.aadhaar_no}
              onChange={formik.handleChange}
              // error={formik.touched.aadhaar_no && Boolean(formik.errors.aadhaar_no)}
              helperText={formik.touched.aadhaar_no && formik.errors.aadhaar_no}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              name="pan"
              label="Pan Number "
              inputProps={{ maxLength: 10 }}
              value={formik.values.pan}
              onChange={formik.handleChange}
              error={formik.touched.pan && Boolean(formik.errors.pan)}
              helperText={formik.touched.pan && formik.errors.pan}
            />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
