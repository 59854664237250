import {useEffect,useState} from 'react';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Link,
  Box,
  Grid
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import {UserListHead,UserMoreMenu} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL} from '../constant';
import { LoadingButton } from '@mui/lab';
import {Link as RouterLink} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Styles from '../css/filter.module.css'
import { STATE, TYPE } from '../constant';
import { isEmpty } from 'lodash';
import CsvDownloader from 'react-csv-downloader';
import moment from 'moment';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'companyName',label: 'Company Name',alignRight: false},
  {id: 'purchase',label: 'Total Purchase ',alignRight: false},
  {id: 'sales',label: 'Total Sales',alignRight: false},
  {id: 'leads',label: 'Customer Leads',alignRight: false},
  {id: 'viewCount',label: 'Ledger View Count',alignRight: false},
  {id: 'loginCount',label: 'Login Count',alignRight: false},
  {id: 'lastSeen',label: 'Last Seen',alignRight: false},
];

// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
  if(b[orderBy] < a[orderBy]) {
    return -1;
  }
  if(b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order,orderBy) {
  return order === 'desc'
    ? (a,b) => descendingComparator(a,b,orderBy)
    : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
  const stabilizedThis = array.map((el,index) => [el,index]);
  stabilizedThis.sort((a,b) => {
    const order = comparator(a[0],b[0]);
    if(order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

let companyName = ''


export default function SystemUserReports() {
  const [page,setPage] = useState(0);
  const [value,setValue] = useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [order,setOrder] = useState('asc');
  const [selected,setSelected] = useState([]);
  const [orderBy,setOrderBy] = useState('name');
  const [rowsPerPage,setRowsPerPage] = useState(10);
  const [fromDate , setFromDate]=useState('2000-01-01')
  const [toDate , setToDate] = useState('2000-01-01')

  const handleRequestSort = (event,property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };


  const handleChangePage = (event,newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value,10));
    setPage(0);
  };

  

  const handleFilter = (e) => {
    if(e.target.name === 'companyName'){
       companyName = e.target.value
    }
   
    filteredValue = value.filter(v => ((v?.companyName || '').toLowerCase()).includes((companyName).toLowerCase())) 
    setFilteredValue([...filteredValue])
  };

  const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(filteredValue,getComparator(order,orderBy));

  const isUserNotFound = value.length === 0;

 

  useEffect(() => {
    async function getUsers() {
      const res = await axios.get(`${API_URL}/all_system_users`);
      setValue(res?.data?.data);
      setFilteredValue(res?.data?.data)
    }
    if(isMobile){
      TABLE_HEAD.length = 4
    }else{
      TABLE_HEAD.length = 7
    }
    getUsers()
  },[]);

  const handleChange = (e) => {
    if(e.target.name === 'from_date'){
      setFromDate(e.target.value)
    }else{
      setToDate(e.target.value)
    }
  }

  const getDataByDate = () => {
    const fDate = new Date(fromDate).getTime()
    const tDate = new Date(toDate).getTime()
    let filterData = []
    filterData = value.filter(data =>(fDate <= (new Date(data.createdAt)).getTime() && (new Date(data.createdAt)).getTime() <=  tDate))
    // setValue([...filterData])
    if(isEmpty(filterData)){
      for(let userData of value){
        filterData.push({companyName:userData.companyName,lastSeen:userData.lastSeen})
      }
    }
    setFilteredValue([...filterData])
  }
 
  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            System Users Report
          </Typography>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/user" underline="none">
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-person-circle"></i> Add User</LoadingButton>
          </Link>
        </Stack>

        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='left'>
          <Grid item xs={4} sm={2.5}>
          <TextField
            id="from_date"
            type="date"
            autoComplete='off'
            name="from_date"
            label="From Date"
            // className={fromDate === null && Styles.dates}
            onChange={handleChange}
            value={fromDate}
          />
          </Grid>
          <Grid item xs={4} sm={2.5}>
          <TextField
            id="to_date"
            type="date"
            autoComplete='off'
            name="to_date"
            label="To Date"
            // className={toDate === null && Styles.dates}
            onChange={handleChange}
            value={toDate}
          />
          </Grid>
          <Grid item xs={4} sm={1.8}>
          <LoadingButton size="large" type="submit" variant="contained" onClick={getDataByDate}><span>Submit</span></LoadingButton>
          </Grid>
          <Grid item xs={4} sm={4}>
          <CsvDownloader
          filename={`system_user_report(${moment().format('DD-MM-YYYY hh:mm:ss')})`}
          extension=".csv"
          separator=";"
          wrapColumnChar=""
          columns={TABLE_HEAD}
          datas={filteredUsers}>
          <LoadingButton size="large" color='secondary' type="submit" variant="contained"><i className="bi bi-bar-chart-fill"></i>Export Report</LoadingButton>
          </CsvDownloader>
          </Grid>
          </Grid>
          </Box>

        <Card>
          <Scrollbar>
            <TableContainer sx={{minWidth: 100}}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  <TableRow>
                    <TableCell align="left"><TextField className={Styles.filter} name='companyName' onChange={handleFilter} autoComplete='off' /></TableCell>
                   
                  </TableRow>
                  {filteredUsers.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage).map((v,index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{v?.companyName}</TableCell>
                        <TableCell align="left">{v?.purchase || 0}</TableCell>
                        <TableCell align="left">{v?.sales || 0}</TableCell>
                        {!isMobile && <>
                        <TableCell align="left">{v?.leads || 0}</TableCell>
                        <TableCell align="left">{v?.viewCount ? v?.viewCount : 0}</TableCell>
                        <TableCell align="left">{v?.loginCount || 0}</TableCell>
                        </>}
                        <TableCell align="left">{v?.lastSeen !=='Invalid date' ? v?.lastSeen : 'Not Login'}</TableCell>
                        {/* <TableCell align="right">
                          <UserMoreMenu linkTo={`/dashboard/users/${v.user_id}/edit`}
                            view={`/dashboard/users/${v.user_id}`}
                          />
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5,10,25]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
