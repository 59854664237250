import * as yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {FormControl,TextField,Typography,ToggleButtonGroup,ToggleButton,Autocomplete, Card, Button, Container, TableRow, TableCell} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL,CATEGORY,FILE_UPLOAD_TYPE} from '../constant';
import {useContext,useEffect,useRef,useState} from 'react';
import {isEmpty} from 'lodash';
import {styled} from '@mui/material/styles';
import Iconify from '../components/Iconify';
import LinearProgress from '@mui/material/LinearProgress';
import {LoginContext} from '../context/logincontext';
import ConfirmDialog from '../components/confirmDialog';
import { ImageConfig } from '../pages/upload/config';
import uploadImg from '../pages/upload/cloud.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// ----------------------------------------------------------------------
const phoneRegExp = /^([0-9]){10}?$/
const validationSchema = yup.object({
  name: yup.string().required('field cannot be empty'),
  address: yup.string().required('field cannot be empty'),
  company_type: yup.string().required('field cannot be empty'),
  city: yup.string().required('field cannot be empty'),
  website: yup.string().nullable(true),
  cin: yup.string().nullable(true),
  primary_email: yup.string().required('Email is required').nullable(true),
  secondary_email: yup.string().nullable(true),
  primary_mobile: yup.string().matches(phoneRegExp,'Phone number is not valid').required('field cannot be empty'),
  secondary_mobile: yup.string().matches(phoneRegExp,'Phone number is not valid').nullable(true),
  pin_code: yup.string().min(4,'should not less than 4 digits').required('this field cannot be empty')
});
const Input = styled('input')({
  display: 'none',
});
export default function AddCompany() {
  const navigate = useNavigate();
  const context = useContext(LoginContext)
  const [userData,setUserData] = useState(context?.user)
  const [aadharDocs,setAadharDocs] = useState([])
  const [panDocs,setPandocs] = useState([])
  const [gstRegistration,setGSTRegistration] = useState([])
  const [rentAgreement,setRentAgreement] = useState([])
  const [dealerAgreement,setDealerAgreement] = useState([])
  const [udhyodAadhar,setUdhyodAadhar] = useState([])
  const [uploadDocs,setUploadDocs] = useState(false)
  const [company,setCompany] = useState(undefined)
  const [alignment,setAlignment] = useState('details');
  const [parentCompanies,setParentCompanies] = useState([])
  const [parentCompany,setParentCompany] = useState({name: ''})
  const [docsubmitting,setDocsubmitting] = useState(false)
  const [allDealers,setAllDealers] = useState()
  const [dealer,setDealer] = useState({dealer_id: '',firm_name: ''})
  const [pinCode,setPinCode] = useState([])
  const [pin,setPin] = useState('')
  const [modelList,setModelList] = useState([])
  const [progress,setProgress] = useState(0);
  const [open, setOpen] = useState(false)
  const [formvalue, setFormValue] = useState({}) 
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [img, setImg] = useState([]);
  const [show,setShow]=useState(true)
  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');
  const [tradeCertificate,setTrade] = useState([])
  const [loi,setLoi] = useState([])
  const [bankingOfFirm,setBankingOfFirm] = useState([])
  const [electricityBill,setBill] = useState([])
  const [gstr1,setGstr1] = useState([])
  const [gstr3,setGstr3] = useState([])
  const [icat,setIcat] = useState([])
  const [personalBankingOfProprietor,setPersonalBanking] = useState([])


  const formik = useFormik({
    initialValues: {
      name: '',
      company_type: '',
      address: '',
      primary_email: '',
      secondary_email: '',
      primary_mobile: '',
      secondary_mobile: '',
      city: '',
      website: '',
      cin: '',
      owner_name: '',
      owner_pan: '',
      owner_mobile: '',
      gstin: '',
      category: '',
      pan: '',
      account_no: '',
      ifsc_code: '',
      bank_name: '',
      approval_status: 'PENDING_APPROVAL',
      dealer_id: '',
      is_active: false,
      pin_code: ''
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  async function submitForm(value){
    value.dealer_id = dealer?.dealer_id || null
      value.parent_company_id = parentCompany?.company_id
      value.parent_company_name = parentCompany?.name
      if(pin.length > 5) {
        value.pin_code = pin
      }
      const data = new FormData();
      const files = []
      if(!isEmpty(aadharDocs)) {
        for(let doc of aadharDocs) {
          files.push({fileType: 'dealer_kyc_aadhar',file: doc})
        }
      }
      if(!isEmpty(panDocs)) {
        console.log(panDocs);
        for(let doc of panDocs) {
          files.push({fileType: 'dealer_kyc_pan',file: doc})
        }
      }
      if(!isEmpty(gstRegistration)) {
        for(let doc of gstRegistration) {
          files.push({fileType: 'dealer_gst_registration',file: doc})
        }
      }
      if(!isEmpty(rentAgreement)) {
        for(let doc of rentAgreement) {
          files.push({fileType: 'dealer_rent_agreement',file: doc})
        }
      }
      if(!isEmpty(udhyodAadhar)) {
        for(let doc of udhyodAadhar) {
          files.push({fileType: 'dealer_udhyog_aadhar',file: doc})
        }
      }
      if(!isEmpty(dealerAgreement)) {
        for(let doc of dealerAgreement) {
          files.push({fileType: 'dealer_agreement',file: doc})
        }
      }
      if(!isEmpty(modelList)) {
        for(let doc of modelList) {
          files.push({fileType: 'model_list',file: doc})
        }
      }if(!isEmpty(tradeCertificate)) {
        for(let doc of tradeCertificate) {
          files.push({fileType: 'trade_certificate',file: doc})
        }
      }
      if(!isEmpty(loi)) {
        for(let doc of loi) {
          files.push({fileType: 'loi',file: doc})
        }
      }
      if(!isEmpty(bankingOfFirm)) {
        for(let doc of bankingOfFirm) {
          files.push({fileType: 'banking_of_firm',file: doc})
        }
      }if(!isEmpty(electricityBill)) {
        for(let doc of electricityBill) {
          files.push({fileType: 'electricity_bill',file: doc})
        }
      }
      if(!isEmpty(gstr1)) {
        for(let doc of gstr1) {
          files.push({fileType: 'gstr1',file: doc})
        }
      }
      if(!isEmpty(gstr3)) {
        for(let doc of gstr3) {
          files.push({fileType: 'gstr3',file: doc})
        }
      }
      if(!isEmpty(icat)) {
        for(let doc of icat) {
          files.push({fileType: 'icat',file: doc})
        }
      }
      if(!isEmpty(personalBankingOfProprietor)) {
        for(let doc of personalBankingOfProprietor) {
          files.push({fileType: 'personal_banking_of_proprietor',file: doc})
        }
      }
      files.forEach((file) => {
        data.append(file.fileType,file.file);
      })
      data.append('data',JSON.stringify(value));
      if(isEmpty(company)) {
        let response = await axios.post(`${API_URL}/company`,data);
        if(response.data.status === 'success') {
          const companyData = response.data.data
          setCompany(companyData)
          setAlignment('upload')
          setUploadDocs(true)
        }
      } else if(!isEmpty(company)) {
        if(!isEmpty(company)) {
          let response = await axios.post(`${API_URL}/company/update/${company.company_id}`,data);
          if(response.data.status === 'success') {
            navigate('/dashboard/companies',{replace: true});
          }
        }
      }
   }



  const onFileDrop = (e) => {
    const allFiles=e.target.files
    if(!isEmpty(allFiles)){
    for (let i = 0; i < allFiles.length; i++) {
      let file=allFiles[i]
      let imagePreview=URL.createObjectURL(file)
      allFiles[i].preview=imagePreview
    }
    if(allFiles.type==="application/pdf"){
      delete allFiles.preview
    }
  }
      const updatedList = allFiles;
      setFileList([...updatedList]);
      setInterval(() => {
        setProgress(100);
      }, 1000);
}    


const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);

    
}

const fileDrop = (doc,name) => {
  const updatedList = [...fileList];
  updatedList.splice(fileList.indexOf(doc), 1);
  setFileList(updatedList);
  let list
  switch (name) {
    case "dealer_kyc_aadhar": list = [...aadharDocs];
      list.splice(list.indexOf(doc), 1);
      setAadharDocs(list);
      break;
    case "dealer_kyc_pan": list = [...panDocs];
      list.splice(list.indexOf(doc), 1);
      setPandocs(list);
      break;
    case "dealer_gst_registration": list = [...gstRegistration];
      list.splice(list.indexOf(doc), 1);
      setGSTRegistration(list);
    break;
    case "dealer_rent_agreement": list = [...rentAgreement];
      list.splice(list.indexOf(doc), 1);
      setRentAgreement(list);
    break;
    case "dealer_udhyog_aadhar": list = [...udhyodAadhar];
      list.splice(list.indexOf(doc), 1);
      setUdhyodAadhar(list);
    break;
    case "dealer_agreement": list = [...dealerAgreement];
      list.splice(list.indexOf(doc), 1);
      setDealerAgreement(list);
    break;
    case "model_list": list = [...modelList];
      list.splice(list.indexOf(doc), 1);
      setModelList(list);
    break;
    case "trade_certificate": list = [...tradeCertificate];
      list.splice(list.indexOf(doc), 1);
      setTrade(list);
    break;
    case "loi": list = [...loi];
      list.splice(list.indexOf(doc), 1);
      setLoi(list);
    break;
    case "banking_of_firm": list = [...bankingOfFirm];
      list.splice(list.indexOf(doc), 1);
      setBankingOfFirm(list);
    break;
    case "electricity_bill": list = [...electricityBill];
      list.splice(list.indexOf(doc), 1);
      setBill(list);
    break;
    case "gstr1": list = [...gstr1];
      list.splice(list.indexOf(doc), 1);
      setGstr1(list);
    break;
    case "gstr3": list = [...gstr3];
      list.splice(list.indexOf(doc), 1);
      setGstr3(list);
    break;
    case "icat": list = [...icat];
      list.splice(list.indexOf(doc), 1);
      setIcat(list);
    break;
    case "personal_banking_of_proprietor": list=[...personalBankingOfProprietor];
      list.splice(list.indexOf(doc), 1);
      setPersonalBanking(list);
    break;
    default:
      break;
  }
}
  

const handleChange=(e,item)=>{
  let value=e.target.value;
  switch (value) {
    case "dealer_kyc_pan":setPandocs([...panDocs,item])
      break;
      case "dealer_kyc_aadhar":setAadharDocs([...aadharDocs,item])
      break;
      case "dealer_gst_registration":setGSTRegistration([...gstRegistration,item])
      break;
      case "dealer_rent_agreement":setRentAgreement([...rentAgreement,item])
      break;
      case "dealer_agreement":setDealerAgreement([...dealerAgreement,item])
      break;
      case "dealer_udhyog_aadhar":setUdhyodAadhar([...udhyodAadhar,item])
      break;
      case "model_list":setModelList([...modelList,item])
      break;
      case "trade_certificate":setTrade([...tradeCertificate ,item])
      break;
      case "loi":setLoi([...loi,item])
      break;
      case "banking_of_firm":setBankingOfFirm([...bankingOfFirm,item])
      break;
      case "personal_banking_of_proprietor":setPersonalBanking([...personalBankingOfProprietor,item])
      break;
      case "electricity_bill":setBill([...electricityBill,item])
      break;
      case "gstr1":setGstr1([...gstr1,item])
      break;
      case "gstr3":setGstr3([...gstr3,item])
      break;
      case "icat":setIcat([...icat,item])
      break;
    default:
      break;
  }
  

}
    const handleAlignmentChange = (event,newAlignment) => {
    setAlignment(newAlignment);
    if(newAlignment === 'upload') {
      setUploadDocs(true)
    } else {
      setUploadDocs(false)
    }
  };

  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/company`)
      if(res.data.status === 'success') {
        let datas = res?.data?.data
        datas = datas.filter(d => d?.company_id !== userData?.company?.company_id && d?.approval_status === 'APPROVED')
        setParentCompanies([...datas]);
      }
    }
    get()
  },[]);

  useEffect(() => {
    const gettingDealers = async () => {
      const result = await axios.get(`${API_URL}/los_all_dealer`)
      if(result?.data?.status === 'success') {
        setAllDealers(result?.data?.data)
      }
    }
    gettingDealers()
  },[])

  const handlePinChange = async (e) => {
    setPin(e.target.value)
    if((e.target.value).length > 5) {
      formik.setFieldValue('pin_code',e.target.value)
      const response = await axios.get(`${API_URL}/get_city_by_pin/${e.target.value}`);
      if(response.data.status === 'success') {
        formik.setFieldValue('city',response?.data?.data?.district_name)
      }
    } else {
      formik.setFieldValue('city','')
      formik.setFieldValue('pin_code',e.target.value)
    }
  }
  const handleCompanyChange = (company) => {
    if(company) {
      setParentCompany(company)
    }
  };

  const handleDealerChange = (dealer) => {
    if(dealer) {
      setDealer(dealer)
      formik.setFieldValue('name',dealer?.firm_name)
      formik.setFieldValue('owner_name',dealer?.proprietor || '')
      formik.setFieldValue('category',dealer?.category || '')
      formik.setFieldValue('pan',dealer?.pan_no || '')
      formik.setFieldValue('account_no',dealer?.account_no || '')
      formik.setFieldValue('ifsc_code',dealer?.ifsc_code || '')
      formik.setFieldValue('bank_name',dealer?.bank_name || '')
      formik.setFieldValue('address',dealer?.address || '')
      formik.setFieldValue('primary_email',dealer?.email_id || '')
      formik.setFieldValue('primary_mobile',dealer?.mobile || '')
      formik.setFieldValue('secondary_mobile',dealer?.mobile2 || '')
      formik.setFieldValue('city',dealer?.city || '')
      formik.setFieldValue('approval_status',dealer?.approval_status)
      formik.setFieldValue('is_active',dealer?.active)
      formik.setFieldValue('dealer_id',dealer?.dealer_id)
      setAadharDocs(dealer?.dealer_kyc_aadhar ?dealer?.dealer_kyc_aadhar: [])
      setGSTRegistration(dealer?.dealer_gst_registration ? dealer?.dealer_gst_registration: [])
      setUdhyodAadhar(dealer?.dealer_udhyog_aadhar?dealer?.dealer_udhyog_aadhar: [])
      setPandocs(dealer?.dealer_kyc_pan?dealer?.dealer_kyc_pan: [])
      setRentAgreement(dealer?.dealer_rent_agreement?dealer?.dealer_rent_agreement: [])
      setDealerAgreement(dealer?.dealer_agreement?dealer?.dealer_agreement: [])
      setTrade(dealer?.trade_certificate ? dealer?.trade_certificate:[])
      setModelList(dealer?.model_list ? dealer?.model_list : [])
      setLoi(dealer?.loi ? dealer?.loi:[])
      setBankingOfFirm(dealer?.banking_of_firm ? dealer?.banking_of_firm: [])
      setPersonalBanking(dealer?.personal_banking_of_proprietor ? dealer?.personal_banking_of_proprietor:[])
      setBill(dealer?.electricity_bill ? dealer?.electricity_bill:[])
      setGstr1(dealer?.gstr1 ? dealer?.gstr1: [])
      setGstr3(dealer?.gstr3 ? dealer?.gstr3:[])
      setIcat(dealer?.icat ? dealer?.icat:[])
    }
  };
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{paddingLeft: '20px'}} variant="h4" gutterBottom>
        Add Client
      </Typography>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        fullWidth
        style={{paddingLeft: '20px',paddingRight: '20px'}}
        size='large'
        onChange={handleAlignmentChange}
      >
        <ToggleButton value="details">Profile Details</ToggleButton>
        <ToggleButton value="upload">Upload Documents</ToggleButton>
      </ToggleButtonGroup>
      <form onSubmit={formik.handleSubmit}>
        {!uploadDocs &&
          <Box sx={{flexGrow: 1,p: 2}}>
            <Grid container spacing={2} justifyContent='left'>
              <Grid item xs={11} sm={4}>
                <Autocomplete
                  id="dealer_id"
                  name='dealer_name'
                  options={allDealers ? allDealers : []}
                  getOptionLabel={(option) => option?.firm_name}
                  renderInput={(params) => <TextField {...params} label="Dealer Name" />}
                  value={dealer}
                  onChange={(event,newValue) => {
                    handleDealerChange(newValue)
                  }}
                  error={formik.touched.dealer_name && Boolean(formik.errors.dealer_name)}
                  helpertext={formik.touched.dealer_name && formik.errors.dealer_name}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="name"
                  type={'text'}
                  label="Company Name *"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="company_type">Company Type *</InputLabel>
                  <Select
                    fullWidth
                    labelId='company_type'
                    id="company_type"
                    name="company_type"
                    label="Company Type"
                    value={formik.values.company_type}
                    onChange={formik.handleChange}
                    error={formik.touched.company_type && Boolean(formik.errors.company_type)}
                    helpertext={formik.touched.company_type && formik.errors.company_type}
                  >
                    <MenuItem value={'Proprietorship'}>Proprietorship</MenuItem>
                    <MenuItem value={'Limited Liability Partnership'}>Limited Liability Partnership</MenuItem>
                    <MenuItem value={'Private Limited'}>Private Limited</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="address"
                  type={'text'}
                  label="Address *"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  fullWidth
                  autoComplete='off'
                  name="primary_email"
                  type={'email'}
                  label="Primary Email address *"
                  value={formik.values.primary_email}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.primary_email && formik.errors.primary_email)}
                  helperText={formik.touched.primary_email && formik.errors.primary_email}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="pin_code"
                  label="Pin Code *"
                  inputProps={{maxLength: 6}}
                  value={pin}
                  onChange={handlePinChange}
                  error={formik.touched.pin_code && Boolean(formik.errors.pin_code)}
                  helperText={formik.touched.pin_code && formik.errors.pin_code}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="city"
                  type={'text'}
                  label="City *"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="primary_mobile"
                  type={'number'}
                  label="Company Phone Number *"
                  value={formik.values.primary_mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.primary_mobile && Boolean(formik.errors.primary_mobile)}
                  helperText={formik.touched.primary_mobile && formik.errors.primary_mobile}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="pan"
                  type={'text'}
                  label="Pan Number"
                  value={formik.values.pan}
                  onChange={formik.handleChange}
                  error={formik.touched.pan && Boolean(formik.errors.pan)}
                  helperText={formik.touched.pan && formik.errors.pan}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="owner_name"
                  type={'text'}
                  label="Owner Name"
                  value={formik.values.owner_name}
                  onChange={formik.handleChange}
                  error={formik.touched.owner_name && Boolean(formik.errors.owner_name)}
                  helperText={formik.touched.owner_name && formik.errors.owner_name}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="owner_mobile"
                  type={'text'}
                  label="Owner Mobile"
                  value={formik.values.owner_mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.owner_mobile && Boolean(formik.errors.owner_mobile)}
                  helperText={formik.touched.owner_mobile && formik.errors.owner_mobile}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="owner_pan"
                  type={'text'}
                  label="Owner Pan Number"
                  value={formik.values.owner_pan}
                  onChange={formik.handleChange}
                  error={formik.touched.owner_pan && Boolean(formik.errors.owner_pan)}
                  helperText={formik.touched.owner_pan && formik.errors.owner_pan}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="gstin"
                  type={'text'}
                  label="GSTIN"
                  value={formik.values.gstin}
                  onChange={formik.handleChange}
                  error={formik.touched.gstin && Boolean(formik.errors.gstin)}
                  helperText={formik.touched.gstin && formik.errors.gstin}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  select
                  fullWidth
                  name="category"
                  type={'text'}
                  label="Category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  error={formik.touched.category && Boolean(formik.errors.category)}
                  helperText={formik.touched.category && formik.errors.category}
                >
                  {Object.values(CATEGORY).map(category =>{return <MenuItem value={category}>{category}</MenuItem>}) }
                </TextField>
              </Grid>
              <Grid item xs={11} sm={4}>
                <Autocomplete
                  id="parent_company"
                  name='parent_company_name'
                  options={parentCompanies}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => <TextField {...params} label="Parent Company Name" />}
                  value={parentCompany}
                  onChange={(event,newValue) => {
                    handleCompanyChange(newValue)
                  }}
                  error={formik.touched.parent_company_name && Boolean(formik.errors.parent_company_name)}
                  helpertext={formik.touched.parent_company_name && formik.errors.parent_company_name}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="account_no"
                  type={'text'}
                  label="Account Number"
                  value={formik.values.account_no}
                  onChange={formik.handleChange}
                  error={formik.touched.account_no && Boolean(formik.errors.account_no)}
                  helperText={formik.touched.account_no && formik.errors.account_no}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="ifsc_code"
                  type={'text'}
                  label="Ifsc Code"
                  value={formik.values.ifsc_code}
                  onChange={formik.handleChange}
                  error={formik.touched.ifsc_code && Boolean(formik.errors.ifsc_code)}
                  helperText={formik.touched.ifsc_code && formik.errors.ifsc_code}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="bank_name"
                  type={'text'}
                  label="Bank Name"
                  value={formik.values.bank_name}
                  onChange={formik.handleChange}
                  error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
                  helperText={formik.touched.bank_name && formik.errors.bank_name}
                />
              </Grid>


              <Grid item xs={11} sm={4}>
                <TextField
                  fullWidth
                  autoComplete='off'
                  name="secondary_email"
                  type={'email'}
                  label="Secondary Email address"
                  value={formik.values.secondary_email}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.secondary_email && formik.errors.secondary_email)}
                  helperText={formik.touched.secondary_email && formik.errors.secondary_email}
                />
              </Grid>

              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="secondary_mobile"
                  type={'number'}
                  label="Secondary Mobile"
                  value={formik.values.secondary_mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.secondary_mobile && Boolean(formik.errors.secondary_mobile)}
                  helperText={formik.touched.secondary_mobile && formik.errors.secondary_mobile}
                />
              </Grid>

              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="website"
                  type={'text'}
                  label="Website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  error={formik.touched.website && Boolean(formik.errors.website)}
                  helperText={formik.touched.website && formik.errors.website}
                />
              </Grid>
              <Grid item xs={11} sm={4}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  name="cin"
                  type={'text'}
                  label="Cin No"
                  value={formik.values.cin}
                  onChange={formik.handleChange}
                  error={formik.touched.cin && Boolean(formik.errors.cin)}
                  helperText={formik.touched.cin && formik.errors.cin}
                />
              </Grid>
            </Grid>
          </Box>
        }
        {uploadDocs &&
          <Box sx={{flexGrow: 1,p: 2}}>
            <Grid container spacing={2} justifyContent='center' columns={{xs: 1,sm: 2}}>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Aadhar KYC </Typography>
                  </div>
                </div>
                <div>
                  {aadharDocs?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type || doc.name} <Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'dealer_kyc_aadhar')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>PAN KYC</Typography>
                  </div>
                </div>
                <div>
                {panDocs?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type || doc.name} <Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'dealer_kyc_pan')}>X</Button></Button>, </span>)}                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>GST Registration </Typography>
                  </div>
                </div>
                <div>
                {gstRegistration?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type|| doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'dealer_gst_registration')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Udhyog Aadhar</Typography>
                  </div>
                </div>
                <div>
                {udhyodAadhar?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type|| doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'dealer_udhyog_aadhar')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Dealer Agreement </Typography>
                  </div>
                </div>
                <div>
                {dealerAgreement?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type ||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'dealer_agreement')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                 
                  <div>
                    <Typography>Dealer Rent Agreement</Typography>
                  </div>
                </div>
                <div>
                {rentAgreement?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type ||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'dealer_agreement')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload Model List </Typography>
                  </div>
                </div>
                <div>
                {modelList?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'model_list')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload Trade Certificate</Typography>
                  </div>
                </div>
                <div>
                {tradeCertificate?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'trade_certificate')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload Banking Of Firm </Typography>
                  </div>
                </div>
                <div>
                {bankingOfFirm?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type|| doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'banking_of_firm')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload Electricity Bill</Typography>
                  </div>
                </div>
                <div>
                {electricityBill?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'electricity_bill')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload GSTR1 </Typography>
                  </div>
                </div>
                <div>
                {gstr1?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'gstr1')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload GSTR3 </Typography>
                  </div>
                </div>
                <div>
                {gstr3?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'gstr3')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload ICAT</Typography>
                  </div>
                </div>
                <div>
                {icat?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'icat')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload Personal Banking Of Proprietor</Typography>
                  </div>
                </div>
                <div>
                {personalBankingOfProprietor?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'personal_banking_of_proprietor')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
              <Grid item xs={1} sm={1} sx={{textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <div>
                    <Typography>Upload LOI</Typography>
                  </div>
                </div>
                <div>
                {loi?.map(doc => <span style={{fontSize: '10px'}}><Button>{doc.file_type||doc.name}<Button style={{color:'red',minWidth:'40px',borderRadius:'50%'}} onClick={() => fileDrop(doc,'loi')}>X</Button></Button>, </span>)}
                </div>
              </Grid>
            </Grid>
          </Box>
        }
        {uploadDocs &&
           <div style={{textAlign:"-webkit-center"}}>
           {!show && <h1 style={{marginBottom:'20px',fontFamily:'Times New Roman'}}> Preview Your Documents</h1>}
            {show && <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={4}>
            <Card style={{maxWidth:'400px',height:'390px',minWidth:'300px'}}>
                <h1 style={{marginBottom:'20px',fontFamily:'Times New Roman'}}> Upload Files Here</h1>
            <div
                style={{position:'relative',maxWidth:'500px',height:'300px',minWidth:'300px',border:"2px dashed lightsteelblue",borderRadius:'20px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'var(input-bg)'}}
                ref={wrapperRef}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div style={{textAlign:'center',fontWeight:'400px',padding:'10px'}}>
                    <img src={uploadImg} alt="" />
                    <p>Drag & Drop your files here</p>
                </div>
                <input accept='image/*,application/pdf,.jpg,.jpeg' style={{opacity: 0,position: "absolute",top: 0,left:0,width: "100%",height:'100%',cursor: "pointer"}} type="file" multiple onChange={(e)=>onFileDrop(e)}/>
            </div>
            </Card>
            </Grid>
            </Grid>
            </Box>
            }
            {
              show && fileList.length > 0 &&
                  
                    <div style={{marginTop:'30px'}}>
                         <p style={{marginBotton:'20px'}}>
                            Ready to upload
                        </p> 
                              
                            {
                            fileList.map((item, index) => ( 
                                <>   
                                  {show && <div key={index} style={{position:'relative',display:'flex',marginBottom:'10px',padding:'15px',borderRadius:'20px',width:'400px',backgroundColor:'lightsteelblue'}}>
                                   <img style={{width:'50px',marginRight:'10px',height:'48px'}} src={ImageConfig[item.type.split('/')[1]] ||  ImageConfig['default']} alt="" />
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between',width:'230px'}}>  
                                        <p>Name-{item.name}</p>
                                        <p>Size-{item.size}B</p>
                                    </div>
                                     <span 
                                    style={{width:'40px',height:'40px',borderRadius:'50%',display:'flex',alignItems:'center',justifyContent:'center',position:'absolute',right:'10px',top:'50%',transform:'translateY(-50%)',cursor:'pointer',transition:'opacity 0.3s ease',backgroundColor:'white'}}
                                    onClick={() => fileRemove(item)}>x</span>
                                </div>
                                }
                                {show &&
                                 <Box sx={{ display: "flex", justifyContent: "center" }}>
                                 <Box sx={{ width: "20%", mr: 1 }}>
                                   <LinearProgress variant="determinate" value={progress} />
                                 </Box>
                                 <Box sx={{ minWidth: 35 }}>
                                   <Typography variant="body2" color="text.secondary">{progress}%</Typography>
                                 </Box>
                               </Box>
                              }


                               {!show && <>
                                <Grid item xs={4} sm={3}>
                                <Card sx={{ boxShadow: '1px 2px 9px grey',width:'fit-content'}}> 
                               <select style={{width:'210px',borderRadius:'7px',height:'25px',marginBottom:'4px',backgroundColor:'white',marginTop:'4px'}} onChange={(e)=>handleChange(e,item)}>
                               <option value="">Choose Document</option>
                              <option value="dealer_kyc_aadhar" >Aadhaar</option>
                              <option value="dealer_kyc_pan">Pan</option>
                              <option value="dealer_gst_registration">Gst Registration</option>
                              <option value="dealer_rent_agreement">Rent Agreement</option>
                              <option value="dealer_agreement">Agreement </option>
                              <option value="dealer_udhyog_aadhar">Udhyog Aadhaar</option>
                              <option value="model_list">Model List</option>
                              <option value="trade_certificate"> Trade Certificate</option>
                              <option value="loi"> LOI</option>
                              <option value="banking_of_firm">Banking of firm (1 year atleast)</option>
                              <option value="personal_banking_of_proprietor">Personal Banking of Proprietor (1 year atleast)</option>
                              {/* <option value="lat">Lat/Long of Outlet with complete address with Landmark</option> */}
                              <option value="electricity_bill">Electricity Bill</option>
                              <option value="gstr1">GSTR1</option>
                              <option value="gstr3">GSTR3</option>
                              <option value="icat">ICAT</option>
                               </select>
                               <div style={{width:'230px'}}>
                                  <img style={{width:'150px',height:'150px'}} src={item?.preview || ImageConfig[item.type.split('/')[1]]} alt="" />
                                    <p>Name-{item.name}</p>
                                    <p>Size-{item.size}B</p>
                                </div>
                                </Card>
                                </Grid>
                                
                               </>
                               }
                               
                                </>
                                
                            ))
                        }
                    </div>                     
                }
                {!show && fileList.length > 0 &&
                  
                  <div style={{marginTop:'30px'}}>   
                  <Grid container spacing={1} justifyContent='center'>                        
                          { 
                          fileList.map((item, index) => ( 
                              <>   
                                {show && <div key={index} style={{position:'relative',display:'flex',marginBottom:'10px',padding:'15px',borderRadius:'20px',width:'400px',backgroundColor:'lightsteelblue'}}>
                                 <img style={{width:'50px',marginRight:'10px',height:'48px'}} src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" />
                                  <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between',width:'230px'}}>  
                                      <p style={{marginTop:'5px'}}>{item.name}</p>
                                  </div>
                                   <span 
                                  style={{width:'40px',height:'40px',borderRadius:'50%',display:'flex',alignItems:'center',justifyContent:'center',position:'absolute',right:'10px',top:'50%',transform:'translateY(-50%)',cursor:'pointer',transition:'opacity 0.3s ease',backgroundColor:'white'}}
                                  onClick={() => fileRemove(item)}>x</span>
                              </div>
                              }
                              {show &&
                               <Box sx={{ display: "flex", justifyContent: "center" }}>
                               <Box sx={{ width: "20%", mr: 1 }}>
                                 <LinearProgress variant="determinate" value={progress} />
                               </Box>
                               <Box sx={{ minWidth: 35 }}>
                                 <Typography variant="body2" color="text.secondary">{progress}%</Typography>
                               </Box>
                             </Box>
                            }


                             {!show && <>
                              <Grid item xs={12} sm={3}>
                              <Card sx={{ boxShadow: '1px 2px 9px grey',width:'fit-content',borderRadius:'10px'}}> 
                             <select style={{width:'210px',borderRadius:'5px',height:'25px',marginBottom:'8px',backgroundColor:'white',marginTop:'4px',borderColor:'darkgrey'}} onClick={(e)=>handleChange(e,item)}>
                              <option value="">Choose Document</option>
                              <option value="dealer_kyc_aadhar">Aadhaar</option>
                              <option value="dealer_kyc_pan">Pan</option>
                              <option value="dealer_gst_registration">Gst Registration</option>
                              <option value="dealer_rent_agreement">Rent Agreement</option>
                              <option value="dealer_agreement">Agreement </option>
                              <option value="dealer_udhyog_aadhar">Udhyog Aadhaar</option>
                              <option value="model_list">Model List</option>
                              <option value="trade_certificate"> Trade Certificate</option>
                              <option value="loi"> LOI</option>
                              <option value="banking_of_firm">Banking of firm (1 year atleast)</option>
                              <option value="personal_banking_of_proprietor">Personal Banking of Proprietor (1 year atleast)</option>
                              {/* <option value="lat">Lat/Long of Outlet with complete address with Landmark</option> */}
                              <option value="electricity_bill">Electricity Bill</option>
                              <option value="gstr1">GSTR1</option>
                              <option value="gstr3">GSTR3</option>
                              <option value="icat">ICAT</option>
                             </select>
                             <div style={{width:'230px'}}>
                                <img style={{width:'150px',height:'150px'}} src={item?.preview || ImageConfig[item.type.split('/')[1]]} alt="" />
                                  <p style={{marginTop:'5px'}}>{item.name}</p>
                              </div>
                              </Card>
                              </Grid>
                              
                             </>
                             }
                             
                              </>
                              
                          ))
                      }
                     
                    </Grid> 
                  </div>
              }
      
        </div>
  }
    {uploadDocs &&show && !isEmpty(fileList) &&
        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={4} sx={{textAlign:'center'}}>
            <Button
                style={{width:'170px'}}
                size="large"
                variant="contained"
                onClick={()=>setShow(false)}
                
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
        }
        
        {((!uploadDocs) || (!show)) &&
        <Box sx={{m: 2}}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={8} sm={4}>
          <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={formik.isSubmitting}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>   }
         
  
  </form>
  </div>
  )
}