import {filter, get, includes, isEmpty} from 'lodash';
import {useContext, useEffect,useState} from 'react';
import { useSnackbar } from 'notistack';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    TextField,
    Tooltip,
    Box,
    Grid,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL, DEALER_LEDGER_MAIN_TYPES, FILE_UPLOAD_TYPE} from '../constant';
import moment from 'moment';
 import Styles from '../css/filter.module.css'
import { LoadingButton } from '@mui/lab';
import CsvDownloader from 'react-csv-downloader';
import Style from '../css/dates.module.css'
import { LoginContext } from '../context/logincontext';
import { isAdminRole } from '../utils/helper';
import SelectOptionSearch from './Alert/SelectOptionSearch';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
    {id: 'ledger_date',label: 'Ledger Date',alignRight: false},
    {id: 'loan_id',label: 'Loan Id',alignRight: false},
    {id: 'bulk_loan_ids',label: 'Bulk Loan Id',alignRight: false},
    {id: 'customer',label: 'Customer Name',alignRight: false},
    {id: 'loan_ledger_type',label: 'Txn Type',alignRight: false},
    {id: 'amount',label: 'Amount',alignRight: false},
    {id: 'cumlative_amount',label: 'Balance',alignRight: false},
    {id: 'payment_type',label: 'Payment Type',alignRight: false},
    {id: 'payment_ref_no',label: 'UTR',alignRight: false},
];

const CSV_HEAD = [
    {id: 'ledger_date', displayName: 'Ledger Date',alignRight: false},
    {id: 'loan_id', displayName: 'Loan Id',alignRight: false},
    {id: 'bulk_loan_ids',label: 'Bulk Loan Id',alignRight: false},
    {id: 'loan_ledger_type', displayName: 'Txn Type',alignRight: false},
    {id: 'amount', displayName: 'Amount',alignRight: false},
    {id: 'balance', displayName: 'Balance',alignRight: false},
    {id: 'payment_type', displayName: 'Payment Type',alignRight: false},
    {id: 'payment_ref_no', displayName: 'UTR',alignRight: false},
];

// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
    if(b[orderBy] < a[orderBy]) {
        return -1;
    }
    if(b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order,orderBy) {
    return order === 'desc'
        ? (a,b) => descendingComparator(a,b,orderBy)
        : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
    const stabilizedThis = array?.map((el,index) => [el,index]);
    stabilizedThis?.sort((a,b) => {
        const order = comparator(a[0],b[0]);
        if(order !== 0) return order;
        return a[1] - b[1];
    });

    if(query) {
        if(isNaN(query)) {
            const filteringArray = array.filter(user => user?.loan !== null)
            return filter(filteringArray,(_user) => _user?.loan?.customer_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
        }
        else {
            const filteringArray = array.filter(user => user?.loan_id !== null)
            return filter(filteringArray,(_user) => ((_user?.loan_id).toString()).toLowerCase().indexOf(query.toLowerCase()) !== -1)
        }
    }
    return stabilizedThis.map((el) => el[0]);
}

let loanId = ''
let ledgerType = ''
let paymentType = ''
let utr = ''
let date = ''
let customerName=''

export default function RcLedger() {
    const [page,setPage] = useState(0);
    const [value,setValue] = useState([]);
    const [order,setOrder] = useState('asc');
    const [selected,setSelected] = useState([]);
    const [orderBy,setOrderBy] = useState('name');
    const [filterName,setFilterName] = useState('');
    const [rowsPerPage,setRowsPerPage] = useState(25);
    const [filteredValue,setFilteredValue] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [fromDate , setFromDate]=useState(null)
    const [toDate , setToDate] = useState(null)
    const { enqueueSnackbar } = useSnackbar();
    const context = useContext(LoginContext)
    const dealerId=context?.user?.company?.dealer_id
    const [allDealer,setAllDealer] = useState([])
    const [selectedDealerId , setSelectedDealerId] = useState(null)


    const handleRequestSort = (event,property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        setSelected([]);
    };

    useEffect(() => {
        async function getAllDealer(){
            const res= await axios.get(`${API_URL}/los_all_dealer`);
            if(res.data.status === 'success') {
                const allDealers = res?.data?.data
                setAllDealer([...allDealers])
            }
        }
        if(isAdminRole(context?.user)){
            getAllDealer()
        }
        LoanLedger(dealerId)
    },[context?.user]);

    async function LoanLedger(id){
        let finance = []
        if(id && id !== dealerId){
            finance = await axios.get(`${API_URL}/loan_ledger/${id}`);
        }else{
            finance = await axios.get(`${API_URL}/finance_ledger`);
        }
        if(finance.data.status === 'success') {
            const financeLedger = get(finance, 'data.data.dealerTALedgerData', [])
            let rcLedger=[]
            let allBalance=0
            for (let ledgerData of financeLedger) {
                if(includes([DEALER_LEDGER_MAIN_TYPES.RC_PAYMENT_DUE,DEALER_LEDGER_MAIN_TYPES.RC_OPENING_BALANCE_APRIL_2023],ledgerData.loan_ledger_type) || (ledgerData.loan_ledger_type===DEALER_LEDGER_MAIN_TYPES.DEALER_PAYMENT && ledgerData.ledger_sub_type===DEALER_LEDGER_MAIN_TYPES.RC_PAYMENT)){
                    if(ledgerData.loan_ledger_type===DEALER_LEDGER_MAIN_TYPES.DEALER_PAYMENT){
                        ledgerData.loan_ledger_type=DEALER_LEDGER_MAIN_TYPES.RC_PAYMENT
                    }
                    allBalance +=parseInt(ledgerData.amount, 10);
                    rcLedger.push({...ledgerData,balance:allBalance})
                }
            }
            setValue([...rcLedger]);
            setFilteredValue([...rcLedger]);
            setSelectedDealerId(id)
        }
    }
   
    useEffect(() => { 
        async function getViewCount() {
        const getCount = await axios.get(`${API_URL}/view_count/${dealerId}`);
        let count =getCount?.data?.data?.view_count || 0;
        let data={
            count:count
        }
        const updateCount = await axios.post(`${API_URL}/view_count/${dealerId}`,data);
        if(updateCount.data.status === 'success') {
            console.log("Count increased");
        }
    }
    getViewCount() 
    }, [])
    

    const handleChangePage = (event,newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value,10));
        setPage(0);
    };



    const handleFilter = async(e) => {
        if(e.target.name === 'loan_id') {
            loanId = e.target.value
        }
        else if(e.target.name === 'date') {
            date =e.target.value
        }
        else if(e.target.name === 'utr') {
            utr = e.target.value
        }
        else if(e.target.name === 'ledger_type') {
            ledgerType = e.target.value
        }
        else if(e.target.name === 'payment_type') {
            paymentType = e.target.value
        }
        else if(e.target.name === 'customer_name'){
            customerName=e.target.value
        }
        const filteredData = value.filter(v => ((String(v?.loan_id) || '').toLowerCase()).includes((String(loanId)).toLowerCase()) && ((v?.loan_ledger_type || '').toLowerCase()).includes((ledgerType).toLowerCase()) && ((v?.payment_type || '').toLowerCase()).includes((paymentType).toLowerCase()) && ((v?.payment_ref_no || '').toLowerCase()).includes((utr).toLowerCase()) && (moment(v?.ledger_date).format('DD-MM-YYYY') || '').toLowerCase().includes(String((date)).toLowerCase()) && ((v?.loan?.customer_name || '').toLowerCase()).includes((customerName).toLowerCase()))
        setFilteredValue([...filteredData])
        setPage(0)
    };

    const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;
    const filteredUsers = applySortFilter(filteredValue,getComparator(order,orderBy),filterName);
    const isUserNotFound = value.length === 0;

    const handleChecked = () => {
        setIsChecked(!isChecked)
    }

    const handleChange = (e) => {
        if(e.target.name === 'from_date'){
          setFromDate(e.target.value)
        }else{
          setToDate(e.target.value)
        }
      }


    const getDataByDate = () => {
        setPage(0)
        if (fromDate===null && toDate===null) {
            enqueueSnackbar(`Select Date First`, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
        }
        else{
        const fDate = new Date(fromDate).getTime()
        const tDate = new Date(toDate).getTime()
          const filterData = value.filter(data => (fDate <= (new Date(data.ledger_date)).getTime() && (new Date(data.ledger_date)).getTime() <=  tDate))
          setFilteredValue([...filterData])
        }
    }

    useEffect(()=>{
        if(isChecked){
            const securityFinaceLedger = value.filter((dealerLedger) => dealerLedger.payment_type === 'dealer security payment');
            setValue([...securityFinaceLedger])
        }else{
            setValue([...filteredValue])
        }
    },[isChecked])
    const adjustAmount = (amount) => {
        if(amount === 0){
            return amount
        }
        if(amount) {
          let x = amount;
          x = x?.toString();
          let lastThree = x?.substring(x.length - 3);
          let otherNumbers = x?.substring(0,x.length - 3);
          if(otherNumbers !== '')
            lastThree = ',' + lastThree;
          let res = otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g,",") + lastThree;
          return res
        }
      }

    const downloadLegder = async(type)=>{
        const result= await axios.get(`${API_URL}/dealer_docs/${selectedDealerId}/${type}`)
        const data= get(result,'data.data','')
        if(!isEmpty(data)){
            window.open(data)
        }else{
            enqueueSnackbar(`No Documents Found`, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'},autoHideDuration:1000 });
        }
    }  

    return (
        <Page>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Rc Ledger
                    </Typography>
                </Stack>
                {Boolean(filteredValue[filteredValue?.length-1]?.balance) &&
                <div style={{marginBottom: '10px',padding: '10px', boxShadow: '#00000030 0px 0px 10px', display: 'inline-block', borderRadius: '10px'}}>
                    <div style={{fontSize: '1.2rem' }}>Current Balance : {filteredValue[filteredValue?.length-1]?.balance < 0 ? <Tooltip title={"Ascend Capital Owes To Me"}><div style={{ height: '20px', display: 'inline-block', cursor: 'pointer'}}>{adjustAmount(Math.abs((filteredValue[filteredValue?.length-1]?.balance))) + ' DR'}</div></Tooltip>  : <Tooltip title={"I Owe To Ascend Capital"}><div style={{ height: '20px', display: 'inline-block', cursor: 'pointer'}}>{adjustAmount(Math.abs((filteredValue[filteredValue?.length-1]?.balance))) + ' CR'}</div></Tooltip>}</div>
                </div>
                }
                {isAdminRole(context?.user) && <div style={{paddingLeft:100,display:'inline-block',minWidth:400}}>
                    <span>
                        <SelectOptionSearch submit={(dealerId)=>LoanLedger(dealerId)} {...{label_name:'Dealer Name',search_name:'Search Dealer Name',data:[...allDealer]}} />
                    </span>
                </div>}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='left'>
          <Grid item xs={4} sm={2.5}>
            <TextField
                id="from_date"
                type="date"
                autoComplete='off'
                name="from_date"
                label="From Date"
                className={fromDate === null && Style.dates}
                onChange={handleChange}
                value={fromDate}
            />
          </Grid>
          <Grid item xs={4} sm={2.5}>
            <TextField
                id="to_date"
                type="date"
                autoComplete='off'
                name="to_date"
                label="To Date"
                className={toDate === null && Style.dates}
                onChange={handleChange}
                value={toDate}
            />
          </Grid>
          <Grid item xs={4} sm={1.8}>
            <LoadingButton size="large" type="submit" variant="contained" onClick={getDataByDate}><span>Submit Date</span></LoadingButton>
          </Grid>
          {!isEmpty(value) &&<Grid item xs={4} sm={2}>
            <CsvDownloader
                filename={`${"RC Ledger"}`}
                extension=".excel"
                separator=";"
                wrapColumnChar=""
                columns={CSV_HEAD}
                datas={[...filteredUsers]}
            >
            <LoadingButton size="large" color='secondary' type="submit" variant="contained"><i className="bi bi-bar-chart-fill"></i>Export Data</LoadingButton>
          </CsvDownloader>
          </Grid>}
          <Grid item xs={4} sm={2.5}>
            <LoadingButton size="large" type="submit" variant="contained" onClick={() => downloadLegder(FILE_UPLOAD_TYPE.DEALER_RC_LEDGER)}><span>Download Ledger - 31/03/2023</span></LoadingButton>
          </Grid>
          </Grid>
          </Box>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{width: 'auto'}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={filteredUsers.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableRow>
                                    <TableCell align="center"><TextField variant='standard' type='search' name='date' onChange={handleFilter} autoComplete='off' /></TableCell>
                                    <TableCell align="center"><TextField variant='standard' type='search' name = 'loan_id' onChange={handleFilter} autoComplete='off' /></TableCell>
                                    <TableCell align="center"><TextField variant='standard' type='search' name='customer_name' onChange={handleFilter} autoComplete='off' /></TableCell>
                                    <TableCell align="center"><TextField variant='standard' type='search' name='ledger_type' onChange={handleFilter} autoComplete='off' /></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="center"><TextField variant='standard' type='search' name='payment_type' onChange={handleFilter} autoComplete='off' /></TableCell>
                                    <TableCell align="center"><TextField variant='standard' type='search' name='utr' onChange={handleFilter} autoComplete='off' /></TableCell>
                                </TableRow>
                                <TableBody>
                                    {filteredUsers?.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)?.map((v,index) => {
                                        return (
                                            <TableRow
                                                hover
                                                key={index}
                                            >   
                                                <TableCell align="center" style={{width:'120px'}}>{moment(v?.ledger_date).format('DD-MM-YYYY') ||''}</TableCell>
                                                <TableCell align="center" style={{width:'100px'}}>{v?.loan_id}</TableCell>
                                                <TableCell align="center" style={{width:'120px'}}>{v?.bulk_loan_ids?.toString()}</TableCell>
                                                <TableCell align="center" style={{width:'120px'}}>{v?.loan?.customer_name}</TableCell>
                                                <TableCell align="center" style={{width:'200px'}}><div style={{overflow: 'hidden', height: '20px', display: 'inline-block'}}>{(v?.loan_ledger_type).toUpperCase()}</div></TableCell>
                                                <TableCell align="center" style={{width:'120px'}}>{v?.amount < 0 ? <Tooltip title={"Ascend Capital Owes To Me"}><div style={{overflow: 'hidden', height: '20px', display: 'inline-block', cursor: 'pointer'}}>{adjustAmount(Math.abs((v?.amount))) + ' DR'.slice(0,6)}</div></Tooltip>  : <Tooltip title={"I Owe To Ascend Capital"}><div style={{overflow: 'hidden', height: '20px', display: 'inline-block', cursor: 'pointer'}}>{adjustAmount(Math.abs((v?.amount))) + ' CR'.slice(0,6)}</div></Tooltip>}</TableCell>
                                                <TableCell align="center" style={{width:'140px'}}> {v?.balance < 0 ? <Tooltip title={"Ascend Capital Owes To Me"}><div style={{overflow: 'hidden', height: '20px', display: 'inline-block', cursor: 'pointer'}}>{adjustAmount(Math.abs((v?.balance))) + ' DR'.slice(0,6)}</div></Tooltip>  : <Tooltip title={"I Owe To Ascend Capital"}><div style={{overflow: 'hidden', height: '20px', display: 'inline-block', cursor: 'pointer'}}>{adjustAmount(Math.abs((v?.balance))) + ' CR'.slice(0,6)}</div></Tooltip>}</TableCell>   
                                                <TableCell align="center" style={{width:'130px'}}><div style={{maxWidth: '70%', overflow: 'hidden', height: '20px', display: 'inline-block'}}>{(v?.payment_type)?.toUpperCase()}</div></TableCell>
                                                <TableCell align="center" style={{width:'100px'}}><Tooltip title={v?.payment_ref_no}><div style={{overflow: 'hidden', height: '20px', display: 'inline-block', cursor: 'pointer'}}>{v?.payment_ref_no ? (v?.payment_ref_no).slice(0,6) :''}...</div></Tooltip></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[25,50,100,500]}
                        component="div"
                        count={value.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}

