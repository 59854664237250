import { isEmpty } from 'lodash';
import React, {createContext, useState, useEffect} from 'react'
import axios from 'axios'
import { API_URL } from '../constant';


export const LoginContext = createContext()

function LoginContextProvider(props) {
    const [mobile, setMobile] = useState('')
    const [user, setUser] = useState({})
    useEffect(() => {
      async function getUserData(){
        if(isEmpty(user)){
          const response = await axios.get(`${API_URL}/user`);
          const userData = response?.data?.data
          if(userData){
            setUser(userData)
          }
        }
      }
     getUserData() 
    },[]);
  return (
    <LoginContext.Provider value={{
        mobile, setMobile, user, setUser
    }}>
        {props.children}
    </LoginContext.Provider>
  )
}

export default LoginContextProvider