export function isAdminRole (user) {
    return user.company_id ===1
}

export function isRegister (user) {
    return user.master_user_id ===1
}

//Capitalize the first letter of each word in a string
export function firstLetetrUpperOfString(str = '', separator = " "){
    // const str = 'i have learned something new today';

    //split the above string into an array of strings 
    //whenever a blank space is encountered<<<<<<<<saADFSGDHFJGKGFHDGFSEDA

    const arr = str.split(`${separator}`);

    //loop through each element of the array and capitalize the first letter.


    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }

    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    const str2 = arr.join(" ");
    return str2

    //Outptut: I Have Learned Something New Today
}

// firstLetetrUpperOfString('co-applicant', '-')

export function isServiceCenter (user) {
    return user.company_id ===333
}