import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Link
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL} from '../constant';
import Styles from '../css/filter.module.css'
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
// ----------------------------------------------------------------------

let TABLE_HEAD = [
    { id: 'vendor_name', label: 'Name', alignRight: false },
    { id: 'primary_mobile', label: 'Mobile', alignRight: false },
    { id: 'owner_name', label: 'Owner Name', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'company_type', label: 'Company Type', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: 'primary_email', label: 'Email', alignRight: false },
    { id: 'city', label: 'City', alignRight: false },
  ];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

  let vendorName=''
  let ownerName=''
  let companyType=''
  let address=''
  let primaryMobile=''
  let category=''

export default function VendorList() {
  const [page, setPage] = useState(0);
  const[value,setValue]=useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    setSelected([]);
  };

  useEffect(() => {
    async function get() {
      const res= await axios.get(`${API_URL}/vendor`);
      setValue(res.data.data);
      setFilteredValue(res.data.data);
    }
    if(isMobile){
      TABLE_HEAD.length = 2
      TABLE_HEAD = [...TABLE_HEAD,{ id: 'city', label: 'City', alignRight: false }]
    }
    else{
      TABLE_HEAD.length = 8
    }
    get()
  },[]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const handleFilter = (e) => {
    if(e.target.name === 'vendor_name'){
      vendorName = e.target.value
    }
    else if(e.target.name === 'owner_name'){
      ownerName =e.target.value
    }
    else if(e.target.name === 'company_type'){
      companyType =e.target.value
    }
    else if(e.target.name === 'address'){
      address =e.target.value
    }
    else if(e.target.name === 'primary_mobile'){
      primaryMobile =e.target.value
    }
    else if(e.target.name === 'category'){
      category =e.target.value
    }
    filteredValue = value.filter(v => ((v?.vendor_name || '').toLowerCase()).includes((vendorName).toLowerCase()) && ((v?.owner_name || '').toLowerCase()).includes((ownerName).toLowerCase()) && ((v?.company_type || '').toLowerCase()).includes((companyType).toLowerCase()) && ((v?.address || '').toLowerCase()).includes((address).toLowerCase()) && ((v?.primary_mobile || '').toLowerCase()).includes((primaryMobile).toLowerCase()) && ((v?.category || '').toLowerCase()).includes((category).toLowerCase()))
    setFilteredValue([...filteredValue])
  };

const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(filteredValue, getComparator(order, orderBy));
  const isUserNotFound = value.length === 0;
  

  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Supplier List
          </Typography>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/supplier" underline="none">
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-person-lines-fill"></i> Add Supplier</LoadingButton>
          </Link>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                <TableRow>
                    <TableCell align="left"><TextField className={Styles.filter} name='vendor_name' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='primary_mobile' onChange={handleFilter} autoComplete='off' /></TableCell>
                    {!isMobile && <>
                    <TableCell align="left"><TextField className={Styles.filter} name='owner_name' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='category' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='company_type' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell align="left"><TextField className={Styles.filter} name='address' onChange={handleFilter} autoComplete='off' /></TableCell>
                    <TableCell></TableCell>
                    </>}
                  </TableRow>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((v,index)=>{
                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell align="left">{v.vendor_name}</TableCell>
                          <TableCell align="left">{v.primary_mobile}</TableCell>
                          {!isMobile && <>
                          <TableCell align="left">{v.owner_name}</TableCell>
                          <TableCell align="left">{v.category}</TableCell>
                          <TableCell align="left">{v.company_type}</TableCell>
                          <TableCell align="left">{v.address}</TableCell>
                          <TableCell align="left">{v.primary_email}</TableCell>
                          </>}
                          <TableCell align="left">{v.city}</TableCell>
                          {/* <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(status === 'banned' && 'error') || 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell> */}

                          <TableCell align="right">
                            <UserMoreMenu linkTo={`/dashboard/supplier/${v.vendor_id}/edit`}
                            view={`/dashboard/vendors`}/>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
