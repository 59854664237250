// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Link, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
//
import Iconify from '../../../components/Iconify';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../constant';
import { LoadingButton } from '@mui/lab';
import {  Link as RouterLink } from 'react-router-dom';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 234;

export default function AppBugReports() {
  const [total,setTotal]=useState(0)

  useEffect(()=>{
    async function get() {
      const res= await axios.get(`${API_URL}/parts`);
      let data = res.data.data
      data = data.filter(d=>Number(d?.min_qty) >= Number(d?.qty))
     setTotal(data.length);
    }
    get()
    },[])
  return (
    <RootStyle>
      <IconWrapperStyle>
      <i className="bi bi-bag-check" style={{fontSize: '30px'}}></i>
      </IconWrapperStyle>
      <div style={{display:'inline-flex'}}>
      <Typography variant="h3">{fShortenNumber(total)}</Typography><Typography variant="subtitle1" sx={{ opacity: 0.72 }} style={{paddingTop:'15px',paddingLeft:'10px'}}>
        Low Stock Alert
      </Typography> 
      </div>
      <Link style={{display:'block'}} variant="subtitle1"  component={RouterLink} to="/dashboard/product" underline="none">
     <LoadingButton style={{color:'#7A0C2E',backgroundColor:'inherit',border:'none',boxShadow:'0 0 10px #7A0C2E'}}>Add Model</LoadingButton>
      </Link>
    </RootStyle>
  );
}
