import PropTypes from 'prop-types';
import {useEffect,useRef,useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {alpha} from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Paper
} from '@mui/material';
// utils
// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import axios from 'axios';
import {UPDATE_NOTIFICATION, GET_NOTIFICATION} from '../../ApiURL';
import {isEmpty} from 'lodash';
import moment from 'moment'
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

// ----------------------------------------------------------------------

function renderContent(notification) {
  let subHeader =[]
  if(!isEmpty(notification)){
    const text = notification.text
    subHeader = text.split(',')
  }
  const title = 
    (!isEmpty(notification)) && (
      <Typography variant="subtitle2">
        {notification?.name} <br></br>
        {subHeader.map((text) =>{
            return (<>
              <Typography component="span" variant="body2" sx={{color: 'text.secondary'}}>
                {text}
              </Typography><br></br>
              </>)})
            }
      </Typography>
    );
  return {
    title,notifications: notification
  }
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({notification, onClick}) {
  const {title,notifications} = renderContent(notification);

  return (
    <ListItemButton
      to={`/dashboard/lead/${notification.type_id}/status`}
      disableGutters
      onClick={()=>onClick(notification.id)}
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.last_view && {
          bgcolor: 'blanchedalmond'
        }),
        color:'green'
      }}
    >
      <ListItemAvatar>
      {/* <Avatar sx={{bgcolor: 'background.neutral'}}><img alt={'.....'} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZYg42F-WfofnkBdmKu5E9Qeu-8nYtlz4U6Q&usqp=CAU" /></Avatar> */}
      {(notification.type === 'Lead') ? <LeaderboardIcon /> : ''}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Iconify icon="eva:clock-fill" sx={{mr: 0.5,width: 16,height: 16}} />
            {moment(notifications.time).format('MMMM Do YYYY, h:mm:ss a')}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover(props) {
  const anchorRef = useRef(null);
  const [open,setOpen] = useState(false);
  const [notifications,setNotifications] = useState([]);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;
  const [newRemarks, setNewRemarks] = useState(null)

  const handleOpen = async () => {
    setOpen(true);
    const remarks = await axios.post(`${UPDATE_NOTIFICATION}`,{last_seen:true});
    if(remarks.data.status === 'success') {
      setNewRemarks(null)
    }
  };

  const handleClose = async (notificationId) => {
    if(!isNaN(notificationId)){
      const remarks = await axios.post(`${UPDATE_NOTIFICATION}`,{notification_id:notificationId,last_view:true});
    }
    setOpen(false);
  };

  const clearRemarks = async () => {
    const remarks = await axios.post(`${UPDATE_NOTIFICATION}`,{last_seen:true,is_delete:true});
    if(remarks.data.status === 'success') {
      setNotifications([])
      setNewRemarks(null)
      setOpen(false);
    }
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false
      }))
    );
  };

  const getRemarks = async () => {
    const data = await axios.get(`${GET_NOTIFICATION}`)
    if(data.data.status === 'success'){
      const notificationData=data?.data?.data
      if(!isEmpty(notificationData)){
        const seenNotification = notificationData.filter(notification=> notification.last_seen === false)
        setNewRemarks(seenNotification.length)
        setNotifications([...notificationData])
      }
    }
  }
  useEffect(() => {
    getRemarks()
  },[open])
  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main,theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={newRemarks} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{width: 360}}
        
      >
        <Box sx={{display: 'flex',alignItems: 'center',py: 2,px: 2.5}}>
          <Box sx={{flexGrow: 1,display: 'flex',justifyContent: 'space-between'}}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Tooltip title="Clear all">
              <div style={{cursor: 'pointer'}} onClick={clearRemarks}>
                <Iconify icon="fluent:delete-20-filled" width={20} height={20} color='red' />
              </div>
            </Tooltip>
          </Box>
          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Divider />
        <Paper style={{maxHeight: 400, overflow: 'auto'}}>
          <List
          >
            {[...notifications].map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClick={(id)=>handleClose(id)} />
            ))}
          </List>
        </Paper>
        <Divider />
      </MenuPopover>
    </>
  );
}
