import React from "react";

// Define styles as a JavaScript object
const spinnerStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
    zIndex: 9999,
  },
  spinner: {
    border: "8px solid rgba(0, 0, 0, 0.1)",
    borderLeftColor: "#ffffff",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 1s linear infinite",
  },
  // Add keyframes as a CSS string and inject into the document
  spinnerKeyframes: `
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `,
};

const injectKeyframes = () => {
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(spinnerStyles.spinnerKeyframes, styleSheet.cssRules.length);
};

const LoadingSpinner = () => {
  injectKeyframes(); // Inject the keyframes when the component is rendered

  return (
    <div style={spinnerStyles.overlay}>
      <div style={spinnerStyles.spinner}></div>
    </div>
  );
};

export default LoadingSpinner;
