import { useContext, useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {LoginContext} from '../context/logincontext';
// material
import { Dialog, Stack, Typography, Box, Grid, TextField, MenuItem, TableContainer, Table, TableBody, TableRow, TableCell, TablePagination, Card, LinearProgress,styled, ClickAwayListener,Button, getFormControlLabelUtilityClasses } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// component
import axios from 'axios';
import {API_URL, BATTERY_WARRANTY, FILE_UPLOAD_TYPE, TVR_TYPE} from '../constant';
// import NachForm from './NachForm';
import { filter, get, isEmpty } from 'lodash';
import ConfirmDialog from '../components/confirmDialog';
import { UserListHead } from '../sections/@dashboard/user';
import SearchNotFound from '../components/SearchNotFound';
import { isMobile } from 'react-device-detect';
import Styles from '../css/filter.module.css'
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import FileUpload from './FIleUpload';

axios.defaults.withCredentials = true
// ----------------------------------------------------------------------
const validationSchema = yup.object({
  relation_id:yup.number().required('field cannot be empty'),
  relation_type:yup.string().required('field cannot be empty'),
  reference_mobile: yup.number().required('field cannot be empty').test('len', 'Mobile number must be exactly 10 digits', val => val && val.toString().length === 10),
  tvr_type: yup.string().required('field cannot be empty'),
  loan_amount: yup.number().required('field cannot be empty'),
  tenure: yup.number().required('field cannot be empty'),
  emi_amount: yup.number().required('field cannot be empty'),
  battery_warranty:yup.number().required('field cannot be empty')
});

const Input = styled('input')({
    display: 'none',
  });

const TABLE_HEAD = [
    { id: 'reference_name', label: 'Name', alignLeft: true },
    { id: 'reference_mobile', label: 'Mobile' },
    { id: 'type', label: 'Type' },
    { id: 'verified', label: 'Verified' },
    { id: 'remarks', label: 'Remarks' },
    { id: 'upload', label: 'Upload' },
    { id: 'download', label: 'Download' }
  ];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis?.map((el) => el[0]);
  }

let referenceName=''
let referenceMobile=''

export default function TvrDetails() {
  const {id} =useParams()
  const[lead,SetLead]=useState()
  const [addForm, setAddForm] = useState(false)
  const context = useContext(LoginContext)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterName, setFilterName] = useState('');
  const [formvalue, setFormValue] = useState({})
  const[members,setMembers]=useState([])
  const [disable, setDisable] = useState(false)
  const [open, setOpen] = useState(false)
  const[value,setValue]=useState([]);
  let [filteredValue,setFilteredValue] = useState([]);
  const [tvrFile, setTvrFile] = useState([])
  const [progress,setProgress]= useState(0)
  const [uploadModel,setUploadModel] = useState(false)
  const [selectedTvr,setSelectedTvr] = useState(false)
  const [mobilePresent,setmobilePresent]=useState(false)
  const formik = useFormik({
    initialValues: {
      loan_amount:'',
      status:'pending',
      tenure:'',
      emi_amount:'',
      first_emi_date:null,
      source:'partners_web',
      relation_id:null,
      relation_type:'',
      tvr_type:'',
      reference_name:'',
      reference_mobile:'',
      loan_id:'',
      unique_tvr:'',
      created_by:null,
      created_by_name:'',
      is_insurer_ascend:false,
      battery_warranty:null,
      user_id:context?.user?.user_id,
      lead_id:null,
      is_edit:false
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });

  async function submitForm(value){
    setDisable(true)
    formik.values.loan_id=lead?.loan_id;
    formik.values.customer_name=lead?.name;
    formik.values.source='partners_web';
    formik.values.unique_tvr = value.tvr_type+'-'+value.relation_id
    formik.values.created_by = context?.user?.los_staff_id
    formik.values.created_by_name = context?.user?.user_name
    formik.values.is_insurer_ascend = false
    formik.values.user_id = context?.user?.user_id
    formik.values.lead_id=lead?.lead_id
    formik.values.is_edit=false
    const memberDetails = members?.filter(member=>member.relation_id===value.relation_id) 
    formik.values.reference_name= !isEmpty(memberDetails) ? memberDetails[0].name:''
    try{
        const data = new FormData();
        const files = []
        if(!isEmpty(tvrFile)) {
            for(let doc of tvrFile) {
                files.push({fileType: FILE_UPLOAD_TYPE.TVR_RECORDING,file: doc})
            }
        }
        data.append('data',JSON.stringify(formik.values));
        if(!isEmpty(files)){
            files.forEach((file) => {
                data.append(file.fileType,file.file);
            })
        }
      const response = await axios.post(`${API_URL}/loan/${lead.loan_id}/tvr`, data);
      if(response?.data?.status === 'success'){
        setAddForm(false)
        setProgress(0)
        setTvrFile([])
        await getTvrDetails()
        alert('Added Successfully')
        formik.resetForm()
      }
      setDisable(false)
    }
    catch(err){
      return err
    } 
  }


  const getTvrDetails=async()=>{
    const result= await axios.get(`${API_URL}/loan/${lead.loan_id}/tvr`)
    if(result?.data?.status === 'success'){
      setValue(result?.data?.data);
      setFilteredValue(result?.data?.data)
    }
  }


  useEffect(() => {
    async function getLeadData(){
    const res= await axios.get(`${API_URL}/leads/${id}`)
    if(res?.data?.status === 'success'){
      SetLead(res?.data?.data);
        const result= await axios.get(`${API_URL}/loan/${res?.data?.data?.loan_id}/tvr`)
            if(result?.data?.status === 'success'){
                setValue(result?.data?.data);
                setFilteredValue(result?.data?.data)
            }
            if(isMobile){
                TABLE_HEAD.length = 4
            }else{
                TABLE_HEAD.length = 7
            }
        }
    }
    getLeadData()
  },[id]);

  useEffect(() => {
    async function getLoanMembers(){ 
      if(lead){
        const res= await axios.get(`${API_URL}/customer/loan/${lead?.loan_id}/loan_members`)
        if(res?.data?.status === 'success'){
          setMembers(res?.data?.data);
        }
      } 
    }
    getLoanMembers()
  },[lead]);

  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
        formik.resetForm()
    }
    },[])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (e) => {
    if(e.target.name === 'reference_name'){
      referenceName = e.target.value
    }
    else if(e.target.name === 'reference_mobile'){
      referenceMobile =e.target.value
    }  
    filteredValue = value.filter(v => ((v?.reference_name || '').toLowerCase()).includes((referenceName).toLowerCase()) && ((v?.reference_mobile || '').toLowerCase()).includes((referenceMobile).toLowerCase()))
    setFilteredValue([...filteredValue])
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - value?.length) : 0;

  const filteredUsers = applySortFilter(filteredValue, getComparator(order, orderBy));
  const isUserNotFound = value?.length === 0;

  const handleTvrFile = (e) => {
    setTvrFile(Object.values(e.target.files))
    e.target.files.length > 0 ? setProgress(100) : setProgress(0)
  }

  const onFileUpload=async(files=[])=>{
    const data = new FormData();
    if(!isEmpty(files)){
      files?.forEach((file) => {
        file?.file?.forEach((doc=>{
          data.append(file.fileType,doc);
        }))
      })
    }
    const body = {...selectedTvr,tvr_type:selectedTvr.type,is_edit:true,user_id:context?.user?.user_id,lead_id:lead?.lead_id}
    data.append('data',JSON.stringify(body));
    const response = await axios.post(`${API_URL}/loan/${lead.loan_id}/tvr`, data);
      if(response?.data?.status === 'success'){
        await getTvrDetails()
        setUploadModel(false)
        setTvrFile([])
        alert('Uploaded Successfully')
      }
  }

  const openModel=(data)=>{
    setSelectedTvr(data)
    setUploadModel(true)
  }

  const downloadDocument=async(docId)=>{
    if(docId){
      const response = await axios.get(`${API_URL}/loan/${lead?.loan_id}/doc/${docId}`)
      const data = get(response,'data.data','')
      if(!isEmpty(data)){
        const url = data?.tempFilePaths[0]?.tempUrl
        window.open(url,'_blank')
      }
    }
    else{
      alert('No Document Found. Please Upload File')
    }
  }

  useEffect(() => {
    async function getMemberMobile(){ 
      const memberDetails = members.filter(member=>member.relation_id===formik.values.relation_id)
      if(!isEmpty(memberDetails)){
        if(!isEmpty(get(memberDetails,'[0].mobile',''))){
          formik.setFieldValue('reference_mobile',memberDetails[0].mobile)
          setmobilePresent(true)
        }else{
          formik.setFieldValue('reference_mobile','')
          setmobilePresent(false)
        } 
      }
    }
    getMemberMobile()
  },[formik.values.relation_id,members]);

  return (
    <div>
      <div style={{marginTop:10,marginBottom:10}}>
      <Stack direction="row" sx={{marginBottom:'6px',marginLeft:'30px'}} alignItems="center" justifyContent="center" mb={5}>
        <LoadingButton size="large" color='secondary' variant="contained" onClick={()=>setAddForm(true)}><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i>Create Tvr</LoadingButton>
      </Stack>
      </div>
      {addForm &&<div>
      <Dialog
       id={`nach_form`}
       anchorEl={addForm}
       open={addForm}
       placement="bottom-start"
       maxWidth={'lg'}
       onClose={()=> setAddForm(false)}
      >
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{paddingLeft:'20px',marginTop:5}} variant="h4" gutterBottom>
        Add New TVR
      </Typography>
      <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              autoComplete='off'
              type={'text'}
              inputProps={{ maxLength: 18}}
              name="customer_name"
              label="Customer Name"
              value={lead?.name}
              disabled={true}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="relation_type"
                id="relation_type"
                name="relation_type"
                label="Relation Type*"
                value={formik.values.relation_type}
                onChange={formik.handleChange}
                error={formik.touched.relation_type && Boolean(formik.errors.relation_type)}
                helpertext={formik.touched.relation_type && formik.errors.relation_type}
              >
                {
                  Object.values(TVR_TYPE).map((type,i)=>{
                    return <MenuItem value={type}>{type}</MenuItem>
                  })
                }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="relation_id"
                id="relation_id"
                name="relation_id"
                label="Member *"
                value={formik.values.relation_id}
                onChange={formik.handleChange}
                error={formik.touched.relation_id && Boolean(formik.errors.relation_id)}
                helpertext={formik.touched.relation_id && formik.errors.relation_id}
              >{  
                members?.map((member,i)=>{
                  return <MenuItem key={i} value={member.relation_id}>{`${member.name}-${member.relation_type}-${member.relation}`}</MenuItem>
                })
              }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              type={'number'}
              autoComplete='off'
              name="reference_mobile"
              label="Customer Mobile"
              value={formik.values.reference_mobile}
              onChange={formik.handleChange}
              disabled={mobilePresent}
              error={formik.touched.reference_mobile && Boolean(formik.errors.reference_mobile)}
              helperText={formik.touched.reference_mobile && formik.errors.reference_mobile}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="tvr_type"
                id="tvr_type"
                name="tvr_type"
                label="TVR Type*"
                value={formik.values.tvr_type}
                onChange={formik.handleChange}
                error={formik.touched.tvr_type && Boolean(formik.errors.tvr_type)}
                helpertext={formik.touched.tvr_type && formik.errors.tvr_type}
              >
                <MenuItem value="pre_disbursement">Pre Disbursement</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              type={'number'}
              autoComplete='off'
              name="loan_amount"
              label="Loan Amount"
              value={formik.values.loan_amount}
              onChange={formik.handleChange}
              error={formik.touched.loan_amount && Boolean(formik.errors.loan_amount)}
              helperText={formik.touched.loan_amount && formik.errors.loan_amount}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              type={'number'}
              autoComplete='off'
              name="tenure"
              label="Tenure (months)"
              value={formik.values.tenure}
              onChange={formik.handleChange}
              error={formik.touched.tenure && Boolean(formik.errors.tenure)}
              helperText={formik.touched.tenure && formik.errors.tenure}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
            <TextField
              fullWidth
              type={'number'}
              autoComplete='off'
              name="emi_amount"
              label="Emi Amount"
              value={formik.values.emi_amount}
              onChange={formik.handleChange}
              error={formik.touched.emi_amount && Boolean(formik.errors.emi_amount)}
              helperText={formik.touched.emi_amount && formik.errors.emi_amount}
            />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                fullWidth
                select
                labelId="battery_warranty"
                id="battery_warranty"
                name="battery_warranty"
                label="Battery Warranty"
                value={formik.values.battery_warranty}
                onChange={formik.handleChange}
                error={formik.touched.battery_warranty && Boolean(formik.errors.battery_warranty)}
                helpertext={formik.touched.battery_warranty && formik.errors.battery_warranty}
              >
                {
                  Object.values(BATTERY_WARRANTY).map((type,i)=>{
                    return <MenuItem value={type}>{type}</MenuItem>
                  })
                }
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="first_emi_date"
                type={'Date'}
                className={formik.values.first_emi_date === null && Styles.dates}
                label="First Emi Date"
                value={formik.values.first_emi_date}
                onChange={formik.handleChange}
                error={formik.touched.first_emi_date && Boolean(formik.errors.first_emi_date)}
                helperText={formik.touched.first_emi_date && formik.errors.first_emi_date}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{textAlign: 'center',marginTop: '20px'}}>
            <div style={{display: 'flex',justifyContent: 'center'}}>
                <label htmlFor="doc">
                  <Input accept="audio/*" id="doc" type="file" onChange={handleTvrFile} />
                  <Iconify icon="entypo:upload" sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                </label>
                <div>
                  <LinearProgress variant='determinate' value={progress} sx={{width: '200px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                  <Typography>Upload Tvr Recording {tvrFile?.length > 0 && <CheckCircleIcon sx={{fontSize: 20}} color="success" />}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={disable}
              loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        </form>
     </Dialog>
    </div>}
    <Card>
    <Scrollbar>
    <TableContainer sx={{ minWidth: 100 }}>
        <Table size='small'>
        <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={value?.length}
            onRequestSort={handleRequestSort}
        />
        <TableBody>
        <TableRow>
            <TableCell align="center"><TextField className={Styles.filter} name='reference_name' onChange={handleFilter} autoComplete='off' /></TableCell>
            <TableCell align="center"><TextField className={Styles.filter} name='reference_mobile' onChange={handleFilter} autoComplete='off' /></TableCell>
            </TableRow>
            {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v,index)=>{
                return (
                <TableRow
                    hover
                    key={index}
                    tabIndex={-1}
                    role="checkbox"
                >                          
                    <TableCell align="center">{v?.reference_name}</TableCell>
                    <TableCell align="center">{v?.reference_mobile}</TableCell>
                    <TableCell align="center">{v?.type}</TableCell>
                    <TableCell align="center">{v?.verified ? 'yes' :'no'}</TableCell>
                    {!isMobile && <>
                    <TableCell align="center">{v?.remarks}</TableCell>
                    <TableCell align="left"><div style={{display:'flex',justifyContent:'space-between'}}><Button variant="contained" size="small" style={{color: 'white',backgroundColor: 'green'}} onClick={()=>openModel(v)}><Iconify icon="entypo:upload" sx={{color: 'white',fontSize: '15px',cursor: 'pointer',marginRight:1}} />Upload Tvr</Button></div></TableCell>
                    <TableCell align="left"><div style={{display:'flex',justifyContent:'space-between'}}><Button variant="contained" size="small" style={{color: 'white',backgroundColor: 'green'}} onClick={()=>downloadDocument(v?.doc_id)}><Iconify icon="entypo:download" sx={{color: 'white',fontSize: '15px',cursor: 'pointer',marginRight:1}} />Download Tvr</Button></div></TableCell>
                    </>}
                    <TableCell align="right">
                </TableCell>
                </TableRow>
                );
            })}
            {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
            </TableRow>
            )}
        </TableBody>
        {isUserNotFound && (
            <TableBody>
            <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                <SearchNotFound searchQuery={filterName} />
                </TableCell>
            </TableRow>
            </TableBody>
        )}
        </Table>
    </TableContainer>
    </Scrollbar>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={value?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </Card>
    {<Dialog
       id={`documents`}
       open={uploadModel}
       anchorEl={uploadModel}
       placement="bottom-start"
       fullWidth
       onClose={()=> setUploadModel(false)}
     >
       <ClickAwayListener id={`documents`} name={`documents`} onClickAway={() => setUploadModel(false)}>
          <FileUpload docType={[FILE_UPLOAD_TYPE.TVR_RECORDING]} doc_type={FILE_UPLOAD_TYPE.TVR_RECORDING} onClose={()=> setUploadModel(false)} submit={(filePath)=>onFileUpload(filePath)} />
       </ClickAwayListener>
     </Dialog>}
    </div>
  );
}
