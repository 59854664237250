import { useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton, ToggleButtonGroup, ToggleButton } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
import axios from 'axios';
import { API_URL } from '../../constant';
import { LoginContext } from '../../context/logincontext';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const context = useContext(LoginContext)
  const [userData,setUserData]=useState(context.user);
  const isActive = context?.user?.company?.is_active
  const navigate = useNavigate()
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [userCompany,setUserCompany]=useState()
  const [menu,setMenu]=useState()

  const MENU_OPTIONS = [
    {
      label: 'Home',
      icon: 'eva:home-fill',
      linkTo: '/'
    },
    {
      label: 'Profile',
      icon: 'eva:person-fill',
      linkTo: '/dashboard/user_company'
    },
    // {
    //   label: 'Settings',
    //   icon: 'eva:settings-2-fill',
    //   linkTo: '#'
    // }
  ];
  

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    const get=async()=>{
      const data = await axios.get(`${API_URL}/user/company/${userData.user_id}`)
      const datas=data?.data?.data
      setUserCompany(datas)
    }
    if(isActive){
      setMenu(MENU_OPTIONS)
    }else{
      const OPTIONS = MENU_OPTIONS.filter(Menu => Menu.label !=='Profile')
      setMenu(OPTIONS)
    }
    get()
    },[userCompany?.company_id])

  

  const logout=async()=>{
    await axios.get(`${API_URL}/logout`)
    window.location = '/login';
    /* if(alert('Do you really want to log out?')){
      await axios.get(`${API_URL}/logout`)
      navigate('/', { replace: true });
    } */
  }

  // useEffect(()=>{
  //   let finalSideBarData = sidebarConfig
  //   if(!isAdminRole(context.user) && (context.user.is_active)){
  //     finalSideBarData = sidebarConfig.filter(array => array.title !== 'Admin')
  //   }
  //   else if(!(context.user.is_active)){
  //     finalSideBarData=[]
      
  //   }


  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" sx={{fontSize:'20px', fontWeight:'bold' }} noWrap>
            {userData.user_name}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'black', fontWeight:'normal' }} noWrap>
            {userData.company.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userData.mobile}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {menu?.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Iconify
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
