import { filter, isEmpty } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Link,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL} from '../constant';
import AddTransaction from './AddTransaction';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { isMobile } from 'react-device-detect';
import CsvDownloader from 'react-csv-downloader';
import { useFormik } from 'formik';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    if(isNaN(query)){
      return filter(array, (_user) => _user?.other_party_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }else{
      return filter(array, (_user) => _user?.txn_ref_no?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ExpenseList(props) {
  const {id , type} = useParams()
  const navigate=useNavigate()
  const [page, setPage] = useState(0);
  const[value,setValue]=useState([]);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [company, setCompany] = useState();
  const [counterPartyId, setCounterPartyId] = useState(undefined)
  const[isClick,setClick]=useState(false)
  const [invoiceAmount,setInvoiceAmount] = useState(0)
  const [counterParty,setCounterParty] = useState([])
  const [filteredArray,setFilteredArray] = useState([])

  const formik = useFormik({
    initialValues: {
      counter_party_name: '',
    }
  })
  
  const TABLE_HEAD = [
    { id: 'counter_party_id', label: 'Counter Party Name', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
    { id: 'payment_mode', label: 'Payment Mode ', alignRight: false },
    { id: 'txn_date', label: 'Transaction Date', alignRight: false },
    { id: 'txn_ref_no', label: 'Transaction Ref No.', alignRight: false },
  ];

  let balance=0

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };

  useEffect(() => {
    async function get() {
      const res= await axios.get(`${API_URL}/transactions`);
      const data = res?.data?.data
      const result = await axios.get(`${API_URL}/counter_party`);
      console.log(result);
      if(result.data.status === 'success') {
        setCounterParty([...result.data.data])
      }
      
      if(id){
        const salesTransaction=[]
        const purchaseTransaction = [];
        for (const list of data){
          if(Number(id)===Number(list.ref_id)){
            if(type === 'Sales'){
              salesTransaction.push(list)
            }else{
              purchaseTransaction.push(list)
            }
            
          }
        }
        if(type === 'Sales'){
          const res= await axios.get(`${API_URL}/sales/${id}`);
          const salesData = res?.data?.data
          setInvoiceAmount(salesData?.total_amount)
          setValue(salesTransaction)
        setFilteredArray(salesTransaction)

        }else{
          const res= await axios.get(`${API_URL}/purchase/${id}`);
          const purchaseData = res?.data?.data
          setInvoiceAmount(purchaseData?.total_amount)
          setValue(purchaseTransaction)
        setFilteredArray(purchaseTransaction)

        }
      }else if(!isEmpty(props)){
        const finacerData = data.filter(d=> d?.other_party_type === props.other_type)
        setValue(finacerData)
        setFilteredArray(finacerData)
      }else{
        setValue(data)
        setFilteredArray(data)

      }
    }
    get()
  },[props.other_type]);

  if(isMobile){
    TABLE_HEAD.length = 3
  }else{
    TABLE_HEAD.length = 7
  }
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const redirect=()=>{
    navigate(`/dashboard/transaction/${id}/${type}`, { replace: true });
  }

  

 const handleCounterParty=(e)=>{
  setCounterPartyId(e.target.value);
 }
 useEffect(()=>{
   if(counterPartyId){
     setFilteredArray(()=>{
       return value.filter(data => counterPartyId === data.counter_party_id)
     }) 
   }
 }, [counterPartyId])
  

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(filteredArray, getComparator(order, orderBy), filterName);

const isUserNotFound = value.length === 0;

  const netBalance=filteredUsers.filter((v)=>{
    return v.type==="expense"
  } )
  
  netBalance.map((v)=>{
    balance=balance+ Number(v.amount)
  })
  
  

  return (
    <Page>
     
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
                Expense List
          </Typography>
          <Grid item xs={11} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="counter_party_name">Select Counter Party </InputLabel>
                <Select
                  fullWidth
                  labelId='counter_party_name'
                  id="counter_party_name"
                  name="counter_party_name"
                  label="Select Counter Party"
                  value={counterPartyId}
                  onChange={e => handleCounterParty(e)}
                  error={formik.touched.counter_party_name && Boolean(formik.errors.counter_party_name)}
                  helpertext={formik.touched.counter_party_name && formik.errors.counter_party_name}
                >
                  {
                  counterParty?.map((v,i)=>{
                    return(
                    <MenuItem value={v?.counter_party_id}>{v?.name}</MenuItem>
                    )
                  })
                    
                }
                  
                </Select>
              </FormControl>
            </Grid>

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Link variant="subtitle2" component={RouterLink} to={`/dashboard/expense/expense`} underline="none" style={{paddingRight:'10px'}}>
            <LoadingButton size="large" color='secondary' variant="contained"style={{whiteSpace: "break-spaces"}} ><Icon icon="icon-park-outline:transaction"/> Add Expense</LoadingButton>
          </Link>
          <CsvDownloader
            filename={((id)&&`${type} Transaction Report`) || (!isEmpty(props) && `${props.other_type} Transaction Report`) || 'All Transaction Report'}
            extension=".csv"
            separator=";"
            wrapColumnChar=""
            columns={TABLE_HEAD}
            datas={value}>
            <LoadingButton size="large" color='secondary' type="submit" variant="contained"><i className="bi bi-bar-chart-fill"></i>Export Report</LoadingButton>
            </CsvDownloader>
          </Stack>
        </Stack>
        {type && <div style={{paddingBottom:'50px'}}><Card>
          <div style={{textAlign:'left',paddingLeft:'30px',paddingTop:'5px'}}>Invoice Amount</div><div style={{textAlign:'right',paddingRight:'30px',position:'relative',bottom:'20px'}}>{invoiceAmount}</div>
        </Card></div>}
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  { netBalance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((v,index)=>{
                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                          role="checkbox"
                        //   selected={isItemSelected}
                        //   aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                            //   checked={isItemSelected}
                              onChange={(event) => handleClick(event, v.po_number)}
                            />
                          </TableCell> */}
                         
                           <TableCell align="left">{v?.counter_party_name}</TableCell>
                           <TableCell align="left">{v?.category}</TableCell>
                           <TableCell align="left">{v?.amount}</TableCell>
                           {!isMobile && <>
                          <TableCell align="left">{v?.payment_mode}</TableCell>
                          <TableCell align="left">{v?.txn_date}</TableCell>
                          <TableCell align="left">{v?.txn_ref_no}</TableCell>
                         
                          </>}
                          <TableCell align="right">
                            <UserMoreMenu linkTo={`/dashboard/expense/${v.transaction_id}/expense/edit`}
                             />
                          </TableCell>
                         
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          
        </Card>
        <div style={{paddingTop:'50px'}}><Card>
          <div style={{textAlign:'left',paddingLeft:'30px',paddingTop:'5px'}}>Balance</div><div style={{textAlign:'right',paddingRight:'30px',position:'relative',bottom:'20px'}}>{balance}</div>
          
        </Card></div>
      </Container>
    </Page>
  );
}