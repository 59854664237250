import * as yup from 'yup';
import {useNavigate,useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {MenuItem,Select,TextField,Typography,FormControl,InputLabel,LinearProgress,styled} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL,NumberToWordConverter} from '../constant';
import {useContext,useEffect,useState} from 'react';
import {LoginContext} from '../context/logincontext';
import Iconify from '../components/Iconify';
import Styles from '../css/dates.module.css'
import {isEmpty} from 'lodash';
import ConfirmDialog from '../components/confirmDialog';
// ----------------------------------------------------------------------


const validationSchema = yup.object({

});

const Input = styled('input')({
  display: 'none',
});

export default function EditTransaction() {
  const {id,type} = useParams()
  const context = useContext(LoginContext)
  const navigate = useNavigate();
  const [userData] = useState(context.user);
  const [docs,setDocs] = useState(undefined)
  const [isActive,setIsActive] = useState(true)
  const [file,setFile] = useState()
  const [financers,setFinancers] = useState([])
  const [suppliers,setSuppliers] = useState([])
  const [customers,setCustomers] = useState([])
  const [insurer,setInsurer] = useState([])
  const [menu,setMenu] = useState([])
  const [progress,setProgress] = useState({
    doc: 0,
  })
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const formik = useFormik({
    initialValues: {
      type: type,
      amount: '',
      payment_mode: '',
      txn_date: '',
      txn_ref_no: '',
      ref_id: id,
      remarks: '',
      other_party_id: '',
      other_party_type: '',
      updated_by: userData.user_id,
    },
    validationSchema,
    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });
  async function submitForm(value) {
    value.user_id = userData.user_id
    if(!isActive) {
      value.is_active = isActive
    }
    const data = new FormData();
    const files = []
    if(!isEmpty(docs)) {
      for(let doc of docs) {
        files.push({fileType: "transaction_document",file: doc})
      }
    }
    data.append('data',JSON.stringify(value));
    files.forEach((file) => {
      data.append(file.fileType,file.file);
    })
    const response = await axios.put(`${API_URL}/transaction/${id}`,data);
    if(response.data.status === 'success') {
      if(type) {
        navigate(`/dashboard/transactions/${id}/${type}`,{replace: true});
      }
      else {
        navigate('/dashboard/all_transactions',{replace: true});
      }
    }
  }
  const uploadDocuments = (e,type) => {
    if(type) {
      setDocs(Object.values(e.target.files))
      e.target.files.length > 0 ? setProgress({...progress,doc: 100}) : setProgress({...progress,doc: 0})
    }
  }

  const getFileDownLoad = (type) => {
    return `${API_URL}/transaction/${id}/docType/${type}/download`
  }

  const hideTransaction = () => {
    setIsActive(false)
  }
  useEffect(() => {
    async function get() {
      const res = await axios.get(`${API_URL}/financer`);
      if(res.data.status === 'success') {
        const financerData = res?.data?.data
        setFinancers([...financerData])
      }
      const res1 = await axios.get(`${API_URL}/vendor`);
      if(res1.data.status === 'success') {
        const supplierData = res1?.data?.data
        setSuppliers([...supplierData])
      }
      const res2 = await axios.get(`${API_URL}/data/leads`);
      if(res2.data.status === 'success') {
        const customerData = res2?.data?.data
        setCustomers([...customerData])
      }
      const res3 = await axios.get(`${API_URL}/insurers`);
      if(res3.data.status === 'success') {
        const insurerData = res3?.data?.data
        setInsurer([...insurerData])
      }
      const transactionData = await axios.get(`${API_URL}/transaction/${id}`);
      if(transactionData.data.status === 'success') {
        formik.setFieldValue('amount',transactionData?.data?.data?.amount)
        formik.setFieldValue('payment_mode',transactionData?.data?.data?.payment_mode)
        formik.setFieldValue('txn_date',transactionData?.data?.data?.txn_date ? transactionData.data.data.txn_date : null)
        formik.setFieldValue('remarks',transactionData?.data?.data?.remarks)
        formik.setFieldValue('txn_ref_no',transactionData?.data?.data?.txn_ref_no)
        formik.setFieldValue('other_party_id',transactionData?.data?.data?.other_party_id)
        formik.setFieldValue('other_party_type',transactionData?.data?.data?.other_party_type)
       setFile(transactionData?.data?.data?.transaction_document);
      }
    }
    get()
  },[]);
  useEffect(() => {
    if(financers && suppliers && insurer && customers) {
      switch(formik.values.other_party_type) {
        case 'Financer':
          setMenu(() => financers.map(value => <MenuItem value={value?.financer_id}>{value?.financer_name}</MenuItem>))
          break;
        case 'Vendor':
          setMenu(() => suppliers.map(value => <MenuItem value={value?.vendor_id}>{value?.vendor_name}</MenuItem>))
          break;
        case 'Insurer':
          setMenu(() => insurer.map(value => <MenuItem value={value?.insurer_id}>{value?.insurer_name}</MenuItem>))
          break;
        case 'Customer':
          setMenu(() => customers.map(value => <MenuItem value={value?.lead_id}>{value?.name}</MenuItem>))
          break;
        default:
          break;
      }
    }
  },[formik.values.other_party_type])
  const submitFormValue = () => {
    submitForm(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <div>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{paddingLeft: '20px'}} variant="h4" gutterBottom>
        Edit Transaction
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{flexGrow: 1,p: 2}}>
          <Grid container spacing={2} justifyContent='center'>

            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="amount"
                type={'text'}
                label="Amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={Boolean(formik.values.amount) && NumberToWordConverter.convert(formik.values.amount)}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="payment_mode">Payment Mode</InputLabel>
                <Select
                  fullWidth
                  labelId='payment_mode'
                  id="payment_mode"
                  name="payment_mode"
                  label="Payment Mode"
                  value={formik.values.payment_mode}
                  onChange={formik.handleChange}
                  error={formik.touched.payment_mode && Boolean(formik.errors.payment_mode)}
                  helpertext={formik.touched.payment_mode && formik.errors.payment_mode}
                >
                  <MenuItem value={'UPI'}>UPI</MenuItem>
                  <MenuItem value={'CASH'}>CASH</MenuItem>
                  <MenuItem value={'CHEQUE'}>CHEQUE</MenuItem>
                  <MenuItem value={'NEFT'}>NEFT</MenuItem>
                  <MenuItem value={'IMPS'}>IMPS</MenuItem>
                  <MenuItem value={'DD'}>DD</MenuItem>
                  <MenuItem value={'NACH'}>NACH</MenuItem>
                  <MenuItem value={'CREDIT NOTE'}>CREDIT NOTE</MenuItem>
                  <MenuItem value={'DEBIT NOTE'}>DEBIT NOTE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="txn_date"
                type={'Date'}
                className={formik.values.txn_date === null && Styles.dates}
                label="Transaction Date"
                value={formik.values.txn_date}
                onChange={formik.handleChange}
                error={formik.touched.txn_date && Boolean(formik.errors.txn_date)}
                helperText={formik.touched.txn_date && formik.errors.txn_date}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="txn_ref_no"
                type={'text'}
                label="Reference No."
                value={formik.values.txn_ref_no}
                onChange={formik.handleChange}
                error={formik.touched.txn_ref_no && Boolean(formik.errors.txn_ref_no)}
                helperText={formik.touched.txn_ref_no && formik.errors.txn_ref_no}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="other_party_type">Other Party Type</InputLabel>
                <Select
                  fullWidth
                  labelId="other_party_type"
                  id="other_party_type"
                  name="other_party_type"
                  label="Other Party Type"
                  value={formik.values.other_party_type}
                  onChange={formik.handleChange}
                  error={formik.touched.other_party_type && Boolean(formik.errors.other_party_type)}
                  helpertext={formik.touched.other_party_type && formik.errors.other_party_type}
                >
                  <MenuItem value={'Customer'}>Customer</MenuItem>
                  <MenuItem value={'Insurer'}>Insurer</MenuItem>
                  <MenuItem value={'Financer'}>Financer</MenuItem>
                  <MenuItem value={'Vendor'}>Vendor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                select
                fullWidth
                id="other_party_id"
                name="other_party_id"
                label="Other Party Name"
                value={formik.values.other_party_id}
                onChange={formik.handleChange}
                error={formik.touched.other_party_id && Boolean(formik.errors.other_party_id)}
                helpertext={formik.touched.other_party_id && formik.errors.other_party_id}
              >{menu}
              </TextField>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                autoComplete='off'
                fullWidth
                name="remarks"
                type={'text'}
                label="Remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helperText={formik.touched.remarks && formik.errors.remarks}
              />
            </Grid>

            <Grid item xs={6} sm={6} sx={{textAlign: 'center'}}>

              <div style={{display: 'flex',justifyContent: 'center',marginTop: '20px'}}>
                <label htmlFor="transaction_document">
                  <Input accept="image/*,application/pdf" id="transaction_document" type="file" multiple onChange={(e) => uploadDocuments(e,"transaction_document")} />
                  <Iconify icon="entypo:upload" sx={{color: '#000',fontSize: '25px',cursor: 'pointer',verticalAlign: 'middle',marginRight: '10px'}} />
                </label>
                <div>
                  <LinearProgress variant='determinate' value={progress.file} sx={{width: '160px',margin: 'auto',height: '5px',marginTop: '5px'}} />
                  <Typography>Payment Receipt  {file?.length > 0 && <CheckCircleIcon sx={{fontSize: 20}} color="success" />} </Typography>
                </div>
                {file && <div>
                  {file?.length > 0 &&
                    <Iconify icon="entypo:download" sx={{color: 'green',fontSize: '25px',cursor: 'pointer',marginLeft: '10px',verticalAlign: 'middle'}} onClick={() => window.open(getFileDownLoad('transaction_document'),'_blank')} />
                  }
                </div>}

              </div>
              <div>
                {docs?.map(doc => <span style={{fontSize: '10px'}}>{doc.name}, </span>)}
              </div>
            </Grid>

          </Grid>
        </Box>
        <Box sx={{m: 2}}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={4}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                name='submit'
                // onClick={()=>setState('draft')}
                variant="contained"
                loading={formik.isSubmitting}>
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={8} sm={4}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                name='delete'
                onClick={hideTransaction}
                variant="contained"
                color="error"
              >
                Delete
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}