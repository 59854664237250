import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Link,
  Paper,
  TextField
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL, windowSize} from '../constant';
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import Styles from '../css/filter.module.css'
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'part_name', label: 'Name', alignRight: false },
  { id: 'hsn', label: 'HSN/SAC', alignRight: false },
  { id: 'qty', label: 'Quantity', alignRight: false },
  { id: 'price', label: 'Unit Price', alignRight: false },
  { id: 'category', label: 'Model Type', alignRight: false },
  { id: 'min_qty', label: 'Min Qty', alignRight: false },
  { id: 'units', label: 'Units', alignRight: false },
  { id: 'gst_percent', label: 'GST %', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.part_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

  let partName=''
  let category=''

export default function PartList() {
  const [page, setPage] = useState(0);
  const[value,setValue]=useState([]);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let [filteredValue,setFilteredValue] = useState([]);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = USERLIST.map((n) => n.name);
    //   setSelected(newSelecteds);
    //   return;
    // }
    setSelected([]);
  };

  useEffect(() => {
    async function get() {
      const res= await axios.get(`${API_URL}/parts`);
      setValue(res?.data?.data);
      setFilteredValue(res?.data?.data)
    }
    if(isMobile){
      TABLE_HEAD.length = 3
    }else{
      TABLE_HEAD.length = 8
    }
    get()
  },[]);
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilter = (e) => {
    if(e.target.name === 'part_name'){
      partName = e.target.value
    }
    else if(e.target.name === 'category'){
      category =e.target.value
    }
    filteredValue = value.filter(v => ((v?.part_name || '').toLowerCase()).includes((partName).toLowerCase()) && ((v?.category || '').toLowerCase()).includes((category).toLowerCase()))
    setFilteredValue([...filteredValue])
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - value.length) : 0;

  const filteredUsers = applySortFilter(filteredValue, getComparator(order, orderBy));

  const isUserNotFound = value.length === 0;
  
  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Model List
          </Typography>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard/product" underline="none">
            <LoadingButton size="large" color='secondary' variant="contained"><i style={{paddingRight:'10px'}} className="bi bi-cart4"></i> Add Model</LoadingButton>
          </Link>
        </Stack>

        <Card style={{width: '100%'}}>

          <Scrollbar >
            <TableContainer sx={{minWidth:100}}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={value.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableRow>
                <TableCell align="left"><TextField className={Styles.filter} name='part_name' onChange={handleFilter} autoComplete='off' /></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="left"><TextField className={Styles.filter} name='category' onChange={handleFilter} autoComplete='off' /></TableCell>
                </TableRow>
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v,index)=>{
                      return (
                        <TableRow
                          hover
                           key={index}
                          tabIndex={-1}
                          role="checkbox"
                        //   selected={isItemSelected}
                        //   aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                            //   checked={isItemSelected}
                              onChange={(event) => handleClick(event, v.po_number)}
                            />
                          </TableCell> */}
                          <TableCell align="left">{v.part_name}</TableCell>
                          <TableCell align="left">{v.hsn}</TableCell>
                          <TableCell align="left">{v.qty}</TableCell>
                          {!isMobile&& <>
                          <TableCell align="left">{v.price}</TableCell>
                          <TableCell align="left">{v.category}</TableCell>
                          <TableCell align="left">{v.min_qty}</TableCell>
                          <TableCell align="left">{v.units}</TableCell>
                          <TableCell align="left">{v.gst_percentage}</TableCell>
                          </>}
                          <TableCell align="right">
                            <UserMoreMenu linkTo={`/dashboard/product/${v.part_id}/edit`}
                            view={`/dashboard/user/view-parts/${v.part_id}`}
                             />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
