import {filter, isEmpty, upperCase} from 'lodash';
import {sentenceCase} from 'change-case';
import {useEffect,useState} from 'react';
import {Link as RouterLink,useNavigate,useParams} from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    TextField,
    Button
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead,UserListToolbar} from '../sections/@dashboard/user';
import axios from 'axios';
import {API_URL, LOAN_TYPE} from '../constant';
import moment from 'moment';
import Styles from '../css/filter.module.css'
import { isMobile } from 'react-device-detect';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------
 
const TABLE_HEAD1 = [
    {id: 'loan_id',label: 'Loan Id'},
    {id: 'customer_name',label: 'Customer Name'},
    {id: 'type',label: 'Type'},
    {id: 'state',label: 'State'},
    {id: 'amount',label: 'Loan Amount'},
    {id: 'emi',label: 'EMI'},
    {id: 'emi_due',label: 'EMI Due'},
    {id: 'tenure',label: 'Tenure'},
    {id: 'rc_payment_status',label: 'RC Payment Status'},
];

const TABLE_HEAD2 = [
    {id: 'loan_id',label: 'Loan Id'},
    {id: 'customer_name',label: 'Customer Name'},
    {id: 'type',label: 'Type'},
    {id: 'state',label: 'State'},
    {id: 'amount',label: 'Amount'},
    {id:'created_at',label:'Loan Created Date'}
];
// ----------------------------------------------------------------------

function descendingComparator(a,b,orderBy) {
    if(b[orderBy] < a[orderBy]) {
        return -1;
    }
    if(b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order,orderBy) {
    return order === 'desc'
        ? (a,b) => descendingComparator(a,b,orderBy)
        : (a,b) => -descendingComparator(a,b,orderBy);
}

function applySortFilter(array,comparator,query) {
    const stabilizedThis = array?.map((el,index) => [el,index]);
    stabilizedThis?.sort((a,b) => {
        const order = comparator(a[0],b[0]);
        if(order !== 0) return order;
        return a[1] - b[1];
    });

    if(query) {
        if(isNaN(query)) {
            const filteringArray = array.filter(user => user?.loan !== null)
            return filter(filteringArray,(_user) => _user?.loan?.customer_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
        }
        else {
            const filteringArray = array.filter(user => user?.loan_id !== null)
            return filter(filteringArray,(_user) => ((_user?.loan_id).toString()).toLowerCase().indexOf(query.toLowerCase()) !== -1)
        }
    }
    return stabilizedThis.map((el) => el[0]);
}

let loanId = ''
let name=''
let loanType=''
let rcStatus = ''
let loanState = ''
let filterValues = []

export default function LoanList() {
    const {state} = useParams()
    const [page,setPage] = useState(0);
    const [value,setValue] = useState([]);
    const [order,setOrder] = useState('asc');
    const [selected,setSelected] = useState([]);
    const [orderBy,setOrderBy] = useState('name');
    const [filterName,setFilterName] = useState('');
    const [rowsPerPage,setRowsPerPage] = useState(25);
    const [filteredValue,setFilteredValue] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleRequestSort = (event,property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        setSelected([]);
    };

    useEffect(() => {
        async function get(state) {
            const dealerLoan = await axios.get(`${API_URL}/dealer_loans/${state}`);
            if(dealerLoan.data.status === 'success'){
                const dealerLoans = (dealerLoan.data.data)?.reverse();
                setValue([...dealerLoans]);
                setFilteredValue([...dealerLoans]);
            }
        }
        if(isMobile){
            TABLE_HEAD1.length = 3
            TABLE_HEAD2.length = 3
        }
        get(state)
    },[state,isMobile]);

    useEffect(() => {
        async function getLeadData(){
            if(!isEmpty(value)){
                const leadData= await axios.get(`${API_URL}/leads/${value[1]?.loan_id}`)
            }
        }
    getLeadData()
    }, [])
    
    const handleChangePage = (event,newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value,10));
        setPage(0);
    };

    const handleFilter = (e) => {
        setPage(0)
        const filteringArray = value.filter(user => user?.loan_id !== null)
        if(e.target.name === 'loan_id'){
            loanId = e.target.value
        }
        else if(e.target.name === 'customer_name'){
            name = e.target.value
        }
        else if(e.target.name === 'state') {
            loanState = e.target.value
        }
        else if(e.target.name === 'rc_status') {
            rcStatus = e.target.value
        }
        else if(e.target.name === 'type') {
            loanType = e.target.value
        }
        filterValues = filter(filteringArray,(_user) => (((_user?.loan_id).toString()).toLowerCase().indexOf((loanId || '').toLowerCase()) !== -1 && _user?.customer?.name?.toLowerCase().indexOf((name || '').toLowerCase()) !== -1 && _user?.state?.toLowerCase().indexOf((loanState || '').toLowerCase()) !== -1 && (_user?.dealer_snapshot?.is_rc_payment_done ? "Completed" : 'Pending').toLowerCase().indexOf((rcStatus || '').toLowerCase()) !== -1 && _user?.type?.toLowerCase().indexOf((loanType || '').toLowerCase()) !== -1))
        setFilteredValue([...filterValues])
      };

    const emptyRows = page > 0 ? Math.max(0,(1 + page) * rowsPerPage - value.length) : 0;

    const filteredUsers = applySortFilter(filteredValue,getComparator(order,orderBy),filterName);
    const isUserNotFound = value.length === 0;

    const redirect= async (id)=>{
        const leads = await axios.get(`${API_URL}/data/leads`);
        if(leads.data.status === 'success'){
            let leadsData = leads?.data?.data
            if(leadsData){
                leadsData = leadsData.filter(lead=>lead?.loan_id === id)
                leadsData = leadsData[0]
                if(leadsData){
                    const lead_id = leadsData.lead_id
                    navigate(`/dashboard/lead/${lead_id}/status`)
                }else{
                    enqueueSnackbar(`No lead for this Loan id: ${id} `, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
                }
            }else{
                enqueueSnackbar('Empty lead', { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
            }
        }else{
            enqueueSnackbar('Something went worng', { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
        }
    }

    return (
        <Page>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {state ===LOAN_TYPE.DISBURSED_LOANS ? 'Disbursed Loans List' : 'Pending Loans List'}
                    </Typography>
                </Stack>
                
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{width: 'auto'}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={state===LOAN_TYPE.DISBURSED_LOANS ? TABLE_HEAD1 : TABLE_HEAD2}
                                    rowCount={value.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableRow>
                                 <TableCell align="center" width={'150px'}><TextField type={'search'} variant='standard' name = 'loan_id' onChange={handleFilter} autoComplete='off' /></TableCell>
                                <TableCell align="center" width={'200px'}><TextField type={'search'} variant='standard' name='customer_name' onChange={handleFilter} autoComplete='off' /></TableCell>
                                <TableCell align="center" ><TextField type={'search'} variant='standard' name = 'type' onChange={handleFilter} autoComplete='off' /></TableCell>
                                <TableCell align="center" ><TextField type={'search'} variant='standard' name = 'state' onChange={handleFilter} autoComplete='off' /></TableCell>
                                <TableCell align='center' width={'150px'}></TableCell>
                                <TableCell align='center'></TableCell>
                                <TableCell align='center'></TableCell>
                                <TableCell align='center'></TableCell>
                                <TableCell align="center"><TextField type={'search'} variant='standard' name = 'rc_status' onChange={handleFilter} autoComplete='off' /></TableCell>
                                </TableRow>
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage).map((v,index) => { 
                                        return (
                                            <TableRow
                                                hover
                                                key={index}
                                            >
                                                <TableCell align="center" style={{cursor:'pointer',color:'blue'}} onClick={()=>redirect(v?.loan_id)}>{v?.loan_id}</TableCell>
                                                <TableCell align="center">{v?.customer?.name}</TableCell>
                                                <TableCell align="center">{v?.type}</TableCell>
                                                {!isMobile && 
                                                <>
                                                <TableCell align="center" padding='none'>{v?.state}</TableCell>
                                                {state !== LOAN_TYPE.DISBURSED_LOANS && <>
                                                <TableCell align="center">{Number(v?.amount)}</TableCell>
                                                <TableCell align="center">{moment(v?.created_at).format('DD-MM-YYYY')}</TableCell>
                                                </>}
                                                {state === LOAN_TYPE.DISBURSED_LOANS && 
                                                <>
                                                 <TableCell align="center">{Number(v?.amount)}</TableCell>
                                                <TableCell align="center">{v?.emi}</TableCell>
                                                <TableCell align="center">{Number(v?.loan_snapshot?.total_interest_due) + Number(v?.loan_snapshot?.total_principal_due)}</TableCell>
                                                <TableCell align="center">{v?.tenure}</TableCell>
                                                <TableCell align="center">{v?.dealer_snapshot?.is_rc_payment_done ? 'Completed' : 'Pending'}</TableCell>
                                                </>}
                                                </>}
                                              
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[25,50,100,500]}
                        component="div"
                        count={value.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
