import React,{useContext,useState,useEffect} from 'react'
import * as yup from 'yup';
import {useFormik} from 'formik';
import {Alert, Stack, TextField, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {LoginContext} from '../../../../context/logincontext';
import axios from 'axios';
import {API_URL, NumberToWordConverter} from '../../../../constant';
import ConfirmDialog from '../../../../components/confirmDialog';
import {LoadingButton} from '@mui/lab';
import {LeadContext} from '../../../../context/leadContext';
import publicIp from 'public-ip';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { get, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

const aadhaarRegex = /^([0-9]){4}([0-9]){4}([0-9]){4}?$/
const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/

const validationSchema = yup.object({
    aadhaar_no: yup.string().matches(aadhaarRegex,'Aadhaar number is not valid').required('This field is required').nullable(true),
    pan: yup.string().matches(panRegex,'Pan number is not valid').required('This field is required').nullable(true),
    address: yup.string().required('This field is required').nullable(true),
    occupation_type: yup.string().required('This field is required').nullable(true),
    residential_status: yup.string().required('This field is required').nullable(true),
    monthly_income: yup.number().required('This field is required').nullable(true),
    city: yup.string().required('This field is required').nullable(true),
    loan_amount_requested: yup.string().required('This field is required').nullable(true),
    pin_code: yup.string().min(6,'pin code not less than 6 digits').required('This field is required').nullable(true),
    city_state: yup.string().required('This field is required').nullable(true),
});

function Index(props) {
    const context = useContext(LoginContext)
    let {id} =useParams()
    const {leadId,setAlignment,setMeassage,setIsOpen,setSeverity,customerRelationData, setCustomerRelationData} = useContext(LeadContext)
    const { enqueueSnackbar } = useSnackbar();
    const [pin,setPin] = useState('')
    const [open,setOpen] = useState(false)
    const [formvalue,setFormValue] = useState({})
    const [panVerified,setPanVerified] = useState(false)
    const [aadharVerified,setAadharVerified] = useState(false)
    const [otp,setOpt] = useState(undefined)
    const [verifyAadhar,setVerifyAadhar] = useState(false)
    const [verifyPanButton,setVerifyPanButton] = useState(false)
    const [isOtpSend,setIsOtpSend] = useState(false)
    const [inCorrectOtp,setInCorrectOtp] = useState(false)
    const [showAadhaarAlert,setShowAadharAlert] = useState(false)
    const [lead,setLead] = useState({})
    const [aadhaarText, setAadhaarText] = useState('')
    const [submitButtonName, setSubmitButtonName] = useState('')
    const [closeButtonName, setCloseButtonName] = useState('')
    const navigate=useNavigate()
    const formik = useFormik({
        initialValues: {
            email: '',
            aadhaar_no: '',
            pan: '',
            address: '',
            city: '',
            occupation_type: '',
            residential_status: '',
            monthly_income: '',
            user_id: context?.user?.user_id,
            company_id: context?.user?.company_id,
            // dealer_id: context?.user?.company?.dealer_id,
            loan_amount_requested: '',
            pin_code: '',
            city_state: '',
        },
        validationSchema: isEmpty(props.name) ? validationSchema : '',
        onSubmit: async (value) => {
            if(pin.length > 5) {
                value.pin_code = pin
            }
            if(isEmpty(props?.name)){
                setFormValue({...value})
            }else{
                setFormValue({...value,state : value?.state})
            }
            setOpen(true)
        }
    });
    async function submitForm(value) {
        value={...value,to_los:true}
        const data = new FormData();
        data.append('data',JSON.stringify(value));
        if(leadId) {
            const response = await axios.post(`${API_URL}/lead/update/${leadId}`,data);
            if(response.data.status === 'success') {
                if(!aadhaarText){
                    if(lead?.aadhaar_no){
                        enqueueSnackbar('Successfully update KYC details', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                    }else{
                        enqueueSnackbar('Successfully added KYC details', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                    }
                    await getLeadData(leadId)
                    aadhaarSubmit()
                }else{
                    await getLeadData(leadId)
                }
            }
        }else if(!isEmpty(props.name)){
            const datas = new FormData();
            datas.append('data',JSON.stringify({relation_id: customerRelationData?.relation_id, address_id : customerRelationData?.address_id,...customerRelationData, ...value}));
            const response = await axios.post(`${API_URL}/lead`,datas);
            if(response.data.status === 'success') {
                const relationData = response.data.data
                setCustomerRelationData({...relationData.relation,...relationData.address})
                enqueueSnackbar('Successfully added KYC details', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                setAlignment("documents")
            }
        }
         else {
            enqueueSnackbar('First fill the profile details', { variant:'info',anchorOrigin:{vertical:'top',horizontal:'center'} });
            setAlignment('details')
        }
    }
    const handlePinChange = async (e) => {
        setPin(e.target.value)
        formik.setFieldValue('pin_code',e.target.value)
        if((e.target.value).length > 5) {
            const response = await axios.get(`${API_URL}/get_city_by_pin/${e.target.value}`);
            if(response.data.status === 'success') {
                formik.setFieldValue('pin_code',e.target.value)
                formik.setFieldValue('city',response?.data?.data?.district_name)
                formik.setFieldValue('city_state',response?.data?.data?.state_name)
            } else {
                formik.setFieldValue('pin_code',e.target.value)
            }
        } else {
            formik.setFieldValue('city','')
        }
    }
    const closeDialog = (isVerify) => {
        setOpen(false);
        if(aadhaarText){
            setAadhaarText('')
            setCloseButtonName('')
            setSubmitButtonName('')
            if(!isVerify){
                setAlignment("documents")
            }
        }
    };
    const submitFormValue = async() => {
        if(aadhaarText){
            await submitForm({...lead,to_los:true})
            await verifyAadhaar()
            if(!panVerified){
                if(!verifyPanButton){
                    await verifyPan()
                }
            }
        }else{
            submitForm(formvalue)
            setOpen(false);
        }
    };
    const getLeadData = async (leadId) => {
        const data = await axios.get(`${API_URL}/leads/${leadId}`)
        if(data.data.status === 'success') {
            const leadData = data.data.data
            setFieldData(leadData)
            setPanVerified(leadData?.is_pan_verified)
            setAadharVerified(leadData?.is_aadhaar_verified)
            setLead(leadData)
        }
    }

    const setFieldData = (data) =>{
        formik.setFieldValue('aadhaar_no',data?.aadhaar_no || data?.aadhar_no)
            formik.setFieldValue('pan',data?.pan)
            formik.setFieldValue('email',data?.email)
            formik.setFieldValue('address',data?.address || data?.landmark)
            formik.setFieldValue('city',data?.city)
            formik.setFieldValue('occupation_type',data?.occupation_type || '')
            formik.setFieldValue('residential_status',data?.residential_status || '')
            formik.setFieldValue('monthly_income',data?.monthly_income || 0)
            formik.setFieldValue('loan_amount_requested',data?.loan_amount_requested || 0)
            formik.setFieldValue('pin_code',data?.pin_code || data?.pincode)
            formik.setFieldValue('city_state',data?.city_state)
            if(props?.name){
                formik.setFieldValue('residential_status',data?.ownership_type === 'rent' ? 'rented' : 'own house')
                formik.setFieldValue('city_state',data?.state)
            }
            setPin(data?.pin_code || data?.pincode || '')
    }

    const verifyPan = async () => {
        setVerifyPanButton(true)
        try{
            const pan = await axios.post(`${API_URL}/verify_pan/${leadId}`)
            if(pan.data.status === 'success') {
                setPanVerified(true)
                enqueueSnackbar('Your pan details verify successfully', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
            }
        }catch(err){
            enqueueSnackbar('Something went wrong check your pan details and verify after some time !', { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
        }
        getLeadData(leadId)
    }

    const verifyAadhaar = async (click=false) => {
        setVerifyAadhar(true)
        if(!aadharVerified) {
            const ipaddress = await publicIp.v4()
            const data = {
                aadhar: formik.values.aadhaar_no,
                mobile: lead.mobile,
                name: lead.name,
                ip_address: ipaddress,
                id: lead?.los_lead_id,
                lead_id: leadId,
                type: 'aadhar'
            }
            if(otp) {
                data.otp = otp
            }
            try{
                const aadharVerify = await axios.post(`${API_URL}/verify_aadhaar`,data)
                if(aadharVerify.data.status === 'success') {
                    closeDialog(true)
                    if(otp) {
                        setAadharVerified(true)
                        enqueueSnackbar('Your Aadhaar details verify successfully', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                        setIsOtpSend(false)
                    } else {
                        if(aadharVerify?.data?.data?.is_aadhaar_verified) {
                            setAadharVerified(true)
                            enqueueSnackbar('Your Aadhaar details verify successfully', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                        } else {
                            setIsOtpSend(true)
                        }
                    }
                }
            }
            catch(err){
                enqueueSnackbar('Something went wrong check your aadhar number and verify after some time !', { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
                if(!click){
                    closeDialog(false)
                    setAlignment("documents")
                }
            }
            
        } else {
            setInCorrectOtp(true)
            setShowAadharAlert(true)
            setTimeout(() => {
                setShowAadharAlert(false)
            },2000)
        }
        getLeadData(leadId)
    }
    const optStyle = {
        position: 'fixed',
        background: '#eee',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '99',
        padding: '100px',
        borderRadius: '10px'
    }

    const ColorButton = styled(Button)(({theme}) => ({
        color: '#fff',
        backgroundColor: '#0089ff',
        '&:hover': {
            backgroundColor: '#0275d8',
        },
    }));

    useEffect(() => {
        if(leadId) {
            getLeadData(leadId)
        }else if(!isEmpty(customerRelationData) && props?.name){
            if(!isEmpty(customerRelationData)){
                if(!isEmpty(customerRelationData?.city)){
                    setFieldData(props?.data)
                }else{
                    setFieldData(customerRelationData)
                }
            }else{
                setFieldData(props?.data)
            }
        }
    },[leadId,props])
    useEffect(() => {
        return () => {
            formik.resetForm()
        }
    },[])

   useEffect(() => {
        if(!isEmpty(props?.name)){
            setFieldData({...customerRelationData})
            if(get(props?.data,'aadhaar_details[0].document_detail_id','')){
                const relationAadhaarDetails = get(props?.data,'aadhaar_details[0]',{})
                if(relationAadhaarDetails?.verified === 'yes' || relationAadhaarDetails?.is_verified_by_karza){
                    setAadharVerified(true)
                }
            }
            if(get(props?.data,'pan_details[0].document_detail_id','')){
                const relationPanDetails = get(props?.data,'pan_details[0]',{})
                if(relationPanDetails?.verified === 'yes' || relationPanDetails?.is_verified_by_karza){
                    setPanVerified(true)
                }
            }
        }
    },[customerRelationData])

    const relationAadhaarVerified = async() =>{
        const relationData = get(customerRelationData,'aadhaar_details[0]',{})
        setVerifyAadhar(true)
        if(!aadharVerified) {
            const ipaddress = await publicIp.v4()
            const data = {
                ...relationData,
                aadhar: relationData?.aadhaar_no,
                mobile: relationData?.mobile,
                name: relationData?.name,
                ip_address: ipaddress,
                id: relationData?.document_detail_id,
                relation_id: relationData?.relation_id,
                type: 'aadhar'
            }
            if(otp) {
                data.otp = otp
            }
            const aadharVerify = await axios.post(`${API_URL}/relation/verify_aadhaar`,data)
            if(get(aadharVerify, 'data.status', '') === 'success') {
                closeDialog(false)
                if(otp) {
                    setAadharVerified(true)
                    enqueueSnackbar('Your Aadhaar details verify successfully', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                    setIsOtpSend(false)
                } else {
                    if(get(aadharVerify, 'data.data.is_verified_by_karza', false)) {
                        setAadharVerified(true)
                        enqueueSnackbar('Your Aadhaar details verify successfully', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                    } else {
                        setIsOtpSend(true)
                    }
                }
            }
        } else {
            setInCorrectOtp(true)
            setShowAadharAlert(true)
            setTimeout(() => {
                setShowAadharAlert(false)
            },2000)
        }
    }

    const relationVerifyPan = async () => {
        const relationData = get(customerRelationData,'pan_details[0]',{})
        setVerifyPanButton(true)
        const pan = await axios.post(`${API_URL}/relation/verify_pan`,relationData)
        if(pan.data.status === 'success') {
            setPanVerified(true)
            enqueueSnackbar('Your Pan details verify successfully', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
        }
    }

    const aadhaarSubmit = () =>{
        if(!isOtpSend){
            if(!aadharVerified){
                if(!verifyAadhar){
                    setAadhaarText(`Do you want to verify aadhaar and pan details ?`)
                    setCloseButtonName('No')
                    setSubmitButtonName('Yes')
                    setOpen(true)
                }else{
                    setAlignment("documents")
                }
            }else{
                setAlignment("documents")
            }
        }
    }

    return (
        <>
        {isOtpSend &&
                <div style={{...optStyle,height: '100%',width: '100%',zIndex: 10,backgroundColor: '#000000c7'}}>
                    <div style={optStyle}>
                        <div>
                            <Typography variant="h5" gutterBottom sx={{textAlign: 'center'}}>
                                Enter Aadhaar OTP
                            </Typography>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                name="otp"
                                required
                                type="Otp Number"
                                inputProps={{maxLength: 6}}
                                value={!isNaN(Number(otp)) ? otp : ''}
                                onChange={(e) => {
                                    setOpt(e.target.value)
                                    setInCorrectOtp(false)
                                }}
                            />
                            {inCorrectOtp &&
                                <div style={{textAlign: 'center',color: 'red'}}>Enter correct OTP</div>
                            }
                            <div style={{display: 'flex'}}>
                                <Button variant="contained" sx={{mt: 1,boxShadow: 'none',alignSelf: 'center',display: 'block',marginLeft: 'auto',marginRight: 'auto',width: 'max-content'}} onClick={()=>(!props?.name && ((id || lead?.lead_id) && lead?.aadhaar_no)) ? verifyAadhaar() : relationAadhaarVerified()}>
                                    Submit
                                </Button>
                                <Button variant="contained" sx={{mt: 1,boxShadow: 'none',background: 'red',alignSelf: 'center',display: 'block',marginLeft: 'auto',marginRight: 'auto',width: 'max-content'}} onClick={() => setIsOtpSend(false)}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showAadhaarAlert &&
                <Stack sx={{width: '100%'}} spacing={2}>
                    <Alert severity="success"><strong>Your Aadhaar is already verified !</strong></Alert>
                </Stack>
            }
            <ConfirmDialog text={aadhaarText} subText={aadhaarText ? [`Your aadhaar number : ${formik.values.aadhaar_no}`,`Your pan number : ${formik.values.pan}`] : []} closeButton={closeButtonName} submitButton={submitButtonName} open={open} onClose={()=>closeDialog(false)} submit={submitFormValue} close={()=>closeDialog(false)} />
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{flexGrow: 1,p: 2}}>
                    <Grid container spacing={2} justifyContent='left'>
                        <Grid item xs={11} sm={6} style={{position: 'relative'}}>
                            <TextField
                                fullWidth
                                required
                                disabled={aadharVerified}
                                autoComplete='off'
                                inputProps={{maxLength: 12}}
                                name="aadhaar_no"
                                label="Aadhaar Number"
                                value={!isNaN(Number(formik.values.aadhaar_no)) ? formik.values.aadhaar_no : ''}
                                onChange={formik.handleChange}
                                error={formik.touched.aadhaar_no && Boolean(formik.errors.aadhaar_no)}
                                helperText={formik.touched.aadhaar_no && formik.errors.aadhaar_no} 
                            />
                            {(((!props?.name && ((id || lead?.lead_id) && lead?.aadhaar_no)) || get(props?.data,'aadhaar_details[0].document_detail_id','')) && aadharVerified) &&
                                    <CheckCircleIcon style={{position: 'absolute',right:'1%',zIndex: 9,bottom:'1%',transform: 'translate(-10%, -30%)'}} sx={{fontSize: 40}} color="success" />
                            }
                            {(((!props?.name && ((id || lead?.lead_id) && lead?.aadhaar_no)) || get(props?.data,'aadhaar_details[0].document_detail_id','')) && !aadharVerified) &&
                                <ColorButton disabled={verifyAadhar} variant="contained" sx={{mt: 1,boxShadow: 'none',alignSelf: 'end',display: 'block',marginLeft: 'auto',marginRight: 'auto',width: 'max-content'}} style={{position: 'absolute',right:'1%',zIndex: 9,top: '45%',transform: 'translate(-10%, -30%)'}} onClick={()=> !props?.name ? verifyAadhaar(true) : relationAadhaarVerified()}>
                                    Verify Aadhar
                                </ColorButton>
                            }
                        </Grid>
                        
                        <Grid item xs={11} sm={6} style={{position: 'relative'}}>
                            <TextField
                                fullWidth
                                required
                                disabled={panVerified}
                                autoComplete='off'
                                name="pan"
                                label="Pan Number"
                                placeholder='ABCDE1234F'
                                inputProps={{maxLength: 10,style: {textTransform: "uppercase"}}}
                                onBlur={() => formik.setFieldValue('pan',(formik.values.pan).toUpperCase())}
                                value={formik.values.pan}
                                onChange={formik.handleChange}
                                error={formik.touched.pan && Boolean(formik.errors.pan)}
                                helperText={formik.touched.pan && formik.errors.pan}
                            />
                            {(((!props?.name && ((id || lead?.lead_id) && lead?.pan)) || get(props?.data,'pan_details[0].document_detail_id','')) && panVerified) &&
                                <CheckCircleIcon style={{position: 'absolute',right:'1%',zIndex: 9,top: '45%',transform: 'translate(-10%, -30%)'}} sx={{fontSize: 40}} color="success" />
                            }
                            {(((!props?.name && ((id || lead?.lead_id) && lead?.pan)) || get(props?.data,'pan_details[0].document_detail_id','')) && !panVerified) &&
                                <ColorButton disabled={verifyPanButton} variant="contained" sx={{mt: 1,boxShadow: 'none',alignSelf: 'end',display: 'block',marginLeft: 'auto',marginRight: 'auto',width: 'max-content'}} style={{position: 'absolute',right:'1%',zIndex: 9,top: '45%',transform: 'translate(-10%, -30%)'}} onClick={ !props?.name ? verifyPan : relationVerifyPan}>
                                    Verify Pan
                                </ColorButton>
                            }  
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                required
                                name="address"
                                label="Address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                required
                                name="pin_code"
                                label="Pin Code"
                                inputProps={{maxLength: 6}}
                                value={!isNaN(Number(pin)) ? pin : ''}
                                onChange={handlePinChange}
                                error={formik.touched.pin_code && Boolean(formik.errors.pin_code)}
                                helperText={formik.touched.pin_code && formik.errors.pin_code}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                required
                                name="city"
                                label="City"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                required
                                name="city_state"
                                label="State"
                                value={formik.values.city_state}
                                onChange={formik.handleChange}
                                error={formik.touched.city_state && Boolean(formik.errors.city_state)}
                                helperText={formik.touched.city_state && formik.errors.city_state}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                select
                                fullWidth
                                required
                                labelId="residential_status"
                                id="residential_status"
                                name="residential_status"
                                label="Residential Status"
                                value={formik.values.residential_status}
                                onChange={formik.handleChange}
                                error={formik.touched.residential_status && Boolean(formik.errors.residential_status)}
                                helpertext={formik.touched.residential_status && formik.errors.residential_status}
                            >
                                <MenuItem value={'rented'}>Rented</MenuItem>
                                <MenuItem value={'own house'}>Own House</MenuItem>
                            </TextField>
                        </Grid>
                        {isEmpty(props.name) && <>
                            <Grid item xs={11} sm={6}>
                            <TextField
                                fullWidth
                                required
                                select
                                labelId="occupation_type"
                                id="occupation_type"
                                name="occupation_type"
                                label="Occupation Type"
                                value={formik.values.occupation_type}
                                onChange={formik.handleChange}
                                error={formik.touched.occupation_type && Boolean(formik.errors.occupation_type)}
                                helpertext={formik.touched.occupation_type && formik.errors.occupation_type}
                            >
                                <MenuItem value={'BUSINESS'}>BUSINESS</MenuItem>
                                <MenuItem value={'SALARIED'}>SALARIED</MenuItem>
                                <MenuItem value={'OTHERS'}>OTHERS</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                fullWidth
                                required
                                inputMode='numeric'
                                autoComplete='off'
                                inputProps={{maxLength: 12}}
                                name="monthly_income"
                                type={'number'}
                                label="Monthly Income"
                                value={formik.values.monthly_income}
                                onChange={formik.handleChange}
                                error={formik.touched.monthly_income && Boolean(formik.errors.monthly_income)}
                                helperText={(formik.touched.monthly_income && formik.errors.monthly_income) || (Boolean(formik.values.monthly_income) && NumberToWordConverter.convert(formik.values.monthly_income))}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                required
                                inputMode='numeric'
                                type={'number'}
                                name="loan_amount_requested"
                                label="Loan Amount Requested"
                                value={formik.values.loan_amount_requested}
                                onChange={formik.handleChange}
                                error={formik.touched.loan_amount_requested && Boolean(formik.errors.loan_amount_requested)}
                                helperText={(formik.touched.loan_amount_requested && formik.errors.loan_amount_requested) || (Boolean(formik.values.loan_amount_requested) && NumberToWordConverter.convert(formik.values.loan_amount_requested))}
                            />
                        </Grid>
                        </>}
                        <Grid item xs={11} sm={6}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                name="email"
                                type="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{m: 2}}>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={8} sm={6}>
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </LoadingButton>

                        </Grid>
                    </Grid>
                </Box>
            </form>
        </>
    )
}

export default Index